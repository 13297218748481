import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { Button, Drawer, Title } from 'rizzui';
import { toast } from 'sonner';
import Spinner from '../../../components/bootstrap/Spinner';
import CustomSelect from '../../../components/forms/CustomSelect';
import RequiredInput from '../../../components/forms/InputHOC';
import StatusDropdown from '../../../components/forms/StatusDropdown';
import Icon from '../../../components/icon/Icon';
import useOrderStatuses from '../../../hooks/api-calls/orders/useOrderStatuses';
import useOrderTypes from '../../../hooks/api-calls/orders/useOrderTypes';
import useHandleErrors from '../../../hooks/useHandleErrors';
import { OrderService } from '../../../services/order/orderService';
import { getLastDayOfNextMonth, subtractSevenDays } from '../../../utils/getFormattedDate';

interface OrderCanvasProps {
    order: any;
    isOpen: boolean;
    setOpen: (status: boolean) => void;
}

const OrderCanvas: React.FC<OrderCanvasProps> = ({ order, isOpen, setOpen }) => {

    // HOOKS

    const { getOrderTypesList } = useOrderTypes();
    const { handleErrors } = useHandleErrors();
    const { getOrderStatusesList } = useOrderStatuses();

    // STATES

    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    // FORMIK

    const formik = useFormik({
        initialValues: {
            orderId: order?.id || '',
            orderTypeId: order?.orderType?.id || '',
            startDate: order?.start ?? '',
            endDate: order?.end ?? '',
            collectionDate: order?.collectionDate ?? '',
            orderDate: order?.orderDate ?? '',
        },
        onSubmit: values => { values.orderId ? handleEdit(values) : handleCreate(values) },
    });

    // FUNCTIONS

    //-------------------------------------------------------------------------------------------------------------------------------
    /**
     * @ES CONTROLA EL ENVÍO DEL FORMULARIO PARA CREAR UN PEDIDO
     * @EN CONTROLS THE FORM SUBMISSION TO CREATE AN ORDER
     */
    //-------------------------------------------------------------------------------------------------------------------------------
    const handleCreate = async (values: any) => {
        setIsLoading(true);
        try {
            const response = (await (new OrderService()).createOrder(values)).getResponseData();
            if (response.success) {
                setOpen(false);
                formik.resetForm();
                toast.success("Pedido creado correctamente");
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error("Error al crear el pedido");
        } finally {
            setIsLoading(false);
        }
    };
    //-------------------------------------------------------------------------------------------------------------------------------

    //-------------------------------------------------------------------------------------------------------------------------------
    /**
     * @ES CONTROLA EL ENVÍO DEL FORMULARIO PARA EDITAR UN PEDIDO
     * @EN CONTROLS THE FORM SUBMISSION TO EDIT AN ORDER
     */
    //-------------------------------------------------------------------------------------------------------------------------------
    const handleEdit = async (values: any) => {
        setIsLoading(true);
        try {
            const response = (await (new OrderService()).editOrder(values)).getResponseData();
            if (response.success) {
                setOpen(false);
                formik.resetForm();
                toast.success("Pedido editado correctamente");
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error("Error al editar el pedido");
        } finally {
            setIsLoading(false);
        }
    };
    //-------------------------------------------------------------------------------------------------------------------------------

    //-------------------------------------------------------------------------------------------------------------------------------
    /**
     * @ES CONTROLA EL CAMBIO DE ESTADO DE UN PEDIDO
     * @EN CONTROLS THE CHANGE OF ORDER STATUS
     */
    //-------------------------------------------------------------------------------------------------------------------------------
    const handleChangeStatus = async (orderId: string, statusId: string) => {
        try {
            const response = await (await (new OrderService).toggleOrderState(orderId, statusId)).getResponseData();
            if (response.success) {
                toast.success('Estado del pedido cambiado correctamente');
            } else {
                handleErrors(response);
            }
        } catch (error) {
            handleErrors(error);
        }
    };
    //-------------------------------------------------------------------------------------------------------------------------------

    // USE EFFECTS

    useEffect(() => {
        if (order) {
            formik.setValues({
                orderId: order?.id || '',
                orderTypeId: order?.orderType?.id || '',
                startDate: order?.start ?? '',
                endDate: order?.end ?? '',
                collectionDate: order?.collectionDate ?? '',
                orderDate: order?.orderDate ?? '',
            });
        }
    }, [order]);

    /**
     * @ES CONTROLA EL CAMBIO DE FECHAS PARA ACTUALIZAR LOS VALORES DE LAS FECHAS DE PEDIDO Y COBRO CUANDO SE ESTÁ CREANDO UN PEDIDO
     * @EN CONTROLS THE DATE CHANGE TO UPDATE THE ORDER AND COLLECTION DATES VALUES WHEN CREATING AN ORDER
     */
    useEffect(() => {
        if (!formik.values.orderId) {
            // Set orderDate 7 days before startDate
            if (formik.values.startDate && !formik.values.orderDate) {
                formik.setFieldValue('orderDate', subtractSevenDays(new Date(formik.values.startDate)));
            }

            // Set collectionDate to the last day of the next month of endDate
            if (formik.values.endDate && !formik.values.collectionDate) {
                formik.setFieldValue('collectionDate', getLastDayOfNextMonth(new Date(formik.values.endDate)));
            }
        }
    }, [formik.values.startDate, formik.values.endDate]);

    // RENDER

    return (
        <React.Suspense fallback={<div className='text-center'><Spinner /></div>}>
            <Drawer
                size="md"
                isOpen={isOpen}
                onClose={() => { setOpen(false); formik.resetForm() }}
                overlayClassName="dark:bg-opacity-40 dark:backdrop-blur-[3px]"
                containerClassName="dark:bg-gray-100 overflow-auto"
                className="z-[999]"
            >
                <div className="flex flex-col p-5">
                    <div className="-mx-5 flex items-center justify-between border-b border-muted px-5 pb-4">
                        <Title as="h5">Pedido {order?.code}</Title>
                        {(order?.id && order?.currentStatus && order?.currentStatusDate) && (
                            <div className='flex flex-col gap-2 justify-content-center text-center' key={order?.id + order?.currentStatus.id}>
                                <StatusDropdown
                                    entityId={order?.id}
                                    title="Se cambiará el estado del pedido"
                                    statesOptions={getOrderStatusesList()}
                                    currentState={order?.currentStatus}
                                    currentStateDate={order?.currentStatusDate}
                                    handleStateChange={(entityId: string, statusId: string) => handleChangeStatus(order?.id, statusId)}
                                    hasComments={false}
                                />
                            </div>
                        )}
                        <Icon icon='Close' className='h-5 w-5 cursor-pointer ms-3' onClick={() => { setOpen(false); formik.resetForm() }} />
                    </div>
                </div>

                <form onSubmit={formik.handleSubmit} className='grid grid-cols-2 px-3 gap-4'>
                    <CustomSelect
                        isSearchable
                        isClearable
                        id={'orderTypeId'}
                        label="Tipo de Pedido"
                        value={getOrderTypesList()?.find((g: any) => g.value === formik.values.orderTypeId)}
                        options={getOrderTypesList()}
                        onChange={(e: any) => { formik.setFieldValue('orderTypeId', e?.value) }}
                        required
                        error={formik.errors.orderTypeId}
                        containerClassName='col-span-2 md:col-span-2'
                    />

                    <RequiredInput
                        id='startDate'
                        type="date"
                        className="md:col-span-1"
                        label="Fecha de inicio"
                        formik={formik}
                    />

                    <RequiredInput
                        id='endDate'
                        type="date"
                        className="md:col-span-1"
                        label="Fecha de fin"
                        formik={formik}
                    />

                    <RequiredInput
                        id='collectionDate'
                        required={false}
                        type="date"
                        className="md:col-span-1"
                        label="Fecha de cobro"
                        formik={formik}
                    />

                    <RequiredInput
                        id='orderDate'
                        required={false}
                        type="date"
                        className="md:col-span-1"
                        label="Fecha de pedido"
                        formik={formik}
                    />

                    <div className="col-span-2 mt-7">
                        <Button
                            type='submit'
                            className='w-full'
                            disabled={isLoading}
                        >
                            Guardar
                        </Button>
                    </div>
                </form>
            </Drawer>
        </React.Suspense>
    );
};

export default OrderCanvas;