import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import CustomSelect from '../../components/forms/CustomSelect';
import RequiredInput from '../../components/forms/InputHOC';
import useOrderTypes from "../../hooks/api-calls/orders/useOrderTypes";
import Page from '../../layout/Page/Page';
import FormGroup from '../../layout/shared/form-group';
import { menuRoutes } from '../../router/menu';
import { Order } from "../../type/entities/order-type";
import { getLastDayOfNextMonth, subtractSevenDays } from '../../utils/getFormattedDate';
import FormFooter from '../_layout/_footers/form-footer';

interface OrderFormProps {
    isLoading: boolean;
    submit: Function;
    data?: Order | undefined;
}

const schema = yup.object({
    orderTypeId: yup.string().required('El tipo de pedido es obligatorio'),
    startDate: yup.string().required('La fecha de inicio es obligatoria'),
    endDate: yup.string().required('La fecha de fin es obligatoria'),
    collectionDate: yup.string().nullable(),
    orderDate: yup.string().nullable(),
});

const OrderForm: React.FC<OrderFormProps> = ({ isLoading, submit, data }) => {

    const mode = data ? 'Editar' : 'Crear';
    const navigate = useNavigate();
    const { getOrderTypesList } = useOrderTypes();

    const formik = useFormik({
        initialValues: {
            orderId: data?.id || '',
            orderTypeId: data?.orderTypes?.id || '',
            startDate: data?.startDate?.date.split(' ')[0] ?? '',
            endDate: data?.endDate?.date.split(' ')[0] ?? '',
            collectionDate: data?.collectionDate?.date.split(' ')[0] ?? '',
            orderDate: data?.orderDate?.date.split(' ')[0] ?? '',
        },
        validationSchema: schema,
        onSubmit: values => { submit(values) },
    });

    /**
     * Set orderDate and collectionDate when startDate and endDate are set
     */
    useEffect(() => {
        // Set orderDate 7 days before startDate
        if (formik.values.startDate && !formik.values.orderDate) {
            formik.setFieldValue('orderDate', subtractSevenDays(new Date(formik.values.startDate)));
        }

        // Set collectionDate to the last day of the next month of endDate
        if (formik.values.endDate && !formik.values.collectionDate) {
            formik.setFieldValue('collectionDate', getLastDayOfNextMonth(new Date(formik.values.endDate)));
        }
    }, [formik.values.startDate, formik.values.endDate]);

    const getContent = () => {
        return (
            <div className="@container">
                <form onSubmit={formik.handleSubmit}>
                    <div className="grid gap-7 divide-y-2 divide-dashed divide-gray-200 font-medium">
                        <FormGroup
                            title="Información General"
                            description="Datos principales"
                            titleCols="@md:col-span-2"
                            childCols="@md:col-span-10 md:grid-cols-12"
                        >
                            <CustomSelect
                                isSearchable
                                isClearable
                                id={'orderTypeId'}
                                label="Tipo de Pedido"
                                value={getOrderTypesList()?.find((g: any) => g.value === formik.values.orderTypeId)}
                                options={getOrderTypesList()}
                                onChange={(e: any) => { formik.setFieldValue('orderTypeId', e?.value) }}
                                required
                                error={formik.errors.orderTypeId}
                                containerClassName='md:col-span-12'
                            />

                            <RequiredInput
                                id='startDate'
                                type="date"
                                className="md:col-span-3"
                                label="Fecha de inicio"
                                formik={formik}
                            />

                            <RequiredInput
                                id='endDate'
                                type="date"
                                className="md:col-span-3"
                                label="Fecha de fin"
                                formik={formik}
                            />

                            <RequiredInput
                                id='collectionDate'
                                required={false}
                                type="date"
                                className="md:col-span-3"
                                label="Fecha de cobro"
                                formik={formik}
                            />

                            <RequiredInput
                                id='orderDate'
                                required={false}
                                type="date"
                                className="md:col-span-3"
                                label="Fecha de pedido"
                                formik={formik}
                            />
                        </FormGroup>
                    </div>
                </form>
            </div>
        )
    };

    return (
        <>
            <Page container="fluid">
                {(mode === "Editar" && data) && getContent()}
                {mode === "Crear" && getContent()}
            </Page>
            <FormFooter
                submitBtnText={mode + ' Pedido'}
                handleCancelBtn={() => navigate(menuRoutes.orders.path + menuRoutes.orders.list as string)}
                handleSubmitBtn={formik.submitForm}
                isLoading={isLoading}
            />
        </>
    );
};

export default OrderForm;