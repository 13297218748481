import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import AttivaCard from '../../../components/card/AttivaCard';
import PageHeader from '../../../layout/shared/page-header';
import { menuRoutes } from '../../../router/menu';
import { StudentService } from '../../../services/student/StudentService';
import StudentForm from '../StudentForm';

const pageHeader = {
    title: 'Crear Empleado',
    breadcrumb: [
        {
            href: menuRoutes.students.path,
            name: 'Empleados',
        },
        {
            name: 'Crear',
        },
    ],
};

export default function StudentCreate() {

    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);

    const handleSubmit = async (values: any) => {
        setLoading(true);

        try {
            const response = await (await (new StudentService()).createStudent(values)).getResponseData();
            if (response.success) {
                setLoading(false);
                navigate(-1);
                setTimeout(() => { toast.success('Empleado creado correctamente') }, 100);
            } else {
                response.data.errors.forEach((error: any) => {
                    toast.error(error.message);
                });
            }
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb} />

            <AttivaCard title='Datos Principales' className='mb-4'>
                <StudentForm submit={handleSubmit} isLoading={loading} />
            </AttivaCard>
        </>
    );
}