import React from 'react';
import { IoClose } from 'react-icons/io5';
import { ActionIcon, Button, Modal, Text } from 'rizzui';

interface CustomModalProps {
    children: React.ReactNode;
    isOpen: boolean;
    onConfirm: () => void;
    onClose: () => void;
    name: string;
    confirmText?: string;
    cancelText?: string;
    size?: 'sm' | 'md' | 'lg' | 'xl';
}

const CustomModal: React.FC<CustomModalProps> = ({ children, isOpen, onConfirm, onClose, name, confirmText = 'Confirmar', cancelText = 'Cancelar', size = 'sm' }) => {
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            size={size}
            overlayClassName='backdrop-blur'
            containerClassName='!shadow-2xl'
            className='z-[999] [&_.pointer-events-none]:overflow-visible'
        >
            <div className='m-auto px-7 pt-6 pb-8'>
                <div className='mb-7 flex items-center justify-between'>
                    <Text className="text-xl">{name}</Text>
                    <ActionIcon size='md' variant='text' onClick={onClose}>
                        <IoClose className='h-auto' strokeWidth={1.8} />
                    </ActionIcon>
                </div>

                {children}

                <div className='flex gap-4 justify-end'>
                    <Button variant='outline' onClick={onClose}>
                        {cancelText}
                    </Button>
                    <Button onClick={onConfirm}>
                        {confirmText}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default CustomModal;