import React from 'react';
import { FiltersProvider } from '../../../../../components/providers/FiltersProvider';
import UserProfileLayout from '../../UserProfileLayout';
import UserActivityHistory from './UserActivityHistory';

interface UserActivityHistoryWrapperProps { }

const UserActivityHistoryWrapper: React.FC<UserActivityHistoryWrapperProps> = () => {

    return (
        <FiltersProvider>
            <UserProfileLayout>
                <UserActivityHistory />
            </UserProfileLayout>
        </FiltersProvider>
    );
};

export default UserActivityHistoryWrapper;