import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import { useFormik } from 'formik';
import { FC, useEffect, useRef, useState } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { Document, Page, pdfjs } from 'react-pdf';
import { useNavigate, useParams } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import { Button, Text } from 'rizzui';
import { toast } from 'sonner';
import fullLogo from '../../../assets/logo_completo.png';
import { useWindowDimensions } from "../../../hooks/useWindowDimensions";
import { ContractService } from "../../../services/contract/contractService";
import SignContractMobile from "./SignContractMobile";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`; // Configura el worker para la última versión estable disponible

const SignContract: FC = () => {

    // HOOKS

    const navigate = useNavigate();
    const { hash } = useParams<{ hash: string }>();

    // STATES

    const [signatureHash, setSignatureHash] = useState<string | undefined>();
    const [contractLoaded, setContractLoaded] = useState(false);
    const [showSignature, setShowSignature] = useState(false);
    const signatureRef = useRef<SignatureCanvas>(null);
    const [numPages, setNumPages] = useState<number | null>(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [pdfBlob, setPdfBlob] = useState<any | null>(null);
    const [contractData, setContractData] = useState<any | null>(null);
    const { width } = useWindowDimensions();
    const [isLoading, setIsLoading] = useState(false);
    const [signingContract, setSigningContract] = useState(false);

    // FUNCTIONS

    const handleObtainContractInfo = async (hash: string | undefined) => {
        try {
            const response = await (await (new ContractService()).findContractByHash(hash, null)).getResponseData();
            if (response?.data) {
                setContractData(response.data);
            }
        } catch (error) {
            console.error('Error al obtener la información del contrato:', error);
            toast.error('Hubo un problema al obtener la información del contrato.');
        }
    };

    const handlePreviewContract = async (signatureHash: string | undefined) => {
        try {
            const response = await (new ContractService()).visualizeContractPdf(signatureHash, undefined);
            if (response?.data) {
                let base64String;
                const fileData = response.data;
                // Verificar si el contenido del blob es diferente antes de actualizar el estado
                if (pdfBlob && pdfBlob.size === fileData.byteLength) return;
                const blob = new Blob([fileData]);
                let reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                    base64String = reader.result;
                    setPdfBlob(base64String);
                };
            }
        } catch (error) {
            console.error(error);
        } finally {
            setContractLoaded(true);
        }
    };

    function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
        setNumPages(numPages);
    };

    function onDocumentLoadError(error: any) {
        navigate('/find-contract/not-found');
    };

    const handleSignContract = async (signatureHash: string, contract: any) => {
        setIsLoading(true);
        setSigningContract(true);

        if (signatureRef.current?.isEmpty()) {
            toast.error('Por favor, realice su firma');
            setIsLoading(false);
            return;
        }
        const signatureImage = signatureRef.current?.toDataURL(); // Obtiene la firma en formato base64

        try {
            const response = await (await (new ContractService()).signContractPDF(signatureHash, signatureImage)).getResponseData();
            if (!response.success) {
                toast.error(response.message);
                return;
            }
            toast.success('Contrato firmado correctamente');
            navigate('/signed-contract/' + contract?.id);
        } catch (error) {
            toast.error('Error al firmar el contrato');
        } finally {
            setIsLoading(false);
        }
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: { signature: null, },
        validateOnChange: false,
        onSubmit: () => {
            if (signatureHash && signatureHash.length > 4) {
                handleSignContract(signatureHash, contractData);
            } else {
                toast.error('Hash inválido o nulo');
            }
        },
    });

    // USE EFFECTS

    useEffect(() => {
        const fetchData = async () => {
            if (hash && !contractData) {
                setSignatureHash(hash);
                await handleObtainContractInfo(hash);
                await handlePreviewContract(hash);
            }
        };
        fetchData();
    }, [hash, contractData]);

    // RENDER

    return (
        <>
            <div className={`${width < 1024 ? 'block' : 'hidden'}`}>
                <SignContractMobile />
            </div>
            <div className={`${width >= 1024 ? 'block' : 'hidden'}`}>
                {/* Componente principal */}
                <div className="relative min-h-screen">
                    <div className="w-full h-[100vh] grid grid-cols-1 xl:grid-cols-3">
                        {/* Vista del PDF */}
                        <div className="col-span-2 bg-gray-100 hidden lg:block">
                            <div className="flex flex-col justify-center items-center h-full">
                                {pdfBlob && contractLoaded
                                    ? (
                                        <>
                                            <Document
                                                file={pdfBlob}
                                                onLoadSuccess={onDocumentLoadSuccess}
                                                loading={<img
                                                    src="/media/loading-name-lg.svg"
                                                    alt="Cargando contrato"
                                                    className="w-25 h-25"
                                                />}
                                                onLoadError={onDocumentLoadError}
                                            >
                                                {/* Solo mostrar la página correspondiente */}
                                                {numPages && (
                                                    <Page
                                                        pageNumber={pageNumber}
                                                        renderMode="canvas" // Para asegurarte de que el texto esté renderizado correctamente
                                                        renderTextLayer={false}  // Desactiva la capa de texto
                                                        renderAnnotationLayer={false}  // Desactiva las anotaciones
                                                    />
                                                )}
                                            </Document>

                                            {numPages && (
                                                <p className="mt-4 text-gray-600">
                                                    Página {pageNumber} de {numPages}
                                                </p>
                                            )}
                                        </>
                                    )
                                    : <img src="/media/loading-name-lg.svg" alt="Cargando contrato" className="w-25 h-25" />
                                }
                            </div>
                        </div>

                        {/* Formulario de firma */}
                        <div className="mx-auto w-full max-w-md rounded-xl px-4 py-9 flex flex-col justify-center">
                            <div className="flex flex-col justify-center text-center mb-5">
                                <img src={fullLogo} height="10vh" alt="Logo de Attiva" />
                                <Text className="text-2xl font-bold mt-4">Firma de contrato</Text>
                            </div>

                            {/* Botón para mostrar el campo de firma */}
                            {!showSignature && (
                                <Button
                                    type="button"
                                    onClick={() => setShowSignature(true)}
                                    className="w-full"
                                    size="lg"
                                >
                                    Firmar contrato
                                </Button>
                            )}


                            {showSignature && !signingContract && (
                                <form onSubmit={formik.handleSubmit} className="space-y-5 mt-5">
                                    <div className="border border-gray-300 rounded-md p-2">
                                        <SignatureCanvas
                                            ref={signatureRef}
                                            canvasProps={{
                                                width: 500,
                                                height: 200,
                                                className: 'signature-canvas',
                                            }}
                                        />
                                        <div className="mt-2 flex justify-between">
                                            <Button type="button" onClick={() => signatureRef.current?.clear()} variant="outline">
                                                Limpiar
                                            </Button>
                                            <Button
                                                type="submit"
                                                onClick={() => {
                                                    if (signatureRef.current?.isEmpty()) {
                                                        toast.error('Por favor, realice su firma');
                                                        return;
                                                    }
                                                    const signatureImage = signatureRef.current?.toDataURL();
                                                    formik.setFieldValue('signature', signatureImage);
                                                    handleObtainContractInfo(hash);
                                                }}
                                                isLoading={isLoading}
                                                disabled={isLoading}
                                            >
                                                Firmar
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            )}

                            {signingContract && (
                                <div className="flex flex-col justify-center items-center text-center">
                                    <DotLottieReact
                                        src="https://lottie.host/e7d7459a-de1c-4374-9631-3e03d9d81b11/S0RljqPaCz.lottie"
                                        loop={false}
                                        autoplay
                                        speed={0.6}
                                        className="mb-4" // Margen abajo para separar la animación del texto
                                    />
                                    <p className="text-gray-600">Firmando tu contrato...</p>
                                </div>
                            )}

                            {/* Navegación con flechas y tooltips */}
                            <div className="mt-4 flex justify-center space-x-4">
                                {pdfBlob && contractLoaded ? (
                                    <>
                                        <button
                                            onClick={() => setPageNumber((prevPage) => Math.max(prevPage - 1, 1))}
                                            disabled={pageNumber <= 1}
                                            title="Página anterior" // Tooltip
                                            className="text-2xl p-2 hover:bg-gray-200 rounded-full"
                                        >
                                            <FaArrowLeft />
                                        </button>
                                        <button
                                            onClick={() => setPageNumber((prevPage) => Math.min(prevPage + 1, numPages || 1))}
                                            disabled={pageNumber >= (numPages || 1)}
                                            title="Página siguiente" // Tooltip
                                            className="text-2xl p-2 hover:bg-gray-200 rounded-full"
                                        >
                                            <FaArrowRight />
                                        </button>
                                    </>
                                )
                                    : <p className="text-gray-500">Cargando contrato...</p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SignContract;