import { isEqual } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectReact from "react-select";
import DateField from '../../../../../components/forms/DateField';
import { RootState } from '../../../../../redux/store';
import { changeViewMode } from '../../../../../redux/userProfileSlice';

interface UserStatsFiltersProps {
    filters: any;
    updateFilters: any;
    resetFilters: any;
}

const UserStatsFilters: React.FC<UserStatsFiltersProps> = ({ filters, updateFilters, resetFilters }) => {

    const dispatch = useDispatch();
    const viewMode = useSelector((state: RootState) => state.userProfile.viewMode);

    return (
        <div className='flex justify-end my-3'>
            <SelectReact
                options={[
                    { value: 'daily', label: 'Diario' },
                    { value: 'weekly', label: 'Semanal' },
                    { value: 'monthly', label: 'Mensual' },
                    { value: 'yearly', label: 'Anual' },
                ]}
                onChange={(option: any) => {
                    dispatch(changeViewMode(option.value));
                    updateFilters({ datePeriod: option.value });
                }}
                value={{ value: viewMode, label: viewMode === 'daily' ? 'Diario' : viewMode === 'weekly' ? 'Semanal' : viewMode === 'monthly' ? 'Mensual' : 'Anual' }}
                className="me-4"
                styles={{
                    control: (base: any, state: any) => ({
                        ...base,
                        border: '1px !important',
                        borderRadius: '0.375rem',
                        borderColor: 'rgb(229 231 235) !important',
                        boxShadow: state.isFocused ? '0 0 0 2px rgb(0, 172, 216)' : '0 0 0 1px rgba(0,0,0, 0.2) !important',
                    }),
                    option: (provided: any, state: any) => ({
                        ...provided,
                        backgroundColor: state.isFocused ? 'rgb(0, 172, 216)' : 'white',
                        color: state.isFocused ? 'white' : 'black',
                        '&:hover': {
                            backgroundColor: 'rgb(0, 172, 216)',
                            color: 'white',
                            borderColor: '#000000 !important'
                        }
                    }),
                }}
            />

            <DateField
                isClearable
                className="w-full"
                placeholderText="Rango de fechas"
                startDate={filters.filter_filters?.startDate ? new Date(filters.filter_filters.startDate) : null}
                endDate={filters.filter_filters?.endDate ? new Date(filters.filter_filters.endDate) : null}
                onChange={(date: any) => {
                    const filtersStartDdate = filters.filter_filters?.startDate;
                    const filtersEndDate = filters.filter_filters?.endDate;
                    const betweenDates = date ? { startDate: date[0], endDate: date[1] } : null;

                    if (betweenDates && !isEqual(betweenDates, { startDate: filtersStartDdate, endDate: filtersEndDate })) {
                        updateFilters({ startDate: date[0], endDate: date[1] });
                    } else if (!betweenDates && filtersStartDdate && filtersEndDate) {
                        updateFilters({ startDate: moment().subtract(1, 'months').format('YYYY-MM-DD'), endDate: moment().format('YYYY-MM-DD') });
                    }
                }}
            />
        </div>
    );
};

export default UserStatsFilters;