import React, { useCallback, useContext, useEffect, useState } from 'react';
import { MdOutlineModeEditOutline } from 'react-icons/md';
import { useLocation } from 'react-router-dom';
import { ActionIcon, Button, Switch, Tooltip, cn } from 'rizzui';
import { toast } from 'sonner';
import DeletePopover from '../../components/buttons/DeletePopover';
import OrderControl from '../../components/buttons/OrderControl';
import { PrivilegeContext, permissionsGroup } from "../../components/priviledge/PriviledgeProvider";
import { useFiltersPR } from '../../components/providers/FiltersProvider';
import CustomTable from '../../components/table/CustomTable';
import useFetch from '../../hooks/useFetch';
import useHandleErrors from '../../hooks/useHandleErrors';
import { traductions } from '../../utils/traductions';
import { ConfigModalForm } from './ConfigModalForm';
import GeneralConfigLayout from './GeneralConfigLayout';

interface ConfigurationsListProps { }

const columnOrder = [
    'id',
    'entityOrder',
    'name',
    'description',
    'active',
    'createdAt',
    'updatedAt',
];

const ConfigurationsList: React.FC<ConfigurationsListProps> = ({ }) => {

    const location = useLocation();
    const { userCan } = useContext(PrivilegeContext);
    const { handleErrors } = useHandleErrors();
    const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters } = useFiltersPR();

    const [openModal, setOpenModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState<any>();
    const [entity, setEntity] = useState<string>(location.pathname.replace('/general-configuration/', ''));
    const [entityName, setEntityName] = useState<any>(traductions.find((trad) => trad.key === entity));
    const [changingState, setChangingState] = useState<string[]>([]);

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        if (!entityName || entityName.key !== entity) return;
        const response = await entityName?.list_service(filters) as any;
        return response.getResponseData();
    }, [filters, entity, entityName]));

    const updateOrder = async (id: string, action: 'up' | 'down') => {
        const response = await (await entityName?.update_order_service(id, action)).getResponseData();
        if (response.success) {
            toast.success('Orden actualizado');
            refetch();
        } else {
            handleErrors(response);
        }
    };

    const toggleState = async (id: string, status: boolean) => {
        try {
            setChangingState([...changingState, id]);
            const response = (await entityName?.toggle_service(id)).getResponseData();
            if (response.success) {
                setChangingState(changingState.filter((item) => item !== id));
                refetch();
                toast.success(status ? "Se ha desactivado correctamente" : "Se ha activado correctamente");
            } else {
                setChangingState(changingState.filter((item) => item !== id));
                toast.error(response.message);
            }
        } catch (error: any) {
            setChangingState(changingState.filter((item) => item !== id));
            toast.error(error.message);
        }
    };

    const handleDelete = async (id: string) => {
        const response = await (await entityName?.delete_service(id))?.getResponseData();
        if (response.success) {
            toast.success('Se ha eliminado correctamente');
            refetch();
        } else {
            handleErrors(response);
        }
    };

    const handleMultiDelete = async (ids: string[]) => {
        const response = await (await entityName?.delete_multi_service(ids))?.getResponseData();
        if (response.success) {
            toast.success('Se han eliminado correctamente');
            refetch();
        } else {
            handleErrors(response);
        }
    };

    /**
     * Order the data to match the column order
     */
    const orderedData = () => data?.data.map((row: any) => {
        const orderedRow: any = {};
        columnOrder.forEach((key) => {
            orderedRow[key] = row[key];
        });
        return orderedRow;
    });

    useEffect(() => {
        if (entity !== location.pathname.replace('/general-configuration/', '')) {
            setEntity(location.pathname.replace('/general-configuration/', ''));
            resetFilters();
            updateFilterOrder('entityOrder', 'asc');
        }
    }, [location]);

    useEffect(() => {
        if (entityName !== traductions.find((trad) => trad.key === entity)) {
            setEntityName(traductions.find((trad) => trad.key === entity));
        }
    }, [entity]);

    return (
        <GeneralConfigLayout>
            {data !== undefined && data && (
                <CustomTable
                    id={`${entity}-table`}
                    columnOrder={columnOrder}
                    data={orderedData()}
                    filter={false}
                    isLoading={loading}
                    columnsNotShown={['updatedAt']}
                    overrideColumns={[
                        {
                            key: 'entityOrder',
                            permissions: [{ group: entity as permissionsGroup, permission: `update_${entity}_order` }],
                            render: (row: any) => {
                                return (
                                    <OrderControl
                                        id={row.row.original.id}
                                        order={row.row.original.entityOrder}
                                        index={row.row.index}
                                        totalRegisters={data.totalRegisters}
                                        onOrderChange={updateOrder}
                                    />
                                );
                            },
                        },
                        {
                            key: 'active',
                            render: (row: any) => {
                                return (
                                    <Switch
                                        id={row.row.original.id}
                                        checked={row.row.original.active}
                                        onChange={() => toggleState(row.row.original.id, row.row.original.active)}
                                        switchKnobClassName='bg-secondary'
                                    />
                                );
                            },
                        },
                    ]}
                    actions={[
                        {
                            label: 'Edit',
                            permissions: { group: entity as permissionsGroup, permission: `edit_${entity}` },
                            render: (row: any) => {
                                return (
                                    <Tooltip
                                        size="sm"
                                        content={`Editar ${traductions.find((trad) => trad.key === entity)?.value}`}
                                        placement="top"
                                        color="invert"
                                    >
                                        <ActionIcon
                                            as="span"
                                            size="sm"
                                            variant="outline"
                                            className="hover:!border-gray-900 hover:text-gray-700 cursor-pointer"
                                            onClick={() => {
                                                setSelectedRow(row);
                                                setOpenModal(true);
                                            }}
                                        >
                                            <MdOutlineModeEditOutline className="h-4 w-4" size={20} />
                                        </ActionIcon>
                                    </Tooltip>
                                );
                            },
                        },
                        {
                            label: 'Delete',
                            permissions: { group: entity as permissionsGroup, permission: `delete_${entity}` },
                            render: (row: any) => {
                                return (
                                    <Tooltip
                                        size="sm"
                                        content={`Eliminar ${traductions.find((trad) => trad.key === entity)?.value}`}
                                        placement="top"
                                        color="invert"
                                    >
                                        <div>
                                            <DeletePopover
                                                title={`Eliminar ${traductions.find((trad) => trad.key === entity)?.value}`}
                                                description={`¿Estás seguro de que deseas eliminar ${row.name}?`}
                                                onDelete={() => handleDelete(row.id)}
                                            />
                                        </div>
                                    </Tooltip>
                                );
                            },
                        },
                    ]}
                    handleMultipleDelete={handleMultiDelete}
                    filters={filters}
                    updateFilters={updateFilters}
                    updateFilterOrder={updateFilterOrder}
                    defaultOrder={filters.filter_order || undefined}
                    paginationData={{
                        pageSize: filters.limit,
                        currentPage: filters.page,
                        pageCount: data ? data.lastPage : 1,
                        totalCount: data?.totalRegisters,
                        handlePagination: updatePage,
                        handlePerPage: updatePageSize,
                    }}
                >
                    {userCan(`create_${entity}`, entity as permissionsGroup) && (
                        <Button
                            color="primary"
                            onClick={() => { setSelectedRow(undefined); setOpenModal(true); }}
                            className={cn(['mt-4 md:mt-0 text-nowrap'])}
                        >
                            Crear {traductions.find(trad => trad.key === entity)?.value}
                        </Button>
                    )}
                </CustomTable>
            )}

            {entity && entityName && <ConfigModalForm isOpen={openModal} onClose={() => setOpenModal(false)} data={selectedRow} refetch={refetch} entity={entity} entityName={entityName} />}
        </GeneralConfigLayout>
    );
};

export default ConfigurationsList;