import React from 'react';
import { FiltersProvider } from '../../../../../components/providers/FiltersProvider';
import ClientProfileLayout from '../../ClientProfileLayout';
import ClientActivityHistory from './ClientActivityHistory';

interface ClientActivityHistoryWrapperProps { }

const ClientActivityHistoryWrapper: React.FC<ClientActivityHistoryWrapperProps> = () => {

    return (
        <FiltersProvider>
            <ClientProfileLayout>
                <ClientActivityHistory />
            </ClientProfileLayout>
        </FiltersProvider>
    );
};

export default ClientActivityHistoryWrapper;