import { AxiosResponse } from 'axios';
import { IncidenceFieldsModel } from '../../type/entities/incidence-type';
import { RestServiceConnection } from '../restServiceConnection';

const ENDPOINT = '/contract';

export class ContractService extends RestServiceConnection {

    listContracts = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/list',
            data: filters,
        }, true) as AxiosResponse;
        return this;
    }

    getContractById = async (contractId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get',
            data: { contractId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    getProductComplements = async (productId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get-product-complements',
            data: { productId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    listContractDocuments = async (filters: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/list-documents',
            data: filters,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    getContractDocuments = async (contractId: string, maxResults?: number | string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get-documents',
            data: { contractId, maxResults },
        }, true);
        return this;
    }

    changeContractStatus = async (contractId: string, statusId: string, comment: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/change-contract-status',
            data: { contractId, statusId, comment },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    changeMultiContractStatus = async (contractIds: string[], statusId: string, comment: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/change-multiple-contract-status',
            data: { contractIds, statusId, comment },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    changeContractProductStatus = async (contractId: string, statusId: string, productId: string, comment: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/change-contract-product-status',
            data: { contractId, statusId, productId, comment },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    changeMultiContractProductStatus = async (contractIds: string[], statusId: string, productId: string, comment: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/change-multiple-contract-product-status',
            data: { contractIds, statusId, productId, comment },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    changeContractInvoicingStatus = async (contractId: string, statusId: string, invoiceId: string, comment: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/change-contract-invoicing-status',
            data: { contractId, statusId, invoiceId, comment },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    changeContractIncidenceStatus = async (contractId: string, statusId: string, incidenceId: string, comment: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/change-contract-incidence-status',
            data: { contractId, statusId, incidenceId, comment },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    changeContractCommissionStatus = async (contractId: string, statusId: string, comment: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/change-contract-commission-status',
            data: { contractId, statusId, comment },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    changeMultiContractCommissionStatus = async (contractIds: string[], statusId: string, comment: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/change-multiple-contract-commission-status',
            data: { contractIds, statusId, comment },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    saveAsContract = async (entity: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/save-as-complete',
            data: entity,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    saveAsDraft = async (entity: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/save-as-draft',
            data: entity,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    exportContracts = async (filters: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/export',
            data: filters,
            responseType: 'arraybuffer',
        }, true);
        return this;
    }

    verifyContractDataBeforeExport = async (orderId: string, exportAll: boolean) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/verify-contracts-data-before-export',
            data: { orderId, exportAll },
        }, true);
        return this;
    }

    generateXMLStart = async (orderId: string, exportAll: boolean, isVerified: boolean) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/generate-contracts-start-xml',
            data: { orderId, exportAll, isVerified },
        }, true);
        return this;
    }

    generateXMLEnd = async (orderId: string, exportAll: boolean, isVerified: boolean) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/generate-contracts-end-xml',
            data: { orderId, exportAll, isVerified },
        }, true);
        return this;
    }

    createDraft = async (entity: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/create-draft',
            data: entity,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    editDraft = async (entity: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/edit-draft',
            data: entity,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    createCompleteContract = async (entity: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/create-complete-contract',
            data: entity,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    editCompleteContract = async (entity: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/edit-complete-contract',
            data: entity,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    createContractIncidence = async (incidence: IncidenceFieldsModel) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/register-incidence',
            data: incidence,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    listContractIncidences = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/list-contract-incidences',
            data: filters,
        }, true) as AxiosResponse;
        return this;
    }

    deleteContract = async (contractId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/delete',
            data: { contractId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    deleteMultiContracts = async (contractIds: string[]) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/delete-multi',
            data: { contractIds: contractIds },
        }, true);
        return this;
    }

    listContractComments = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/list-comments',
            data: filters,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    getContractComments = async (contractId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get-contract-comments',
            data: { contractId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    addComment = async (contractId: string, comment: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/add-comment',
            data: { contractId, comment },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    editComment = async (commentId: string, comment: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/edit-comment',
            data: { commentId, comment },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    deleteComment = async (contractCommentId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/delete-comment',
            data: { contractCommentId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    multiDeleteComments = async (contractCommentIds: string[]) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/delete-multi-comments',
            data: { contractCommentIds },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    findClientsOnContract = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/find-clients',
            data: filters,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    findStudentsOnContract = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/find-students',
            data: filters,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    findProductsTypesOnContract = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/find-product-types',
            data: filters,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    findProductsOnContract = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/find-products',
            data: filters,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    findCampaignsOnContract = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/find-campaigns',
            data: filters,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    findNetworksOnContract = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/find-commercial-networks',
            data: filters,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    findCorporationsOnContract = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/find-corporations',
            data: filters,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    findCommercialsOnContract = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/find-commercials',
            data: filters,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    findStudiesOnContract = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/find-studies',
            data: filters,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    findProfessionalCategoriesOnContract = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/find-professional-categories',
            data: filters,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    findQuotationOnContract = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/find-quotation-groups',
            data: filters,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    findConsultanciesOnContract = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/find-consultancies',
            data: filters,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    showCommentsOnClient = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/show-comments-on-client',
            data: filters,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    showClientContractHistory = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/show-client-contract-history',
            data: filters,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    showClientStudentsHistory = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/show-client-students',
            data: filters,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    showClientConsultaciesHistory = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/show-client-consultancies',
            data: filters,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    showContractStatusHistory = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/show-contract-statuses-history',
            data: filters,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    getContractStatuses = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get-contract-statuses',
            data: filters,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    getContractProductStatuses = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get-contract-product-statuses',
            data: filters,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    getContractInvoicingStatuses = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get-contract-invoicing-statuses',
            data: filters,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    getContractIncidenceStatuses = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get-contract-incidence-statuses',
            data: filters,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    getContractCommissionStatuses = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get-contract-commission-statuses',
            data: filters,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    editClientOnContract = async (entity: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/modify-client-on-contract',
            data: entity,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    editStudentOnContract = async (entity: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/modify-student-on-contract',
            data: entity,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    editConsultancyOnContract = async (entity: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/modify-consultancy-on-contract',
            data: entity,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    invoicingContracts = async (contractIds: string[]) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/invoicing-contracts',
            data: { contractIds },
        }, true);
        return this;
    }

    generateContractPdf = async (contractId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/generate-contract-pdf',
            data: { contractId },
            responseType: 'arraybuffer', // to avoid string conversion
        }, true);
        return this;
    }

    listActivityHistory = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/list-contract-history',
            data: filters,
        }, true) as AxiosResponse;
        return this;
    }

    listContractInvoicing = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/list-contract-invoicing',
            data: filters,
        }, true) as AxiosResponse;
        return this;
    }

    // Public methods
    findContractByHash = async (hash: any, id: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: '/find-contract',
            data: {
                hash: hash,
                contractId: id
            },
            headers: { "Content-Type": "application/json" }
        }, false, true) as AxiosResponse;
        return this;
    }

    visualizeContractPdf = async (hash: string | undefined, id: string | undefined) => {
        return await this.makeRequest({
            method: 'POST',
            url: '/preview-contract',
            data: {
                hash: hash,
                contractId: id,
                publicUrl: true
            },
            responseType: 'arraybuffer',
        });
    }

    signContractPDF = async (hash: string, signature: string | undefined) => {
        const formData = new FormData();
        formData.append("hash", hash);

        // Convierte la imagen de la firma (base64) a un archivo Blob
        const byteArray = atob(<string>signature?.split(',')[1]); // Elimina el prefijo 'data:image/png;base64,' y decodifica base64
        const arrayBuffer = new ArrayBuffer(byteArray.length);
        const uintArray = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteArray.length; i++) {
            uintArray[i] = byteArray.charCodeAt(i);
        }

        const blob = new Blob([uintArray], { type: "image/png" }); // Se asegura de que el archivo tenga el tipo correcto
        formData.append("signature", blob, "signature.png"); // El tercer parámetro es el nombre del archivo

        this.response = await this.makeRequest({
            method: 'POST',
            url: '/sign-contract',
            data: formData, // Aquí envía directamente el FormData
            headers: { "Content-Type": "multipart/form-data" }
        }, false, true);

        return this;
    }

}