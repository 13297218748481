import { SVGProps } from 'react';

const SvgContactSupport = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
			<path d='M0 0h24v24H0V0z' fill='none' />
			<path
				d='M11.5 3C7.36 3 4 6.36 4 10.5S7.36 18 11.5 18H13v2.3c3.64-2.3 6-6.08 6-9.8C19 6.36 15.64 3 11.5 3zm1 13.5h-2v-2h2v2zm0-3.5h-2c0-3.25 3-3 3-5 0-1.1-.9-2-2-2s-2 .9-2 2h-2c0-2.21 1.79-4 4-4s4 1.79 4 4c0 2.5-3 2.75-3 5z'
				opacity={0.3}
			/>
			<path d='M11.5 1C6.26 1 2 5.26 2 10.5c0 5.07 3.99 9.23 9 9.49v3.6l1.43-.69C17.56 20.43 21 15.45 21 10.5 21 5.26 16.74 1 11.5 1zM13 20.3V18h-1.5C7.36 18 4 14.64 4 10.5S7.36 3 11.5 3 19 6.36 19 10.5c0 3.73-2.36 7.51-6 9.8zm-2.5-5.8h2v2h-2zm1-10.5c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z' />
		</svg>
	);
};

export default SvgContactSupport;
