import { FC, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";
import AttivaCard from "../../../components/card/AttivaCard";
import StatusDropdown from "../../../components/forms/StatusDropdown";
import { Loader } from "../../../components/loader/SpinnerLogo";
import useInvoiceStates from "../../../hooks/api-calls/useInvoiceStates";
import useFetch from "../../../hooks/useFetch";
import useHandleErrors from "../../../hooks/useHandleErrors";
import PageHeader from "../../../layout/shared/page-header";
import { menuRoutes } from "../../../router/menu";
import { InvoiceService } from "../../../services/invoice/invoiceService";
import { Invoice } from "../../../type/entities/invoice-type";
import InvoiceForm from "../InvoiceForm";

const InvoiceEdit: FC = () => {

    const { id = "" } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const { handleErrors } = useHandleErrors();
    const service = new InvoiceService();

    const { getInvoiceStatesList } = useInvoiceStates({ active: true });
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [data, loading, error, refetchData] = useFetch(useCallback(async () => {
        const response = await service.getInvoiceById(id as string);
        return response.getResponseData() as Invoice;
    }, [id]));

    const pageHeader = {
        title: (`Editar factura ${data && data.number}`),
        breadcrumb: [
            { href: menuRoutes.invoices.path, name: "Facturas" },
            { name: "Editar" },
        ],
    };

    const handleSubmit = async (values: any) => {
        setIsLoading(true);
        try {
            const response = await service.editInvoice(values);
            const responseData = response.getResponseData();

            if (responseData.success) {
                navigate(-1);
                toast.success("Factura editada correctamente");
            } else {
                responseData.data.errors.forEach((error: any) => {
                    toast.error(error.message);
                });
            }
        } catch (error: any) {
            handleErrors(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleStateChange = async (entityId: string, newStateId: string, comment: string) => {
        try {
            const response = await service.changeInvoiceState(id, newStateId, comment);
            const responseData = response.getResponseData();

            if (responseData && responseData.success) {
                toast.success("Estado de la factura actualizado correctamente");
                refetchData();
            } else if (responseData) {
                toast.error(responseData.message);
            }
        } catch (error: any) {
            handleErrors(error);
        }
    };

    if (loading) return <Loader />;

    return (
        <>
            <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb}>
                <div className="flex flex-row align-middle">
                    <StatusDropdown
                        entityId={id}
                        currentState={data?.currentStatus}
                        statesOptions={getInvoiceStatesList()}
                        handleStateChange={handleStateChange}
                        title="Esta acción modificará el estado de la factura"
                    />
                </div>
            </PageHeader>

            <AttivaCard title="Datos Principales">
                {(data !== null && data !== undefined) && <InvoiceForm data={data} submit={handleSubmit} isLoading={isLoading} />}
            </AttivaCard>
        </>
    );
};

export default InvoiceEdit;