import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { FaCommentAlt, FaPrint } from 'react-icons/fa';
import { HiOutlineEye } from 'react-icons/hi2';
import { MdOutlineModeEditOutline } from 'react-icons/md';
import { Link, useParams } from 'react-router-dom';
import { ActionIcon, Text, Tooltip } from 'rizzui';
import { toast } from 'sonner';
import DeletePopover from '../../../../../components/buttons/DeletePopover';
import StatusDropdown from '../../../../../components/forms/StatusDropdown';
import { usePrivilege } from '../../../../../components/priviledge/PriviledgeProvider';
import CustomTable from '../../../../../components/table/CustomTable';
import { FilterDrawerView } from '../../../../../components/table/components/TableFilter';
import useContractCommissionStates from '../../../../../hooks/api-calls/contract/useContractCommissionStates';
import useContractIncidenceStates from '../../../../../hooks/api-calls/contract/useContractIncidenceStates';
import useContractInvoiceStates from '../../../../../hooks/api-calls/contract/useContractInvoicingStates';
import useContractProductsStates from '../../../../../hooks/api-calls/contract/useContractProductsStates';
import useContractStates from '../../../../../hooks/api-calls/contract/useContractStates';
import useHandleDownloadDocument from '../../../../../hooks/useDownloadDocument';
import useFetch from '../../../../../hooks/useFetch';
import useFilters from '../../../../../hooks/useFilters';
import useHandleErrors from '../../../../../hooks/useHandleErrors';
import { menuRoutes } from '../../../../../router/menu';
import { ContractService } from '../../../../../services/contract/contractService';
import { InvoiceService } from '../../../../../services/invoice/invoiceService';
import { ContractsApiResponse } from '../../../../../type/entities/contract-type';
import { fixNumber } from '../../../../../utils/formatNumber';
import { AddCommentModal } from '../../../../clients/list/components/AddCommentModal';
import ContractsFilters from '../../../../contracts/list/ContractFilters';
import { contractColumnOrder } from '../../../../contracts/list/ContractList';
import TutorProfileLayout from '../../TutorProfileLayout';

interface TutorContractsProps { }

const TutorContracts: React.FC<TutorContractsProps> = ({ }) => {

    const { id = '' } = useParams<{ id: string }>();
    const { userCan } = usePrivilege();
    const { handleErrors } = useHandleErrors();
    const { handleDownloadDocumentNewTab } = useHandleDownloadDocument();
    const { getContractStatesList, isLoadingContractStates } = useContractStates({ active: true, action: 'edit' });
    const { getContractProductsStatesList, isLoadingContractProductsStates } = useContractProductsStates({ active: true, action: 'edit' });
    const { getContractInvoiceStatesList, isLoadingContractInvoiceStates } = useContractInvoiceStates({ active: true, action: 'edit' });
    const { getContractIncidenceStatesList, isLoadingContractIncidenceStates } = useContractIncidenceStates({ active: true, action: 'edit' });
    const { getContractCommissionStatesList, isLoadingContractCommissionStates } = useContractCommissionStates({ active: true, action: 'edit' });
    const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters } = useFilters({ tutor: id, contract_invoicing_is_pending: true });
    const service = new ContractService();

    const [openFilters, setOpenFilters] = useState<boolean>(false);
    const [openCommentModal, setOpenCommentModal] = useState<boolean>(false);
    const [rowId, setRowId] = useState<string>('');

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = await service.listContracts(filters);
        return response.getResponseData() as ContractsApiResponse;
    }, [filters]));

    const handleDelete = async (id: string) => {
        const response = await (await service.deleteContract(id)).getResponseData();
        if (response.success) {
            toast.success('Contrato eliminado correctamente');
            refetch();
        } else {
            handleErrors(response);
        }
    };

    const handleMultiDelete = async (ids: string[]) => {
        const response = await (await service.deleteMultiContracts(ids)).getResponseData();
        if (response.success) {
            toast.success('Contrato eliminados correctamente');
            refetch();
        } else {
            handleErrors(response);
        }
    };

    const handleInvoicing = async (selectedRows: any) => {
        const response = await (await service.invoicingContracts(selectedRows)).getResponseData();
        if (response.success) {
            toast.success('Factura generada correctamente');
            handleDownloadDocumentNewTab((new InvoiceService).printInvoice, response?.data, moment().format('DD-MM-YYYY') + '_factura.pdf')
        } else {
            handleErrors(response);
        }
    };

    const handleStateChange = async (id: string, stateId: string, comment: string, entityId: string, entityName: string) => {
        var response = { success: false };
        switch (entityName) {
            case 'contract':
                try {
                    response = await (await service.changeContractStatus(id, stateId, comment)).getResponseData();
                    if (response.success) {
                        toast.success('Estado del contrato actualizado correctamente');
                    } else {
                        handleErrors(response);
                    }
                } catch (error) {
                    toast.error('Error al actualizar el estado');
                }
                break;
            case 'invoice':
                try {
                    response = await (await service.changeContractInvoicingStatus(id, stateId, entityId, comment)).getResponseData();
                    if (response.success) {
                        toast.success('Estado de la factura actualizado correctamente');
                    } else {
                        handleErrors(response);
                    }
                } catch (error) {
                    toast.error('Error al actualizar el estado');
                }
                break;
            case 'product':
                try {
                    response = await (await service.changeContractProductStatus(id, stateId, entityId, comment)).getResponseData();
                    if (response.success) {
                        toast.success('Estado del producto actualizado correctamente');
                    } else {
                        handleErrors(response);
                    }
                } catch (error) {
                    toast.error('Error al actualizar el estado del producto');
                }
                break;
            case 'incidence':
                try {
                    response = await (await service.changeContractIncidenceStatus(id, stateId, entityId, comment)).getResponseData();
                    if (response.success) {
                        toast.success('Estado de la incidencia actualizado correctamente');
                    } else {
                        handleErrors(response);
                    }
                } catch (error) {
                    toast.error('Error al actualizar el estado de la incidencia');
                }
                break;
            case 'commission':
                try {
                    response = await (await service.changeContractCommissionStatus(id, stateId, comment)).getResponseData();
                    if (response.success) {
                        toast.success('Estado de la comisión actualizado correctamente');
                    } else {
                        handleErrors(response);
                    }
                } catch (error) {
                    toast.error('Error al actualizar el estado de la comisión');
                }
                break;
            default:
                break;
        }
        refetch();
    };

    /**
     * Order the data to match the column order
     */
    const orderedData = () => data?.data.map((row: any) => {
        const orderedRow: any = {};
        contractColumnOrder.forEach((key) => {
            orderedRow[key] = row[key];
        });
        return orderedRow;
    });

    return (
        <TutorProfileLayout isLoading={loading}>
            <div className="pt-4">
                {!loading && !isLoadingContractStates && !isLoadingContractProductsStates && !isLoadingContractInvoiceStates &&
                    !isLoadingContractIncidenceStates && !isLoadingContractCommissionStates && data !== undefined && (
                        <CustomTable
                            largeTable
                            id={'contracts-table'}
                            columnOrder={contractColumnOrder}
                            data={orderedData()}
                            isLoading={loading}
                            columnsNotShown={[
                                'currentContractProductStatusDate',
                                'currentInvoicingStatusDate',
                                'clientCompanyName',
                                'clientCifNif',
                                'currentContractStatusDate',
                                'invoiceId',
                                'incidence',
                                'complements',
                                'isDraft',
                                'currentIncidence',
                                'currentIncidenceStatus',
                                'currentIncidenceStatusColor',
                                'currentIncidenceStatusDate',
                                'currentCommissionStatusColor',
                                'currentCommissionStatusDate',
                                'currentCommissionStatus',
                            ]}
                            overrideColumns={[
                                {
                                    key: 'code',
                                    render: (row: any) => {
                                        return (
                                            userCan('get_contracts', 'contracts')
                                                ? (
                                                    <Link to={`${menuRoutes.contracts.path}/${row.row.original.id}/profile${menuRoutes.contracts.profile.info}`}>
                                                        <Text className='font-bold text-primary'>{row.row.original.code}</Text>
                                                    </Link>
                                                )
                                                : <Text className='font-bold text-primary'>{row.row.original.code}</Text>
                                        );
                                    }
                                },
                                {
                                    key: 'client',
                                    render: (row: any) => {
                                        const client = row.row.original?.client;
                                        return (
                                            <>
                                                {client
                                                    ? userCan('get_clients', 'clients')
                                                        ? (
                                                            <div className='flex justify-center items-center'>
                                                                <Link
                                                                    to={`${menuRoutes.clients.path}/${client?.id}/profile/info`}
                                                                    className='font-bold text-primary cursor-pointer'
                                                                >
                                                                    {client?.companyName}
                                                                </Link>
                                                                {userCan('get_clients_comments', 'clients') && client.lastComment && (
                                                                    <Tooltip
                                                                        size="sm"
                                                                        content={<div className='tooltip-container'>{client.lastComment}</div>}
                                                                        placement="top"
                                                                        color="invert"
                                                                    >
                                                                        <ActionIcon
                                                                            as="span"
                                                                            size="sm"
                                                                            variant="text"
                                                                            className="hover:!border-gray-900 hover:text-gray-700"
                                                                        >
                                                                            <FaCommentAlt className="h-4 w-4" size={20} />
                                                                        </ActionIcon>
                                                                    </Tooltip>
                                                                )}
                                                            </div>
                                                        )
                                                        : 'N/A'
                                                    :
                                                    (<span className='font-bold text-primary'>{client?.companyName}</span>)
                                                }
                                            </>
                                        )
                                    }
                                },
                                {
                                    key: 'product',
                                    render: (row: any) => {
                                        const product = row.row.original?.product;
                                        const complements = row.row.original?.complements;
                                        return (
                                            <div className='flex flex-col'>
                                                {product ?
                                                    (<Link
                                                        to={`${menuRoutes.products.path}?filter_filters=%7B"active"%3Atrue%2C"search_text"%3A"${product.name}"%7D&filter_order=%5B%5D&page=1&limit=50`}
                                                        className='font-bold text-primary'
                                                    >
                                                        {product.name}
                                                    </Link>
                                                    )
                                                    : 'N/A'}

                                                {product && complements && complements.length >= 1 && (
                                                    <div className='flex flex-col gap-1 mt-2 font-semibold'>
                                                        <Text className='font-semibold text-md'>Complementos:</Text>
                                                        <ul className='indent-4'>
                                                            {complements.map((complement: any, index: number) => (
                                                                <li
                                                                    key={product.id + index}
                                                                    className='text-md'
                                                                >
                                                                    <Text className='text-xs text-nowrap'>- {complement.complements.name} ({fixNumber(complement.complements.amount)}€)</Text>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>)
                                                }
                                            </div>
                                        )
                                    }
                                },
                                {
                                    key: 'productType',
                                    render: (row: any) => {
                                        return row.row.original?.productType?.name;
                                    }
                                },
                                {
                                    key: 'currentContractStatus',
                                    render: (row: any) => {
                                        return (
                                            <div className='flex flex-col gap-2 justify-content-center text-center'>

                                                <StatusDropdown
                                                    entityId={row.row.original?.id}
                                                    title="¿Estas seguro de cambiar el estado del contrato?"
                                                    statesOptions={getContractStatesList()}
                                                    currentState={row.row.original?.currentContractStatus}
                                                    currentStateDate={row.row.original?.currentContractStatusDate?.date}
                                                    handleStateChange={(entityId: string, statusId: string, comment: string) =>
                                                        handleStateChange(entityId, statusId, comment, entityId, 'contract')
                                                    }
                                                />
                                            </div>
                                        );
                                    }
                                },
                                {
                                    key: 'currentInvoicingStatus',
                                    render: (row: any) => {
                                        const invoiceStatusDate = row.row.original?.currentContractInvoicingStatusDate?.date;
                                        const invoiceStatus = row.row.original?.currentInvoicingStatus;
                                        const invoice = row.row.original?.invoice;
                                        return (
                                            <div className='flex flex-col gap-2 justify-content-center text-center'>
                                                {(invoice && invoiceStatusDate && invoiceStatus) ? (
                                                    <StatusDropdown
                                                        entityId={row.row.original?.id}
                                                        title="¿Estas seguro de cambiar el estado de la factura?"
                                                        statesOptions={getContractInvoiceStatesList()}
                                                        currentState={invoiceStatus}
                                                        currentStateDate={invoiceStatusDate}
                                                        handleStateChange={(entityId: string, statusId: string, comment: string) =>
                                                            handleStateChange(entityId, statusId, comment, invoice.id, 'invoice')
                                                        }
                                                    />
                                                ) : "N/A"}
                                            </div>
                                        );
                                    }
                                },
                                {
                                    key: 'currentProductStatus',
                                    render: (row: any) => {
                                        const productStatusDate = row.row.original?.currentContractProductStatusDate?.date;
                                        const productStatus = row.row.original?.currentProductStatus;
                                        const product = row.row.original?.product;
                                        return (
                                            <div className='flex flex-col gap-2 justify-content-center text-center'>
                                                {(productStatusDate && productStatus) ? (
                                                    <StatusDropdown
                                                        entityId={row.row.original?.id}
                                                        title="¿Estas seguro de cambiar el estado del producto?"
                                                        statesOptions={getContractProductsStatesList()}
                                                        currentState={productStatus}
                                                        currentStateDate={productStatusDate}
                                                        handleStateChange={(entityId: string, statusId: string, comment: string) =>
                                                            handleStateChange(entityId, statusId, comment, product.id, 'product')
                                                        }
                                                    />
                                                ) : "N/A"}
                                            </div>
                                        );
                                    }
                                },
                                {
                                    key: 'currentIncidenceStatusId',
                                    render: (row: any) => {
                                        const incidenceStatus = {
                                            'id': row.row.original?.currentIncidenceStatusId,
                                            'color': row.row.original?.currentIncidenceStatusColor,
                                            'date': row.row.original?.currentIncidenceStatusDate,
                                            'name': row.row.original?.currentIncidenceStatus,
                                        }
                                        const incidence = row.row.original?.currentIncidence;

                                        return (
                                            <div className='flex flex-col gap-2 justify-content-center text-center'>
                                                {(incidenceStatus && incidenceStatus.id && incidence) ? (
                                                    <StatusDropdown
                                                        disabled={row.row.original?.isDraft}
                                                        entityId={row.row.original?.id}
                                                        title="¿Estas seguro de cambiar el estado de la incidencia?"
                                                        statesOptions={getContractIncidenceStatesList(null, incidenceStatus.id, false)}
                                                        currentState={incidenceStatus}
                                                        currentStateDate={incidenceStatus.date}
                                                        handleStateChange={(entityId: string, statusId: string, comment: string) =>
                                                            handleStateChange(entityId, statusId, comment, incidence.id, 'incidence')
                                                        }
                                                    />
                                                ) : "N/A"}
                                            </div>
                                        );
                                    }
                                },
                                {
                                    key: 'currentCommissionStatusId',
                                    render: (row: any) => {
                                        const commissionStatus = {
                                            'id': row.row.original?.currentCommissionStatusId,
                                            'color': row.row.original?.currentCommissionStatusColor,
                                            'date': row.row.original?.currentCommissionStatusDate,
                                            'name': row.row.original?.currentCommissionStatus,
                                        }
                                        const commission = row.row.original?.currentIncidence;

                                        return (
                                            <div className='flex flex-col gap-2 justify-content-center text-center'>
                                                {(commissionStatus && commissionStatus.id) ? (
                                                    <StatusDropdown
                                                        disabled={row.row.original?.isDraft}
                                                        entityId={row.row.original?.id}
                                                        title="¿Estas seguro de cambiar el estado de la comisión?"
                                                        statesOptions={getContractCommissionStatesList(null, commissionStatus.id)}
                                                        currentState={commissionStatus}
                                                        currentStateDate={commissionStatus.date}
                                                        handleStateChange={(entityId: string, statusId: string, comment: string) =>
                                                            handleStateChange(entityId, statusId, comment, '', 'commission')
                                                        }
                                                    />
                                                ) : "N/A"}
                                            </div>
                                        );
                                    }
                                },
                                {
                                    key: 'totalAmount',
                                    render: (row: any) => {
                                        return (typeof row.row.original?.totalAmount === 'number' && !isNaN(row.row.original?.totalAmount)) ? `${fixNumber(row.row.original?.totalAmount)}€` : 'N/A';
                                    }
                                },
                            ]}
                            actions={[
                                {
                                    label: 'Generar PDF',
                                    permissions: { group: 'contracts', permission: 'print_contract' },
                                    render: (row: any) => {
                                        if (row.isDraft) return null;
                                        return (
                                            <Tooltip
                                                size="sm"
                                                content={'Generar PDF'}
                                                placement="top"
                                                color="invert"
                                            >
                                                <ActionIcon
                                                    as="span"
                                                    size="sm"
                                                    variant="outline"
                                                    className="hover:!border-gray-900 hover:text-gray-700 cursor-pointer"
                                                    onClick={() => handleDownloadDocumentNewTab(service.generateContractPdf, row.id, moment().format('DD-MM-YYYY') + '_contrato.pdf')}
                                                >
                                                    <FaPrint className="h-4 w-4" size={20} />
                                                </ActionIcon>
                                            </Tooltip>
                                        );
                                    },
                                },
                                {
                                    label: 'Add Comment',
                                    permissions: { group: 'contracts', permission: 'create_contract_comments' },
                                    render: (row: any) => {
                                        return (
                                            <Tooltip
                                                size="sm"
                                                content={'Añadir comentario'}
                                                placement="top"
                                                color="invert"
                                            >
                                                <ActionIcon
                                                    as="span"
                                                    size="sm"
                                                    variant="outline"
                                                    className="hover:!border-gray-900 hover:text-gray-700 cursor-pointer"
                                                    onClick={() => { setRowId(row.id); setOpenCommentModal(true) }}
                                                >
                                                    <FaCommentAlt className="h-4 w-4" size={20} />
                                                </ActionIcon>
                                            </Tooltip>
                                        );
                                    },
                                },
                                {
                                    label: 'View',
                                    permissions: { group: 'user', permission: 'get_user' },
                                    render: (row: any) => {
                                        return (
                                            <Tooltip
                                                size="sm"
                                                content={'Ver detalles'}
                                                placement="top"
                                                color="invert"
                                            >
                                                <Link
                                                    to={`${menuRoutes.contracts.path}/${row.id}/profile${menuRoutes.contracts.profile.info}`}>
                                                    <ActionIcon
                                                        as="span"
                                                        size="sm"
                                                        variant="outline"
                                                        className="hover:!border-gray-900 hover:text-gray-700"
                                                    >
                                                        <HiOutlineEye className="h-4 w-4" size={20} />
                                                    </ActionIcon>
                                                </Link>
                                            </Tooltip>
                                        );
                                    },
                                },
                                {
                                    label: 'Edit',
                                    permissions: { group: 'contracts', permission: 'edit_contracts' },
                                    render: (row: any) => {
                                        return (
                                            <Tooltip
                                                size="sm"
                                                content={'Editar contrato'}
                                                placement="top"
                                                color="invert"
                                            >
                                                <Link to={`${menuRoutes.contracts.path}/${row.id}/edit`}>
                                                    <ActionIcon
                                                        as="span"
                                                        size="sm"
                                                        variant="outline"
                                                        className="hover:!border-gray-900 hover:text-gray-700"
                                                    >
                                                        <MdOutlineModeEditOutline className="h-4 w-4" size={20} />
                                                    </ActionIcon>
                                                </Link>
                                            </Tooltip>
                                        );
                                    },
                                },
                                {
                                    label: 'Delete',
                                    permissions: { group: 'contracts', permission: 'delete_contracts' },
                                    render: (row: any) => {
                                        return (
                                            <Tooltip
                                                size="sm"
                                                content={'Eliminar'}
                                                placement="top"
                                                color="invert"
                                            >
                                                <div>
                                                    <DeletePopover
                                                        title={`Eliminar contrato`}
                                                        description={`¿Estás seguro de que deseas eliminar a ${row.companyName}?`}
                                                        onDelete={() => handleDelete(row.id)}
                                                    />
                                                </div>
                                            </Tooltip>
                                        );
                                    },
                                },
                            ]}
                            /* multiActions={[
                                {
                                    label: 'Generar factura',
                                    permissions: { group: 'invoices', permission: 'print_invoices' },
                                    render: (selectedRows: any) => {
                                        return (
                                            <CustomPopover
                                                title={'Generar factura'}
                                                description={'¿Generar la factura de los contratos seleccionados?'}
                                                onClick={() => handleInvoicing(selectedRows)}
                                                isButton
                                                warning={false}
                                                confirmColor='primary'
                                            />
                                        );
                                    }
                                },
                            ]} */
                            handleMultipleDelete={handleMultiDelete}
                            filters={filters}
                            updateFilters={updateFilters}
                            updateFilterOrder={updateFilterOrder}
                            defaultOrder={filters.filter_order || undefined}
                            paginationData={{
                                pageSize: filters.limit,
                                currentPage: filters.page,
                                pageCount: (data as ContractsApiResponse) ? data.lastPage : 1,
                                totalCount: data?.totalRegisters,
                                handlePagination: updatePage,
                                handlePerPage: updatePageSize,
                            }}
                            toggleFilters={() => setOpenFilters(!openFilters)}
                        />
                    )}

                <AddCommentModal service={(new ContractService).addComment} id={rowId} isOpen={openCommentModal} onClose={() => setOpenCommentModal(false)} refetch={refetch} />

                <FilterDrawerView isOpen={openFilters} setOpenDrawer={setOpenFilters} drawerTitle={'Filtros Contratos (Tutor)'}>
                    <ContractsFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} />
                </FilterDrawerView>
            </div>
        </TutorProfileLayout>
    );
};

export default TutorContracts;