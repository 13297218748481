import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { MdOutlineModeEditOutline } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { ActionIcon, Button, Tooltip, cn } from 'rizzui';
import { toast } from 'sonner';
import DeletePopover from '../../../../../components/buttons/DeletePopover';
import { usePrivilege } from '../../../../../components/priviledge/PriviledgeProvider';
import CustomTable from '../../../../../components/table/CustomTable';
import useFetch from '../../../../../hooks/useFetch';
import useFilters from '../../../../../hooks/useFilters';
import useHandleErrors from '../../../../../hooks/useHandleErrors';
import { IncidenceService } from '../../../../../services/incidence/incidenceService';
import { StudentsApiResponse } from '../../../../../type/entities/student-type';
import IncidenceProfileLayout from '../../IncidenceProfileLayout';
import { CommentModal } from './CommentModal';

interface IncidenceCommentsProps { }

const columnOrder = [
    'id',
    'comment',
    'user',
    'createdAt',
    'updatedAt',
];

const IncidenceComments: React.FC<IncidenceCommentsProps> = () => {

    const { id = '' } = useParams<{ id: string }>();
    const { handleErrors } = useHandleErrors();
    const { userCan } = usePrivilege();
    const service = new IncidenceService();

    const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters({ incidence: id });

    const [openCommentModal, setOpenCommentModal] = useState<boolean>(false);
    const [rowData, setRowData] = useState<any>({});

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        if (!id || id === '') return null;
        const response = await service.listIncidenceComments(filters);
        return response.getResponseData();
    }, [id, filters]));

    const handleDelete = async (id: string) => {
        const response = await (await service.deleteIncidenceComment(id)).getResponseData();
        if (response.success) {
            toast.success('Comentario eliminado correctamente');
            refetch();
        } else {
            handleErrors(response);
        }
    };

    const handleMultiDelete = async (ids: string[]) => {
        const response = await (await service.multiDeleteIncidenceComments(ids)).getResponseData();
        if (response.success) {
            toast.success('Comentarios eliminados correctamente');
            refetch();
        } else {
            handleErrors(response);
        }
    };

    /**
     * Order the data to match the column order
     */
    const orderedData = () => data?.data.map((row: any) => {
        const orderedRow: any = {};
        columnOrder.forEach((key) => {
            orderedRow[key] = row[key];
        });
        return orderedRow;
    });

    return (
        <IncidenceProfileLayout isLoading={loading}>
                <div className="pt-4">
                    <CustomTable
                        id={'comments-table'}
                        columnOrder={columnOrder}
                        filter={false}
                        data={orderedData()}
                        isLoading={loading}
                        columnsNotShown={[]}
                        overrideColumns={[
                            {
                                key: 'user',
                                render: (row: any) => {
                                    return `${row.row.original.user.name} ${row.row.original.user.lastName || ''}`;
                                },
                            },
                            {
                                key: 'createdAt',
                                render: (row: any) => {
                                    const date = row.row.original?.createdAt?.date;
                                    return date ? moment(date).format('DD/MM/YY') : 'N/A';
                                },
                            },
                            {
                                key: 'updatedAt',
                                render: (row: any) => {
                                    const date = row.row.original?.updatedAt?.date;
                                    return date ? moment(date).format('DD/MM/YY') : 'N/A';
                                },
                            },
                        ]}
                        actions={[
                            {
                                label: 'Edit',
                                permissions: { group: 'incidences', permission: 'admin_incidence_comments' },
                                render: (row: any) => {
                                    return (
                                        <Tooltip
                                            size="sm"
                                            content={'Editar comentario'}
                                            placement="top"
                                            color="invert"
                                        >
                                            <ActionIcon
                                                as="span"
                                                size="sm"
                                                variant="outline"
                                                className="hover:!border-gray-900 hover:text-gray-700 cursor-pointer"
                                                onClick={() => { setRowData(row); setOpenCommentModal(true) }}
                                            >
                                                <MdOutlineModeEditOutline className="h-4 w-4" size={20} />
                                            </ActionIcon>
                                        </Tooltip>
                                    );
                                },
                            },
                            {
                                label: 'Delete',
                                permissions: { group: 'incidences', permission: 'admin_incidence_comments' },
                                render: (row: any) => {
                                    return (
                                        <Tooltip
                                            size="sm"
                                            content={'Eliminar'}
                                            placement="top"
                                            color="invert"
                                        >
                                            <div>
                                                <DeletePopover
                                                    title={`Eliminar comentario`}
                                                    description={`¿Estás seguro de que deseas eliminar el comentario?`}
                                                    onDelete={() => handleDelete(row.id)}
                                                />
                                            </div>
                                        </Tooltip>
                                    );
                                },
                            },
                        ]}
                        handleMultipleDelete={handleMultiDelete}
                        filters={filters}
                        updateFilters={updateFilters}
                        updateFilterOrder={updateFilterOrder}
                        defaultOrder={filters.filter_order || undefined}
                        paginationData={{
                            pageSize: filters.limit,
                            currentPage: filters.page,
                            pageCount: (data as StudentsApiResponse) ? data.lastPage : 1,
                            totalCount: data?.totalRegisters,
                            handlePagination: updatePage,
                            handlePerPage: updatePageSize,
                        }}
                    >
                        {userCan('admin_incidence_comments', 'incidences') && (
                            <Button
                                color="primary"
                                onClick={() => { setRowData({}); setOpenCommentModal(true); }}
                                className={cn(['mt-4 md:mt-0'])}
                            >
                                Añadir comentario
                            </Button>
                        )}
                    </CustomTable>
                </div>

            <CommentModal data={rowData} isOpen={openCommentModal} onClose={() => setOpenCommentModal(false)} refetch={refetch} />
        </IncidenceProfileLayout>
    );
};

export default IncidenceComments;