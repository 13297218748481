import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Input, Text, Textarea } from 'rizzui';
import PhoneInput from '../../../../../components/forms/PhoneInputWrapper';
import useFetch from '../../../../../hooks/useFetch';
import FormGroup from '../../../../../layout/shared/form-group';
import { ClientService } from '../../../../../services/client/clientService';
import ClientProfileLayout from '../../ClientProfileLayout';

interface ClientConsultancyProps { }

const ClientConsultancy: React.FC<ClientConsultancyProps> = ({ }) => {

    const { id = '' } = useParams<{ id: string }>();
    const service = new ClientService();

    const [data, loading] = useFetch(useCallback(async () => {
        if (!id || id === '') return null;
        const response = await service.getConsultancy(id);
        return response.getResponseData();
    }, [id]));

    return (
        <ClientProfileLayout isLoading={loading}>
            {data
                ? (
                    <div className="rounded-md border-solid border-2 col-span-7 p-6 divide-y-2 divide-dashed divide-gray-200 gap-7 mt-4">
                        <FormGroup
                            title="Información General"
                            description="Asesoría asociada al contrato."
                            className={"py-4 @3xl:grid-cols-12"}
                            titleCols="@md:col-span-2"
                            childCols="@md:col-span-10 md:grid-cols-4"
                        >
                            <div className="grid grid-cols-12 col-span-8 gap-4">
                                <Input
                                    disabled
                                    id='consultancyName'
                                    label="Nombre"
                                    className="col-span-12 md:col-span-4"
                                    value={data.name}
                                />

                                <Input
                                    disabled
                                    id='consultancyEmail'
                                    type="email"
                                    label="Correo Electrónico"
                                    className="col-span-12 md:col-span-4"
                                    value={data.email}
                                />

                                <Input
                                    disabled
                                    id='consultancyContact'
                                    label="Persona de Contacto"
                                    className="col-span-12 md:col-span-4"
                                    value={data.contact}
                                />

                                <PhoneInput
                                    disabled
                                    id='consultancyTelephone'
                                    label="Teléfono"
                                    className="col-span-12 md:col-span-4"
                                    value={data.telephone}
                                    required={false}
                                />

                                <Textarea
                                    disabled
                                    id='consultancyDescription'
                                    label="Descripción"
                                    className="[&>label>span]:font-medium col-span-12 md:col-span-8"
                                    value={data.description}
                                />
                            </div>
                        </FormGroup>
                    </div>
                )
                : (
                    <div className='p-4 h-[60vh] flex justify-center items-center text-muted500'>
                        <Text>No hay información de asesoría</Text>
                    </div>
                )
            }
        </ClientProfileLayout>
    );
};

export default ClientConsultancy;