import React, { useEffect } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { HiCalendarDays } from 'react-icons/hi2';
import { IoDocumentText } from 'react-icons/io5';
import { MdPerson3, MdSimCardAlert } from 'react-icons/md';
import { TbCashRegister } from 'react-icons/tb';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'rizzui';
import SimpleBar from 'simplebar-react';
import { setSelectedTab } from '../../redux/settingsSlice';
import { RootState } from '../../redux/store';
import { menuRoutes } from '../../router/menu';
import cn from '../../utils/classNames';
import { useSettingsProvider } from './provider/SettingsProvider';

const menuLinks = [
    { label: 'Contratos', path: menuRoutes.settings.path + menuRoutes.settings.contracts, icon: <IoDocumentText /> },
    { label: 'Facturación', path: menuRoutes.settings.path + '#invoices', icon: <TbCashRegister /> },
    { label: 'Incidencias', path: menuRoutes.settings.path + '#incidences', icon: <MdSimCardAlert /> },
    { label: 'Tutores', path: menuRoutes.settings.path + menuRoutes.settings.tutors, icon: <MdPerson3 /> },
    { label: 'Días Festivos', path: menuRoutes.settings.path + menuRoutes.settings.holidays, icon: <HiCalendarDays /> },
];

const SettingsSidebar: React.FC = () => {

    const { setShowCacheModal } = useSettingsProvider();
    const { setShowImportCRMModal } = useSettingsProvider();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { selectedTab } = useSelector((state: RootState) => state.settings);
    const location = useLocation();
    const pathname = location.pathname;
    const hash = location.hash.replace('#', '');

    useEffect(() => {
        if (pathname !== selectedTab) navigate(selectedTab);
    }, [selectedTab])

    return (
        <SimpleBar className="lg:pt-6 lg:ps-6">
            <div className="flex flex-col items-left justify-start h-full p-4">
                <h2 className="uppercase font-bold text-gray-400 mb-4">Menú Navegación</h2>
                <div className="flex flex-row lg:flex-col text-nowrap flex-nowrap overflow-auto gap-1 scrollbar-thin">
                    {menuLinks.map((link) => {
                        let isActive = (pathname === (link.path as string));
                        let isActiveMultiple = link.path.split('#').includes(hash);
                        return (
                            <Link to={link.path}
                                key={link.label}
                                className={cn("flex flex-row flex-nowrap items-center gap-2 py-2 px-4 text-gray-500 hover:text-gray-800 hover:bg-gray-100 rounded-lg",
                                    (isActive || isActiveMultiple) ? 'bg-primary-100 text-gray-800 hover:bg-primary-300' : '',
                                )}
                                onClick={() => dispatch(setSelectedTab(link.path))}
                            >
                                {link.icon}
                                {link.label}
                            </Link>
                        )
                    })}
                    <Button
                        className='mt-2'
                        onClick={() => setShowCacheModal(true)}
                    >
                        <FaTrashAlt className='me-2' />&nbsp;
                        Limpiar Caché
                    </Button>
                    {/*<Button
                        className='mt-2'
                        onClick={() => setShowImportCRMModal(true)}
                    >
                        <FaTrashAlt className='me-2' />&nbsp;
                        Importar datos CRM
                    </Button>
                    */}
                </div>
            </div>
        </SimpleBar>
    );
};

export default SettingsSidebar;