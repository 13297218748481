import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { FaCommentAlt } from 'react-icons/fa';
import { HiOutlineEye } from 'react-icons/hi2';
import { MdOutlineModeEditOutline } from 'react-icons/md';
import { Link, useParams } from 'react-router-dom';
import { ActionIcon, Button, Checkbox, Text, Tooltip } from 'rizzui';
import { toast } from 'sonner';
import Swal from 'sweetalert2';
import DeletePopover from '../../../../../components/buttons/DeletePopover';
import StatusDropdown from '../../../../../components/forms/StatusDropdown';
import { Loader } from '../../../../../components/loader/SpinnerLogo';
import { usePrivilege } from '../../../../../components/priviledge/PriviledgeProvider';
import CustomTable from '../../../../../components/table/CustomTable';
import { FilterDrawerView } from '../../../../../components/table/components/TableFilter';
import useContractStates from '../../../../../hooks/api-calls/contract/useContractStates';
import useFetch from '../../../../../hooks/useFetch';
import useFilters from '../../../../../hooks/useFilters';
import useHandleErrors from '../../../../../hooks/useHandleErrors';
import { menuRoutes } from '../../../../../router/menu';
import { ContractService } from '../../../../../services/contract/contractService';
import { OrderService } from '../../../../../services/order/orderService';
import OrderProfileLayout from '../../OrderProfileLayout';
import ContractExportsFilters from './ContractExportsFilters';

const contractColumnOrder = [
    'id',
    'code',
    'currentContractStatus',
    'currentContractStatusDate',
    'product',
    'tutor',
    'client',
    'clientCompanyName',
    'clientCifNif',
    'studentId',
    'studentName',
    'studentFirstName',
    'studentLastName',
    'studentNif',
];

interface ContractExportsProps { }

const ContractExports: React.FC<ContractExportsProps> = ({ }) => {

    // HOOKS

    const { id = '' } = useParams<{ id: string }>();
    const { userCan } = usePrivilege();
    const { handleErrors } = useHandleErrors();
    const { getContractStatesList, isLoadingContractStates } = useContractStates({ active: true, action: 'edit' });
    const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters } = useFilters({ order: id }, [], 1, 50, { order: id });
    const service = new ContractService();

    // STATES

    const [openFilters, setOpenFilters] = useState<boolean>(false);
    const [exportAllStart, setExportAllStart] = useState(false);
    const [exportAllEnd, setExportAllEnd] = useState(false);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = await (new OrderService).getOrderContracts(filters);
        return response.getResponseData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]));

    //-------------------------------------------------------------------------------------------------------------------------------
    /**
     * @ES CAMBIAR ESTADO DEL CONTRATO
     * @EN CHANGE CONTRACT STATUS
     */
    //-------------------------------------------------------------------------------------------------------------------------------
    const handleStateChange = async (id: string, stateId: string, comment: string, entityId: string, entityName: string) => {
        var response = { success: false };
        switch (entityName) {
            case 'contract':
                try {
                    response = await (await service.changeContractStatus(id, stateId, comment)).getResponseData();
                    if (response.success) {
                        toast.success('Estado del contrato actualizado correctamente');
                    } else {
                        handleErrors(response);
                    }
                } catch (error) {
                    toast.error('Error al actualizar el estado');
                }
                break;
            case 'product':
                try {
                    response = await (await service.changeContractProductStatus(id, stateId, entityId, comment)).getResponseData();
                    if (response.success) {
                        toast.success('Estado del producto actualizado correctamente');
                    } else {
                        handleErrors(response);
                    }
                } catch (error) {
                    toast.error('Error al actualizar el estado del producto');
                }
                break;
            case 'commission':
                try {
                    response = await (await service.changeContractCommissionStatus(id, stateId, comment)).getResponseData();
                    if (response.success) {
                        toast.success('Estado de la comisión actualizado correctamente');
                    } else {
                        handleErrors(response);
                    }
                } catch (error) {
                    toast.error('Error al actualizar el estado de la comisión');
                }
                break;
            default:
                break;
        }
        refetch();
    };
    //-------------------------------------------------------------------------------------------------------------------------------

    //-------------------------------------------------------------------------------------------------------------------------------
    /**
     * @ES ELIMINAR CONTRATO
     * @EN DELETE CONTRACT
     */
    //-------------------------------------------------------------------------------------------------------------------------------
    const handleDelete = async (id: string) => {
        const response = await (await service.deleteContract(id)).getResponseData();
        if (response.success) {
            toast.success('Contrato eliminado correctamente');
            refetch();
        } else {
            handleErrors(response);
        }
    };
    //-------------------------------------------------------------------------------------------------------------------------------

    //-------------------------------------------------------------------------------------------------------------------------------
    /**
     * @ES ELIMINAR VARIOS CONTRATOS
     * @EN DELETE MULTIPLE CONTRACTS
     */
    //-------------------------------------------------------------------------------------------------------------------------------
    const handleMultiDelete = async (ids: string[]) => {
        const response = await (await service.deleteMultiContracts(ids)).getResponseData();
        if (response.success) {
            toast.success('Contratos eliminados correctamente');
            refetch();
        } else {
            handleErrors(response);
        }
    };
    //-------------------------------------------------------------------------------------------------------------------------------

    //-------------------------------------------------------------------------------------------------------------------------------
    /**
     * @ES EXPORTAR CONTRATOS
     * @EN EXPORT CONTRACTS
     */
    //-------------------------------------------------------------------------------------------------------------------------------
    const handleExport = async (ids: string[]) => {
        try {
            const response = (await (new ContractService).exportContracts({ filter_filters: { contracts_to_export: ids } }));
            if (response) {
                const fileData = response.getResponseData();
                const blob = new Blob([fileData]);
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `contratos_${moment().format('DD-MM-YYYY')}.xlsx`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } else {
                toast.error('Error al exportar los contratos a excel');
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };
    //-------------------------------------------------------------------------------------------------------------------------------

    //-------------------------------------------------------------------------------------------------------------------------------
    /**
     * @ES DESCARGA EL XML
     * @EN DOWNLOADS THE XML
     */
    //-------------------------------------------------------------------------------------------------------------------------------
    const downloadXML = (xmlContent: string, fileName: string) => {
        const blob = new Blob([xmlContent], { type: "application/xml" });
        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();

        // Limpiar memoria
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };
    //-------------------------------------------------------------------------------------------------------------------------------

    //-------------------------------------------------------------------------------------------------------------------------------
    /**
     * @ES GENERA EL XML DE INICIOS O FINALIZACIONES DE UN CONTRATO
     * @EN GENERATES THE XML OF STARTS OR ENDS OF A CONTRACT
     */
    //-------------------------------------------------------------------------------------------------------------------------------
    const handleGenerateXML = async (id: string, type: 'start' | 'end') => {
        switch (type) {
            case 'start':
                try {
                    const response = await (await (new ContractService()).verifyContractDataBeforeExport(id, exportAllStart)).getResponseData();
                    if (response.success) {
                        Swal.fire({
                            title: 'Generar XML de inicios',
                            text: `${response.message}`,
                            icon: 'info',
                            showCancelButton: true,
                            confirmButtonColor: '#009737',
                            cancelButtonColor: '#e5133d',
                            confirmButtonText: 'Confirmar',
                            cancelButtonText: 'Cancelar',
                        }).then(async (result) => {
                            if (result.isConfirmed) {
                                try {
                                    const xmlContent = await (await (new ContractService()).generateXMLStart(id, exportAllStart, true)).getResponseData();

                                    if (xmlContent) {
                                        downloadXML(xmlContent, "export.xml");
                                    } else {
                                        toast.error('Error al generar el XML de inicios');
                                    }
                                } catch (error) {
                                    toast.error('Error al generar el XML de inicios');
                                }
                            }
                        });
                    } else {
                        handleErrors(response);
                    }
                } catch (error) {
                    toast.error('Error al verificar los datos del contrato');
                }
                break;

            case 'end':
                try {
                    const response = await (await (new ContractService()).verifyContractDataBeforeExport(id, exportAllEnd)).getResponseData();
                    if (response.success) {
                        Swal.fire({
                            title: 'Generar XML de finalizaciones',
                            text: `${response.message}`,
                            icon: 'info',
                            showCancelButton: true,
                            confirmButtonColor: '#009737',
                            cancelButtonColor: '#e5133d',
                            confirmButtonText: 'Confirmar',
                            cancelButtonText: 'Cancelar',
                        }).then(async (result) => {
                            if (result.isConfirmed) {
                                try {
                                    const xmlContent = await (await (new ContractService()).generateXMLEnd(id, exportAllEnd, true)).getResponseData();

                                    if (xmlContent) {
                                        downloadXML(xmlContent, "export.xml");
                                    } else {
                                        toast.error('Error al generar el XML de finalizaciones');
                                    }
                                } catch (error) {
                                    toast.error('Error al generar el XML de finalizaciones');
                                }
                            }
                        });

                    } else {
                        handleErrors(response);
                    }
                } catch (error) {
                    toast.error('Error al verificar los datos del contrato');
                }
                break;

            default:
                break;
        }
    };
    //-------------------------------------------------------------------------------------------------------------------------------

    /**
     * Order the data to match the column order
     */
    const orderedData = () => data?.data.map((row: any) => {
        const orderedRow: any = {};
        contractColumnOrder.forEach((key) => {
            orderedRow[key] = row[key];
        });
        return orderedRow;
    });

    // RENDER

    return (
        <OrderProfileLayout>
            <>
                {!isLoadingContractStates && data !== undefined
                    ? (
                        <CustomTable
                            largeTable
                            id={'contracts-table'}
                            columnOrder={contractColumnOrder}
                            data={orderedData()}
                            isLoading={loading}
                            columnsNotShown={[
                                'currentContractStatusDate',
                                'clientCompanyName',
                                'clientCifNif',
                                'studentId',
                                'studentFirstName',
                                'studentLastName',
                                'studentNif',
                            ]}
                            overrideColumns={[
                                {
                                    key: 'code',
                                    render: (row: any) => {
                                        return (
                                            userCan('get_contracts', 'contracts')
                                                ? (
                                                    <Link to={`${menuRoutes.contracts.path}/${row.row.original.id}/profile${menuRoutes.contracts.profile.info}`}>
                                                        <Text className='font-bold text-primary'>{row.row.original.code}</Text>
                                                    </Link>
                                                )
                                                : <Text className='font-bold text-primary'>{row.row.original.code}</Text>
                                        );
                                    }
                                },
                                {
                                    key: 'currentContractStatus',
                                    render: (row: any) => {
                                        return (
                                            <div className='flex flex-col gap-2 justify-content-center text-center' key={row.row.original.id}>
                                                <StatusDropdown
                                                    entityId={row.row.original?.id}
                                                    title="¿Estas seguro de cambiar el estado del contrato?"
                                                    statesOptions={getContractStatesList(null, row.row.original?.currentContractStatus?.id)}
                                                    currentState={row.row.original?.currentContractStatus}
                                                    currentStateDate={row.row.original?.currentContractStatusDate?.date}
                                                    handleStateChange={(entityId: string, statusId: string, comment: string) =>
                                                        handleStateChange(entityId, statusId, comment, entityId, 'contract')
                                                    }
                                                />
                                            </div>
                                        );
                                    }
                                },
                                {
                                    key: 'product',
                                    render: (row: any) => {
                                        const product = row.row.original?.product;
                                        return (
                                            <div className='flex flex-col'>
                                                {product
                                                    ? (
                                                        <Link
                                                            to={`${menuRoutes.products.path}?filter_filters=%7B"active"%3Atrue%2C"search_text"%3A"${product.name}"%7D&filter_order=%5B%5D&page=1&limit=50`}
                                                            className='font-bold text-primary'
                                                        >
                                                            {product.code} - {product.name}
                                                        </Link>
                                                    )
                                                    : 'N/A'
                                                }
                                            </div>
                                        )
                                    }
                                },
                                {
                                    key: 'tutor',
                                    render: (row: any) => {
                                        const tutor = row.row.original.tutor;
                                        return (
                                            tutor
                                                ? userCan('get_tutors', 'tutors')
                                                    ? (
                                                        <>
                                                            <Link to={`${menuRoutes.tutors.path}/${tutor?.id}/profile${menuRoutes.tutors.profile.info}`}>
                                                                <Text className='font-bold text-primary'>{`${tutor?.name} ${tutor?.lastName || ''}`}</Text>
                                                            </Link>
                                                            {tutor?.nifCif && <div className='d-block'>{tutor?.nifCif}</div>}
                                                        </>
                                                    )
                                                    : <Text className='font-bold text-primary'>`${tutor?.name} ${tutor?.lastName || ''}`</Text>
                                                : <Text>N/A</Text>
                                        )
                                    },
                                },
                                {
                                    key: 'client',
                                    render: (row: any) => {
                                        const client = row.row.original?.client;
                                        return (
                                            <>
                                                {client
                                                    ? userCan('get_clients', 'clients')
                                                        ? (
                                                            <div>
                                                                <Link
                                                                    to={`${menuRoutes.clients.path}/${client?.id}/profile/info`}
                                                                    className='font-bold text-primary cursor-pointer d-block'
                                                                >
                                                                    {row.row.original?.clientCompanyName || 'N/A'}
                                                                </Link>
                                                                {row.row.original?.clientCifNif && <div className='d-block'>{row.row.original?.clientCifNif}</div>}
                                                                {userCan('get_clients_comments', 'clients') && client.lastComment && (
                                                                    <Tooltip
                                                                        size="sm"
                                                                        content={<div className='tooltip-container'>{client.lastComment}</div>}
                                                                        placement="top"
                                                                        color="invert"
                                                                    >
                                                                        <ActionIcon
                                                                            as="span"
                                                                            size="sm"
                                                                            variant="text"
                                                                            className="hover:!border-gray-900 hover:text-gray-700"
                                                                        >
                                                                            <FaCommentAlt className="h-4 w-4" size={20} />
                                                                        </ActionIcon>
                                                                    </Tooltip>
                                                                )}
                                                            </div>
                                                        )
                                                        : 'N/A'
                                                    :
                                                    (<span className='font-bold text-primary'>{row.row.original?.clientCompanyName || row.row.original?.clientCifNif || 'N/A'}</span>)
                                                }
                                            </>
                                        )
                                    }
                                },
                                {
                                    key: 'studentName',
                                    render: (row: any) => {
                                        const name = row.row.original?.studentName
                                            ? `${row.row.original?.studentName} ${row.row.original?.studentFirstName ? ' ' + row.row.original?.studentFirstName : ''} ${row.row.original?.studentLastName ? ' ' + row.row.original?.studentLastName : ''}`
                                            : null;
                                        return (
                                            <>
                                                {row.row.original?.studentId
                                                    ? userCan('get_clients', 'clients')
                                                        ? (
                                                            <div>
                                                                <Link
                                                                    to={`${menuRoutes.students.path}/${row.row.original?.studentId}/profile/info`}
                                                                    className='font-bold text-primary cursor-pointer d-block'
                                                                >
                                                                    {name || 'N/A'}
                                                                </Link>
                                                                {row.row.original?.studentNif && <div className='d-block'>{row.row.original?.studentNif}</div>}
                                                            </div>
                                                        )
                                                        : 'N/A'
                                                    :
                                                    (<span>{name || row.row.original?.studentNif || 'N/A'}</span>)
                                                }
                                            </>
                                        )
                                    }
                                },
                            ]}
                            actions={[
                                {
                                    label: 'View',
                                    permissions: { group: 'user', permission: 'get_user' },
                                    render: (row: any) => {
                                        return (
                                            <Tooltip
                                                size="sm"
                                                content={'Ver detalles'}
                                                placement="top"
                                                color="invert"
                                            >
                                                <Link
                                                    to={`${menuRoutes.contracts.path}/${row.id}/profile${menuRoutes.contracts.profile.info}`}>
                                                    <ActionIcon
                                                        as="span"
                                                        size="sm"
                                                        variant="outline"
                                                        className="hover:!border-gray-900 hover:text-gray-700"
                                                    >
                                                        <HiOutlineEye className="h-4 w-4" size={20} />
                                                    </ActionIcon>
                                                </Link>
                                            </Tooltip>
                                        );
                                    },
                                },
                                {
                                    label: 'Edit',
                                    permissions: { group: 'contracts', permission: 'edit_contracts' },
                                    render: (row: any) => {
                                        return (
                                            <Tooltip
                                                size="sm"
                                                content={'Editar contrato'}
                                                placement="top"
                                                color="invert"
                                            >
                                                <Link to={`${menuRoutes.contracts.path}/${row.id}/edit`}>
                                                    <ActionIcon
                                                        as="span"
                                                        size="sm"
                                                        variant="outline"
                                                        className="hover:!border-gray-900 hover:text-gray-700"
                                                    >
                                                        <MdOutlineModeEditOutline className="h-4 w-4" size={20} />
                                                    </ActionIcon>
                                                </Link>
                                            </Tooltip>
                                        );
                                    },
                                },
                                {
                                    label: 'Delete',
                                    permissions: { group: 'contracts', permission: 'delete_contracts' },
                                    render: (row: any) => {
                                        return (
                                            <Tooltip
                                                size="sm"
                                                content={'Eliminar'}
                                                placement="top"
                                                color="invert"
                                            >
                                                <div>
                                                    <DeletePopover
                                                        title={`Eliminar contrato`}
                                                        description={`¿Estás seguro de que deseas eliminar el contrato ${row.code}?`}
                                                        onDelete={() => handleDelete(row.id)}
                                                    />
                                                </div>
                                            </Tooltip>
                                        );
                                    },
                                },
                            ]}
                            multiActions={[
                                {
                                    label: 'Exportar',
                                    permissions: { group: 'contracts', permission: 'export_contracts' },
                                    render: (selectedRows: any) => {
                                        return (
                                            <Button
                                                variant={'outline'}
                                                color={'secondary'}
                                                size={'sm'}
                                                onClick={() => handleExport(selectedRows)}
                                                className='p-3 text-nowrap'
                                            >
                                                Exportar
                                            </Button>
                                        )
                                    },
                                },
                            ]}
                            handleMultipleDelete={handleMultiDelete}
                            filters={filters}
                            updateFilters={updateFilters}
                            updateFilterOrder={updateFilterOrder}
                            defaultOrder={filters.filter_order || undefined}
                            paginationData={{
                                pageSize: filters.limit,
                                currentPage: filters.page,
                                pageCount: data ? data.lastPage : 1,
                                totalCount: data?.totalRegisters,
                                handlePagination: updatePage,
                                handlePerPage: updatePageSize,
                            }}
                            toggleFilters={() => setOpenFilters(!openFilters)}
                        >

                            {userCan('print_contract', 'contracts') && (
                                <>
                                    <div className='flex items-center'>
                                        <Checkbox
                                            label={''}
                                            checked={exportAllStart}
                                            onChange={() => setExportAllStart(!exportAllStart)}
                                            className='me-2'
                                        />

                                        <Tooltip content={exportAllStart ? 'Generar XML de todos los inicios' : 'Generar XML de los inicios restantes (los que aún no han sido generados)'} placement={'top'} color={'invert'} size={'md'}>
                                            <Button
                                                variant={'outline'} color={'primary'} size={'md'}
                                                onClick={() => { handleGenerateXML(id, 'start') }}
                                                className='text-nowrap flex-1'
                                            >
                                                {exportAllStart ? 'XML todos los inicios' : 'XML inicios restantes'}
                                            </Button>
                                        </Tooltip>
                                    </div>

                                    <div className='flex items-center ms-md-3'>
                                        <Checkbox
                                            label={''}
                                            checked={exportAllEnd}
                                            onChange={() => setExportAllEnd(!exportAllEnd)}
                                            className='me-2'
                                        />

                                        <Tooltip content={exportAllEnd ? 'Generar XML de todas las finalizaciones' : 'Generar XML de las finalizaciones restantes (los que aún no han sido generados)'} placement={'top'} color={'invert'} size={'md'}>
                                            <Button
                                                variant={'outline'} color={'primary'} size={'md'}
                                                onClick={() => { handleGenerateXML(id, 'end') }}
                                                className='text-nowrap flex-1'
                                            >
                                                {exportAllEnd ? 'XML todas las finalizaciones' : 'XML finalizaciones restantes'}
                                            </Button>
                                        </Tooltip>
                                    </div>
                                </>
                            )}
                        </CustomTable>
                    )
                    : <Loader height="70vh" />
                }

                <FilterDrawerView isOpen={openFilters} setOpenDrawer={setOpenFilters} drawerTitle={'Filtros Exportación de Contratos'}>
                    <ContractExportsFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} />
                </FilterDrawerView>
            </>
        </OrderProfileLayout>
    );
};

export default ContractExports;