import { SVGProps } from 'react';

const SvgPolicy = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
			<path fill='none' d='M0 0h24v24H0z' />
			<path
				d='M5 6.3V11c0 4.52 2.98 8.69 7 9.93 1.74-.53 3.28-1.62 4.47-3.04l-1.72-1.72a4.994 4.994 0 01-6.29-.64 5.003 5.003 0 010-7.07 5.003 5.003 0 017.07 0 5.006 5.006 0 01.64 6.29l1.45 1.45C18.49 14.65 19 12.85 19 11V6.3l-7-3.11L5 6.3z'
				opacity={0.3}
			/>
			<path d='M12 1L3 5v6c0 5.55 3.84 10.74 9 12 .65-.16 1.27-.38 1.87-.65 1.8-.82 3.36-2.13 4.57-3.74C20.04 16.46 21 13.77 21 11V5l-9-4zm7 10c0 1.85-.51 3.65-1.38 5.21l-1.45-1.45a4.994 4.994 0 00-.64-6.29 5.003 5.003 0 00-7.07 0 5.003 5.003 0 000 7.07 5.006 5.006 0 006.29.64l1.72 1.72c-1.19 1.42-2.73 2.51-4.47 3.04-4.02-1.25-7-5.42-7-9.94V6.3l7-3.11 7 3.11V11zm-4 1c0 1.66-1.34 3-3 3s-3-1.34-3-3 1.34-3 3-3 3 1.34 3 3z' />
		</svg>
	);
};

export default SvgPolicy;
