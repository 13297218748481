import { SVGProps } from 'react';

const SvgSnippetFolder = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
			<path fill='none' d='M0 0h24v24H0z' />
			<path d='M9.17 6H4v12h16V8h-8.83l-2-2z' opacity={0.3} />
			<path d='M20 6h-8l-2-2H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm0 12H4V6h5.17l2 2H20v10zm-2.5-5.88v3.38h-3v-5h1.38l1.62 1.62zM16.5 9H13v8h6v-5.5L16.5 9z' />
		</svg>
	);
};

export default SvgSnippetFolder;
