import { SVGProps } from 'react';

const SvgCloudCircle = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
			<path d='M0 0h24v24H0V0z' fill='none' />
			<path
				d='M12 4c-4.41 0-8 3.59-8 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8zm4.08 12H8.5a3.5 3.5 0 01-.38-6.98 4.373 4.373 0 018.17 1.16c1.52.1 2.71 1.35 2.71 2.89 0 1.62-1.31 2.93-2.92 2.93z'
				opacity={0.3}
			/>
			<path d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.29-9.81a4.373 4.373 0 00-8.17-1.16A3.483 3.483 0 005 12.5C5 14.43 6.57 16 8.5 16h7.58c1.61 0 2.92-1.31 2.92-2.92 0-1.54-1.2-2.79-2.71-2.89zM16 14H8.5c-.83 0-1.5-.67-1.5-1.5S7.67 11 8.5 11h.9l.49-1.05a2.377 2.377 0 014.44.63l.28 1.42H16c.55 0 1 .45 1 1s-.45 1-1 1z' />
		</svg>
	);
};

export default SvgCloudCircle;
