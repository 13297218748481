import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { Button, Switch, Tooltip } from 'rizzui';
import { toast } from 'sonner';
import DeletePopover from '../../../components/buttons/DeletePopover';
import { Loader } from '../../../components/loader/SpinnerLogo';
import TabNav, { MenuItem } from "../../../components/navigation/TabNav";
import { usePrivilege } from '../../../components/priviledge/PriviledgeProvider';
import useFetch from '../../../hooks/useFetch';
import useHandleErrors from '../../../hooks/useHandleErrors';
import PageHeader from "../../../layout/shared/page-header";
import { RootState } from '../../../redux/store';
import { setSelectedView } from '../../../redux/studentSlice';
import { menuRoutes } from "../../../router/menu";
import { StudentService } from '../../../services/student/StudentService';
import { StudentApiResponse } from '../../../type/entities/student-type';

const menuItems: MenuItem[] = [
    {
        label: 'Información',
        path: menuRoutes.students.profile.info,
        permission: {
            group: 'students',
            action: 'get_students',
        },
    },
    {
        label: 'Actividad',
        path: menuRoutes.students.profile.activityHistory,
        permission: {
            group: 'students',
            action: 'student_changes_log',
        },
    },
];

export default function StudentProfileLayout({ isLoading, children }: { isLoading?: boolean, children: React.ReactNode }) {

    const { selectedView } = useSelector((state: RootState) => state.student);
    const { id = '' } = useParams<{ id: string }>();
    const { userCan } = usePrivilege();
    const navigate = useNavigate();
    const { handleErrors } = useHandleErrors();
    const service = new StudentService();

    const [changingStatus, setChangingStatus] = useState<string[]>([]);

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        if (!id || id === '') return null;
        const response = await service.getStudentById(id);
        return response.getResponseData() as StudentApiResponse;
    }, [id]));

    const pageHeader = {
        title: `Perfil de ${data && data?.name} ${data && (data?.firstName) ? data?.firstName : ''} ${data && (data?.lastName) ? data?.lastName : ''}`,
        breadcrumb: [
            { name: 'Ventas' },
            { name: 'Gestión de empleados' },
            { name: 'Empleados', href: menuRoutes.students.path },
            { name: 'Perfil de Empleado' },
        ],
    };

    const toggleState = async (id: string, status: boolean) => {
        try {
            setChangingStatus([...changingStatus, id]);
            const response = (await service.toggleStudentState(id, status)).getResponseData();
            if (response.success) {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                refetch();
                toast.success(status ? "Se ha desactivado el empleado" : "Se ha activado el empleado");
            } else {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                toast.error(response.message);
            }
        } catch (error: any) {
            setChangingStatus(changingStatus.filter((item) => item !== id));
            toast.error(error.message);
        }
    };

    const handleDelete = async (id: string) => {
        try {
            const response = await (await service.deleteStudent(id)).getResponseData();
            if (response.success) {
                navigate(menuRoutes.students.path);
                toast.success('Empleado eliminado correctamente');
            } else {
                handleErrors(response);
            }
        } catch (error) {
            handleErrors(error);
        }
    };

    useEffect(() => {
        navigate(`${menuRoutes.students.path}/${id}/profile${selectedView}`);
    }, [selectedView]);

    return (
        <>
            <PageHeader title={data ? pageHeader.title : 'Perfil del empleado'} breadcrumb={pageHeader.breadcrumb}>
                <div className='flex flex-row flex-nowrap gap-3 mt-3 md:mt-0'>
                    {userCan('edit_students', 'students') && (
                        <>
                            {data && (
                                <Tooltip
                                    size="sm"
                                    content={data.active ? 'Desactivar empleado' : 'Activar empleado'}
                                    placement="top"
                                    color="invert"
                                >
                                    <Switch
                                        id={id}
                                        label={data.active ? 'Activo' : 'Desactivado'}
                                        checked={data.active}
                                        onChange={() => toggleState(data.id, data.active)}
                                        switchKnobClassName='bg-secondary'
                                    />
                                </Tooltip>
                            )}

                            <div className='border-l border-gray-300' />

                            <Tooltip content={'Editar empleado'} placement={'top'} color={'invert'} size={'sm'}>
                                <Button variant={'outline'} color={'primary'} size={'md'} onClick={() => {
                                    navigate(`${menuRoutes.students.path}/${id}/edit`)
                                }}>
                                    Editar
                                </Button>
                            </Tooltip>
                        </>
                    )}

                    {userCan('delete_students', 'students') && (
                        <Tooltip content={'Eliminar empleado'} placement={'top'} color={'invert'} size={'sm'} className='text-danger'>
                            <DeletePopover
                                title={`Eliminar empleado`}
                                description={`¿Estás seguro de que deseas eliminar el empleado?`}
                                size={'md'}
                                onDelete={() => handleDelete(id)}
                                actionIconClassName='h-auto w-10'
                            />
                        </Tooltip>
                    )}
                </div>
            </PageHeader>

            <TabNav menuItems={menuItems} setSelectedView={setSelectedView} />

            {isLoading ? <Loader height='60vh' /> : children}
        </>
    );
}