import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { Button, Tooltip } from 'rizzui';
import { toast } from 'sonner';
import DeletePopover from '../../../components/buttons/DeletePopover';
import StatusDropdown from '../../../components/forms/StatusDropdown';
import { Loader } from '../../../components/loader/SpinnerLogo';
import { usePrivilege } from '../../../components/priviledge/PriviledgeProvider';
import useOrderStatuses from '../../../hooks/api-calls/orders/useOrderStatuses';
import useFetch from '../../../hooks/useFetch';
import useHandleErrors from '../../../hooks/useHandleErrors';
import PageHeader from "../../../layout/shared/page-header";
import { RootState } from '../../../redux/store';
import { menuRoutes } from "../../../router/menu";
import { OrderService } from "../../../services/order/orderService";
import { OrderApiResponse } from "../../../type/entities/order-type";
import cn from '../../../utils/classNames';
import OrderInfoSidebar from './OrderInfoSidebar';

export default function OrderProfileLayout({ children, isLoading }: { children: React.ReactNode, isLoading?: boolean }) {

    const { selectedInfoTab } = useSelector((state: RootState) => state.orders);
    const { id = '' } = useParams<{ id: string }>();
    const { userCan } = usePrivilege();
    const { handleErrors } = useHandleErrors();
    const navigate = useNavigate();
    const { getOrderStatusesList } = useOrderStatuses();

    const [data] = useFetch(useCallback(async () => {
        if (!id || id === '') return null;
        const response = await (new OrderService()).getOrderById(id);
        return response.getResponseData() as OrderApiResponse;
    }, [id]));

    const pageHeader = {
        title: `Datos del pedido ${data?.code ? `(${data.code})` : ''}`,
        breadcrumb: [
            { name: 'Ventas' },
            { name: 'Gestión de pedidos' },
            { name: 'Pedidos', href: menuRoutes.orders.path + menuRoutes.orders.list },
            { name: 'Detalles del Pedido' },
        ],
    };

    //-------------------------------------------------------------------------------------------------------------------------------
    /**
     * @ES CONTROLA LA ELIMINACIÓN DE UN PEDIDO
     * @EN CONTROLS THE DELETION OF AN ORDER
     */
    //-------------------------------------------------------------------------------------------------------------------------------
    const handleDelete = async (id: string) => {
        try {
            const response = await (await (new OrderService()).deleteOrder(id)).getResponseData();
            if (response.success) {
                navigate(menuRoutes.orders.path + menuRoutes.orders.list);
                toast.success('Pedido eliminado correctamente');
            } else {
                handleErrors(response);
            }
        } catch (error) {
            handleErrors(error);
        }
    };
    //-------------------------------------------------------------------------------------------------------------------------------

    //-------------------------------------------------------------------------------------------------------------------------------
    /**
     * @ES CONTROLA EL CAMBIO DE ESTADO DE UN PEDIDO
     * @EN CONTROLS THE CHANGE OF ORDER STATUS
     */
    //-------------------------------------------------------------------------------------------------------------------------------
    const handleChangeStatus = async (orderId: string, statusId: string) => {
        try {
            const response = await (await (new OrderService).toggleOrderState(orderId, statusId)).getResponseData();
            if (response.success) {
                toast.success('Estado del pedido cambiado correctamente');
            } else {
                handleErrors(response);
            }
        } catch (error) {
            handleErrors(error);
        }
    };
    //-------------------------------------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (selectedInfoTab && selectedInfoTab?.length > 0) navigate(`${menuRoutes.orders.path}/${id}/profile/` + selectedInfoTab);
    }, [selectedInfoTab]);

    // RENDER

    const getHeaderActionButtons = (className: string | undefined) => {
        return (
            <div className={cn('flex flex-col sm:flex-row flex-wrap gap-3 mt-3 md:mt-0', className)}>
                {(data?.id && data?.currentStatus && data?.currentStatusDate) && (
                    <div className='flex flex-col gap-2 justify-content-center text-center' key={data?.id + data?.currentStatus.id}>
                        <StatusDropdown
                            entityId={data?.id}
                            title="Se cambiará el estado del pedido"
                            statesOptions={getOrderStatusesList()}
                            currentState={data?.currentStatus}
                            currentStateDate={data?.currentStatusDate?.date}
                            handleStateChange={(entityId: string, statusId: string) => handleChangeStatus(data?.id, statusId)}
                            hasComments={false}
                        />
                    </div>
                )}

                {userCan('edit_orders', 'orders') && (
                    <Tooltip content={'Editar pedido'} placement={'top'} color={'invert'} size={'md'}>
                        <Button variant={'outline'} color={'primary'} size={'md'} onClick={() => {
                            navigate(`${menuRoutes.orders.path}/${id}/edit`)
                        }}>
                            Editar
                        </Button>
                    </Tooltip>
                )}

                {userCan('delete_orders', 'orders') && (
                    <Tooltip content={'Eliminar pedido'} placement={'top'} color={'invert'} size={'md'} className='text-danger'>
                        <DeletePopover
                            title={`Eliminar pedido`}
                            description={`¿Estás seguro de que deseas eliminar el pedido?`}
                            size={'md'}
                            onDelete={() => handleDelete(id)}
                            actionIconClassName='h-auto w-10 h-10 m-auto sm:m-0'
                        />
                    </Tooltip>
                )}
            </div>
        );
    };

    return (
        <>
            <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb}>
                {getHeaderActionButtons("hidden lg:flex")}
            </PageHeader>

            <div className="flex flex-grow flex-col 3xl:pt-4 4xl:px-10 animate-slide-down">
                <div className="page-container flex flex-col lg:flex-row flex-nowrap w-full">
                    <div className="sidebar w-full lg:w-[20vw] overflow-hidden">
                        <OrderInfoSidebar />
                    </div>

                    {getHeaderActionButtons("flex lg:hidden mb-5 p-4")}

                    <div className="content w-full h-full lg:w-[80vw] p-2 pt-0 ">
                        {isLoading && !data ? <Loader height='60vh' /> : children}
                    </div>
                </div>
            </div>
        </>
    );
}