import { useEffect, useState } from 'react';
import { ContractService } from '../../../services/contract/contractService';

/*----------------------------------------------------------------------------------------
* EN: Hook to manage contract fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de contratos.
----------------------------------------------------------------------------------------*/

const useContract = (initialFilters = {}) => {

    const [data, setData] = useState<any[]>([]);
    const [filters, setFilters] = useState({
        limit: 999999,
        filter_filters: { ...initialFilters }
    });

    const fetchContracts = async () => {
        try {
            const service = new ContractService();
            const response = await service.listContracts(filters);
            const fetchedData = response.getResponseData().data;

            if (fetchedData && fetchedData?.data) {
                const mappedData = fetchedData.data?.map((data: { id: string; code: string; }) => ({
                    value: data.id,
                    label: data.code,
                }));
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching contracts:', error);
        }
    };

    useEffect(() => {
        fetchContracts();
    }, [filters]);

    const updateContractsHookFilters = (newFilters: any) => {
        setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
    };

    const getContractsList = () => {
        return data;
    };

    return { fetchContracts, getContractsList, updateContractsHookFilters };
}

export default useContract;