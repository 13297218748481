import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import AttivaCard from "../../../../components/card/AttivaCard";
import useHandleErrors from "../../../../hooks/useHandleErrors";
import PageHeader from "../../../../layout/shared/page-header";
import { menuRoutes } from "../../../../router/menu";
import { OrderTypeService } from "../../../../services/order-type/OrderTypeService";
import OrderTypeForm from "../OrderTypeForm";

const pageHeader = {
    title: "Crear Tipo de Pedido",
    breadcrumb: [
        { name: 'Administración' },
        { name: 'Control de errores' },
        { href: menuRoutes.orderTypes.path, name: "Tipos de Pedido" },
        { name: "Crear" },
    ],
};

const OrderTypeCreate: FC = () => {

    const { handleErrors } = useHandleErrors();
    const navigate = useNavigate();
    const service = new OrderTypeService();

    const [loading, setLoading] = useState<boolean>(false);

    const handleSubmit = async (values: any) => {
        setLoading(true);
        if (values.hoursNumber === '') values.hoursNumber = 0;
        try {
            const response = (await service.createOrderType(values)).getResponseData();

            if (response.success) {
                navigate(-1);
                toast.success("Tipo de pedido creado correctamente");
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error("Error al crear el tipo de pedido");
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb} />

            <AttivaCard title="Datos Principales" className="mb-4">
                <OrderTypeForm submit={handleSubmit} isLoading={loading} />
            </AttivaCard>
        </>
    );
}

export default OrderTypeCreate;