import { useEffect, useState } from 'react';
import { IncidenceType } from '../../../type/entities/incidence-type';
import { IncidenceService } from '../../../services/incidence/incidenceService';

/*----------------------------------------------------------------------------------------
* EN: Hook to manage incidence statuses fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de estados de incidencias.
----------------------------------------------------------------------------------------*/

const useIncidenceContractStatuses = () => {

    const [data, setData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const service = new IncidenceService();
            const response = await service.getIncidenceStatutes('contract_incidence');
            const fetchedData = response.getResponseData();

            if (fetchedData && fetchedData?.data) {
                const mappedData = fetchedData.data?.map((data: any) => ({
                    value: data.id,
                    label: data.name,
                    color: data.color,
                    initialStatus: data.initialStatus,
                    draftStatus: data.draftStatus,
                }));
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching contract incidence statuses:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setIsLoading(true);
        fetchData();
    }, []);
    

    const getList = (draftStatuses?: boolean, entity?: string) => {
        let filteredData = draftStatuses ? data : data.filter((state: any) => !state.draftStatus );
        if (entity) return filteredData.filter((state: any) => state.value !== entity);
        return filteredData;
    };

    const getEntity = (id: string) => {
        return data.find((entity: any) => entity.id === id);
    }

    return { 
        fetchIncidenceContractStatusesList: fetchData,
        getIncidenceContractStatusesList: getList,
        getIncidenceContractStatus: getEntity, 
        isLoadingIncidenceStatuses: isLoading
    };
}

export default useIncidenceContractStatuses;