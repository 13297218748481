import React from 'react';
import { FiltersProvider } from '../../../../../components/providers/FiltersProvider';
import UserProfileLayout from '../../UserProfileLayout';
import UserContractsList from './UserContracts';

interface UserContractsWrapperProps { }

const UserContractsWrapper: React.FC<UserContractsWrapperProps> = () => {

    return (
        <FiltersProvider>
            <UserProfileLayout>
                <UserContractsList />
            </UserProfileLayout>
        </FiltersProvider>
    );
};

export default UserContractsWrapper;