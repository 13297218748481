import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import SpinnerLogo from '../../../../../components/loader/SpinnerLogo';
import useFetch from '../../../../../hooks/useFetch';
import { ContractService } from '../../../../../services/contract/contractService';
import { ContractApiResponse } from '../../../../../type/entities/contract-type';
import ContractProfileLayout from '../../ContractProfileLayout';
import ClientContractFormInfo from './ClientContractFormInfo';
import ConsultancyContractFormInfo from './ConsultancyContractFormInfo';
import ContractDataFormInfo from './ContractDataFormInfo';
import ProductContractFormInfo from './ProductContractFormInfo';
import StudentContractFormInfo from './StudentContractFormInfo';

const ContractInfo = () => {

    const { id = '' } = useParams<{ id: string }>();
    const service = new ContractService();

    const [data] = useFetch(useCallback(async () => {
        if (!id || id === '') return null;
        const response = await service.getContractById(id);
        return response.getResponseData() as ContractApiResponse;
    }, [id]));

    return (
        <ContractProfileLayout>
            <div className={`flex flex-col gap-7 w-full transition-all duration-1000 ease-in-out md:h-[calc(100vh-270px)] md:overflow-y-scroll md:relative pe-2 scrollbar`} >
                {data ? (
                    <>
                        <ClientContractFormInfo data={data} />
                        <ProductContractFormInfo data={data} />
                        <ContractDataFormInfo data={data} />
                        <StudentContractFormInfo data={data} />
                        <ConsultancyContractFormInfo data={data} />
                    </>
                ) : (
                    <SpinnerLogo />
                )}
            </div>
        </ContractProfileLayout>
    );
};

export default ContractInfo;