import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

interface CustomDonutGraphProps {
    data: {
        series: number[];
        labels: string[];
        colors: string[];
        ids?: string[];
    };
    height?: string;
    total?: { value: number; label?: string };
    onClick?: (value: number, label: string, id?: string) => void;
}

export const CustomDonutGraph: React.FC<CustomDonutGraphProps> = ({ data, height, total, onClick }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    const totalLabel = total?.label || "Total";
    const maxLabelLength = 10; // Max length of the label before truncating
    const truncatedLabel = totalLabel.length > maxLabelLength ? totalLabel.substring(0, maxLabelLength) + "..." : totalLabel;

    return (
        <div style={{ position: "relative", display: "flex", justifyContent: "center" }}>
            <ReactApexChart
                series={data.series}
                type="donut"
                width={"100%"}
                height={height || "225px"}
                options={{
                    labels: data.labels,
                    legend: { show: false },
                    plotOptions: {
                        pie: {
                            donut: {
                                labels: {
                                    show: true,
                                    value: { fontSize: "16px" },
                                    total: {
                                        formatter: () =>
                                            (total !== undefined
                                                ? total.value
                                                : data.series.reduce((a, b) => a + b, 0)
                                            ).toString(),
                                        label: truncatedLabel,
                                        showAlways: true,
                                        show: true,
                                        fontSize: "16px",
                                    },
                                },
                            },
                        },
                    },
                    fill: { opacity: 0.8 },
                    colors: data.colors,
                    chart: {
                        events: {
                            dataPointSelection: (event, chartContext, config) => {
                                const dataPointIndex = config.dataPointIndex;
                                const value = data.series[dataPointIndex];
                                const label = data.labels[dataPointIndex];
                                const id = data.ids?.[dataPointIndex];
                                if (onClick) onClick(value, label, id);
                            },
                        },
                    },
                }}
            />

            {/* Invisible div to show the tooltip */}
            {totalLabel.length > maxLabelLength && (
                <div
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "80px",
                        height: "50px",
                        cursor: "pointer",
                        zIndex: 2,
                    }}
                    onMouseEnter={() => setShowTooltip(true)}
                    onMouseLeave={() => setShowTooltip(false)}
                ></div>
            )}

            {/* Tooltip with the full label */}
            {showTooltip && (
                <div
                    style={{
                        position: "absolute",
                        bottom: "60px",
                        background: "rgba(0, 0, 0, 0.7)",
                        color: "#fff",
                        padding: "5px 10px",
                        borderRadius: "4px",
                        fontSize: "12px",
                        whiteSpace: "nowrap",
                        zIndex: 3,
                    }}
                >
                    {totalLabel}
                </div>
            )}
        </div>
    );
};
