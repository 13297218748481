import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "rizzui";
import { toast } from "sonner";
import AttivaCard from "../../../components/card/AttivaCard";
import useFetch from "../../../hooks/useFetch";
import PageHeader from "../../../layout/shared/page-header";
import { menuRoutes } from "../../../router/menu";
import { OrganisationService } from "../../../services/organisation/organisationService";
import { Organisation } from "../../../type/entities/organisation-type";
import OrganisationForm from "../OrganisationForm";

const pageHeader = {
  title: "Editar Organización",
  breadcrumb: [
    {
      href: menuRoutes.organisations.path,
      name: "Organizaciones",
    },
    {
      name: "Editar",
    },
  ],
};

export default function OrganisationEdit() {

  const { id = "" } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const service = new OrganisationService();

  const [loading, setLoading] = useState<boolean>(false);

  const [data] = useFetch(useCallback(async () => {
    const response = await service.getOrganisationById(id as string);
    return response.getResponseData() as Organisation;
  }, [id]));

  const handleSubmit = async (values: any) => {
    setLoading(true);

    const formData = new FormData();

    for (let value in values) {
      if (values[value]) {
        formData.append(value, values[value]);
      }
    }

    try {
      const response = await (
        await service.editOrganisation(formData, true)
      ).getResponseData();
      if (response.success) {
        setLoading(false);
        navigate(-1);
        setTimeout(() => {
          toast.success("Organización editada");
        }, 100);
      } else {
        response.data.errors.forEach((error: any) => {
          toast.error(error.message);
        });
      }
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Loader />;

  return (
    <>
      <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb} />

      <AttivaCard title="Datos Principales" className="mb-4">
        {(data !== null && data !== undefined) && <OrganisationForm data={data} submit={handleSubmit} isLoading={loading} />}
      </AttivaCard>
    </>
  );
}