import { formatDistance } from "date-fns";
import { es } from "date-fns/locale";
import { capitalize } from "lodash";
import { useCallback, useState } from "react";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import { ActionIcon, Tooltip } from "rizzui";
import { toast } from "sonner";
import DeletePopover from "../../../../components/buttons/DeletePopover";
import { useFiltersPR } from "../../../../components/providers/FiltersProvider";
import { FilterDrawerView } from "../../../../components/table/components/TableFilter";
import CustomTable from "../../../../components/table/CustomTable";
import useFetch from "../../../../hooks/useFetch";
import useHandleErrors from "../../../../hooks/useHandleErrors";
import Page from '../../../../layout/Page/Page';
import { menuRoutes } from "../../../../router/menu";
import { IncidenceTypeService } from "../../../../services/incidence-type/incidenceTypeService";
import { IncidenceTypesApiResponse } from "../../../../type/entities/incidence-type";
import IncidenceTypesFilters from "./IncidenceTypesFilters";

const columnOrder = [
    'id',
    'name',
    'description',
    'updatedAt',
    'createdAt'
];

const IncidenceTypesList = () => {
    // Implement your component logic here
    const service = new IncidenceTypeService();
    const { handleErrors } = useHandleErrors();

    const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters } = useFiltersPR();
    const [openFilters, setOpenFilters] = useState<boolean>(false);

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = await service.listIncidenceTypes(filters);
        return response.getResponseData() as IncidenceTypesApiResponse;
    }, [filters]));

    /**
     * Order the data to match the column order
     */
    const orderedData = () => data?.data.map((row: any) => {
        const orderedRow: any = {};
        columnOrder.forEach((key) => {
            orderedRow[key] = row[key];
        });
        return orderedRow;
    });

    const handleDelete = async (id: string) => {
        try {
            const response = await (await service.deleteIncidenceType(id)).getResponseData();
            if (response.success) {
                refetch();
                toast.success('Tipo de Incidencia eliminado correctamente');
            } else {
                handleErrors(response);
            }
        } catch (error) {
            handleErrors(error);
        }
    };

    const handleMultiDelete = async (ids: string[]) => {
        try {
            const response = await (await service.deleteMultiIncidenceTypes(ids)).getResponseData();
            if (response.success) {
                refetch();
                toast.success('Tipos de Incidencias eliminados correctamente');
            } else {
                handleErrors(response);
            }
        } catch (error) {
            handleErrors(error);
        }
    };

    return (
        <Page container='fluid'>
            {data !== undefined && (
                <CustomTable
                    id={'incidence-types-table'}
                    columnOrder={columnOrder}
                    data={orderedData()}
                    isLoading={loading}
                    columnsNotShown={['lastName', 'department']}
                    overrideColumns={[
                        {
                            key: 'name',
                            render: (row: any) => {
                                return row.row.original.name ?? 'N/A';
                            }
                        },
                        {
                            key: 'description',
                            render: (row: any) => {
                                return row.row.original.description ?? 'N/A';
                            }
                        },
                        {
                            key: 'Actualización',
                            render: (row: any) => {
                                const date = row.row.original?.lastLogin?.date;
                                return date ? capitalize(formatDistance(new Date(date), new Date(), { addSuffix: true, locale: es })) : 'Nunca';
                            },  
                        },
                        {
                            key: 'Creación',
                            render: (row: any) => {
                                const date = row.row.original?.lastLogin?.date;
                                return date ? capitalize(formatDistance(new Date(date), new Date(), { addSuffix: true, locale: es })) : 'Nunca';
                            },
                        },
                        
                    ]}
                    actions={[
                        {
                            label: 'Edit',
                            permissions: { group: 'incidence_types', permission: 'edit_incidence_types' },
                            render: (row: any) => {
                                return (
                                    <Tooltip
                                        size="sm"
                                        content={'Editar Tipo de Incidencia'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <Link to={`${menuRoutes.incidenceTypes.path}/${row.id}/edit`}>
                                            <ActionIcon
                                                as="span"
                                                size="sm"
                                                variant="outline"
                                                className="hover:!border-gray-900 hover:text-gray-700"
                                            >
                                                <MdOutlineModeEditOutline className="h-4 w-4" size={20} />
                                            </ActionIcon>
                                        </Link>
                                    </Tooltip>
                                );
                            },
                        },
                        {
                            label: 'Delete',
                            permissions: { group: 'incidence_types', permission: 'delete_incidence_types' },
                            render: (row: any) => {
                                return (
                                    <Tooltip
                                        size="sm"
                                        content={'Eliminar'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <div>
                                            <DeletePopover
                                                title={`Eliminar Tipo de Incidencia`}
                                                description={`¿Estás seguro de que deseas eliminar el tipo ${row.name}?`}
                                                onDelete={() => handleDelete(row.id)}
                                            />
                                        </div>
                                    </Tooltip>
                                );
                            },
                        },
                    ]}
                    handleMultipleDelete={handleMultiDelete}
                    multiDeletePermission={{ group: 'incidence_types', action: 'delete_incidence_types' }}
                    filters={filters}
                    updateFilters={updateFilters}
                    updateFilterOrder={updateFilterOrder}
                    defaultOrder={filters.filter_order || undefined}
                    paginationData={{
                        pageSize: filters.limit,
                        currentPage: filters.page,
                        pageCount: (data as IncidenceTypesApiResponse) ? data.lastPage : 1,
                        totalCount: data?.totalRegisters,
                        handlePagination: updatePage,
                        handlePerPage: updatePageSize,
                    }}
                    toggleFilters={() => setOpenFilters(!openFilters)}
                />
            )}

            <FilterDrawerView isOpen={openFilters} setOpenDrawer={setOpenFilters} drawerTitle={'Filtros Tipo de Incidencias'}>
                <IncidenceTypesFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} />
            </FilterDrawerView>
        </Page>
    );
};

export default IncidenceTypesList;