import { useCallback, useState } from "react";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import { ActionIcon, Tooltip } from "rizzui";
import { toast } from "sonner";
import DeletePopover from "../../../../components/buttons/DeletePopover";
import { useFiltersPR } from "../../../../components/providers/FiltersProvider";
import CustomTable from "../../../../components/table/CustomTable";
import { FilterDrawerView } from "../../../../components/table/components/TableFilter";
import useFetch from "../../../../hooks/useFetch";
import useHandleErrors from "../../../../hooks/useHandleErrors";
import Page from '../../../../layout/Page/Page';
import { menuRoutes } from "../../../../router/menu";
import { OrderTypeService } from "../../../../services/order-type/OrderTypeService";
import { OrderTypesApiResponse } from "../../../../type/entities/order-type";
import OrderTypesFilters from "./OrderTypesFilters";

const columnOrder = [
    'id',
    'name',
    'description',
    'color',
    'hoursNumber',
    'updatedAt',
    'createdAt',
];

const OrderTypesList = () => {

    const { handleErrors } = useHandleErrors();
    const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters } = useFiltersPR();
    const service = new OrderTypeService();

    const [openFilters, setOpenFilters] = useState<boolean>(false);

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = await service.listOrderTypes(filters);
        return response.getResponseData() as OrderTypesApiResponse;
    }, [filters]));

    const orderedData = () => data?.data.map((row: any) => {
        const orderedRow: any = {};
        columnOrder.forEach((key) => {
            orderedRow[key] = row[key];
        });
        return orderedRow;
    });

    const handleDelete = async (id: string) => {
        try {
            const response = await (await service.deleteOrderType(id)).getResponseData();
            if (response.success) {
                refetch();
                toast.success('Tipo de pedido eliminado correctamente');
            } else {
                handleErrors(response);
            }
        } catch (error) {
            handleErrors(error);
        }
    };

    const handleMultiDelete = async (ids: string[]) => {
        try {
            const response = await (await service.deleteMultiOrderTypes(ids)).getResponseData();
            if (response.success) {
                refetch();
                toast.success('Tipos de pedidos eliminados correctamente');
            } else {
                handleErrors(response, refetch);
            }
        } catch (error) {
            handleErrors(error);
        }
    };

    return (
        <Page container='fluid'>
            {data !== undefined && (
                <CustomTable
                    id={'order-types-table'}
                    columnOrder={columnOrder}
                    data={orderedData()}
                    isLoading={loading}
                    overrideColumns={[
                        {
                            key: 'color',
                            render: (row: any) => {
                                if (!row.row.original?.color) return 'N/A';
                                return (
                                    <div
                                        key={row.row.original.id + row.row.original?.color}
                                        style={{
                                            backgroundColor: row.row.original?.color,
                                            border: "1px solid rgba(0, 0, 0, 0.1)",
                                            width: "35px",
                                            height: "35px",
                                        }}
                                        className={'rounded-full mr-3 p-3'}
                                    />
                                );
                            }
                        },
                    ]}
                    actions={[
                        {
                            label: 'Edit',
                            permissions: { group: 'order_types', permission: 'edit_order_types' },
                            render: (row: any) => {
                                return (
                                    <Tooltip
                                        size="sm"
                                        content={'Editar Tipo de Pedido'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <Link to={`${menuRoutes.orderTypes.path}/${row.id}/edit`}>
                                            <ActionIcon
                                                as="span"
                                                size="sm"
                                                variant="outline"
                                                className="hover:!border-gray-900 hover:text-gray-700"
                                            >
                                                <MdOutlineModeEditOutline className="h-4 w-4" size={20} />
                                            </ActionIcon>
                                        </Link>
                                    </Tooltip>
                                );
                            },
                        },
                        {
                            label: 'Delete',
                            permissions: { group: 'order_types', permission: 'delete_order_types' },
                            render: (row: any) => {
                                return (
                                    <Tooltip
                                        size="sm"
                                        content={'Eliminar'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <div>
                                            <DeletePopover
                                                title={`Eliminar Tipo de Pedido`}
                                                description={`¿Estás seguro de que deseas eliminar el tipo ${row.name}?`}
                                                onDelete={() => handleDelete(row.id)}
                                            />
                                        </div>
                                    </Tooltip>
                                );
                            },
                        },
                    ]}
                    handleMultipleDelete={handleMultiDelete}
                    multiDeletePermission={{ group: 'order_types', action: 'delete_order_types' }}
                    filters={filters}
                    updateFilters={updateFilters}
                    updateFilterOrder={updateFilterOrder}
                    defaultOrder={filters.filter_order || undefined}
                    paginationData={{
                        pageSize: filters.limit,
                        currentPage: filters.page,
                        pageCount: (data as OrderTypesApiResponse) ? data.lastPage : 1,
                        totalCount: data?.totalRegisters,
                        handlePagination: updatePage,
                        handlePerPage: updatePageSize,
                    }}
                    toggleFilters={() => setOpenFilters(!openFilters)}
                />
            )}

            <FilterDrawerView isOpen={openFilters} setOpenDrawer={setOpenFilters} drawerTitle={'Filtros Tipo de Pedidos'}>
                <OrderTypesFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} />
            </FilterDrawerView>
        </Page>
    );
};

export default OrderTypesList;