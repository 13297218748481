import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { Button, Tooltip } from 'rizzui';
import { toast } from 'sonner';
import DeletePopover from '../../../components/buttons/DeletePopover';
import { Loader } from '../../../components/loader/SpinnerLogo';
import TabNav, { MenuItem } from "../../../components/navigation/TabNav";
import { usePrivilege } from '../../../components/priviledge/PriviledgeProvider';
import useFetch from '../../../hooks/useFetch';
import useHandleErrors from '../../../hooks/useHandleErrors';
import PageHeader from "../../../layout/shared/page-header";
import { setSelectedView } from '../../../redux/invoiceProfileSlice';
import { RootState } from '../../../redux/store';
import { menuRoutes } from "../../../router/menu";
import { InvoiceService } from '../../../services/invoice/invoiceService';
import { InvoiceApiResponse } from '../../../type/entities/invoice-type';

const menuItems: MenuItem[] = [
    {
        label: 'Información',
        path: menuRoutes.invoices.profile.info,
        permission: {
            group: 'invoices',
            action: 'get_invoices',
        },
    },
    {
        label: 'Actividad',
        path: menuRoutes.invoices.profile.activityHistory,
        permission: {
            group: 'invoices',
            action: 'invoice_changes_log',
        },
    },
];

export default function ClientProfileLayout({ isLoading, children }: { isLoading?: boolean, children: React.ReactNode }) {

    const { selectedView } = useSelector((state: RootState) => state.invoiceProfile);
    const { id = '' } = useParams<{ id: string }>();
    const { userCan } = usePrivilege();
    const navigate = useNavigate();
    const { handleErrors } = useHandleErrors();
    const service = new InvoiceService();

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        if (!id || id === '') return null;
        const response = await service.getInvoiceById(id);
        return response.getResponseData() as InvoiceApiResponse;
    }, [id]));

    const pageHeader = {
        title: `Información de la factura ${data?.companyName || ''}`,
        breadcrumb: [
            { name: 'Gestión de facturas' },
            { name: 'Facturas', href: menuRoutes.invoices.path },
            { name: 'Dastos de la factura' },
        ],
    };

    const handleDelete = async (id: string) => {
        try {
            const response = await (await service.deleteInvoice(id)).getResponseData();
            if (response.success) {
                navigate(menuRoutes.invoices.path);
                toast.success('Factura eliminada correctamente');
            } else {
                handleErrors(response);
            }
        } catch (error) {
            handleErrors(error);
        }
    };

    useEffect(() => {
        navigate(`${menuRoutes.invoices.path}/${id}/profile${selectedView}`);
    }, [selectedView]);

    return (
        <>
            <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb}>
                <div className='flex flex-row flex-nowrap gap-3 mt-3 md:mt-0'>
                    {userCan('edit_invoices', 'invoices') && (
                        <>
                            <Tooltip content={'Editar factura'} placement={'top'} color={'invert'} size={'sm'}>
                                <Button variant={'outline'} color={'primary'} size={'md'} onClick={() => {
                                    navigate(`${menuRoutes.invoices.path}/${id}/edit`)
                                }}>
                                    Editar
                                </Button>
                            </Tooltip>
                        </>
                    )}

                    {userCan('delete_invoices', 'invoices') && (
                        <Tooltip content={'Eliminar factura'} placement={'top'} color={'invert'} size={'sm'} className='text-danger'>
                            <DeletePopover
                                title={`Eliminar factura`}
                                description={`¿Estás seguro de que deseas eliminar la factura?`}
                                size={'md'}
                                onDelete={() => handleDelete(id)}
                                actionIconClassName='h-auto w-10'
                            />
                        </Tooltip>
                    )}
                </div>
            </PageHeader>

            <TabNav menuItems={menuItems} setSelectedView={setSelectedView} />

            {isLoading ? <Loader height='60vh' /> : children}
        </>
    );
}