import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { HiOutlineInformationCircle } from 'react-icons/hi2';
import { IoClose } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import { ActionIcon, Button, Checkbox, Modal, Select, Text, Tooltip } from 'rizzui';
import { toast } from 'sonner';
import * as yup from 'yup';
import CustomSelect from '../../../../../components/forms/CustomSelect';
import RequiredInput from '../../../../../components/forms/InputHOC';
import useTutors from '../../../../../hooks/api-calls/useTutors';
import useHandleErrors from '../../../../../hooks/useHandleErrors';
import FormGroup from '../../../../../layout/shared/form-group';
import { OrderService } from '../../../../../services/order/orderService';
import cn from '../../../../../utils/classNames';

interface ContractAssignmentModalProps {
    ids: string[];
    productIds: string[];
    isOpen: boolean;
    onClose: () => void;
}

const schema = yup.object({
    contractIds: yup.array().of(yup.string()).required('Este campo es obligatorio'),
    tutorId: yup.string().when('autoAssign', {
        is: (value: any) => (value === false),
        then: (schema) => schema.required('Este campo es obligatorio'),
        otherwise: (schema) => schema.notRequired(),
    }),
    schedule: yup.string().required('Este campo es obligatorio'),
    autoAssign: yup.boolean().notRequired(),
    assignationDate: yup.string().nullable().notRequired(),
});

const timeOptions = [
    { label: 'Mañana', value: 'morning' },
    { label: 'Tarde', value: 'afternoon' },
];

const ContractAssignmentModal: React.FC<ContractAssignmentModalProps> = ({ ids, productIds, isOpen, onClose }) => {

    // HOOKS

    const { id = '' } = useParams<{ id: string }>();
    const { handleErrors } = useHandleErrors();
    const { fetchAssignableTutors, getAssignableTutorsList } = useTutors({ order: id, products: productIds });

    // STATES

    const [isLoading, setIsLoading] = React.useState(false);

    //-------------------------------------------------------------------------------------------------------------------------------
    /**
     * @ES ENVÍA LA SOLICITUD DE ASIGNACIÓN DE CONTRATO
     * @EN SENDS THE CONTRACT ASSIGNMENT REQUEST
     */
    //-------------------------------------------------------------------------------------------------------------------------------
    const submit = async (values: any) => {
        setIsLoading(true);
        values.contractIds = ids;
        try {
            const response = await (new OrderService).assignToTutor(values);
            const responseData = response.getResponseData();

            if (responseData.success) {
                toast.success(responseData?.message || "Contrato asignado correctamente");
                formik.resetForm();
                onClose();
            } else {
                handleErrors(responseData);
            }
        } catch (error: any) {
            toast.error('Error al asignar el contrato');
        } finally {
            setIsLoading(false);
        }
    };
    //-------------------------------------------------------------------------------------------------------------------------------

    // FORMIK

    const formik = useFormik({
        initialValues: {
            contractIds: ids,
            tutorId: '',
            schedule: '',
            autoAssign: false,
            assignationDate: new Date().toISOString().split('T')[0],
        },
        validationSchema: schema,
        validateOnBlur: false,
        onSubmit: values => { submit(values); },
    });

    // USE EFFECT

    useEffect(() => {
        if (isOpen && formik.values.schedule) fetchAssignableTutors({ order: id, products: productIds, schedule: formik.values.schedule });
    }, [formik.values.schedule]);

    // RENDER

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => {
                onClose();
                formik.resetForm();
            }}
            size='sm'
            overlayClassName='backdrop-blur'
            containerClassName='!max-w-xl !shadow-xl'
            className='z-[999] [&_.pointer-events-none]:overflow-visible'
        >
            <div className='m-auto px-7 pt-6 pb-4'>
                <div className='mb-7 flex items-center justify-between'>
                    <Text className="text-xl">Asignar</Text>
                    <ActionIcon size='sm' variant='text' onClick={() => {
                        onClose();
                        formik.resetForm();
                    }}>
                        <IoClose className='h-auto w-6' strokeWidth={1.8} />
                    </ActionIcon>
                </div>

                <form onSubmit={formik.handleSubmit}>
                    <FormGroup
                        title="Asignar contrato"
                        description="Asigna un contrato a un tutor en un horario específico"
                        className="py-4"
                        titleCols="@md:col-span-4"
                        childCols="@md:col-span-8 md:grid-cols-2"
                    >
                        <Checkbox
                            id='autoAssign'
                            label={
                                <div className={'flex items-center'}>
                                    <label htmlFor='autoAssign'>Asignar automáticamente</label>
                                    <Tooltip
                                        size="md"
                                        content="El sistema asignará automáticamente un tutor disponible en el horario seleccionado"
                                        placement="top"
                                        color="invert"
                                    >
                                        <div className={'inline-block ms-1'}>
                                            <HiOutlineInformationCircle size={20} color={'primary'} />
                                        </div>

                                    </Tooltip>
                                </div>
                            }
                            helperClassName="text-gray-500 text-sm mt-3 ms-8"
                            helperText={'El sistema asignará automáticamente un tutor disponible en el horario seleccionado'}
                            checked={formik.values.autoAssign}
                            onChange={(e) => { formik.setValues({ ...formik.values, autoAssign: e.target.checked, tutorId: '' }) }}
                            error={formik.errors.autoAssign}
                            className='col-span-2'
                        />

                        <Select
                            label='Horario de tutor'
                            id='schedule'
                            value={timeOptions.find((o) => o.value === formik.values.schedule)}
                            options={timeOptions}
                            onChange={(e: any) => { formik.setFieldValue('schedule', e.value) }}
                            placeholder='Seleccione un horario ...'
                            className='col-span-1'
                            error={formik.errors.schedule}
                        />

                        <RequiredInput
                            id='assignationDate'
                            required={false}
                            type="date"
                            label="Fecha de asignación"
                            formik={formik}
                            className='col-span-1'
                            error={formik.errors.assignationDate}
                        />

                        <CustomSelect
                            isSearchable
                            required
                            id={'tutorId'}
                            label="Tutor"
                            value={formik.values.tutorId ? getAssignableTutorsList().filter((u: any) => formik.values.tutorId.includes(u.value)) : []}
                            options={getAssignableTutorsList()}
                            onChange={(e: any) => { formik.setFieldValue('tutorId', e.value) }}
                            isDisabled={!formik.values.schedule}
                            containerClassName={cn('col-span-2', { 'hidden': formik.values.autoAssign })}
                            error={formik.errors.tutorId}
                        />
                    </FormGroup>

                    <div className='flex justify-center p-7'>
                        <Button type='submit' size='md' isLoading={isLoading} disabled={isLoading}>
                            Asignar
                        </Button>
                    </div>
                </form>

            </div>
        </Modal>
    );
};

export default ContractAssignmentModal;
