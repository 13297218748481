import React, { useEffect } from 'react';
import { HiChartPie } from 'react-icons/hi2';
import { IoInformationCircleOutline } from 'react-icons/io5';
import { LiaFileSignatureSolid } from "react-icons/lia";
import { MdAssignmentInd } from 'react-icons/md';
import { PiExport } from 'react-icons/pi';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import { permissionsGroup, usePrivilege } from '../../../components/priviledge/PriviledgeProvider';
import { setSelectedInfoTab } from '../../../redux/ordersSlice';
import { RootState } from '../../../redux/store';
import { menuRoutes } from '../../../router/menu';
import cn from '../../../utils/classNames';

const OrderInfoSidebar: React.FC = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { userCan } = usePrivilege();
    const { selectedInfoTab } = useSelector((state: RootState) => state.orders);
    const location = useLocation();
    const { id = '' } = useParams<{ id: string }>();
    const pathname = location.pathname;

    const menuLinks: { label: string, path: string, icon: JSX.Element, permission: { group: permissionsGroup, action: string } }[] = [
        { label: 'Información', path: `${menuRoutes.orders.path}/${id}/profile${menuRoutes.orders.profile.info}`, icon: <IoInformationCircleOutline />, permission: { group: 'orders', action: 'get_orders' } },
        { label: 'Estadísticas', path: `${menuRoutes.orders.path}/${id}/profile${menuRoutes.orders.profile.stats}`, icon: <HiChartPie />, permission: { group: 'orders', action: 'orders_stats' } },
        { label: 'Contratos', path: `${menuRoutes.orders.path}/${id}/profile${menuRoutes.orders.profile.contracts}`, icon: <LiaFileSignatureSolid />, permission: { group: 'orders', action: 'orders_contracts' } },
        { label: 'Asignaciones', path: `${menuRoutes.orders.path}/${id}/profile${menuRoutes.orders.profile.assignments}`, icon: <MdAssignmentInd />, permission: { group: 'orders', action: 'assign_order_contracts_tutor' } },
        { label: 'Exportaciones', path: `${menuRoutes.orders.path}/${id}/profile${menuRoutes.orders.profile.exports}`, icon: <PiExport />, permission: { group: 'orders', action: 'export_order_contracts' } },
    ];

    useEffect(() => {
        if (pathname !== selectedInfoTab && selectedInfoTab?.length > 0) navigate(`${menuRoutes.orders.path}/${id}/profile/` + selectedInfoTab);
    }, [selectedInfoTab]);

    return (
        <SimpleBar className="lg:pt-6 lg:ps-6">
            <div className="flex flex-col items-left justify-start h-full p-2">
                <div className="flex flex-row lg:flex-col text-nowrap flex-nowrap overflow-auto gap-1 scrollbar-thin">
                    {menuLinks.map((link) => {
                        let isActive = (pathname === (link.path as string));
                        if (!userCan(link.permission.action, link.permission.group)) return null;
                        return (
                            <Link to={link.path}
                                key={link.label}
                                className={cn("flex flex-row flex-nowrap items-center gap-2 py-2 px-4 text-gray-500 hover:text-gray-800 hover:bg-gray-100 rounded-lg",
                                    isActive ? 'bg-primary-100 text-gray-800 hover:bg-primary-300' : '',
                                )}
                                onClick={() => {
                                    let sidebar = link.path.split('/').pop() ?? 'info';
                                    dispatch(setSelectedInfoTab(sidebar));
                                }}
                            >
                                {link.icon}
                                {link.label}
                            </Link>
                        )
                    })}
                </div>
            </div>
        </SimpleBar>
    );
};

export default OrderInfoSidebar;