import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { PiCircle } from "react-icons/pi";
import { useParams } from "react-router-dom";
import { Text, Title, cn } from "rizzui";
import { useWindowDimensions } from "../../../../../hooks/useWindowDimensions";
import { ContractService } from "../../../../../services/contract/contractService";
import ContractProfileLayout from "../../ContractProfileLayout";

const ContractStatesHistory = () => {

    const { id = '' } = useParams<{ id: string }>();
    const { width } = useWindowDimensions();

    const [timeline, setTimeline] = useState<any>(null);

    const allData = useCallback(async () => {
        if (!id) return null;

        const contractService = new ContractService();

        let entities = [
            { name: 'contract', label: 'Estados del contrato' },
            { name: 'contract_product', label: 'Estados del producto' },
            { name: 'contract_invoicing', label: 'Estados de facturación' },
            { name: 'contract_incidences', label: 'Estados de incidencia' },
            { name: 'contract_commissions', label: 'Estados de comisión' },
        ];

        const promises = entities.map(entity =>
            contractService
                .showContractStatusHistory({ filter_filters: { entity: entity.name, contract: id } })
                .then(res => ({
                    entity: entity.name,
                    label: entity.label,
                    data: res.getResponseData()?.data || [],
                }))
        );

        const results = await Promise.all(promises);

        // Join all results into a single array and sort by date
        const combined = results
            .flatMap(result =>
                result.data.map((item: any) => ({
                    ...item,
                    entity: result.entity,
                    label: result.label,
                }))
            )
            .sort((a, b) => new Date(a.createdAt.date).getTime() - new Date(b.createdAt.date).getTime())
            .reverse();

        // Set the timeline state
        setTimeline({ entities, combined });

        return { entities, combined };
    }, [id]);

    useEffect(() => {
        allData();
    }, []);

    return (
        <ContractProfileLayout isLoading={!timeline && !timeline?.entities && !timeline?.combined}>
            <div className="relative @container md:mt-4">
                <div className="md:grid md:grid-cols-2 xl:grid-cols-3 3xl:grid-cols-5 md:divide-x-4 md:divide-gray-100 gap-y-12">
                    {timeline?.entities && timeline.entities.length > 0 &&
                        timeline.entities?.map((entity: any) => {
                            if (width < 768 && timeline?.combined.filter((t: any) => t.entity === entity.name).length === 0) return null;
                            return (
                                <div key={entity.name} className="px-4">
                                    <Title as="h2" className={cn("mb-8 text-lg font-semibold text-center", { "mt-10": width < 768 })}>
                                        {entity.label}
                                    </Title>
                                    {timeline?.combined && timeline.combined.length > 0 &&
                                        timeline.combined?.map((t: any, index: number) => (
                                            <div
                                                className={cn(
                                                    "flex items-center ps-3",
                                                    ((t.entity !== entity.name) && width < 968) && "hidden"
                                                )}
                                                key={`t-${entity.name}-${index}`}
                                            >
                                                <div
                                                    className={cn(
                                                        "w-[147px] flex-shrink-0 @lg:block",
                                                        t.entity !== entity.name && "opacity-0"
                                                    )}>
                                                    <Text as="span" className="pe-5 text-gray-500 @2xl:pe-6 text-nowrap">
                                                        {moment(t?.createdAt?.date).format('DD/MM/YYYY HH:mm')}
                                                    </Text>
                                                </div>
                                                <div
                                                    className={cn(
                                                        "relative flex-grow border-s border-muted py-5 ps-6 before:absolute before:-left-[3px] before:-top-[3px] before:h-1.5 before:w-1.5 before:rounded-full before:bg-gray-200 dark:border-gray-700 dark:before:bg-gray-900 rtl:before:-right-[3px]",
                                                        index !== 0 && "before:hidden",
                                                        index === t.combined?.length - 1 && "before:-bottom-[3px] before:top-auto before:block"
                                                    )}
                                                >
                                                    <span
                                                        className={cn(
                                                            "absolute -left-3 top-1/2 flex h-6 w-6 -translate-y-1/2 items-center justify-center rounded-full bg-gray-0 dark:bg-gray-50 rtl:-right-3",
                                                            t.entity !== entity.name && "opacity-20",
                                                            t.entity === entity.name && "text-primary-500 dark:text-primary-400"
                                                        )}
                                                    >
                                                        <PiCircle className="h-3 w-3" />
                                                    </span>
                                                    <Title
                                                        as="h3"
                                                        className={cn("mb-3 flex items-center text-base font-semibold")}
                                                        style={{
                                                            color: t.entity === entity.name
                                                                ? t.color
                                                                : "transparent",
                                                        }}
                                                    >
                                                        {t.status}
                                                    </Title>
                                                    <div className="relative -ms-10">
                                                        <div className={cn("ps-10", t.entity !== entity.name && "opacity-0")}>
                                                            <Text
                                                                className={cn("block", {
                                                                    "text-gray-600 font-medium": t.entity === entity.name,
                                                                })}
                                                            >
                                                                {t.user}
                                                            </Text>
                                                            <Text className="text-sm font-normal leading-loose text-gray-500">
                                                                {t.comment}
                                                            </Text>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            )
                        })}
                </div>
            </div>
        </ContractProfileLayout>
    );
};

export default ContractStatesHistory;