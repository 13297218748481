import { useFormik } from 'formik';
import React, { Fragment } from 'react';
import { FaEuroSign, FaPercent } from 'react-icons/fa6';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import CustomSelect from '../../components/forms/CustomSelect';
import RequiredInput from '../../components/forms/InputHOC';
import useInvoiceClients from '../../hooks/api-calls/useInvoiceClients';
import useInvoiceCorporations from '../../hooks/api-calls/useInvoiceCorporations';
import useInvoiceTutors from '../../hooks/api-calls/useInvoiceTutors';
import Page from '../../layout/Page/Page';
import FormGroup from '../../layout/shared/form-group';
import { Invoice } from '../../type/entities/invoice-type';
import { fixNumber } from '../../utils/formatNumber';
import FormFooter from '../_layout/_footers/form-footer';
import InvoiceLinesForm from './components/InvoiceLinesForm';

interface CreateFormProps {
    isLoading: boolean;
    submit: Function;
    data?: Invoice | undefined;
}

const schema = yup.object({
    taxableAmount: yup.number().required('Campo obligatorio'),
    tax: yup.number().required('Campo obligatorio'),
    totalTaxesAmount: yup.number().required('Campo obligatorio'),
    totalProducts: yup.number().required('Campo obligatorio'),
    currentStatusId: yup.string().required('Campo obligatorio'),
    totalPrice: yup.number().required('Campo obligatorio'),
    invoiceDate: yup.date().required('Campo obligatorio'),
    paymentDate: yup.date().required('Campo obligatorio'),
    expirationDate: yup.date().required('Campo obligatorio'),
    corporationId: yup.string().required('Campo obligatorio'),
    lines: yup.array().of(
        yup.object().shape({
            contract: yup.mixed().notRequired().nullable(),
            concept: yup.string().when('contract', {
                is: (value: any) => (value === null || value === undefined || value === ''),
                then: (schema) => schema.required('Campo obligatorio'),
                otherwise: (schema) => schema.notRequired(),
            }),
            amount: yup.number().when('contract', {
                is: (value: any) => value === null || value === undefined || value === '',
                then: (schema) => schema
                    .required('Campo obligatorio')
                    .min(0, 'El valor debe ser mayor o igual a 0')
                    .test(
                        'maxDecimals',
                        'Máximo 2 decimales permitidos',
                        (value) => !value || /^\d+(\.\d{1,2})?$/.test(value.toString())
                    ),
                otherwise: (schema) => schema.notRequired(),
            }),
            tax: yup.number().when('contract', {
                is: (value: any) => value === null || value === undefined || value === '',
                then: (schema) => schema
                    .required('Campo obligatorio')
                    .min(0, 'El valor debe ser mayor o igual a 0')
                    .max(100, 'El valor debe ser menor o igual a 100')
                    .test(
                        'maxDecimals',
                        'Máximo 2 decimales permitidos',
                        (value) => !value || /^\d+(\.\d{1,2})?$/.test(value.toString())
                    ),
                otherwise: (schema) => schema.notRequired(),
            }),
            units: yup.number().when('contract', {
                is: (value: any) => value === null || value === undefined || value === '',
                then: (schema) => schema
                    .required('Campo obligatorio')
                    .min(0, 'El valor debe ser mayor o igual a 0')
                    .test(
                        'isInteger',
                        'El valor debe ser un número entero',
                        (value) => !value || Number.isInteger(value)
                    ),
                otherwise: (schema) => schema.notRequired(),
            }),
            totalWithoutTax: yup.number().when('contract', {
                is: (value: any) => value === null || value === undefined || value === '',
                then: (schema) => schema
                    .required('Campo obligatorio')
                    .min(0, 'El valor debe ser mayor o igual a 0')
                    .test(
                        'maxDecimals',
                        'Máximo 2 decimales permitidos',
                        (value) => !value || /^\d+(\.\d{1,2})?$/.test(value.toString())
                    ),
                otherwise: (schema) => schema.notRequired(),
            }),
            totalWithTax: yup.number().when('contract', {
                is: (value: any) => value === null || value === undefined || value === '',
                then: (schema) => schema
                    .required('Campo obligatorio')
                    .min(0, 'El valor debe ser mayor o igual a 0')
                    .test(
                        'maxDecimals',
                        'Máximo 2 decimales permitidos',
                        (value) => !value || /^\d+(\.\d{1,2})?$/.test(value.toString())
                    ),
                otherwise: (schema) => schema.notRequired(),
            }),
        })
    ).notRequired(),
});

const InvoiceForm: React.FC<CreateFormProps> = ({ isLoading, submit, data }) => {

    const { id = '' } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const mode = data ? 'Editar' : 'Crear';

    const { getInvoiceCorporationsList } = useInvoiceCorporations({ active: true });
    const { getInvoiceTutorsList } = useInvoiceTutors({ active: true });
    const { getInvoiceClientsList } = useInvoiceClients({ active: true });

    const formik = useFormik({
        initialValues: {
            invoiceId: id,
            taxableAmount: fixNumber(data?.taxableAmount || 0) || '',
            tax: data?.tax ?? '',
            totalTaxesAmount: fixNumber(data?.totalTaxesAmount || 0) ?? '',
            totalProducts: data?.totalProducts ?? '',
            totalPrice: fixNumber(data?.totalPrice || 0) ?? '',
            invoiceDate: data?.invoiceDate?.date?.split(' ')[0] || null,
            paymentDate: data?.paymentDate?.date?.split(' ')[0] || null,
            expirationDate: data?.expirationDate?.date?.split(' ')[0] || null,
            company: data?.company?.id ?? '',
            corporationId: data?.corporation?.id ?? '',
            tutorId: data?.tutor?.id ?? null,
            clientId: data?.client?.id ?? null,
            currentStatusId: data?.currentStatus?.id ?? '',
            lines: data?.lines?.map((line: any) => ({
                id: line.id || '',
                contract: line.contract?.id || '',
                concept: line.concept || '',
                amount: line.amount || '',
                tax: line.tax || '',
                units: line.units || '',
                totalWithoutTax: fixNumber(line.totalWithoutTax) || '',
                totalWithTax: fixNumber(line.totalWithTax) || '',
            })) || [],
        },
        validationSchema: schema,
        validateOnBlur: false,
        //validateOnChange: false,
        onSubmit: values => { submit(values); },
    });

    const getContent = () => {
        return (
            <>
                <div className="@container">
                    <form onSubmit={formik.handleSubmit}>
                        <FormGroup
                            title="Información General"
                            description="Datos principales de la factura"
                            className='pt-3 pb-4'
                            titleCols="@md:col-span-2"
                            childCols="@md:col-span-10 md:grid-cols-5"
                        >
                            <RequiredInput
                                id='totalProducts'
                                type="number"
                                label="Total Productos"
                                formik={formik}
                            />

                            <RequiredInput
                                id='tax'
                                type="number"
                                label="Impuesto"
                                suffix={<FaPercent />}
                                formik={formik}
                            />

                            <RequiredInput
                                id='taxableAmount'
                                type="number"
                                label="Base Imponible"
                                suffix={<FaEuroSign />}
                                formik={formik}
                            />

                            <RequiredInput
                                id='totalTaxesAmount'
                                type="number"
                                label="Total Impuestos"
                                suffix={<FaEuroSign />}
                                formik={formik}
                            />

                            <RequiredInput
                                id='totalPrice'
                                type="number"
                                label="Precio Total"
                                suffix={<FaEuroSign />}
                                formik={formik}
                            />
                        </FormGroup>

                        <FormGroup
                            title="Empresas y Usuarios"
                            description="Datos de las empresas y usuarios relacionados"
                            className='pt-3 pb-4'
                            titleCols="@md:col-span-2"
                            childCols="@md:col-span-10 md:grid-cols-3"
                        >
                            <CustomSelect
                                isSearchable
                                isClearable
                                id={'corporationId'}
                                label="Empresa"
                                value={{ value: formik.values.corporationId, label: getInvoiceCorporationsList()?.find((g: any) => g.value === formik.values.corporationId)?.label }}
                                options={getInvoiceCorporationsList()}
                                onChange={(e: any) => { formik.setFieldValue('corporationId', e?.value) }}
                                required
                                error={formik.errors.corporationId}
                            />

                            <CustomSelect
                                isSearchable
                                isClearable
                                id={'tutorId'}
                                label="Tutor"
                                value={{ value: formik.values.tutorId, label: getInvoiceTutorsList()?.find((g: any) => g.value === formik.values.tutorId)?.label }}
                                options={getInvoiceTutorsList()}
                                onChange={(e: any) => { formik.setFieldValue('tutorId', e?.value) }}
                                error={formik.errors.tutorId}
                            />

                            <CustomSelect
                                isSearchable
                                isClearable
                                id={'clientId'}
                                label="Cliente"
                                value={{ value: formik.values.clientId, label: getInvoiceClientsList()?.find((g: any) => g.value === formik.values.clientId)?.label }}
                                options={getInvoiceClientsList()}
                                onChange={(e: any) => { formik.setFieldValue('clientId', e?.value) }}
                                error={formik.errors.clientId}
                            />
                        </FormGroup>

                        <FormGroup
                            title="Fechas y Plazos"
                            description="Fechas de la factura y plazos de pago"
                            className='pt-3 pb-4'
                            titleCols="@md:col-span-2"
                            childCols="@md:col-span-10 md:grid-cols-3"
                        >
                            <RequiredInput
                                id='invoiceDate'
                                type="date"
                                label="Fecha Factura"
                                formik={formik}
                            />

                            <RequiredInput
                                id='paymentDate'
                                type="date"
                                label="Fecha Pago"
                                formik={formik}
                            />

                            <RequiredInput
                                id='expirationDate'
                                type="date"
                                label="Fecha Vencimiento"
                                formik={formik}
                            />

                        </FormGroup>

                        <InvoiceLinesForm formik={formik} data={data} />
                    </form>
                </div>
            </>
        )
    };

    return (
        <Fragment>
            <Page container="fluid">
                {(mode === "Editar" && data) && getContent()}
                {mode === "Crear" && getContent()}
            </Page>
            <FormFooter
                submitBtnText={mode + ' Factura'}
                handleCancelBtn={() => navigate(-1)}
                handleSubmitBtn={formik.submitForm} isLoading={isLoading}
            />
        </Fragment>
    )
};

export default InvoiceForm;