import { useFormik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Textarea } from 'rizzui';
import * as yup from 'yup';
import RequiredInput from '../../../components/forms/InputHOC';
import Page from '../../../layout/Page/Page';
import FormGroup from '../../../layout/shared/form-group';
import { IncidenceType } from '../../../type/entities/incidence-type';
import FormFooter from '../../_layout/_footers/form-footer';

interface IncidenceTypeFormProps {
    isLoading: boolean;
    submit: Function;
    data?: IncidenceType | undefined;
}

const schema = yup.object({
    name: yup.string().required("Este campo es requerido"),
    description: yup.string().required("Este campo es requerido"),
});

const IncidenceTypeForm: React.FC<IncidenceTypeFormProps> = ({ isLoading, submit, data }) => {

    const mode = data ? 'Editar' : 'Crear';
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            incidenceTypeId: data?.id || '',
            name: data?.name || '',
            description: data?.description || '',
            incidences: data?.incidences || [],
        },
        validationSchema: schema,
        onSubmit: values => { submit(values) },
    })

    const getContent = () => {
        return (
            <div className="@container">
                <form onSubmit={formik.handleSubmit}>
                    <div className="grid gap-7 divide-y-2 divide-dashed divide-gray-200 font-medium">
                        <FormGroup
                            title="Información General"
                            description="Datos principales"
                            titleCols="@md:col-span-2"
                            childCols="@md:col-span-10 md:grid-cols-4"
                        >
                            <RequiredInput
                                required={true}
                                id='name'
                                label="Nombre"
                                formik={formik}
                                className='@md:col-span-4'
                            />
                            <Textarea
                                id='description'
                                label={<label>Descripción <span className="text-red-500"> *</span></label>}
                                className="[&>label>span]:font-medium col-span-4"
                                onChange={formik.handleChange}
                                value={formik.values.description}
                                error={formik.errors.description}
                            />
                        </FormGroup>

                    </div>
                </form>
            </div>
        )
    };

    return (
        <>
            <Page container="fluid">
                {(mode === "Editar" && data) && getContent()}
                {mode === "Crear" && getContent()}
            </Page>
            <FormFooter
                submitBtnText={mode + ' Tipo de Incidencia'}
                handleCancelBtn={() => navigate(-1)}
                handleSubmitBtn={formik.submitForm}
                isLoading={isLoading}
            />
        </>
    );
};

export default IncidenceTypeForm;