import chroma from 'chroma-js';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Text } from 'rizzui';
import { CustomDonutGraph } from '../../../../../../components/charts/CustomDonutChart';
import useFetch from '../../../../../../hooks/useFetch';
import { OrderService } from '../../../../../../services/order/orderService';
import { generateColor } from '../OrderStats';

interface UsersAndNetworksChartsProps { }

const UsersAndNetworksCharts: React.FC<UsersAndNetworksChartsProps> = ({ }) => {

    // HOOKS

    const { id } = useParams<{ id: string }>();

    const [networksSeries, setNetworksSeries] = useState<{ series: number[], labels: string[], colors: string[], ids: string[], totalContracts: number }>({ series: [], labels: [], colors: [], ids: [], totalContracts: 0 });
    const [corporationsSeries, setCorporationsSeries] = useState<{ series: number[], labels: string[], colors: string[], ids: string[], totalContracts: number }>({ series: [], labels: [], colors: [], ids: [], totalContracts: 0 });
    const [networkValue, setNetworkValue] = useState<number | null>(null);
    const [networkId, setNetworkId] = useState<string | undefined>(undefined);
    const [corporationValue, setCorporationValue] = useState<number | null>(null);
    const [corporationId, setCorporationId] = useState<string | undefined>(undefined);
    const [chartData, setChartData] = useState<any[]>([]);
    const [allContracts, setAllContracts] = useState<any[]>([]);

    const [commercialNetworks] = useFetch(useCallback(async () => {
        if (!id) return null;
        const response = (await (new OrderService).getContractsByCommercialNetwork(id as string)).getResponseData();
        return response;
    }, [id]));

    const [organisingCorporations] = useFetch(useCallback(async () => {
        if (!id) return null;
        const response = (await (new OrderService).getContractsByOrganisingCorporation(id as string)).getResponseData();
        return response;
    }, [id]));

    const [contractsData] = useFetch(useCallback(async () => {
        if (!id) return null;
        const response = await (new OrderService).getCommercialContractsByProduct(id as string);
        return response.getResponseData();
    }, [id]));

    // FUNCTIONS

    const handleNetworkClick = (value: number, label: string, id?: string) => {
        setNetworkId(id);
        setNetworkValue((networkValue && networkValue !== commercialNetworks?.totalContracts && networkId === id) ? commercialNetworks?.totalContracts : value);
    };

    const handleCorporationClick = (value: number, label: string, id?: string) => {
        setCorporationId(id);
        setCorporationValue((corporationValue && corporationValue !== organisingCorporations?.totalContracts && corporationId === id) ? organisingCorporations?.totalContracts : value);
    };

    /**
    * Transform contracts data to be used in the chart
    */
    const transformData = (commercials: any[]) => {
        return commercials?.map((t) => {
            const commercialEntry: any = { commercialName: t.commercialName, commercialId: t.commercialId };

            // Each product is a key in the object, unless it already exists (increment the value)
            t.products?.forEach((p: any) => {
                //console.log(p);
                commercialEntry[p.productName] = p.productQuantity;
            });

            //console.log(commercialEntry);

            return commercialEntry;
        }).sort((a, b) => { // Sort by commercialId to put the ones without commercialId at the end
            if (a.commercialId === null) return 1;
            if (b.commercialId === null) return -1;
            return 0;
        });
    };

    // USE EFFECT

    useEffect(() => {
        if (commercialNetworks) {
            setNetworksSeries({
                series: commercialNetworks.commercialNetworks?.map((item: any) => item.commercialNetworkQuantity),
                labels: commercialNetworks.commercialNetworks?.map((item: any) => item.commercialNetworkName),
                colors: commercialNetworks.commercialNetworks?.map((item: any) => generateColor(item.commercialNetworkName)),
                ids: commercialNetworks.commercialNetworks?.map((item: any) => item.commercialNetworkId),
                totalContracts: commercialNetworks.totalContracts,
            });
            setNetworkValue(commercialNetworks.totalContracts);
        }
    }, [commercialNetworks]);

    useEffect(() => {
        if (organisingCorporations) {
            setCorporationsSeries({
                series: organisingCorporations.organisingCorporations?.map((item: any) => item.organisingCorporationQuantity),
                labels: organisingCorporations.organisingCorporations?.map((item: any) => item.organisingCorporationName),
                colors: organisingCorporations.organisingCorporations?.map((item: any) => generateColor(item.organisingCorporationName)),
                ids: organisingCorporations.organisingCorporations?.map((item: any) => item.organisingCorporationId),
                totalContracts: organisingCorporations.totalContracts,
            });
            setCorporationValue(organisingCorporations.totalContracts);
        }
    }, [organisingCorporations]);

    /**
    * Set all products from all commercials
    */
    useEffect(() => {
        if (contractsData) {
            setChartData(transformData(contractsData));

            // Eliminamos duplicados basándonos en `productName`
            const uniqueProducts = new Map();

            contractsData.forEach((contract: any) => {
                contract.products.forEach((product: any) => {
                    if (!uniqueProducts.has(product.productName)) {
                        uniqueProducts.set(product.productName, {
                            ...product,
                            commercialId: contract.commercialId // Asociamos con el comercial correcto
                        });
                    }
                });
            });

            setAllContracts(Array.from(uniqueProducts.values()).sort((a, b) => {
                if (a.commercialId === null) return 1;
                if (b.commercialId === null) return -1;
                return 0;
            }));
        }
    }, [contractsData]);

    // RENDER

    return (
        <div className="grid grid-cols-6 gap-4 mt-10">
            <div className='col-span-6 lg:col-span-2'>
                {networksSeries?.series?.length > 0 && corporationsSeries?.series?.length > 0 && (
                    <>
                        <Text className="text-center font-bold mb-5">Redes comerciales</Text>
                        <CustomDonutGraph data={networksSeries} total={{ value: networkValue || 0 }} onClick={handleNetworkClick} />

                        <Text className="text-center font-bold mt-9 mb-5">Empresas organizadoras</Text>
                        <CustomDonutGraph data={corporationsSeries} total={{ value: corporationValue || 0 }} onClick={handleCorporationClick} />
                    </>
                )}
            </div>

            <div className='col-span-6 lg:col-span-4'>
                {chartData?.length > 0 && (
                    <>
                        <Text className="text-center font-bold mb-5">Agentes comerciales</Text>
                        <div className="w-full h-96 pe-4">
                            <ResponsiveContainer width="100%" height="100%">
                                <BarChart layout="vertical" data={chartData} barSize={1}>
                                    <XAxis type="number" tick={{ fontSize: 12 }} tickCount={10} domain={[0, "dataMax"]} allowDecimals={false} tickLine={false} />
                                    <YAxis dataKey="commercialName" type="category" tick={{ fontSize: 14 }} tickLine={false} />

                                    <Tooltip cursor={{ fill: "#f5f5f5" }} />
                                    {allContracts?.map((p: any) => {
                                        const barColor = generateColor(p.productName);
                                        // The text color is white or black depending on the contrast with the bar color
                                        const textColor = chroma.contrast(barColor, "white") > 2.5 ? "white" : "black";

                                        return (
                                            <Bar key={p.productName} dataKey={p.productName} stackId="a" barSize={20} fill={barColor}
                                                label={({ x, y, width, height, value }) => (
                                                    width > 30
                                                        ? ( // Only show the text if the bar is wide enough
                                                            <text
                                                                x={x + width / 2}
                                                                y={y + height / 2}
                                                                fill={textColor}
                                                                textAnchor="middle"
                                                                dominantBaseline="middle"
                                                                fontSize={12}
                                                            >
                                                                {p.productName}
                                                            </text>
                                                        )
                                                        : <></>
                                                )}
                                            />
                                        );
                                    })}
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default UsersAndNetworksCharts;