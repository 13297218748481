import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import AttivaCard from "../../../components/card/AttivaCard";
import useHandleErrors from "../../../hooks/useHandleErrors";
import PageHeader from "../../../layout/shared/page-header";
import { menuRoutes } from "../../../router/menu";
import { TutorService } from "../../../services/tutor/tutorService";
import TutorForm from "../TutorForm";

const pageHeader = {
    title: "Crear Tutor",
    breadcrumb: [
        { name: 'Administración' },
        { name: 'Control de acceso' },
        { href: menuRoutes.tutors.path, name: "Tutores" },
        { name: "Crear" },
    ],
};

const TutorCreate: FC = () => {

    const navigate = useNavigate();
    const { handleErrors } = useHandleErrors();
    const service = new TutorService();

    const [loading, setLoading] = useState<boolean>(false);

    const handleSubmit = async (values: any) => {
        setLoading(true);
        try {
            const response = await service.createTutor(values);
            const responseData = response.getResponseData();

            if (responseData.success) {
                navigate(-1);
                toast.success("Tutor creado correctamente");
            } else {
                responseData.data.errors.forEach((error: any) => {
                    toast.error(error.message);
                });
            }
        } catch (error: any) {
            handleErrors(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb} />

            <AttivaCard title="Datos Principales" className="mb-4">
                <TutorForm submit={handleSubmit} isLoading={loading} />
            </AttivaCard>
        </>
    );
};

export default TutorCreate;