import chroma from 'chroma-js';
import moment from 'moment';
import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'rizzui';
import { toast } from 'sonner';
import { DayPilotTS } from '../../../../public/js/daypilot-all.min';
import YearSelector from '../../../components/forms/YearSelector';
import { Loader } from '../../../components/loader/SpinnerLogo';
import { handleConfirmationAlert } from '../../../components/modal/ConfirmationAlert';
import { usePrivilege } from '../../../components/priviledge/PriviledgeProvider';
import useFetch from '../../../hooks/useFetch';
import { FilterOptions } from '../../../hooks/useFilters';
import useHandleErrors from '../../../hooks/useHandleErrors';
import { menuRoutes } from '../../../router/menu';
import { OrderService } from '../../../services/order/orderService';
import '../../../styles/timeline.css';
import { Events, Resources } from '../../../type/timeline-type';
import { useOrdersProvider } from '../provider/OrdersProvider';
import OrderCanvas from './OrderCanvas';
moment.locale('es-ES');

interface OrdersCalendarProps { }

const OrdersCalendar: React.FC<OrdersCalendarProps> = ({ }) => {

    // HOOKS

    const { fromDate, toDate, scrollToCurrentDate, filters, updateFilters, year, setYear } = useOrdersProvider();
    const { userCan } = usePrivilege();
    const { handleErrors } = useHandleErrors();
    const navigate = useNavigate();

    // STATES

    const [data, setData] = React.useState<any[]>([]);
    const [daypilot, setDaypilot] = React.useState<any>(null);
    const [resources, setResources] = React.useState<Resources | null>(null);
    const [events, setEvents] = React.useState<Events | null>(null);
    const [selectedOrder, setSelectedOrder] = React.useState<any>(null);
    const [filtersUpdated, setFiltersUpdated] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState<boolean>(false);

    // FUNCTIONS

    //-------------------------------------------------------------------------------------------------------------------------------
    /**
     * @ES OBTENER LOS DATOS DE LOS PEDIDOS PARA MOSTRARLOS EN EL CALENDARIO AL CARGAR LA PÁGINA
     * @EN GETS THE DATA OF THE ORDERS TO SHOW THEM IN THE CALENDAR WHEN THE PAGE LOADS
     */
    //-------------------------------------------------------------------------------------------------------------------------------
    const fetchEventsData = async (filters?: FilterOptions) => {
        (new OrderService).listOrders(filters).then(response => {
            const groupedOrders = response.getResponseData().data.data.reduce((acc: any, order: any) => {
                const { id, name, color } = order.orderTypes;
                if (!acc[id]) {
                    acc[id] = { id, name, color, children: [] };
                }
                acc[id].children.push(order);
                return acc;
            }, {});

            // Convertir el objeto en un array con índices secuenciales y ordenar los pedidos por fecha de inicio (ascendente)
            const result = Object.values(groupedOrders).map((group: any, index: number) => ({
                index,
                ...group,
                children: group.children.sort((a: any, b: any) => new Date(a.startDate.date).getTime() - new Date(b.startDate.date).getTime()),
            }));

            setData(result);

            if (holidays) {
                const holidaysMap = holidays.map((h: any) => ({
                    id: h.id,
                    concept: h.concept,
                    start: h.date.date.split(' ')[0],
                    end: h.date.date.split(' ')[0],
                    backColor: '#fbffd4',
                }));
                setResources(result.map((o: any) => ({
                    id: `order__${o.id}`,
                    name: `${o.name}`,
                    backColor: chroma(o.color).alpha(0.5).css(),
                    holidays: holidaysMap,
                    cellsAutoUpdated: true,
                    expanded: true,
                    children: o.children.map((child: any) => ({
                        id: `order__${o.id}__${child.id}`,
                        name: `${child.code}`,
                        backColor: /* child.currentStatus?.color || */ '#fafafa',
                        holidays: holidaysMap,
                        cellsAutoUpdated: true,
                    }))
                })));
            } else {
                setResources(result.map((o: any) => ({
                    id: `order__${o.id}`,
                    name: `${o.name}`,
                    backColor: chroma(o.color).alpha(0.5).css(),
                    cellsAutoUpdated: true,
                    expanded: true,
                    children: o.children.map((child: any) => ({
                        id: `order__${o.id}__${child.id}`,
                        name: `${child.code}`,
                        backColor: /* child.currentStatus?.color || */ '#fafafa',
                        cellsAutoUpdated: true,
                    }))
                })));
            }

            setEvents(response.getResponseData().data.data?.map((o: any) => ({
                id: o.id,
                resource: `order__${o.orderTypes?.id}__${o.id}`,
                start: moment(o.startDate?.date).format('YYYY-MM-DD'),
                end: moment(o.endDate?.date).format('YYYY-MM-DD'),
                text: `${o.code}`,
                barColor: o.currentStatus?.color || '#a4c3b2',
                moveVDisabled: true, // Disable vertical movement
                startDate: moment(o.startDate?.date).format('YYYY-MM-DD'),
                endDate: moment(o.endDate?.date).format('YYYY-MM-DD'),
                collectionDate: moment(o.collectionDate?.date).format('YYYY-MM-DD'),
                orderDate: moment(o.orderDate?.date).format('YYYY-MM-DD'),
                status: o.currentStatus,
                statusDate: moment(o.currentStatusDate?.date).format('YYYY-MM-DD'),
                orderType: o.orderTypes,
                bubbleHtml: `Código del pedido: ${o.code}</br>
                             Estado: ${o.currentStatus?.name}</br>
                             Días laborables: ${o.workingDays} días</br>
                             Fecha inicio: ${moment(o.startDate?.date).format('DD/MM/YYYY')}</br>
                             Fecha fin: ${moment(o.endDate?.date).format('DD/MM/YYYY')}</br>
                             Fecha cobro: ${moment(o.collectionDate?.date).format('DD/MM/YYYY')}</br>
                             Fecha pedido: ${moment(o.orderDate?.date).format('DD/MM/YYYY')}`,
            })));
        }).catch(error => {
            console.log('Error fetching orders calendar data: ', error);
        });
    };
    //-------------------------------------------------------------------------------------------------------------------------------

    //-------------------------------------------------------------------------------------------------------------------------------
    /**
     * @ES OBTENER LOS DÍAS FESTIVOS DEL AÑO
     * @EN GET THE HOLIDAYS OF THE YEAR
     */
    //-------------------------------------------------------------------------------------------------------------------------------
    const [holidays] = useFetch(useCallback(async () => {
        const response = await (new OrderService).getFestiveDays(Number(fromDate.split('-')[0]));
        return response.getResponseData();
    }, [fromDate]));
    //-------------------------------------------------------------------------------------------------------------------------------

    //-------------------------------------------------------------------------------------------------------------------------------
    /**
     * @ES INSTANCIAR EL CALENDARIO DAYPILOT
     * @EN INSTANTIATE THE DAYPILOT CALENDAR
     */
    //-------------------------------------------------------------------------------------------------------------------------------
    const instanceDayPilot = () => {
        // @ts-ignore
        if (DayPilot) {
            // @ts-ignore
            const dp = new DayPilot.Scheduler("dp", {
                locale: "es-es",
                crosshairType: "Full",
                // @ts-ignore
                startDate: new DayPilot.Date(fromDate),
                timeHeaders: [
                    { groupBy: "Month", format: "MMMM yyyy" },
                    { groupBy: "Day", format: "d" },
                ],
                days: moment(toDate).diff(moment(fromDate), 'days') + 1,
                scale: "CellDuration",
                treeEnabled: true,
                resources: resources,
                cellWidthSpec: "Fixed",
                cellWidth: 70,
                cellDuration: 1440, // 1 day
                eventHeight: 35,
                rowMinHeight: 35,
                dynamicEventRendering: "Disabled",
                businessWeekends: true,
                eventEndSpec: "Date", // Event ends at the end of the day
                eventResizingStartEndEnabled: true,
                timeRangeSelectingStartEndEnabled: true,
                rowHeaderHideIconEnabled: true,
                rowHeaderWidthAutoFit: false,
                heightSpec: "Max100Pct",
                rowHeaderWidth: 200,
                timeRangeSelectedHandling: "Disabled",
                eventDeleteHandling: "CallBack",
                /* infiniteScrollingEnabled: true,
                infiniteScrollingStepDays: 30, 
                scrollDelayEvents: 1000, */
                // @ts-ignore
                contextMenu: new DayPilot.Menu({
                    items: [
                        { text: "Ver", onClick: (args: any) => { navigate(`${menuRoutes.orders.path}/${args.source.data.id}/profile/info`) } },
                        { text: "Editar", onClick: (args: any) => { setSelectedOrder(args.source.data); setIsOpen(true); } },
                        { text: "Eliminar", onClick: (args: any) => { handleDelete(args.source.data.id) } },
                    ]
                }),
                onEventClicked: async (args: any) => {
                    const argsDetail = args.e.data;
                    setSelectedOrder({
                        id: argsDetail.id,
                        orderType: argsDetail.orderType,
                        start: argsDetail.start,
                        end: argsDetail.end,
                        collectionDate: argsDetail.collectionDate || '',
                        orderDate: argsDetail.orderDate || '',
                        currentStatus: argsDetail.status,
                        currentStatusDate: argsDetail.statusDate,
                    })
                    setIsOpen(true);
                    dp.clearSelection();
                },
                onEventMoved: async (args: DayPilotTS.SchedulerEventMovedArgs) => {
                    handleEdit(args.e.data);
                },
                onEventResized: async (args: DayPilotTS.SchedulerEventResizedArgs) => {
                    handleEdit(args.e.data);
                },
                onEventDeleted: async (args: DayPilotTS.SchedulerEventDeletedArgs) => {
                    if (args.e.data.id) {
                        handleConfirmationAlert({
                            title: "Eliminar pedido",
                            text: "¿Seguro que deseas eliminar el pedido?",
                            icon: "warning",
                            onConfirm: async () => {
                                let response = (await (new OrderService).deleteOrder(args.e.data.id)).getResponseData();
                                if (response.success) {
                                    dp.events.remove(args.e);
                                    fetchEventsData();
                                    toast.success("Pedido eliminado correctamente");
                                } else {
                                    handleErrors(response);
                                }
                            },
                        });
                    }
                },
                onBeforeTimeHeaderRender: (args: any) => {
                    // Highlight the current day
                    // @ts-ignore
                    if (args.header.start <= DayPilot.Date.today() && DayPilot.Date.today() < args.header.end && args.header.level === 1) {
                        // @ts-ignore
                        args.header.backColor = "#fff2cc";
                        args.header.cssClass = "timeline_today";
                    }
                },
                onBeforeCellRender: (args: DayPilotTS.SchedulerBeforeCellRenderArgs) => {
                    // Get the row data
                    const row = dp.rows.find(args.cell.resource);

                    // Highlight the weekends
                    if (args.cell.start.getDayOfWeek() === 0 || args.cell.start.getDayOfWeek() === 6) {
                        // @ts-ignore
                        args.cell.backColor = "#dcf6f6";
                    }

                    // Highlight the holidays
                    var holidays = row.data.holidays;
                    if (holidays) {
                        var item = holidays.find(function (range: any) {
                            // @ts-ignore
                            var start = new DayPilot.Date(range.start);
                            // @ts-ignore
                            var end = new DayPilot.Date(range.end).addDays(1);
                            // @ts-ignore
                            return DayPilot.Util.overlaps(start, end, args.cell.start, args.cell.end);
                        });
                        if (item) {
                            // @ts-ignore
                            if (args.cell.backColor !== "#dcf6f6") args.cell.backColor = item.backColor;
                            // @ts-ignore
                            args.cell.areas = [
                                {
                                    action: 'Bubble',
                                    // @ts-ignore
                                    bubble: new DayPilot.Bubble({
                                        onLoad: (areaArgs: any) => {
                                            areaArgs.html = holidays.find((cell: any) =>
                                                moment(args.cell.start.value).format('YYYY-MM-DD') == moment(cell.start).format('YYYY-MM-DD')
                                            )?.concept;
                                        },
                                        animated: false,
                                        showLoadingLabel: false,
                                        showAfter: 100,
                                        hideAfter: 100
                                    }),
                                    left: 0, right: 0, top: 0, bottom: 0,
                                }
                            ];
                        }
                    }
                },
                onBeforeEventRender: (args: DayPilotTS.SchedulerBeforeEventRenderArgs) => {
                    // @ts-ignore
                    const text = DayPilot.Util.escapeHtml(args.data.text);
                    // @ts-ignore
                    const start = new DayPilot.Date(args.data.start).toString("dd/MM/yy");
                    // @ts-ignore
                    const end = new DayPilot.Date(args.data.end).toString("dd/MM/yy");
                    args.data.html = `${text}  (${start} - ${end})`;
                },
            });

            return dp;
        }
    };
    //-------------------------------------------------------------------------------------------------------------------------------

    //-------------------------------------------------------------------------------------------------------------------------------
    /**
     * @ES ELIMINAR EL DIV DE DEMOSTRACIÓN QUE SE MUESTRA EN LA ESQUINA SUPERIOR
     * @EN DELETE THE DEMO DIV THAT IS SHOWN IN THE UPPER CORNER
     */
    //-------------------------------------------------------------------------------------------------------------------------------
    const deleteDemoDiv = () => {
        const cornerInnerDiv = document.querySelector('.scheduler_default_corner');
        if (cornerInnerDiv) {
            const demoDiv = cornerInnerDiv.querySelector('div[style="position: absolute; padding: 2px; top: 0px; left: 1px; background-color: rgb(255, 102, 0); color: white;"]');
            if (demoDiv && demoDiv.parentNode) {
                demoDiv.parentNode.removeChild(demoDiv);
            }
        }
    };
    //-------------------------------------------------------------------------------------------------------------------------------

    //-------------------------------------------------------------------------------------------------------------------------------
    /**
     * @ES EDITAR UN PEDIDO EN EL CALENDARIO
     * @EN EDIT AN ORDER IN THE CALENDAR
     */
    //-------------------------------------------------------------------------------------------------------------------------------
    const handleEdit = async (values: any) => {
        const order = data?.find((order: any) => {
            return order.children.find((child: any) => child.id == values.id)
        })?.children.find((child: any) => child.id == values.id);

        const newOrder: any = {
            orderId: values.id,
            orderTypeId: values.orderType?.id,
            startDate: moment(values.start.value).format('YYYY-MM-DD'),
            endDate: moment(values.end.value).format('YYYY-MM-DD'),
            collectionDate: order?.collectionDate?.date ? moment(order.collectionDate.date).format('YYYY-MM-DD') : values.collectionDate,
            orderDate: order?.orderDate?.date ? moment(order.orderDate.date).format('YYYY-MM-DD') : values.orderDate,
        };
        try {
            const response = (await (new OrderService()).editOrder(newOrder)).getResponseData();
            if (response.success) {
                fetchEventsData();
                toast.success("Pedido editado correctamente");
            } else {
                fetchEventsData();
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error("Error al editar el pedido");
        }
    };
    //-------------------------------------------------------------------------------------------------------------------------------

    //-------------------------------------------------------------------------------------------------------------------------------
    /**
     * @ES ELIMINAR UN PEDIDO
     * @EN DELETE AN ORDER
     */
    //-------------------------------------------------------------------------------------------------------------------------------
    const handleDelete = async (id: string) => {
        handleConfirmationAlert({
            title: "Eliminar pedido",
            text: "¿Seguro que deseas eliminar el pedido?",
            icon: "warning",
            onConfirm: async () => {
                let response = (await (new OrderService).deleteOrder(id)).getResponseData();
                if (response.success) {
                    fetchEventsData();
                    setIsOpen(false);
                    toast.success("Pedido eliminado correctamente");
                } else {
                    handleErrors(response);
                }
            },
        });
    };
    //-------------------------------------------------------------------------------------------------------------------------------

    // USE EFFECTS

    /**
     * Fetch the events data when the page loads
     */
    useEffect(() => {
        fetchEventsData(filters);
        // Reload daypilot when the filters are updated
        return () => {
            if (daypilot) {
                daypilot.update();
            }
        }
    }, [filters]);

    /**
     * Initialize the scheduler if daypilot is not defined and the resources and events are loaded
     */
    useEffect(() => {
        // Initialize the scheduler when the resources and events are loaded
        if (!daypilot) {
            if (resources !== null && events !== null) {
                setDaypilot(instanceDayPilot());
            }
        }

        // Re-instantiate the scheduler when the resources are changed by the filters
        if (daypilot && filtersUpdated) {
            daypilot.update({ resources, events });
            deleteDemoDiv();
            scrollToCurrentDate();
            setFiltersUpdated(false);
        }
    }, [resources, events]);

    /**
     * Update the scheduler with the new events
     */
    useEffect(() => {
        if (daypilot) {
            daypilot.init();
            daypilot.update({ events });
            deleteDemoDiv();
            scrollToCurrentDate();
        }
    }, [daypilot]);

    /**
     * Update the events when the order events are loaded
     */
    useEffect(() => {
        if (daypilot) {
            daypilot.update({ events });
            deleteDemoDiv();
        }
    }, [events]);

    /**
     * Update the resources when the order resources are loaded
     */
    useEffect(() => {
        if (daypilot) {
            daypilot.update({ resources });
            deleteDemoDiv();
        }
    }, [resources]);

    /**
     * Update the scheduler when the fromDate or toDate changes
     */
    useEffect(() => {
        if (daypilot) {
            // @ts-ignore
            daypilot.update({ startDate: new DayPilot.Date(fromDate) });
            daypilot.update({ days: moment(toDate).diff(moment(fromDate), 'days') + 1 });
            deleteDemoDiv();
        }
    }, [fromDate, toDate]);

    /**
     * Fetch the events data when the holidays are loaded
     */
    useEffect(() => {
        if (holidays) {
            fetchEventsData(filters);
        }
    }, [holidays]);

    // RENDER

    return (
        <>
            {resources && events
                ? (
                    <>
                        <div className={'flex justify-between items-center mb-3'}>
                            {userCan('create_orders', 'orders') && (
                                <Button
                                    color="primary"
                                    onClick={() => { setSelectedOrder(null); setIsOpen(true); }}
                                >
                                    Crear pedido
                                </Button>
                            )}

                            <YearSelector
                                defaultValue={year}
                                onChange={(year: number) => {
                                    setFiltersUpdated(true);
                                    updateFilters({ between_dates: { startDate: `${year}-01-01`, endDate: `${year}-12-31`, type: 'startAndEndDate' } });
                                    setYear(year);
                                }}
                            />
                        </div>
                        <div id="dp" className='overflow-auto' />
                        {isOpen && <OrderCanvas order={selectedOrder} isOpen={isOpen} setOpen={(status: boolean) => { fetchEventsData(); setSelectedOrder(null); setIsOpen(false); }} />}
                    </>
                )
                : <Loader />
            }

        </>
    );
};

export default OrdersCalendar;