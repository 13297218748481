import { useCallback, useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import { useParams } from "react-router-dom";
import { ActionIcon, Button, Modal, Text, Textarea } from "rizzui";
import { toast } from "sonner";
import useHandleErrors from "../../../../../hooks/useHandleErrors";
import { IncidenceService } from "../../../../../services/incidence/incidenceService";

interface CommentModalProps {
    data: any;
    isOpen: boolean;
    onClose: () => void;
    refetch: () => void;
}

export const CommentModal: React.FC<CommentModalProps> = ({ data, isOpen, onClose, refetch }) => {

    const { id = '' } = useParams<{ id: string }>();
    const { handleErrors } = useHandleErrors();
    const service = new IncidenceService();

    const [comment, setComment] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const addComment = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await (await service.createIncidenceComment(id, comment)).getResponseData();
            if (response.success) {
                toast.success('Se ha añadido un nuevo comentario');
                refetch();
                onClose();
            } else {
                handleErrors(response);
            }
        } catch (error) {
            handleErrors(error);
        } finally {
            setIsLoading(false);
        }
    }, [service, handleErrors]);

    const editComment = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await (await service.editIncidenceComment(id, data.id, comment)).getResponseData();
            if (response.success) {
                toast.success('Se ha editado el comentario correctamente');
                refetch();
                onClose();
            } else {
                handleErrors(response);
            }
        } catch (error) {
            handleErrors(error);
        } finally {
            setIsLoading(false);
        }
    }, [service, handleErrors]);

    useEffect(() => {
        if (data && data?.comment) {
            setComment(data.comment);
        }
        return () => setComment('');
    }, [data]);

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            size='sm'
            overlayClassName='backdrop-blur'
            containerClassName='!max-w-xl !shadow-xl'
            className='z-[999] [&_.pointer-events-none]:overflow-visible'
        >
            <div className='m-auto px-7 pt-6 pb-4'>
                <div className='mb-7 flex items-center justify-between'>
                    <Text className="text-xl">{data?.id ? 'Editar' : 'Añadir'} un comentario a la incidencia</Text>
                    <ActionIcon size='sm' variant='text' onClick={onClose}>
                        <IoClose className='h-auto w-6' strokeWidth={1.8} />
                    </ActionIcon>
                </div>

                <Textarea
                    id='comment'
                    label='Comentario'
                    rows={6}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                />
            </div>

            <div className='flex justify-center p-7'>
                <Button
                    disabled={!comment || isLoading}
                    onClick={() => data?.id ? editComment() : addComment()}
                    color='primary'
                >
                    {data?.id ? 'Editar' : 'Añadir'} comentario
                </Button>
            </div>
        </Modal>
    );
};