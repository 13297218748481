import React, { useCallback, useEffect, useRef, useState } from 'react';
import { PermissionsRequired } from '../../type/menu-type';
import { CheckPermissions } from '../../utils/CheckPermissions';
import CustomSelect from './CustomSelect';
import { debounce } from 'lodash';

interface OptionType {
    label: string;
    value: string;
}

interface Props {
    id: string;
    label: string;
    formik: any;
    minInputLength?: number;
    permissions?: PermissionsRequired;
    containerClassName?: string;
    isMulti?: boolean;
    fetchOptions: (searchText: string | null) => Promise<OptionType[]>;
    error?: any;
}

const CustomSelectApiHookForm: React.FC<Props> = ({
    id,
    label,
    formik,
    minInputLength = 2,
    permissions,
    containerClassName,
    isMulti = false,
    fetchOptions,
    error
}) => {
    const [options, setOptions] = useState<OptionType[]>([]);
    const [inputValue, setInputValue] = useState('');
    const [filtersValue, setFiltersValue] = useState<any>([]);
    const [loading, setLoading] = useState(false);

    // Debounced fetch function
    const debouncedFetch = useRef(
        debounce(async (value: string) => {
            try {
                const fetchedOptions = await fetchOptions(value);
                setOptions(fetchedOptions);
            } catch (error) {
                console.error('Error fetching options:', error);
                setOptions([]);
            } finally {
                setLoading(false);
            }
        }, 500)
    ).current;

    // Handle input change with debounce
    const handleInputChange = useCallback((value: string) => {
        setInputValue(value);
        if (value.length >= minInputLength) {
            setLoading(true);
            debouncedFetch(value);
        } else {
            setOptions([]);
            setLoading(false);
        }
    }, [minInputLength]);

    // Handle selected values change
    const handleFiltersValue  = useCallback((data: OptionType | OptionType[] | null) => {
        const newValue = isMulti
            ? (data as OptionType[])?.map(item => item.value)
            : (data as OptionType | null)?.value;
    
        formik.setFieldValue(id, newValue);
        setFiltersValue(data);
    }, [id]);

    // Fetch initial options if necessary
    useEffect(() => {
        if (formik.values?.[id] && options.length === 0) {
            setLoading(true);
            fetchOptions(null)
                .then((fetchedOptions) => {
                    const filteredOptions = fetchedOptions.filter((option) =>
                        formik.values[id].includes(option.value)
                    );
                    setOptions(filteredOptions);
                    setFiltersValue(filteredOptions);
                })
                .catch((error) => console.error('Error fetching options:', error))
                .finally(() => setLoading(false));
        }
    }, []);

    // Clear filters value if no filter exists
    useEffect(() => {
        if (!formik.values?.[id]) setFiltersValue(isMulti ? [] : null);
    }, [formik, formik.values, id]);

    return (
        <>
            {((!permissions) || (permissions && CheckPermissions(permissions))) && (
                <CustomSelect
                    id={id}
                    label={label}
                    isSearchable
                    isClearable
                    isMulti={isMulti}
                    options={options}
                    isLoading={loading}
                    onInputChange={handleInputChange}
                    onChange={handleFiltersValue}
                    value={filtersValue}
                    noOption={() => (inputValue?.length < minInputLength ? `Introduce al menos ${minInputLength} letras` : 'No se encontraron opciones')}
                    containerClassName={containerClassName}
                    error={error}
                />)
            }
        </>
    );
};

export default CustomSelectApiHookForm;