import { useEffect, useState } from 'react';
import {OrderTypeService} from "../../../services/order-type/OrderTypeService";
import {OrderType} from "../../../type/entities/order-type";

/*----------------------------------------------------------------------------------------
* EN: Hook to manage order types fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de tipos de pedidos.
----------------------------------------------------------------------------------------*/


const useOrderTypes = (initialFilters = {}, initialOrders = {}, limit = 99999) => {

    const [data, setData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [filters, setFilters] = useState<Record<string, any>>({
        limit: limit,
        filter_filters: { ...initialFilters },
        filter_orders: { ...initialOrders, createdAt: 'DESC' },
    });

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const service = new OrderTypeService();
            const response = await service.listOrderTypes(filters);
            const fetchedData = response.getResponseData().data;

            if (fetchedData && fetchedData?.data) {
                const mappedData = fetchedData.data?.map((data: OrderType) => ({
                    value: data.id,
                    label: data.name,
                }));
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching order types:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [])
    

    const getList = (entity?: string) => {
        if (entity) return data.filter((state: any) => state.entity === entity);
        return data;
    };

    const addFilter = (key: string, value: any) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            filter_filters: {
                ...prevFilters.filter_filters,
                [key]: value
            }
        }));
    };

    const modifyLimit = (amount = 5) => {
        if (filters.limit + amount >= 5) {
            setFilters(prevFilters => ({
                ...prevFilters,
                limit: prevFilters.limit + amount
            }));
        }
    }

    const getEntity = (id: string) => {
        return data.find((entity: any) => entity.id === id);
    }

    return { 
        fetchOrderTypesList: fetchData,
        getOrderTypesList: getList,
        modifyLimitOrderTypes: modifyLimit,
        addOrderTypesFilter: addFilter,
        getOrderType: getEntity,
        isLoadingOrderTypes: isLoading
    };
}

export default useOrderTypes;