import React from 'react';
import { FiltersProvider } from '../../../../../components/providers/FiltersProvider';
import ContractProfileLayout from '../../ContractProfileLayout';
import ContractIncidence from './ContractIncidence';
import { useParams } from 'react-router-dom';

interface ContractIncidenceWrapperProps { }

const ContractIncidenceWrapper: React.FC<ContractIncidenceWrapperProps> = () => {

    const { id = '' } = useParams<{ id: string }>();

    const defaultFilters = {
        contract: id,
    };
    
    return (
        <FiltersProvider defaultFilterFilters={defaultFilters}>
            <ContractProfileLayout>
                <ContractIncidence />
            </ContractProfileLayout>
        </FiltersProvider>
    );
};

export default ContractIncidenceWrapper;