import React, { useContext, useState } from "react";

type ContractProviderProps = {
    children: React.ReactNode,
}

type ContractContextData = {
    showClientAsideMenuBtn: boolean,
    setShowClientAsideMenuBtn: (showClientAsideMenuBtn: boolean) => void,
    isOpenClientHistory: boolean,
    setIsOpenClientHistory: (isOpenClientHistory: boolean) => void,
    loadingContract: boolean,
    setLoadingContract: (loadingContract: boolean) => void,
    loadingDraft: boolean,
    setLoadingDraft: (loadingDraft: boolean) => void,
    originalClientData?: any,
    setOriginalClientData: (clientData: any) => void,
    originalStudentData?: any,
    setOriginalStudentData: (studentData: any) => void,
    originalConsultancyData?: any,
    setOriginalConsultancyData: (consultancyData: any) => void,
}

const ContractContext: React.Context<ContractContextData> = React.createContext<ContractContextData>({
    showClientAsideMenuBtn: false,
    setShowClientAsideMenuBtn: (showClientAsideMenuBtn: boolean) => { },
    isOpenClientHistory: false,
    setIsOpenClientHistory: (isOpenClientHistory: boolean) => { },
    loadingContract: false,
    setLoadingContract: (loadingContract: boolean) => { },
    loadingDraft: false,
    setLoadingDraft: (loadingDraft: boolean) => { },
    originalClientData: {},
    setOriginalClientData: (clientData: any) => { },
    originalStudentData: {},
    setOriginalStudentData: (studentData: any) => { },
    originalConsultancyData: {},
    setOriginalConsultancyData: (consultancyData: any) => { },
});

const ContractProvider: React.FC<ContractProviderProps> = ({ children }) => {

    const [showClientAsideMenuBtn, setShowClientAsideMenuBtn] = useState<boolean>(false);
    const [isOpenClientHistory, setIsOpenClientHistory] = useState<boolean>(true);
    const [originalClientData, setOriginalClientData] = useState<any>();
    const [originalStudentData, setOriginalStudentData] = useState<any>();
    const [originalConsultancyData, setOriginalConsultancyData] = useState<any>();
    const [loadingContract, setLoadingContract] = useState<boolean>(false);
    const [loadingDraft, setLoadingDraft] = useState<boolean>(false);

    /* TODO: Traer aquí toda la lógica que se repita varias veces en los hijos o que sea necesaria en varios de ellos */

    return (
        <ContractContext.Provider value={{
            showClientAsideMenuBtn,
            setShowClientAsideMenuBtn,
            isOpenClientHistory,
            setIsOpenClientHistory,
            loadingContract,
            setLoadingContract,
            loadingDraft,
            setLoadingDraft,
            originalClientData,
            setOriginalClientData,
            originalStudentData,
            setOriginalStudentData,
            originalConsultancyData,
            setOriginalConsultancyData,
        }}>
            {children}
        </ContractContext.Provider>
    );
}

export { ContractContext, ContractProvider };

export function useContractProvider() {
    return useContext(ContractContext);
}