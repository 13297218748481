import { Button } from 'rizzui';
import Spinner from '../../../components/bootstrap/Spinner';
import cn from '../../../utils/classNames';

interface FormFooterProps {
    customBg?: string;
    customClassName?: string;
    cancelBtnText?: string;
    submitBtnText?: string;
    isLoading?: boolean;
    isDisabled?: boolean;
    handleCancelBtn?: () => void;
    handleSubmitBtn?: () => void;
    classNameCancelBtn?: string;
    classNameSubmitBtn?: string;
}

export const negMargin = 'mx-4 md:-mx-5 lg:-mx-6 3xl:-mx-8 4xl:-mx-10';

export default function FormFooter({
    isLoading,
    isDisabled = false,
    cancelBtnText = 'Cancelar ',
    submitBtnText = 'Guardar Datos',
    customBg = "bg-white dark:bg-gray-50",
    customClassName = '',
    classNameCancelBtn = '',
    classNameSubmitBtn = '',
    handleCancelBtn,
    handleSubmitBtn,
}: FormFooterProps) {
    return (
        <div
            className={cn('sticky bottom-0 left-0 right-0 z-10 md:z-0 flex items-center truncate justify-end gap-4 border-t px-4 py-4 md:px-5 lg:px-6 3xl:px-8 4xl:px-10 -me-4 -ms-4 -mb-4 md:-mb-4 lg:-mb-6 ', customBg, customClassName)}
        >
            <Button
                variant="outline"
                className={cn("w-full xl:w-auto bg-white", classNameCancelBtn)}
                onClick={handleCancelBtn}
            >
                {cancelBtnText}
            </Button>
            <Button
                type="submit"
                className={cn("w-full xl:w-auto", classNameSubmitBtn)}
                onClick={handleSubmitBtn}
                isLoading={isLoading}
                disabled={isLoading || isDisabled}
            >
                {isLoading ? <Spinner /> : submitBtnText}
            </Button>
        </div >
    );
}