import { useEffect, useState } from "react";
import { FaRegClipboard } from "react-icons/fa";
import { PiCaretDownBold } from "react-icons/pi";
import { Button, Collapse, Loader, Text, Tooltip } from "rizzui";
import { toast } from "sonner";
import WidgetCard from "../../../components/card/WidgetCard";
import CustomSearchInput from "../../../components/forms/CustomSearchInput";
import useClientStudentsHistory from "../../../hooks/api-calls/contract/useContractClientStudents";
import { menuRoutes } from "../../../router/menu";
import cn from "../../../utils/classNames";

interface ClientStudentHistoryProps {
    clientId: string;
    formik: any;
}
const ClientStudentsHistory = ({ clientId, formik }: ClientStudentHistoryProps) => {

    const rowLimit = 5;
    const [isLoading, setIsLoading] = useState(false);

    const { updateClientId, getClientStudentsList, modifyLimitClientStudent, addClientStudentFilter, isMoreClientStudents, isLoadingClientStudents } = useClientStudentsHistory({ client: clientId, active: true }, 5);

    useEffect(() => {
        if (clientId) updateClientId(clientId);
    }, [clientId])

    async function updateStudentFormData(studentId: string) {
        let student = getClientStudentsList(studentId);

        formik.setFieldValue('studentId', student[0]?.id);
        formik.setFieldValue('studentStudyId', student[0]?.studentStudyId);
        formik.setFieldValue('studentQuotationGroupId', student[0]?.studentQuotationGroupId);
        formik.setFieldValue('studentProfessionalCategoryId', student[0]?.studentProfessionalCategoryId);
        formik.setFieldValue('studentName', student[0]?.name);
        formik.setFieldValue('studentFirstName', student[0]?.firstName);
        formik.setFieldValue('studentLastName', student[0]?.lastName);
        formik.setFieldValue('studentNif', student[0]?.nif);
        formik.setFieldValue('studentGender', student[0]?.gender);
        formik.setFieldValue('studentTerrorismVictim', student[0]?.terrorismVictim);
        formik.setFieldValue('studentGenderViolenceVictim', student[0]?.genderViolenceVictim);
        formik.setFieldValue('studentDisability', student[0]?.disability);
        formik.setFieldValue('studentSocialSecurityNumber', student[0]?.socialSecurityNumber);
        formik.setFieldValue('studentFunctionalArea', student[0]?.functionalArea);
        formik.setFieldValue('studentEmail', student[0]?.email);
        formik.setFieldValue('studentTelephone', student[0]?.telephone);
        formik.setFieldValue('studentTelephoneSecondary', student[0]?.telephoneSecondary);
        formik.setFieldValue('studentAlternativeShippingAddress', student[0]?.alternativeShippingAddress);
        formik.setFieldValue('studentContactSchedule', student[0]?.contactSchedule);
        formik.setFieldValue('studentBirthDate', student[0]?.birthDate?.date.split(' ')[0]);

        toast.info('Empleado copiado correctamente');
        await new Promise((resolve) => setTimeout(resolve, 500));
        setIsLoading(false);
    }

    function StudentHistoryContent(arrayData: any) {

        return (
            <div className="space-y-2 p-0.5">
                {arrayData.map((activity: any) => (
                    <div
                        key={activity.id}
                        className="group cursor-pointer space-y-1 rounded-lg bg-gray-50 p-4 transition-shadow hover:shadow dark:bg-gray-100"
                    >
                        <div className="flex flex-col xl:flex-row flex-nowrap justify-between gap-8">
                            <div className="w-full">
                                <div className="flex items-center justify-between">
                                    <Text className="font-semibold group-hover:underline"
                                        onClick={() => window.open(`${menuRoutes.students.path}?filter_filters=%7B"active"%3Atrue%2C"search_text"%3A"${activity.name}"%7D&filter_order=%5B%5D&page=1&limit=50`, '_blank')}
                                    >
                                        {activity.fullName}
                                    </Text>
                                </div>
                                <div className="flex items-center mt-2">
                                    <span className="text-gray-400">NIF:&nbsp;</span>
                                    <Text className="ms-1.5 text-sm font-medium capitalize text-gray-700">{activity?.nif ?? "N/A"}</Text>
                                </div>
                                <div className="flex items-center mt-2">
                                    <span className="text-gray-400">Email:&nbsp;</span>
                                    <Text className="ms-1.5 text-sm font-medium capitalize text-gray-700">{activity?.email ?? "N/A"}</Text>
                                </div>
                                {activity?.tfno && (
                                    <div className="flex items-center mt-2">
                                        <span className="text-gray-400">Tfno:&nbsp;</span>
                                        <Text className="ms-1.5 text-sm font-medium capitalize text-gray-700">{activity?.tfno ?? "N/A"}</Text>
                                    </div>
                                )}
                            </div>
                            <Tooltip size="sm" content="Copiar Datos" placement="top" color="invert">
                                <Button variant="outline"
                                    className={cn('m-auto bg-primary-500 text-white hover:text-white hover:bg-primary-700', isLoading && 'bg-gray-400')}
                                    disabled={isLoading}
                                    onClick={() => {
                                        setIsLoading(true);
                                        updateStudentFormData(activity.id);
                                    }}
                                >
                                    {!isLoading ? <FaRegClipboard /> : <Loader />}
                                </Button>
                            </Tooltip>

                        </div>
                    </div>
                ))}
            </div>
        );
    }

    return (
        <div className={cn("my-3")}>
            <Collapse
                className="py-3"
                defaultOpen={true}
                header={({ open, toggle }) => (
                    <button
                        type="button"
                        onClick={toggle}
                        className="flex w-full cursor-pointer items-center justify-between text-base font-semibold text-gray-900"
                    >
                        <div>
                            Empleados/Alumnos
                            <span className="text-gray-400">
                                &nbsp;&nbsp;
                                {getClientStudentsList().length >= 1 ?
                                    `(${getClientStudentsList().length})` : '(0)'}
                            </span>
                        </div>
                        <PiCaretDownBold
                            strokeWidth={3}
                            className={cn(
                                "h-3.5 w-3.5 -rotate-90 text-gray-500 transition-transform duration-200 rtl:rotate-90",
                                open && "rotate-0 rtl:rotate-0"
                            )}
                        />
                    </button>
                )}
            >
                <CustomSearchInput
                    isClearable={true}
                    className="mt-2"
                    placeholder="Buscar Empleados/Alumnos"
                    onSearch={(e) => addClientStudentFilter('search_text', e)}
                />
                <WidgetCard title="" headerClassName="hidden" className="border-transparent p-2 lg:p-3">
                    {!isLoadingClientStudents && getClientStudentsList().length >= 1 ? (
                        StudentHistoryContent(getClientStudentsList())
                    ) : (
                        <div className="flex items-center justify-center h-18">
                            {isLoadingClientStudents ? <Loader variant="spinner" /> : <Text className="text-gray-400">Sin Resultados</Text>}
                        </div>
                    )}
                    <div className="w-full flex flex-row gap-2 justify-center">
                        <Button
                            variant="outline"
                            className={cn("border-gray-300 w-[45%] mt-2", !isMoreClientStudents && 'hidden')}
                            onClick={() => modifyLimitClientStudent(rowLimit)}
                        >
                            <Text className="text-gray-400">Ver Más</Text>
                        </Button>
                        <Button
                            variant="outline"
                            className={cn("border-gray-300 w-[45%] mt-2", getClientStudentsList().length <= rowLimit && 'hidden')}
                            onClick={() => modifyLimitClientStudent(-rowLimit)}
                        >
                            <Text className="text-gray-400">Ver Menos</Text>
                        </Button>
                    </div>
                </WidgetCard>
            </Collapse>
        </div>
    )
}

export default ClientStudentsHistory;