import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import AttivaCard from "../../../components/card/AttivaCard";
import useHandleErrors from "../../../hooks/useHandleErrors";
import PageHeader from "../../../layout/shared/page-header";
import { menuRoutes } from "../../../router/menu";
import { OrderService } from "../../../services/order/orderService";
import OrderForm from "../OrderForm";

const pageHeader = {
    title: "Crear Pedido",
    breadcrumb: [
        { name: 'Ventas' },
        { name: 'Gestión de pedidos', href: menuRoutes.orders.path + menuRoutes.orders.list },
        { name: 'Crear Pedido' },
    ],
}

const OrderCreate: FC = () => {

    const { handleErrors } = useHandleErrors();
    const navigate = useNavigate();
    const service = new OrderService();

    const [loading, setLoading] = useState<boolean>(false);

    const handleSubmit = async (values: any) => {
        setLoading(true);
        try {
            const response = (await service.createOrder(values)).getResponseData();

            if (response.success) {
                navigate(menuRoutes.orders.path + menuRoutes.orders.list);
                toast.success("Pedido creado correctamente");
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error("Error al crear el pedido");
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb} />

            <AttivaCard title="Datos Principales" className="mb-4">
                <OrderForm submit={handleSubmit} isLoading={loading} />
            </AttivaCard>
        </>
    );
}

export default OrderCreate;