import { useFormik } from "formik";
import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { Input, Text } from "rizzui";
import { toast } from "sonner";
import { Loader } from "../../../components/loader/SpinnerLogo";
import { PermissionsGrid } from "../../../components/ui/PermissionsAccordion";
import useFetch from "../../../hooks/useFetch";
import FormGroup from "../../../layout/shared/form-group";
import { PermissionService } from "../../../services/auth/permissionService";
import { UserService } from "../../../services/user/userService";
import { PermissionGroup, PermissionsApiResponse, RolePermission } from "../../../type/entities/role-type";
import { User, UserApiResponse } from "../../../type/entities/user-type";
import FormFooter from "../../_layout/_footers/form-footer";
import UserEditLayout from "./UserEditLayout";

const UserEditPermissions: FC = () => {

    const { id = "" } = useParams<{ id: string }>();
    const service = new UserService();
    const navigate = useNavigate();

    const [groupSearch, setGroupSearch] = useState<string>('');
    const [filteredPermissionsData, setFilteredPermissionsData] = useState<PermissionGroup[] | null>(null);
    const [selectedPermissions, setSelectedPermissions] = useState<number[] | null>([]);
    const [updating, setUpdating] = useState<boolean>(false);

    const formik = useFormik({
        initialValues: {},
        onSubmit: values => { updatePermissions() },
    });

    // GET User Selected permissions
    const [data, loading] = useFetch(useCallback(async () => {
        const response = await service.getUserById(id as string);
        return response.getResponseData() as User;
    }, [id]));


    // GET Avaliable Permissions
    const [permissionsData, permissionsLoading] = useFetch(useCallback(async () => {
        const response = await (new PermissionService()).getPermissions();
        return response.getResponseData() as PermissionsApiResponse;
    }, []));

    const _handleSelectedPermissions = (permissions: RolePermission[]) => {
        let permissionsIds = permissions.map((permission: RolePermission) => permission.permission.id);

        setSelectedPermissions(permissionsIds);
    };

    const _handleSelectAll = (permissions: number[], checked: boolean) => {
        if (checked && selectedPermissions !== null) {
            // if already in selectedPermissions, do nothing
            let permissionsToAdd: number[] = [];
            permissions.forEach((permission: number) => {
                if (!selectedPermissions.includes(permission)) {
                    permissionsToAdd.push(permission);
                }
            });
            setSelectedPermissions([...selectedPermissions, ...permissionsToAdd]);
        }

        if (!checked && selectedPermissions !== null) {
            const selectedPermissionsCopy = selectedPermissions;

            permissions.forEach((permission: number) => {
                const index = selectedPermissionsCopy.indexOf(permission);
                if (index > -1) {
                    selectedPermissionsCopy.splice(index, 1);
                }
            });

            setSelectedPermissions([...selectedPermissionsCopy]);
        }
    };

    const updatePermissions = async () => {
        try {
            setUpdating(true);

            if (selectedPermissions) {
                let response = await (await service.editUserPermissions(id, data.userRoles[0].company?.id, selectedPermissions)).getResponseData() as UserApiResponse;
                setUpdating(false);
                if (response.success && response.data) {
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
            }

        } catch (error: any) {
            toast.error('Error al actualizar los permisos');
        } finally {
            setUpdating(false);
        }
    };

    const _handleOnSelectPermission = (permission: number, checked: boolean) => {
        if (checked && selectedPermissions !== null) {
            if (!selectedPermissions.includes(permission)) {
                setSelectedPermissions([...selectedPermissions, permission]);
            }
        }

        if (!checked && selectedPermissions !== null) {
            const selectedPermissionsCopy = selectedPermissions;
            const index = selectedPermissionsCopy.indexOf(permission);
            if (index > -1) {
                selectedPermissionsCopy.splice(index, 1);
            }
            setSelectedPermissions([...selectedPermissionsCopy]);
        }
    };

    useEffect(() => {
        if (data) _handleSelectedPermissions(data.userPermissions);
    }, [data]);


    /**
     * Filter groups by groupSearch, if groupSearch is empty, return all groups
     */
    useEffect(() => {
        if (permissionsData) {
            if (groupSearch === '') {
                setFilteredPermissionsData(permissionsData);
            } else {
                const filteredGroups = permissionsData.filter((group: PermissionGroup) => { return group.label.toLowerCase().includes(groupSearch.toLowerCase()) });
                setFilteredPermissionsData(filteredGroups);
            }
        }
    }, [permissionsData, groupSearch]);

    return (
        <UserEditLayout>
            <div className="@container">
                <form onSubmit={formik.handleSubmit} autoComplete="off">
                    <div className="mt-6 flex items-center">
                        <Text className="rizzui-input-label block text-sm font-medium me-3">Buscar grupo:</Text>
                        <Input
                            id="groupSearch"
                            value={groupSearch}
                            onChange={(e) => setGroupSearch(e.target.value)}
                        />
                    </div>

                    {selectedPermissions !== null && permissionsData && (filteredPermissionsData && !permissionsLoading)
                        ? filteredPermissionsData.map((permissionGroup: PermissionGroup) => {
                            return (
                                <FormGroup
                                    key={`index-${permissionGroup.id}`}
                                    title={permissionGroup.label}
                                    //description={'Lista de permisos asignados al usuario'}
                                    className='pt-6 mt-6 border-t border-gray-200'
                                    childClassName='col-span-full grid gap-4 sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 @4xl:col-span-8 @4xl:gap-5 xl:gap-7'
                                >
                                    <div key={permissionGroup.id} className={"col-span-full"}>
                                        <PermissionsGrid
                                            group={permissionGroup}
                                            onSelectAll={_handleSelectAll}
                                            onSelectPermission={_handleOnSelectPermission}
                                            selectedPermissions={selectedPermissions}
                                        />
                                    </div>
                                </FormGroup>
                            )
                        })
                        : <Loader height='50vh' />
                    }

                    <FormFooter
                        submitBtnText={'Guardar Permisos'}
                        handleSubmitBtn={formik.submitForm}
                        handleCancelBtn={() => navigate(-1)}
                        isLoading={updating}
                    />
                </form>
            </div>
        </UserEditLayout>
    )
}

export default UserEditPermissions;