import { IoWarningOutline } from "react-icons/io5";
import { Modal } from "rizzui";
import { toast } from "sonner";
import { UserService } from "../../services/user/userService";
import { useSettingsProvider } from "./provider/SettingsProvider";
import { useState } from "react";

interface SettignsComponentsProps {
    modalTitle?: string;
};

const SettingsComponents: React.FC<SettignsComponentsProps> = ({ modalTitle = '¿Quieres actualizar los ajustes?' }) => {

    const { showModal, setShowModal, showCacheModal, setShowCacheModal, showImportCRMModal, setShowImportCRMModal, applyCompanyConfigChanges } = useSettingsProvider();
    const userService = new UserService();
    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            setSelectedFile(event.target.files[0]);
        }
    };

    const handleCacheConfirm = () => {
        setShowCacheModal(false);
        const response = userService.clearSystemCache();
        response.then((res: any) => {
            const message = res.response.data.message;
            if (res.response.data.success) {
                toast.success(message);
            } else {
                toast.error(message);
            }
        }).catch(error => {
            toast.error('Error al limpiar la cache: ' + error);
        });
    };

    const handleImportCRMConfirm = () => {
        if (!selectedFile) {
            toast.error("Por favor, selecciona un archivo antes de confirmar.");
            return;
        }

        setShowImportCRMModal(false);
        const response = userService.importCRMData(selectedFile);
        response.then((res: any) => {
            const message = res.response.data.message;
            if (res.response.data.success) {
                toast.success(message);
            } else {
                toast.error(message);
            }
        }).catch(error => {
            toast.error('Error al importar datos de CRM: ' + error);
        });
    };

    const handleConfirm = () => {
        setShowModal(false);
        applyCompanyConfigChanges();
    };

    const handleCancel = () => {
        setShowModal(false);
        setShowCacheModal(false);
        setShowImportCRMModal(false);
    };

    return (
        <>
            {showModal && (
                <Modal isOpen={showModal} onClose={() => setShowModal(false)} size={'md'}>
                    <div className='flex flex-col justify-center pt-5 pb-4'>
                        <IoWarningOutline className="text-5xl text-yellow-500 m-auto" />
                        <h4 className="text-2xl text-center">{modalTitle}</h4>
                    </div>
                    <p className="text-justify text-gray-500 text-sm mx-8 px-4 pb-6">
                        Al confirmar, los cambios realizados en la configuración de la empresa se guardarán y se aplicarán en los próximos procesos.
                    </p>
                    <div className="flex justify-center p-3 gap-4">
                        <button className="px-4 py-2 bg-primary text-white rounded-md hover:bg-primary-300" onClick={handleConfirm}>
                            Confirmar
                        </button>
                        <button className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600" onClick={handleCancel}>
                            Cancelar
                        </button>
                    </div>
                </Modal>
            )}

            {showCacheModal && (
                <Modal isOpen={showCacheModal} onClose={() => setShowCacheModal(false)} size={'md'}>
                    <div className='flex flex-col justify-center pt-5 pb-4'>
                        <IoWarningOutline className="text-5xl text-yellow-500 m-auto" />
                        <h4 className="text-2xl text-center">Limpiar Caché del Sistema</h4>
                    </div>
                    <p className="text-justify text-gray-500 text-sm mx-8 px-4 pb-6">
                        Al confirmar, se limpiará la caché del sistema. Esto puede afectar el rendimiento de la aplicación.
                    </p>
                    <div className="flex justify-center p-3 gap-4">
                        <button className="px-4 py-2 bg-primary text-white rounded-md hover:bg-primary-300" onClick={handleCacheConfirm}>
                            Confirmar
                        </button>
                        <button className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600" onClick={handleCancel}>
                            Cancelar
                        </button>
                    </div>
                </Modal>
            )}

            {showImportCRMModal && (
                <Modal isOpen={showImportCRMModal} onClose={() => setShowImportCRMModal(false)} size={'md'}>
                    <div className='flex flex-col justify-center pt-5 pb-4'>
                        <IoWarningOutline className="text-5xl text-yellow-500 m-auto" />
                        <h4 className="text-2xl text-center">Importar datos de CRM</h4>
                    </div>
                    <p className="text-justify text-gray-500 text-sm mx-8 px-4 pb-6">
                        Al confirmar, se importarán los datos de CRM. Esto puede afectar el rendimiento de la aplicación.
                    </p>
                    <div className="flex flex-col items-center p-3 gap-4">
                        <input type="file" onChange={handleFileChange} className="mb-2" />
                        <button className="px-4 py-2 bg-primary text-white rounded-md hover:bg-primary-300" onClick={handleImportCRMConfirm}>
                            Confirmar
                        </button>
                        <button className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600" onClick={handleCancel}>
                            Cancelar
                        </button>
                    </div>
                </Modal>
            )}
        </>
    );

}

export default SettingsComponents;