import { useEffect, useState } from 'react';
import { PiCaretRightBold, PiTrendDownBold, PiTrendUpBold, } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';
import { Badge, Text, Title } from 'rizzui';
import { toast } from 'sonner';
import { Loader } from '../../../components/loader/SpinnerLogo';
import useFilters from '../../../hooks/useFilters';
import { menuRoutes } from '../../../router/menu';
import { DashboardService } from '../../../services/dashboard/dashboardService';
import cn from '../../../utils/classNames';
import { formatNumberWithCommas } from '../../../utils/formatNumber';

export type CrmStatType = {
    title: string;
    customer: number;
    increased: boolean;
    percentage: number;
    lastMonth: number;
    route: string;
};

export type StatCardProps = {
    className?: string;
    statItem: CrmStatType;
};

export default function CRMStats({ className }: { className?: string }) {

    const { filters } = useFilters();
    const [isLoading, setIsLoading] = useState(true);
    const [crmStats, setCrmStats] = useState<any>(null);

    const apiCalls = [
        (new DashboardService()).getContractsInfo({ ...filters, filter_filters: { option: 'total-contracts' } }),
        (new DashboardService()).getTutorsInfo({ ...filters, filter_filters: { option: 'total-tutors' } }),
        (new DashboardService()).getStudentsInfo({ ...filters, filter_filters: { option: 'total-students' } }),
        (new DashboardService()).getStudentsInfo({ ...filters, filter_filters: { option: 'total-new-students' } }),
    ];

    useEffect(() => {
        setIsLoading(true);
        Promise.all(apiCalls)
            .then(responses => {
                setCrmStats(responses.map((response: any, index: number) => {
                    const data = response.getResponseData().data;
                    return {
                        title: index === 0 ? 'Contratos' : index === 1 ? 'Tutores' : index === 2 ? 'Empleados' : 'Nuevos Empleados',
                        customer: data.totalContracts || data.totalTutors || data.totalStudents || data.totalNewStudents || 0,
                        lastMonth: data.lastMonthContracts || data.lastMonthTutors || data.lastMonthStudents || data.lastMonthNewStudents || 0,
                        increased: data.totalContracts > data.lastMonthContracts || data.totalTutors > data.lastMonthTutors || data.totalStudents > data.lastMonthStudents || data.totalNewStudents > data.lastMonthNewStudents || false,
                        percentage: Math.round((data.totalContracts || data.totalTutors || data.totalStudents || data.totalNewStudents) / (data.lastMonthContracts || data.lastMonthTutors || data.lastMonthStudents || data.lastMonthNewStudents || 1) * 100) || 0,
                        route: index === 0 ? menuRoutes.contracts.path : index === 1 ? menuRoutes.tutors.path : menuRoutes.students.path,
                    };
                }));
            })
            .catch(error => {
                toast.error('Error al cargar los datos');
                console.error(error);
            })
            .finally(() => setIsLoading(false));
    }, [filters]);

    return (
        <div className={cn('@container', className)}>
            <div className="grid grid-cols-1 gap-4 @lg:grid-cols-2 @3xl:gap-6 @4xl:grid-cols-4">
                {isLoading
                    ? <div className="col-span-full flex justify-center items-center"><Loader height='20vh' /></div>
                    : (crmStats.map((stat: CrmStatType, index: number) => {
                        return <StatCard key={'stat-card-' + index} statItem={stat} />;
                    }))
                }
            </div>
        </div>
    );
}

function StatCard({ className, statItem }: StatCardProps) {
    const { title, customer, increased, percentage, lastMonth, route } = statItem;
    const navigate = useNavigate();

    return (
        <div
            className={cn(
                'space-y-4 rounded-lg border border-muted p-5 dark:bg-[#181818]',
                className
            )}
        >
            <div className='flex justify-between align-middle'>
                <Text className="font-semibold text-gray-900">{title}</Text>
                <PiCaretRightBold className="size-3.5 rtl:rotate-180 cursor-pointer" onClick={() => navigate(route)} />
            </div>

            <div className='flex items-center justify-end gap-2'>
                {title === 'Nuevos Empleados' && (<div className="text-gray-400">este mes:</div>)}
                <Title className="text-3xl font-normal leading-none">
                    {formatNumberWithCommas(customer)}
                </Title>
                <Badge
                    size="sm"
                    style={{
                        backgroundColor: increased ? '#C0F2CC' : '#FCECD6',
                        color: increased ? '#22973F' : '#EE6D3D',
                    }}
                >
                    <span className="pe-1">{percentage}%</span>
                    {increased ? (
                        <PiTrendUpBold className="size-3" />
                    ) : (
                        <PiTrendDownBold className="size-3" />
                    )}
                </Badge>
            </div>

            <div className="text-gray-400">
                último mes:{' '}
                <strong className="text-gray-900">
                    {formatNumberWithCommas(lastMonth)}
                </strong>
            </div>
        </div>
    );
}