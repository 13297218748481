import { useState } from "react"
import { IconType } from 'react-icons/lib'
import { PiCaretDownBold, PiCaretLeftBold, PiCaretRightBold } from "react-icons/pi"
import { useDispatch } from "react-redux"
import { Link, useLocation } from "react-router-dom"
import { Button, cn, Text } from "rizzui"
import { useBerylliumSidebars } from "../../hooks/themes/berylliumUtils"
import { LAYOUT_OPTIONS, useLayout } from "../../hooks/useLayout"
import { useScrollableSlider } from "../../hooks/useScrollableSlider"
import { setSelectedView } from "../../redux/statesSlice"
import { permissionsGroup, usePrivilege } from "../priviledge/PriviledgeProvider"

export type MenuItem = {
    path: string
    label: string
    permission: {
        action: string
        group: permissionsGroup
    }
    children?: MenuItem[]
    icon?: IconType
}

export type VerticalTabNavProps = {
    menuItems: MenuItem[]
    rootPath?: string
}


export default function VerticalTabNav({ menuItems }: VerticalTabNavProps) {

    const location = useLocation();
    const { sliderEl, sliderPrevBtn, sliderNextBtn, scrollToTheRight, scrollToTheLeft } = useScrollableSlider();
    const { layout } = useLayout();
    const { expandedLeft } = useBerylliumSidebars();
    const dispatch = useDispatch();
    const { userCan } = usePrivilege();
    const basePath = location.pathname === '/states' ? location.pathname : location.pathname.split('/').slice(0, -1).join('/');

    // State to keep track of open menus, by default all are open
    const [openMenus, setOpenMenus] = useState<Record<number, boolean>>(menuItems.reduce((acc, _, idx) => ({ ...acc, [idx]: true }), {}));

    /**
     * Toggle the children of a menu item
     */
    const toggleChildren = (index: number) => {
        setOpenMenus((prev) => ({
            ...prev,
            [index]: !prev[index],
        }));
    };

    /**
     * Render the children of a menu item
     */
    const renderChildren = (children: MenuItem[], parentPath: string) => (
        children.map((child: MenuItem, idx: number) => {
            if (child.permission && !userCan(child.permission.action, child.permission.group)) return null;
            return (
                <div className="ps-6" key={`child-${idx}`}>
                    <Link
                        to={`${parentPath}${child.path}`}
                        relative="route"
                        key={`child-${idx}`}
                        className={cn(
                            'group relative cursor-pointer py-2 font-medium text-gray-500 pl-6 before:absolute before:left-0 before:z-[1] before:h-0.5 before:bg-gray-1000 before:transition-all hover:text-gray-900',
                            location.pathname.toLowerCase().includes(child.path.toLowerCase()) && 'text-primary',
                        )}
                        onClick={() => dispatch(setSelectedView(child.path))}
                    >
                        <Text
                            as="span"
                            className="inline-flex rounded-md px-2.5 py-1.5 transition-all duration-200 group-hover:bg-gray-100/70"
                        >
                            {child.label}
                        </Text>
                    </Link>
                </div>
            );
        })
    );

    return (
        <div className={cn(
            'sticky z-20 -mx-4 -mt-4 border-e border-muted bg-white px-4 py-0 font-medium text-gray-500 sm:-mt-2 md:-mx-5 md:px-5 lg:-mx-8 lg:mt-0 lg:px-8 xl:-mx-6 xl:px-6 2xl:top-20 3xl:-mx-[33px] 3xl:px-[33px] 4xl:-mx-10 4xl:px-10 dark:bg-gray-50',
            layout === LAYOUT_OPTIONS.LITHIUM
                ? 'top-[66px] sm:top-[70px] md:top-[73px] '
                : layout === LAYOUT_OPTIONS.BERYLLIUM
                    ? 'top-[62px] sm:top-[72px] 2xl:top-[72px]'
                    : 'top-[62px] md:top-[71px]',
            layout === LAYOUT_OPTIONS.BERYLLIUM && expandedLeft && 'xl:-ms-1 xl:px-0 3xl:-ms-2 3xl:ps-0 4xl:-ms-2'
        )}
        >
            <div className="relative flex flex-col items-start overflow-hidden">
                <Button
                    title="Anterior"
                    variant="text"
                    ref={sliderPrevBtn}
                    onClick={() => scrollToTheLeft()}
                    className="!absolute left-0 top-0.5 z-10 w-8 !justify-start px-0 text-gray-500 hover:text-black lg:hidden dark:from-gray-50 dark:via-gray-50"
                >
                    <PiCaretLeftBold className="w-5" />
                </Button>
                <div className="flex h-auto w-full flex-col overflow-hidden">
                    <div className="flex flex-col gap-y-3 overflow-y-auto scroll-smooth ps-3" ref={sliderEl}>
                        {menuItems.map((menu: MenuItem, index: number) => {
                            if (menu.permission && !userCan(menu.permission.action, menu.permission.group)) return;

                            return (
                                <div key={`menu-${index}`}>
                                    <Link
                                        to={`${basePath}${menu.path}`}
                                        relative="route"
                                        className={cn(
                                            'group relative cursor-pointer py-2 font-medium text-gray-500 before:absolute before:left-0 before:z-[1] before:h-0.5  before:bg-gray-1000 before:transition-all hover:text-gray-900',
                                            location.pathname.toLowerCase().includes(menu.path.toLowerCase()) && 'text-primary',
                                        )}
                                        onClick={() => dispatch(setSelectedView(menu.path))}
                                    >
                                        <Text
                                            as="span"
                                            className="inline-flex rounded-md px-2.5 py-1.5 transition-all duration-200 group-hover:bg-gray-100/70"
                                        >
                                            {menu.label}
                                        </Text>
                                    </Link>

                                    {/* Button to toggle children */}
                                    {menu.children && (
                                        <Button
                                            title={openMenus[index] ? "Ocultar" : "Mostrar"}
                                            variant="text"
                                            onClick={() => toggleChildren(index)}
                                            className="text-gray-500 hover:text-black"
                                        >
                                            {openMenus[index] ? <PiCaretDownBold /> : <PiCaretRightBold />}
                                        </Button>
                                    )}

                                    {/* Render children */}
                                    {menu.children && openMenus[index] && renderChildren(menu.children, basePath)}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <Button
                    title="Siguiente"
                    variant="text"
                    ref={sliderNextBtn}
                    onClick={() => scrollToTheRight()}
                    className="!absolute right-0 top-0.5 z-10 w-8 !justify-end px-0 text-gray-500 hover:text-black lg:hidden dark:from-gray-50 dark:via-gray-50"
                >
                    <PiCaretRightBold className="w-5" />
                </Button>
            </div>
        </div>
    )
}