import React, { useCallback } from 'react';
import { PiEnvelopeSimple } from 'react-icons/pi';
import { useParams } from 'react-router-dom';
import { Input } from 'rizzui';
import PhoneInput from '../../../../../components/forms/PhoneInputWrapper';
import useFetch from '../../../../../hooks/useFetch';
import FormGroup from '../../../../../layout/shared/form-group';
import { ClientService } from '../../../../../services/client/clientService';
import { ClientApiResponse } from '../../../../../type/entities/client-type';
import ClientProfileLayout from '../../ClientProfileLayout';

interface ClientInfoProps { }

const ClientInfo: React.FC<ClientInfoProps> = () => {

    const { id = '' } = useParams<{ id: string }>();
    const service = new ClientService();

    const [data, loading] = useFetch(useCallback(async () => {
        if (!id || id === '') return null;
        const response = await service.getClientById(id);
        return response.getResponseData() as ClientApiResponse;
    }, [id]));

    return (
        <ClientProfileLayout isLoading={loading}>
            <div className="@container">
                <div className="grid gap-7 divide-y-2 divide-dashed divide-gray-200">
                    <FormGroup
                        title="Información General"
                        description="Datos principales"
                        className='pt-4'
                        titleCols="@md:col-span-2"
                        childCols="@md:col-span-10 md:grid-cols-4"
                    >
                        <Input
                            disabled
                            label="CIF/NIF"
                            placeholder="12345678A"
                            value={data?.cifNif ?? ""}
                        />
                        <Input
                            disabled
                            label="Razón Social"
                            placeholder="Nombre de la empresa"
                            value={data?.companyName ?? ""}
                        />
                        <Input
                            prefix={<PiEnvelopeSimple className="h-6 w-6 text-gray-500" />}
                            label="Email"
                            placeholder="info@attiva.es"
                            value={data?.email ?? ""}
                            disabled
                        />
                        <Input
                            disabled
                            label="CNAE"
                            placeholder="1234"
                            value={data?.cnae ?? ""}
                        />
                        <Input
                            disabled
                            label="Descripción de la actividad"
                            placeholder="Actividad de la empresa"
                            value={data?.activityDescription ?? ""}
                            className="md:col-span-2"
                        />
                        <Input
                            disabled
                            label="IBAN"
                            placeholder="ES123456789123456789"
                            value={data?.iban ?? ""}
                        />
                        <PhoneInput
                            id="telephone"
                            disabled
                            label="Teléfono"
                            placeholder="+34 123456789"
                            value={data?.telephone ?? ""}
                        />
                        <PhoneInput
                            id="telephoneSecondary"
                            disabled
                            label="Teléfono secundario"
                            placeholder="+34 123456789"
                            value={data?.telephoneSecondary ?? ""}
                        />
                        <Input
                            disabled
                            label="Créditos"
                            placeholder=""
                            value={data?.credits ?? ""}
                        />
                    </FormGroup>

                    <FormGroup
                        title="Domicilio social"
                        description="Datos de la dirección"
                        className='pt-4'
                        titleCols="@md:col-span-2"
                        childCols="@md:col-span-10 md:grid-cols-6"
                    >
                        <Input
                            disabled
                            label="Provincia"
                            placeholder="Provincia"
                            value={data?.registeredOfficeProvince ?? ""}
                        />
                        <Input
                            disabled
                            label="Población"
                            placeholder="Población"
                            value={data?.registeredOfficeTown ?? ""}
                        />
                        <Input
                            disabled
                            label="Dirección"
                            placeholder="Dirección de la empresa"
                            value={data?.registeredOfficeAddress ?? ""}
                            className='md:col-span-3'
                        />
                        <Input
                            disabled
                            label="Código Postal"
                            placeholder="12345"
                            value={data?.registeredOfficePostalCode ?? ""}
                        />
                    </FormGroup>

                    <FormGroup
                        title="Responsable"
                        description="Datos del responsable"
                        className='pt-4'
                        titleCols="@md:col-span-2"
                        childCols="@md:col-span-10 md:grid-cols-3"
                    >
                        <Input
                            disabled
                            label="CIF/NIF"
                            placeholder="12345678A"
                            value={data?.personResponsibleNif ?? ""}
                        />
                        <Input
                            disabled
                            label="Nombre y apellidos"
                            placeholder="Nombre del responsable"
                            value={data?.personResponsibleName ?? ""}
                        />
                        <Input
                            prefix={<PiEnvelopeSimple className="h-6 w-6 text-gray-500" />}
                            label="Email"
                            placeholder="info@attiva.es"
                            value={data?.personResponsibleEmail ?? ""}
                            disabled
                        />
                        <PhoneInput
                            id="personResponsibleTelephone"
                            disabled
                            label="Teléfono"
                            placeholder="+34 123456789"
                            value={data?.personResponsibleTelephone ?? ""}
                        />
                        <PhoneInput
                            id='personResponsibleTelephoneSecondary'
                            disabled
                            label='Teléfono Secundario'
                            placeholder='+34 123456789'
                            value={data?.personResponsibleTelephoneSecondary ?? ''}
                        />
                    </FormGroup>
                </div>
            </div>
        </ClientProfileLayout>
    );
};

export default ClientInfo;