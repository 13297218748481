import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import AttivaCard from '../../../components/card/AttivaCard';
import useHandleErrors from '../../../hooks/useHandleErrors';
import PageHeader from '../../../layout/shared/page-header';
import { menuRoutes } from '../../../router/menu';
import { ClientService } from '../../../services/client/clientService';
import ClientForm from '../ClientForm';

const pageHeader = {
    title: 'Crear Cliente',
    breadcrumb: [
        {
            href: menuRoutes.clients.path,
            name: 'Clientes',
        },
        {
            name: 'Crear',
        },
    ],
};

export default function ClientCreate() {

    const { handleErrors } = useHandleErrors();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);

    const handleSubmit = async (values: any) => {
        setLoading(true);
        try {
            const response = await (await (new ClientService()).createClient(values)).getResponseData();
            if (response.success) {
                setLoading(false);
                navigate(-1);
                setTimeout(() => { toast.success('Cliente creado correctamente') }, 100);
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb} />

            <AttivaCard title='Datos Principales'>
                <ClientForm submit={handleSubmit} isLoading={loading} />
            </AttivaCard>
        </>
    );
}