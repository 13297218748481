// src/store/slices/orderProfileSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface OrderProfileState {
    selectedView: string;
    orderTypeId: string | null;
}

const initialState: OrderProfileState = {
    selectedView: '/info',
    orderTypeId: '',
};

const orderProfileSlice = createSlice({
    name: 'orderProfile',
    initialState,
    reducers: {
        setSelectedView(state, action: PayloadAction<string>) {
            state.selectedView = action.payload;
        },
        setOrderTypeId(state, action: PayloadAction<string | null>) {
            state.orderTypeId = action.payload;
        }
    },
});

export const { setSelectedView, setOrderTypeId} = orderProfileSlice.actions;
export default orderProfileSlice.reducer;