import { useFormik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Textarea } from 'rizzui';
import * as yup from 'yup';
import RequiredInput from '../../../components/forms/InputHOC';
import { PopoverPicker } from '../../../components/forms/PopoverPicker';
import Page from '../../../layout/Page/Page';
import FormGroup from '../../../layout/shared/form-group';
import { OrderType } from '../../../type/entities/order-type';
import FormFooter from '../../_layout/_footers/form-footer';

interface OrderTypeFormProps {
    isLoading: boolean;
    submit: Function;
    data?: OrderType | undefined;
}

const schema = yup.object({
    name: yup.string().required("Este campo es obligatorio"),
    description: yup.string().required("Este campo es obligatorio"),
    color: yup.string().required('Campo obligatorio').matches(/^#[0-9A-Fa-f]{6}$/, 'Color insválido'),
    hoursNumber: yup.number().min(0, 'El número de horas no puede ser negativo'),
});

const OrderTypeForm: React.FC<OrderTypeFormProps> = ({ isLoading, submit, data }) => {

    const mode = data ? 'Editar' : 'Crear';
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            orderTypesId: data?.id || '',
            name: data?.name || '',
            color: data?.color ?? '#607d8b',
            description: data?.description || '',
            hoursNumber: data?.hoursNumber || 0,
        },
        validationSchema: schema,
        onSubmit: values => { submit(values) },
    })

    const getContent = () => {
        return (
            <div className="@container">
                <form onSubmit={formik.handleSubmit}>
                    <div className="grid gap-7 divide-y-2 divide-dashed divide-gray-200 font-medium">
                        <FormGroup
                            title="Información General"
                            description="Datos principales"
                            titleCols="@md:col-span-2"
                            childCols="@md:col-span-10 md:grid-cols-4"
                        >
                            <RequiredInput
                                id='name'
                                required={true}
                                label="Nombre"
                                formik={formik}
                                className='@md:col-span-2'
                            />

                            <RequiredInput
                                id='hoursNumber'
                                required={false}
                                type="number"
                                min={0}
                                step={1}
                                label="Horas"
                                formik={formik}
                            />

                            <div className='flex flex-row flex-wrap gap-3 align-bottom'>
                                <RequiredInput
                                    id='color'
                                    label="Color"
                                    inputClassName="text-sm flex flex-grow"
                                    formik={formik}
                                />
                                <PopoverPicker color={formik.values.color} onChange={(color: string) => formik.setFieldValue('color', color)} />
                            </div>

                            <Textarea
                                id='description'
                                label={<label>Descripción <span className="text-red-500"> *</span></label>}
                                className="[&>label>span]:font-medium col-span-4"
                                onChange={formik.handleChange}
                                value={formik.values.description}
                                error={formik.errors.description}
                            />
                        </FormGroup>

                    </div>
                </form>
            </div>
        )
    };

    return (
        <>
            <Page container="fluid">
                {(mode === "Editar" && data) && getContent()}
                {mode === "Crear" && getContent()}
            </Page>
            <FormFooter
                submitBtnText={mode + ' Tipo de Pedido'}
                handleCancelBtn={() => navigate(-1)}
                handleSubmitBtn={formik.submitForm}
                isLoading={isLoading}
            />
        </>
    );
};

export default OrderTypeForm;