import { FC, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";
import AttivaCard from "../../../components/card/AttivaCard";
import { Loader } from "../../../components/loader/SpinnerLogo";
import useFetch from "../../../hooks/useFetch";
import useHandleErrors from "../../../hooks/useHandleErrors";
import PageHeader from "../../../layout/shared/page-header";
import { menuRoutes } from "../../../router/menu";
import IncidenceForm from "../IncidenceForm";
import { IncidenceService } from "../../../services/incidence/incidenceService";
import { IncidencesApiResponse } from "../../../type/entities/incidence-type";


const IncidenceEdit: FC = () => {

    const { id = "" } = useParams<{ id: string }>();
    const { handleErrors } = useHandleErrors();
    const navigate = useNavigate();
    const service = new IncidenceService();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [data, loading] = useFetch(useCallback(async () => {
        const response = await service.getIncidenceById(id as string);
        return response.getResponseData() as IncidencesApiResponse;
    }, [id]));

    const pageHeader = {
        title: `Editar Incidencia ( ${data?.code} )`,
        breadcrumb: [
            { name: 'Ventas' },
            { name: 'Gestión de incidencias' },
            { name: 'Incidencia', href: menuRoutes.incidences.path },
            { name: 'Editar Incidencia' },
        ],
    };

    const handleSubmit = async (values: any) => {
        try {
            const response = (await service.editIncidence(values)).getResponseData();
            if (response.success) {
                navigate(-1);
                toast.success("Incidencia editado correctamente");
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error("Error al editar incidencia");
        }
    };

    if (loading) return <Loader />;

    return(
        <>
            <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb} />

            <AttivaCard title="Datos Principales">
                {(data !== null && data !== undefined) && <IncidenceForm data={data} submit={handleSubmit} isLoading={isLoading} />}
            </AttivaCard>
        </>
    );
}

export default IncidenceEdit;