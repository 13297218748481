import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';

const ENDPOINT = '/dashboard';

export class DashboardService extends RestServiceConnection {

    getTutorsInfo = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/tutors-info',
            data: filters,
        }, true) as AxiosResponse;
        return this;
    }

    getStudentsInfo = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/students-info',
            data: filters,
        }, true) as AxiosResponse;
        return this;
    }

    getContractsInfo = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/contracts-info',
            data: filters,
        }, true) as AxiosResponse;
        return this;
    }

    getNearOrdersInfo = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/near-orders-info',
            data: filters,
        }, true) as AxiosResponse;
        return this;
    }

}