import React, { useCallback, useState } from 'react';
import { FaCommentAlt, FaUnlink } from 'react-icons/fa';
import { FaPrint } from 'react-icons/fa6';
import { HiOutlineEye } from 'react-icons/hi2';
import { MdOutlineModeEditOutline } from 'react-icons/md';
import { Link, useParams } from 'react-router-dom';
import { ActionIcon, Button, Text, Tooltip } from 'rizzui';
import { toast } from 'sonner';
import DeletePopover from '../../../../../components/buttons/DeletePopover';
import StatusDropdown from '../../../../../components/forms/StatusDropdown';
import { Loader } from '../../../../../components/loader/SpinnerLogo';
import { usePrivilege } from '../../../../../components/priviledge/PriviledgeProvider';
import CustomTable from '../../../../../components/table/CustomTable';
import { FilterDrawerView } from '../../../../../components/table/components/TableFilter';
import useContractCommissionStates from '../../../../../hooks/api-calls/contract/useContractCommissionStates';
import useContractProductsStates from '../../../../../hooks/api-calls/contract/useContractProductsStates';
import useContractStates from '../../../../../hooks/api-calls/contract/useContractStates';
import useHandleDownloadDocument from '../../../../../hooks/useDownloadDocument';
import useFetch from '../../../../../hooks/useFetch';
import useFilters from '../../../../../hooks/useFilters';
import useHandleErrors from '../../../../../hooks/useHandleErrors';
import { menuRoutes } from '../../../../../router/menu';
import { ContractService } from '../../../../../services/contract/contractService';
import { OrderService } from '../../../../../services/order/orderService';
import { AddCommentModal } from '../../../../clients/list/components/AddCommentModal';
import OrderProfileLayout from '../../OrderProfileLayout';
import AddContractModal from './AddContractModal';
import ContractStatusModal from './ContractStatusModal';
import OrderContractsFilters from './OrderContractsFilters';

export const contractColumnOrder = [
    'id',
    'code',
    'currentContractStatus',
    'currentContractStatusDate',
    'currentProductStatus',
    'currentContractProductStatusDate',
    'currentCommissionStatus',
    'currentCommissionStatusColor',
    'currentCommissionStatusDate',
    'currentCommissionStatusId',
    'product',
    'tutor',
    'client',
    'clientCompanyName',
    'clientCifNif',
    'studentId',
    'studentName',
    'studentFirstName',
    'studentLastName',
    'studentNif',
    'commercialNetworkName',
    'commercialName',
    'createdAt',
    'updatedAt',
    'invoiceId',
    'incidence',
    'isDraft',
    'commercialNetworkId',
    'commercialId',
    'commercialLastName',
];

interface OrderContractsProps { }

const OrderContracts: React.FC<OrderContractsProps> = ({ }) => {

    // HOOKS

    const { id } = useParams<{ id: string }>();
    const { userCan } = usePrivilege();
    const { handleDownloadDocumentNewTab } = useHandleDownloadDocument();
    const { handleErrors } = useHandleErrors();
    const { getContractStatesList, isLoadingContractStates } = useContractStates({ active: true, action: 'edit' });
    const { getContractProductsStatesList, isLoadingContractProductsStates } = useContractProductsStates({ active: true, action: 'edit' });
    const { getContractCommissionStatesList, isLoadingContractCommissionStates } = useContractCommissionStates({ active: true, action: 'edit' });
    const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters } = useFilters({ order: id }, [], 1, 50, { order: id });
    const service = new ContractService();

    // STATES

    const [openFilters, setOpenFilters] = useState<boolean>(false);
    const [openCommentModal, setOpenCommentModal] = useState<boolean>(false);
    const [openStatusModal, setOpenStatusModal] = useState<boolean>(false);
    const [openAddContractModal, setOpenAddContractModal] = useState<boolean>(false);
    const [rowId, setRowId] = useState<string>('');
    const [selectedRows, setSelectedRows] = useState<string[]>([]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = await (new OrderService).getOrderContracts(filters);
        return response.getResponseData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]));

    //-------------------------------------------------------------------------------------------------------------------------------
    /**
     * @ES DESVINCULAR CONTRATO DE PEDIDO
     * @EN UNLINK CONTRACT FROM ORDER
     */
    //-------------------------------------------------------------------------------------------------------------------------------
    const handleRemoveContractFromOrder = async (selectedRows: string[]) => {
        const response = await (await (new OrderService).removeContractsFromOrder({ contractIds: selectedRows })).getResponseData();
        if (response.success) {
            toast.success(response.message);
            refetch();
        } else {
            handleErrors(response);
        }
    };
    //-------------------------------------------------------------------------------------------------------------------------------

    //-------------------------------------------------------------------------------------------------------------------------------
    /**
     * @ES CAMBIAR ESTADO DEL CONTRATO
     * @EN CHANGE CONTRACT STATUS
     */
    //-------------------------------------------------------------------------------------------------------------------------------
    const handleStateChange = async (id: string, stateId: string, comment: string, entityId: string, entityName: string) => {
        var response = { success: false };
        switch (entityName) {
            case 'contract':
                try {
                    response = await (await service.changeContractStatus(id, stateId, comment)).getResponseData();
                    if (response.success) {
                        toast.success('Estado del contrato actualizado correctamente');
                    } else {
                        handleErrors(response);
                    }
                } catch (error) {
                    toast.error('Error al actualizar el estado');
                }
                break;
            case 'product':
                try {
                    response = await (await service.changeContractProductStatus(id, stateId, entityId, comment)).getResponseData();
                    if (response.success) {
                        toast.success('Estado del producto actualizado correctamente');
                    } else {
                        handleErrors(response);
                    }
                } catch (error) {
                    toast.error('Error al actualizar el estado del producto');
                }
                break;
            case 'commission':
                try {
                    response = await (await service.changeContractCommissionStatus(id, stateId, comment)).getResponseData();
                    if (response.success) {
                        toast.success('Estado de la comisión actualizado correctamente');
                    } else {
                        handleErrors(response);
                    }
                } catch (error) {
                    toast.error('Error al actualizar el estado de la comisión');
                }
                break;
            default:
                break;
        }
        refetch();
    };
    //-------------------------------------------------------------------------------------------------------------------------------

    //-------------------------------------------------------------------------------------------------------------------------------
    /**
     * @ES ELIMINAR CONTRATO
     * @EN DELETE CONTRACT
     */
    //-------------------------------------------------------------------------------------------------------------------------------
    const handleDelete = async (id: string) => {
        const response = await (await service.deleteContract(id)).getResponseData();
        if (response.success) {
            toast.success('Contrato eliminado correctamente');
            refetch();
        } else {
            handleErrors(response);
        }
    };
    //-------------------------------------------------------------------------------------------------------------------------------

    //-------------------------------------------------------------------------------------------------------------------------------
    /**
     * @ES ELIMINAR VARIOS CONTRATOS
     * @EN DELETE MULTIPLE CONTRACTS
     */
    //-------------------------------------------------------------------------------------------------------------------------------
    const handleMultiDelete = async (ids: string[]) => {
        const response = await (await service.deleteMultiContracts(ids)).getResponseData();
        if (response.success) {
            toast.success('Contratos eliminados correctamente');
            refetch();
        } else {
            handleErrors(response);
        }
    };
    //-------------------------------------------------------------------------------------------------------------------------------

    /**
     * Order the data to match the column order
     */
    const orderedData = () => data?.data.map((row: any) => {
        const orderedRow: any = {};
        contractColumnOrder.forEach((key) => {
            orderedRow[key] = row[key];
        });
        return orderedRow;
    });

    // RENDER

    return (
        <OrderProfileLayout>
            <>
                {!isLoadingContractStates && !isLoadingContractProductsStates && !isLoadingContractCommissionStates && data !== undefined
                    ? (
                        <CustomTable
                            largeTable
                            id={'contracts-table'}
                            columnOrder={contractColumnOrder}
                            data={orderedData()}
                            isLoading={loading}
                            columnsNotShown={[
                                'currentContractStatusDate',
                                'currentContractProductStatusDate',
                                'currentCommissionStatusColor',
                                'currentCommissionStatusDate',
                                'currentCommissionStatus',
                                'clientCompanyName',
                                'clientCifNif',
                                'studentId',
                                'studentFirstName',
                                'studentLastName',
                                'studentNif',
                                'commercialNetworkId',
                                'commercialId',
                                'commercialLastName',
                                'invoiceId',
                                'incidence',
                                'isDraft',
                            ]}
                            overrideColumns={[
                                {
                                    key: 'code',
                                    render: (row: any) => {
                                        return (
                                            userCan('get_contracts', 'contracts')
                                                ? (
                                                    <Link to={`${menuRoutes.contracts.path}/${row.row.original.id}/profile${menuRoutes.contracts.profile.info}`}>
                                                        <Text className='font-bold text-primary'>{row.row.original.code}</Text>
                                                    </Link>
                                                )
                                                : <Text className='font-bold text-primary'>{row.row.original.code}</Text>
                                        );
                                    }
                                },
                                {
                                    key: 'currentContractStatus',
                                    render: (row: any) => {
                                        return (
                                            <div className='flex flex-col gap-2 justify-content-center text-center' key={row.row.original.id}>
                                                <StatusDropdown
                                                    entityId={row.row.original?.id}
                                                    title="¿Estas seguro de cambiar el estado del contrato?"
                                                    statesOptions={getContractStatesList(null, row.row.original?.currentContractStatus?.id)}
                                                    currentState={row.row.original?.currentContractStatus}
                                                    currentStateDate={row.row.original?.currentContractStatusDate?.date}
                                                    handleStateChange={(entityId: string, statusId: string, comment: string) =>
                                                        handleStateChange(entityId, statusId, comment, entityId, 'contract')
                                                    }
                                                />
                                            </div>
                                        );
                                    }
                                },
                                {
                                    key: 'currentProductStatus',
                                    render: (row: any) => {
                                        const productStatusDate = row.row.original?.currentContractProductStatusDate?.date;
                                        const productStatus = row.row.original?.currentProductStatus;
                                        const product = row.row.original?.product;
                                        return (
                                            <div className='flex flex-col gap-2 justify-content-center text-center' key={row.row.original.id}>
                                                {(productStatusDate && productStatus) ? (
                                                    <StatusDropdown
                                                        disabled={!product?.id || row.row.original?.isDraft}
                                                        entityId={row.row.original?.id}
                                                        title="¿Estas seguro de cambiar el estado del producto?"
                                                        statesOptions={getContractProductsStatesList(null, productStatus.id)}
                                                        currentState={productStatus}
                                                        currentStateDate={productStatusDate}
                                                        handleStateChange={(entityId: string, statusId: string, comment: string) => {
                                                            handleStateChange(entityId, statusId, comment, product.id, 'product');
                                                        }
                                                        }
                                                    />
                                                ) : "N/A"}
                                            </div>
                                        );
                                    }
                                },
                                {
                                    key: 'currentCommissionStatusId',
                                    render: (row: any) => {
                                        const commissionStatus = {
                                            'id': row.row.original?.currentCommissionStatusId,
                                            'color': row.row.original?.currentCommissionStatusColor,
                                            'date': row.row.original?.currentCommissionStatusDate,
                                            'name': row.row.original?.currentCommissionStatus,
                                        }
                                        const commission = row.row.original?.currentIncidence;

                                        return (
                                            <div className='flex flex-col gap-2 justify-content-center text-center'>
                                                {(commissionStatus && commissionStatus.id) ? (
                                                    <StatusDropdown
                                                        disabled={row.row.original?.isDraft}
                                                        entityId={row.row.original?.id}
                                                        title="¿Estas seguro de cambiar el estado de la comisión?"
                                                        statesOptions={getContractCommissionStatesList(null, commissionStatus.id)}
                                                        currentState={commissionStatus}
                                                        currentStateDate={commissionStatus.date}
                                                        handleStateChange={(entityId: string, statusId: string, comment: string) =>
                                                            handleStateChange(entityId, statusId, comment, '', 'commission')
                                                        }
                                                    />
                                                ) : "N/A"}
                                            </div>
                                        );
                                    }
                                },
                                {
                                    key: 'tutor',
                                    render: (row: any) => {
                                        const tutor = row.row.original.tutor;
                                        return (
                                            tutor
                                                ? userCan('get_tutors', 'tutors')
                                                    ? (
                                                        <>
                                                            <Link to={`${menuRoutes.tutors.path}/${tutor?.id}/profile${menuRoutes.tutors.profile.info}`}>
                                                                <Text className='font-bold text-primary'>{`${tutor?.name} ${tutor?.lastName || ''}`}</Text>
                                                            </Link>
                                                            {tutor?.nifCif && <div className='d-block'>{tutor?.nifCif}</div>}
                                                        </>
                                                    )
                                                    : <Text className='font-bold text-primary'>`${tutor?.name} ${tutor?.lastName || ''}`</Text>
                                                : <Text>N/A</Text>
                                        )
                                    },
                                },
                                {
                                    key: 'client',
                                    render: (row: any) => {
                                        const client = row.row.original?.client;
                                        return (
                                            <>
                                                {client
                                                    ? userCan('get_clients', 'clients')
                                                        ? (
                                                            <div>
                                                                <Link
                                                                    to={`${menuRoutes.clients.path}/${client?.id}/profile/info`}
                                                                    className='font-bold text-primary cursor-pointer d-block'
                                                                >
                                                                    {row.row.original?.clientCompanyName || 'N/A'}
                                                                </Link>
                                                                {row.row.original?.clientCifNif && <div className='d-block'>{row.row.original?.clientCifNif}</div>}
                                                                {userCan('get_clients_comments', 'clients') && client.lastComment && (
                                                                    <Tooltip
                                                                        size="sm"
                                                                        content={<div className='tooltip-container'>{client.lastComment}</div>}
                                                                        placement="top"
                                                                        color="invert"
                                                                    >
                                                                        <ActionIcon
                                                                            as="span"
                                                                            size="sm"
                                                                            variant="text"
                                                                            className="hover:!border-gray-900 hover:text-gray-700"
                                                                        >
                                                                            <FaCommentAlt className="h-4 w-4" size={20} />
                                                                        </ActionIcon>
                                                                    </Tooltip>
                                                                )}
                                                            </div>
                                                        )
                                                        : 'N/A'
                                                    :
                                                    (<span className='font-bold text-primary'>{row.row.original?.clientCompanyName || row.row.original?.clientCifNif || 'N/A'}</span>)
                                                }
                                            </>
                                        )
                                    }
                                },
                                {
                                    key: 'studentName',
                                    render: (row: any) => {
                                        const name = row.row.original?.studentName
                                            ? `${row.row.original?.studentName} ${row.row.original?.studentFirstName ? ' ' + row.row.original?.studentFirstName : ''} ${row.row.original?.studentLastName ? ' ' + row.row.original?.studentLastName : ''}`
                                            : null;
                                        return (
                                            <>
                                                {row.row.original?.studentId
                                                    ? userCan('get_clients', 'clients')
                                                        ? (
                                                            <div>
                                                                <Link
                                                                    to={`${menuRoutes.students.path}/${row.row.original?.studentId}/profile/info`}
                                                                    className='font-bold text-primary cursor-pointer d-block'
                                                                >
                                                                    {name || 'N/A'}
                                                                </Link>
                                                                {row.row.original?.studentNif && <div className='d-block'>{row.row.original?.studentNif}</div>}
                                                            </div>
                                                        )
                                                        : 'N/A'
                                                    :
                                                    (<span>{name || row.row.original?.studentNif || 'N/A'}</span>)
                                                }
                                            </>
                                        )
                                    }
                                },
                                {
                                    key: 'product',
                                    render: (row: any) => {
                                        const product = row.row.original?.product;
                                        return (
                                            <div className='flex flex-col'>
                                                {product
                                                    ? (
                                                        <Link
                                                            to={`${menuRoutes.products.path}?filter_filters=%7B"active"%3Atrue%2C"search_text"%3A"${product.name}"%7D&filter_order=%5B%5D&page=1&limit=50`}
                                                            className='font-bold text-primary'
                                                        >
                                                            {product.code} - {product.name}
                                                        </Link>
                                                    )
                                                    : 'N/A'
                                                }
                                            </div>
                                        )
                                    }
                                },
                                {
                                    key: 'commercialNetworkName',
                                    render: (row: any) => {
                                        return (<>{row.row.original?.commercialNetworkName || 'N/A'}</>);
                                    }
                                },
                                {
                                    key: 'commercialName',
                                    render: (row: any) => {
                                        const name = row.row.original?.commercialName ? `${row.row.original?.commercialName} ${row.row.original?.commercialLastName ? ' ' + row.row.original?.commercialLastName : ''}` : null;
                                        return (
                                            <>
                                                {row.row.original?.commercialId
                                                    ? userCan('get_user', 'user')
                                                        ? (
                                                            <div>
                                                                <Link
                                                                    to={`${menuRoutes.users.path}/${row.row.original?.commercialId}/profile/info`}
                                                                    className='font-bold text-primary cursor-pointer d-block'
                                                                >
                                                                    {name || 'N/A'}
                                                                </Link>
                                                            </div>
                                                        )
                                                        : 'N/A'
                                                    :
                                                    (<span>{name || 'N/A'}</span>)
                                                }
                                            </>
                                        )
                                    }
                                }

                            ]}
                            actions={[
                                {
                                    label: 'Desvincular contrato',
                                    permissions: { group: 'orders', permission: 'edit_orders' },
                                    render: (row: any) => {
                                        return (
                                            <Tooltip
                                                size="sm"
                                                content={'Desvincular contrato'}
                                                placement="top"
                                                color="invert"
                                            >
                                                <ActionIcon
                                                    as="span"
                                                    size="sm"
                                                    variant="outline"
                                                    className="hover:!border-gray-900 hover:text-gray-700 cursor-pointer"
                                                    onClick={() => handleRemoveContractFromOrder([row.id])}
                                                >
                                                    <FaUnlink className="h-4 w-4" size={20} />
                                                </ActionIcon>
                                            </Tooltip>
                                        )
                                    },
                                },
                                {
                                    label: 'Generar Contrato',
                                    permissions: { group: 'contracts', permission: 'print_contract' },
                                    render: (row: any) => {
                                        if (row.isDraft) return null;
                                        return (
                                            <Tooltip
                                                size="sm"
                                                content={'Generar contrato'}
                                                placement="top"
                                                color="invert"
                                            >
                                                <ActionIcon
                                                    as="span"
                                                    size="sm"
                                                    variant="outline"
                                                    className="hover:!border-gray-900 hover:text-gray-700 cursor-pointer"
                                                    onClick={() => handleDownloadDocumentNewTab(service.generateContractPdf, row.id, row.code + '_contrato.pdf')}
                                                >
                                                    <FaPrint className="h-4 w-4" size={20} />
                                                </ActionIcon>
                                            </Tooltip>
                                        );
                                    },
                                },
                                {
                                    label: 'Add Comment',
                                    permissions: { group: 'contracts', permission: 'create_contract_comments' },
                                    render: (row: any) => {
                                        return (
                                            <Tooltip
                                                size="sm"
                                                content={'Añadir comentario'}
                                                placement="top"
                                                color="invert"
                                            >
                                                <ActionIcon
                                                    as="span"
                                                    size="sm"
                                                    variant="outline"
                                                    className="hover:!border-gray-900 hover:text-gray-700 cursor-pointer"
                                                    onClick={() => { setRowId(row.id); setOpenCommentModal(true) }}
                                                >
                                                    <FaCommentAlt className="h-4 w-4" size={20} />
                                                </ActionIcon>
                                            </Tooltip>
                                        );
                                    },
                                },
                                {
                                    label: 'View',
                                    permissions: { group: 'user', permission: 'get_user' },
                                    render: (row: any) => {
                                        return (
                                            <Tooltip
                                                size="sm"
                                                content={'Ver detalles'}
                                                placement="top"
                                                color="invert"
                                            >
                                                <Link
                                                    to={`${menuRoutes.contracts.path}/${row.id}/profile${menuRoutes.contracts.profile.info}`}>
                                                    <ActionIcon
                                                        as="span"
                                                        size="sm"
                                                        variant="outline"
                                                        className="hover:!border-gray-900 hover:text-gray-700"
                                                    >
                                                        <HiOutlineEye className="h-4 w-4" size={20} />
                                                    </ActionIcon>
                                                </Link>
                                            </Tooltip>
                                        );
                                    },
                                },
                                {
                                    label: 'Edit',
                                    permissions: { group: 'contracts', permission: 'edit_contracts' },
                                    render: (row: any) => {
                                        return (
                                            <Tooltip
                                                size="sm"
                                                content={'Editar contrato'}
                                                placement="top"
                                                color="invert"
                                            >
                                                <Link to={`${menuRoutes.contracts.path}/${row.id}/edit`}>
                                                    <ActionIcon
                                                        as="span"
                                                        size="sm"
                                                        variant="outline"
                                                        className="hover:!border-gray-900 hover:text-gray-700"
                                                    >
                                                        <MdOutlineModeEditOutline className="h-4 w-4" size={20} />
                                                    </ActionIcon>
                                                </Link>
                                            </Tooltip>
                                        );
                                    },
                                },
                                {
                                    label: 'Delete',
                                    permissions: { group: 'contracts', permission: 'delete_contracts' },
                                    render: (row: any) => {
                                        return (
                                            <Tooltip
                                                size="sm"
                                                content={'Eliminar'}
                                                placement="top"
                                                color="invert"
                                            >
                                                <div>
                                                    <DeletePopover
                                                        title={`Eliminar contrato`}
                                                        description={`¿Estás seguro de que deseas eliminar el contrato ${row.code}?`}
                                                        onDelete={() => handleDelete(row.id)}
                                                    />
                                                </div>
                                            </Tooltip>
                                        );
                                    },
                                },
                            ]}
                            multiActions={[
                                {
                                    label: 'Desvincular contrato',
                                    permissions: { group: 'orders', permission: 'edit_orders' },
                                    render: (selectedRows: any) => {
                                        return (
                                            <Button
                                                variant={'outline'}
                                                color={'secondary'}
                                                size={'sm'}
                                                onClick={() => { handleRemoveContractFromOrder(selectedRows) }}
                                                className='p-3 text-nowrap'
                                            >
                                                Desvincular Contrato
                                            </Button>
                                        )
                                    },
                                },
                                {
                                    label: 'Cambiar estado',
                                    permissions: { group: 'contracts', permission: 'edit_contracts' },
                                    render: (selectedRows: any) => {
                                        return (
                                            <Button
                                                variant={'outline'}
                                                color={'secondary'}
                                                size={'sm'}
                                                onClick={() => { setOpenStatusModal(true); setSelectedRows(selectedRows) }}
                                                className='p-3 text-nowrap'
                                            >
                                                Cambiar Estado
                                            </Button>
                                        )
                                    },
                                },
                            ]}
                            handleMultipleDelete={handleMultiDelete}
                            filters={filters}
                            updateFilters={updateFilters}
                            updateFilterOrder={updateFilterOrder}
                            defaultOrder={filters.filter_order || undefined}
                            paginationData={{
                                pageSize: filters.limit,
                                currentPage: filters.page,
                                pageCount: data ? data.lastPage : 1,
                                totalCount: data?.totalRegisters,
                                handlePagination: updatePage,
                                handlePerPage: updatePageSize,
                            }}
                            toggleFilters={() => setOpenFilters(!openFilters)}
                        >
                            <Button
                                variant={'outline'}
                                color={'primary'}
                                size={'sm'}
                                onClick={() => setOpenAddContractModal(true)}
                                className='p-3 text-nowrap'
                            >
                                Añadir contrato
                            </Button>
                        </CustomTable>
                    )
                    : <Loader height="70vh" />
                }

                <AddCommentModal service={(new ContractService()).addComment} id={rowId} isOpen={openCommentModal} onClose={() => setOpenCommentModal(false)} refetch={refetch} />

                <ContractStatusModal ids={selectedRows} isOpen={openStatusModal} onClose={() => setOpenStatusModal(false)} refetch={refetch} />

                {openAddContractModal && <AddContractModal isOpen={openAddContractModal} onClose={() => { setOpenAddContractModal(false); refetch(); }} />}

                <FilterDrawerView isOpen={openFilters} setOpenDrawer={setOpenFilters} drawerTitle={'Filtros Contratos'}>
                    <OrderContractsFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} />
                </FilterDrawerView>
            </>
        </OrderProfileLayout>
    );
};

export default OrderContracts;