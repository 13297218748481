import classNames from 'classnames';
import moment from 'moment';
import 'moment/locale/es';
import React, { useCallback, useEffect, useState } from 'react';
import Tree from 'react-d3-tree';
import { useSelector } from 'react-redux';
import { Loader } from '../../../components/loader/SpinnerLogo';
import { usePrivilege } from '../../../components/priviledge/PriviledgeProvider';
import { useFiltersPR } from '../../../components/providers/FiltersProvider';
import { FilterDrawerView } from '../../../components/table/components/TableFilter';
import useFetch from '../../../hooks/useFetch';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import Page from '../../../layout/Page/Page';
import { AuthState } from '../../../redux/authSlice';
import { RootState } from '../../../redux/store';
import { CorporationService } from '../../../services/corporation/corporationService';
import { CorporationsApiResponse } from '../../../type/entities/corporation-type';
import CorporationForm from '../CorporationForm';
import CorporationsFilters from './CorporationsFilters';

interface TreeNode {
    name: string;
    id: string;
    attributes?: any;
    children?: TreeNode[];
}

interface CorporationsListProps {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    nodeId: string | undefined;
    setNodeId: (value: string | undefined) => void;
    openFilters: boolean;
    setOpenFilters: any;
}

const CorporationsList: React.FC<CorporationsListProps> = ({ isOpen, setIsOpen, nodeId, setNodeId, openFilters, setOpenFilters }) => {

    const { user }: AuthState = useSelector((state: RootState) => state.auth);
    const { userCan } = usePrivilege();
    const { filters, updateFilters, resetFilters } = useFiltersPR();
    const { width, height } = useWindowDimensions();
    const service = new CorporationService();

    const [corporations, setCorporations] = useState<TreeNode | undefined>(undefined);
    const [tooltip, setTooltip] = useState<{ visible: boolean; x: number; y: number; nodeData: any | null; }>({ visible: false, x: 0, y: 0, nodeData: null });
    const [nodeStatus, setNodeStatus] = useState<boolean>(false);

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = await service.listCorporations({ ...filters, limit: 1000 });
        return response.getResponseData() as CorporationsApiResponse;
    }, [filters]));

    /**
     * Build the tree structure recursively
     */
    function buildTree(data: any, parentId = null, level = '1') {
        // Filter corporations that have the current 'parentId' as parent
        const children = data.filter((item: any) => {
            return parentId ? item.parentCorporation?.id === parentId : !item.parentCorporation;
        });

        // For each child found, we build its node
        return children.map((child: any, index: number) => {
            return {
                name: child.name,
                id: child.id,
                active: child.active,
                iban: child.iban,
                createdAt: child.createdAt.date,
                updatedAt: child.updatedAt?.date,
                cif: child.cif,
                registeredOffice: child.registeredOffice,
                function: child.corporationFunction === 'delivering' ? 'Impartidora' : child.corporationFunction === 'organizing' ? 'Organizadora' : 'N/A',
                attributes: {
                    CIF: child.cif,
                    Dirección: child.registeredOffice,
                    Función: child.corporationFunction === 'delivering' ? 'Impartidora' : child.corporationFunction === 'organizing' ? 'Organizadora' : 'N/A',
                },
                children: buildTree(data, child.id, `${level}.${index + 1}`),
                representativeName: child.representativeName,
                representativeEmail: child.representativeEmail,
                representativeTelephone: child.representativeTelephone,
                postalCode: child.postalCode,
                province: child.province
            };
        });
    };

    /**
     * Generate the corporation tree structure
     */
    function generateCorporationTree(data: any) {
        return {
            name: user?.companyName || 'Attiva',
            id: '1',
            children: buildTree(data),
        };
    };

    /**
     * Handle node click event
     */
    const handleNodeClick = (nodeData: any) => {
        if (nodeData?.id === '1') return;

        if (userCan('edit_corporation', 'corporation')) {
            setNodeId(nodeData?.id);
            setNodeStatus(nodeData?.active);
            setIsOpen(true);
        }
    };

    const handleNodeMouseOver = (nodeData: any, evt: any) => {
        if (nodeData?.data?.id === '1') return;

        // Get node position relative to the SVG container
        const { x, y } = evt.target.getBoundingClientRect();

        // Toggle tooltip visibility and update position
        setTooltip({
            visible: true,
            x: x + window.scrollX + 35,
            y: y + window.scrollY,
            nodeData: nodeData.nodeDatum as any,
        });
    };

    /**
     * Update corporation tree when data is fetched
     */
    useEffect(() => {
        if (data?.data.length > 0) {
            setCorporations(generateCorporationTree(data.data));
        } else {
            setCorporations(undefined);
        }
    }, [data, filters]);

    if (loading) return <Loader height={'70'} />;

    return (
        <Page container='fluid'>
            <div className='grid grid-cols-3 gap-3 static networkWrapper'>
                <div id="treeWrapper" className={classNames('col-span-3 relative', { 'md:col-span-2': isOpen, 'hidden': !userCan('list_corporation', 'corporation') })}>
                    {(data !== undefined && corporations)
                        ? (
                            <Tree
                                data={corporations}
                                orientation="vertical"
                                pathFunc={'step'}
                                translate={{ x: width / 3, y: height / 10 }}
                                //dimensions={{ width: width, height: height - 200 }}
                                separation={{ siblings: 2, nonSiblings: 2 }}
                                nodeSize={{ x: 150, y: 150 }}
                                renderCustomNodeElement={(nodeData: any) => {
                                    return (
                                        <g>
                                            {nodeData?.nodeDatum.id !== '1'
                                                ? (
                                                    <>
                                                        <circle
                                                            r={15}
                                                            style={{ fill: nodeData.nodeDatum.active ? '#D4EDD9' : '#ffc500' }}
                                                            onClick={() => handleNodeClick(nodeData.nodeDatum)}
                                                            onMouseOver={(evt: any) => handleNodeMouseOver(nodeData, evt)} onMouseOut={() => setTooltip({ ...tooltip, visible: false })}
                                                        />
                                                        <g className="rd3t-label">
                                                            <text className={nodeData.nodeDatum.active ? 'rd3t-label__title' : 'rd3t-label__title-disabled'} textAnchor="start" x={40}>
                                                                {nodeData.nodeDatum.name} {nodeData.nodeDatum.active ? '' : ' (Inactiva)'}
                                                            </text>
                                                            <text className="rd3t-label__attributes">
                                                                <tspan x={40} dy="1.2em">CIF: {nodeData.nodeDatum.cif}</tspan>
                                                                <tspan x={40} dy="1.2em">Dirección: {nodeData.nodeDatum.registeredOffice}</tspan>
                                                                <tspan x={40} dy="1.2em">Función: {nodeData.nodeDatum.function}</tspan>
                                                            </text>
                                                        </g>
                                                    </>
                                                )
                                                : (
                                                    <>
                                                        <circle
                                                            r={15}
                                                            style={{ fill: '#04ABD8' }}
                                                        />
                                                        <g className="rd3t-label">
                                                            <text className="rd3t-label__title" textAnchor="start" x={40}>
                                                                {nodeData.nodeDatum.name}
                                                            </text>
                                                        </g>
                                                    </>
                                                )
                                            }
                                        </g>
                                    );
                                }}
                                shouldCollapseNeighborNodes
                                collapsible={false}
                                enableLegacyTransitions // Remove for large trees. Works only if collapsible is true
                                hasInteractiveNodes
                                scaleExtent={{ min: 0.4, max: 3 }} // Zoom scale
                                rootNodeClassName="node__root"
                                branchNodeClassName="node__branch"
                                leafNodeClassName="node__leaf mt-10"
                            />
                        )
                        : (
                            <div className='flex justify-center items-center h-[calc(100vh-100px)]'>
                                <p className='text-gray-500'>No se han encontrado empresas</p>
                            </div>
                        )
                    }
                </div>

                {tooltip.visible && tooltip.nodeData !== null && (
                    <div
                        onMouseLeave={() => setTooltip({ ...tooltip, visible: false })}
                        style={{
                            position: 'absolute',
                            top: tooltip.y,
                            left: tooltip.x,
                            background: 'white',
                            border: '1px solid #ccc',
                            padding: '10px 15px',
                            borderRadius: '5px',
                            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                            minWidth: '120px',
                            maxWidth: '240px',
                            zIndex: 1000
                        }}
                    >
                        <p className='text-sm font-medium text-gray-500'>IBAN: {tooltip.nodeData?.iban}</p>
                        <p className='text-sm font-medium text-gray-500'>Creación: {moment(tooltip.nodeData?.createdAt).format('L')}</p>
                        <p className='text-sm font-medium text-gray-500'>Actualización: {tooltip.nodeData?.updatedAt ? moment(tooltip.nodeData.updatedAt).format('L') : 'N/A'}</p>
                    </div>
                )}

                <div className='col-span-3 md:col-span-1 asideTreeForm ps-3'>
                    <CorporationForm id={nodeId} setNodeId={setNodeId} nodeStatus={nodeStatus} setNodeStatus={setNodeStatus} isOpen={isOpen} setIsOpen={setIsOpen} refetch={refetch} />
                </div>
            </div>

            <FilterDrawerView isOpen={openFilters} setOpenDrawer={setOpenFilters} drawerTitle={'Filtros Empresas'}>
                <div className="grid grid-cols-1 gap-6">
                    <CorporationsFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} />
                </div>
            </FilterDrawerView>
        </Page >
    );
};

export default CorporationsList;