import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";
import { generateColor } from "../OrderStats";

interface TreMapChartProps {
    data: { productId: string; productName: string; productQuantity: number }[];
}
const TreemapChart: React.FC<TreMapChartProps> = ({ data }) => {

    const colors = data?.map((product) => generateColor(product.productName));

    const chartOptions: ApexOptions = {
        chart: {
            type: "treemap",
        },
        legend: {
            show: false,
        },
        dataLabels: {
            enabled: true,
            formatter: (val: number, opts: any) => opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex].x,
            style: {
                fontSize: "14px",
            },
        },
        colors: colors,
        plotOptions: {
            treemap: {
                distributed: true,
                enableShades: false
            }
        },
    };

    const chartSeries = [
        {
            data: data?.map((product) => ({
                x: product.productName,
                y: product.productQuantity,
            })),
        },
    ];

    if (!data) return null;

    return <Chart options={chartOptions} series={chartSeries} type="treemap" height={350} />;
};

export default TreemapChart;