import React, { useEffect, useState } from 'react';
import { PiTrashDuotone } from 'react-icons/pi';
import { Button, Input } from 'rizzui';
import CustomSelect from '../../../../../components/forms/CustomSelect';
import { usePrivilege } from '../../../../../components/priviledge/PriviledgeProvider';
import useDocumentTypes from '../../../../../hooks/api-calls/useDocumentTypes';
import { FilterOptions } from '../../../../../hooks/useFilters';

interface DocumentsFiltersProps {
    updateFilters: (filters: any) => void
    resetFilters: (limit: any) => void
    filters: FilterOptions
}

const DocumentsFilters: React.FC<DocumentsFiltersProps> = ({ filters, updateFilters, resetFilters }) => {

    const [filter_filters, setFilterFilters] = useState<any>({});
    const [loaded, setLoaded] = useState<boolean>(false);
    const { userCan } = usePrivilege();
    const { getDocumentTypesList } = useDocumentTypes();

    useEffect(() => {
        if (loaded && filter_filters !== filters.filter_filters) {
            const delaySearch = setTimeout(() => {
                updateFilters({ ...filter_filters });
            }, 1000);
            return () => clearTimeout(delaySearch);
        }
    }, [filter_filters, loaded]);

    useEffect(() => {
        setFilterFilters(filters.filter_filters || '');
    }, [filters]);

    useEffect(() => {
        setLoaded(true);
    }, []);

    return (
        <>
            <Input
                id='search_text'
                type="text"
                label="Buscar"
                className="[&>label>span]:font-medium"
                inputClassName="text-sm"
                onChange={(e) => setFilterFilters({ ...filter_filters, search_text: e.target.value })}
                value={filter_filters?.search_text ?? ''}
            />

            <CustomSelect
                isSearchable
                isMulti
                id={'documentTypeId'}
                label="Tipo de documento"
                options={getDocumentTypesList()}
                onChange={(e: any) => { setFilterFilters({ ...filter_filters, document_type: e.map((rol: any) => rol.value) }) }}
                value={
                    filter_filters?.document_type
                        ? getDocumentTypesList().filter((rol: any) => filter_filters.document_type.includes(rol.value))
                        : []
                }
                display={userCan('list_document_type', 'document_type')}
            />

            <Button
                size="sm"
                onClick={() => {
                    resetFilters(50);
                }}
                variant="flat"
                className="h-9 bg-gray-200/70"
            >
                <PiTrashDuotone className="me-1.5 h-[17px] w-[17px]" /> Limpiar
            </Button>
        </>
    );
};

export default DocumentsFilters;