import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import AttivaCard from "../../../components/card/AttivaCard";
import useHandleErrors from "../../../hooks/useHandleErrors";
import PageHeader from "../../../layout/shared/page-header";
import { menuRoutes } from "../../../router/menu";
import { UserService } from "../../../services/user/userService";
import UserForm from "../UserForm";

const pageHeader = {
    title: "Crear Usuario",
    breadcrumb: [
        { name: 'Administración' },
        { name: 'Control de acceso' },
        { href: menuRoutes.users.path, name: "Usuarios" },
        { name: "Crear" },
    ],
};

const UserCreate: FC = () => {

    const { handleErrors } = useHandleErrors();
    const navigate = useNavigate();
    const service = new UserService();

    const [loading, setLoading] = useState<boolean>(false);

    const handleSubmit = async (values: any) => {
        setLoading(true);
        try {
            const response = (await service.createUser(values)).getResponseData();

            if (response.success) {
                navigate(-1);
                toast.success("Usuario creado correctamente");
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error("Error al crear el usuario");
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb} />

            <AttivaCard title="Datos Principales" className="mb-4">
                <UserForm submit={handleSubmit} isLoading={loading} />
            </AttivaCard>
        </>
    );
};

export default UserCreate;