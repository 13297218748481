import { useFormik } from 'formik';
import React from 'react';
import { PiEnvelopeSimple } from 'react-icons/pi';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useNavigate } from 'react-router-dom';
import { Switch, Textarea } from 'rizzui';
import * as yup from 'yup';
import CustomSelect from '../../components/forms/CustomSelect';
import RequiredInput from '../../components/forms/InputHOC';
import PhoneInput from '../../components/forms/PhoneInputWrapper';
import useClients from '../../hooks/api-calls/useClients';
import useEducation from '../../hooks/api-calls/useEducation';
import useProfessionalCategories from '../../hooks/api-calls/useProfessionalCategories';
import useQuotationGroups from '../../hooks/api-calls/useQuotationGroups';
import Page from '../../layout/Page/Page';
import FormGroup from '../../layout/shared/form-group';
import { Student } from '../../type/entities/student-type';
import { validateCifNif, validateSS } from '../../utils/validatorFunctions';
import FormFooter from '../_layout/_footers/form-footer';

interface StudentFormProps {
    isLoading: boolean;
    submit: Function;
    data?: Student | undefined;
}

export const gender = [
    { value: 'male', label: 'Masculino' },
    { value: 'female', label: 'Femenino' },
];

const schema = yup.object({
    name: yup.string().min(1, 'Demasiado corto').max(200, 'Demasiado largo').required('Campo obligatorio'),
    firstName: yup.string().min(1, 'Demasiado corto').max(200, 'Demasiado largo').required('Campo obligatorio'),
    lastName: yup.string().min(1, 'Demasiado corto').max(200, 'Demasiado largo').nullable(),
    nif: yup.string().test('valid-cif-nif', 'El CIF/NIF no es válido', (value) => {
        if (value !== undefined && value.length > 0) {
            return validateCifNif(value);
        } else {
            return true;
        }
    }),
    gender: yup.string().nullable(),
    clientId: yup.string().required('Campo obligatorio'),
    birthDate: yup.string().nullable(),
    genderViolenceVictim: yup.boolean().nullable(),
    terrorismVictim: yup.boolean().nullable(),
    disability: yup.boolean().nullable(),
    socialSecurityNumber: yup.string().test('valid-ss', 'El número de la SS no es válido', (value) => {
        if (value !== undefined && value.length > 0) {
            return validateSS(value);
        } else {
            return true;
        }
    }).nullable(),
    studyId: yup.string().nullable(),
    professionalCategoryId: yup.string().nullable(),
    functionalArea: yup.string().nullable(),
    quotationGroupId: yup.string().nullable(),
    email: yup.string().email('Email no válido').required('Campo obligatorio'),
    telephone: yup.string().required('El teléfono es obligatorio').test("valid-phone", "El teléfono no es válido", (value) => isValidPhoneNumber(value)),
    telephoneSecondary: yup.string().nullable().test("valid-phone", "El teléfono no es válido", (value) => value === undefined || isValidPhoneNumber(value ?? '')),
    alternativeShippingAddress: yup.string().nullable(),
    contactSchedule: yup.string().nullable(),
    remark: yup.string().nullable(),
});

const StudentForm: React.FC<StudentFormProps> = ({ isLoading, submit, data }) => {

    const { getClientsList } = useClients({ active: true });
    const { getProfessionalCategoriesList } = useProfessionalCategories({ active: true });
    const { getQuotationGroupsList } = useQuotationGroups({ active: true });
    const { getEducationList } = useEducation({ active: true });
    const mode = data ? 'Editar' : 'Crear';
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            studentId: data?.id ?? '',
            name: data?.name ?? '',
            firstName: data?.firstName ?? '',
            lastName: data?.lastName ?? '',
            nif: data?.nif ?? '',
            gender: data?.gender ?? '',
            birthDate: data?.birthDate?.date.split(' ')[0] ?? '',
            genderViolenceVictim: data?.genderViolenceVictim ?? false,
            terrorismVictim: data?.terrorismVictim ?? false,
            disability: data?.disability ?? false,
            socialSecurityNumber: data?.socialSecurityNumber ?? '',
            studyId: data?.study?.id ?? '',
            professionalCategoryId: data?.professionalCategory?.id ?? '',
            functionalArea: data?.functionalArea ?? '',
            quotationGroupId: data?.quotationGroup?.id ?? '',
            email: data?.email ?? '',
            telephone: data?.telephone ?? '',
            telephoneSecondary: data?.telephoneSecondary ?? '',
            alternativeShippingAddress: data?.alternativeShippingAddress ?? '',
            contactSchedule: data?.contactSchedule ?? '',
            remark: data?.remark ?? '',
            clientId: data?.client?.id ?? '',
        },
        validationSchema: schema,
        validateOnBlur: false,
        // validateOnChange: false,
        onSubmit: values => { submit(values) },
    });

    const getContent = () => {
        return (
            <>
                <div className="@container">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="mb-10 grid gap-7 divide-y-2 divide-dashed divide-gray-200 @2xl:gap-9 @3xl:gap-11 font-medium">
                            <FormGroup
                                title="Información General"
                                description="Datos principales del empleado"
                                className='pt-6'
                                titleCols="@md:col-span-2"
                                childCols="@md:col-span-10 md:grid-cols-12"
                            >
                                <RequiredInput
                                    id='name'
                                    label="Nombre"
                                    className="md:col-span-3"
                                    formik={formik}
                                />

                                <RequiredInput
                                    id='firstName'
                                    label="Primer Apellido"
                                    className="md:col-span-3"
                                    formik={formik}
                                />

                                <RequiredInput
                                    id='lastName'
                                    label="Segundo Apellido"
                                    className="md:col-span-3"
                                    formik={formik}
                                    required={false}
                                />

                                <RequiredInput
                                    id='nif'
                                    label="NIF"
                                    className="md:col-span-3"
                                    formik={formik}
                                    required={false}
                                />

                                <RequiredInput
                                    id='socialSecurityNumber'
                                    label="Número de Seguridad Social"
                                    className="md:col-span-4"
                                    formik={formik}
                                    required={false}
                                />

                                <RequiredInput
                                    prefix={<PiEnvelopeSimple className="h-6 w-6 text-gray-500" />}
                                    id='email'
                                    type="email"
                                    label="Correo Electrónico"
                                    className="md:col-span-4"
                                    formik={formik}
                                />

                                <PhoneInput
                                    id='telephone'
                                    label='Teléfono'
                                    className="md:col-span-4"
                                    onBlur={formik.handleBlur}
                                    onChange={e => { formik.setFieldValue('telephone', e) }}
                                    value={formik.values.telephone}
                                    error={formik.errors.telephone}
                                />

                                <PhoneInput
                                    id='telephoneSecondary'
                                    label='Teléfono Secundario'
                                    className="md:col-span-4"
                                    onBlur={formik.handleBlur}
                                    onChange={e => { formik.setFieldValue('telephoneSecondary', e) }}
                                    value={formik.values.telephoneSecondary}
                                    error={formik.errors.telephoneSecondary}
                                    required={false}
                                />

                                <CustomSelect
                                    isSearchable
                                    isClearable
                                    id={'gender'}
                                    label="Género"
                                    containerClassName="md:col-span-4"
                                    value={{ value: formik.values.gender, label: gender?.find((g: any) => g.value === formik.values.gender)?.label }}
                                    options={gender}
                                    onChange={(e: any) => { formik.setFieldValue('gender', e?.value); }}
                                    error={formik.errors.gender}
                                />

                                <RequiredInput
                                    id='birthDate'
                                    type="date"
                                    label="Fecha de Nacimiento"
                                    className="md:col-span-4"
                                    placeholder="yyyy-mm-dd"
                                    formik={formik}
                                    required={false}
                                />

                            </FormGroup>

                            <FormGroup
                                title="Datos adicionales"
                                description="Información adicional del empleado"
                                className='pt-6'
                                titleCols="@md:col-span-2"
                                childCols="@lg:col-span-10 md:grid-cols-12"
                            >
                                <CustomSelect
                                    isSearchable
                                    isClearable
                                    required
                                    id={'clientId'}
                                    label="Cliente"
                                    containerClassName=' md:col-span-4 '
                                    value={{ value: formik.values.clientId, label: getClientsList()?.find((g: any) => g.value === formik.values.clientId)?.label }}
                                    options={getClientsList()}
                                    onChange={(e: any) => { formik.setFieldValue('clientId', e?.value); }}
                                    error={formik.errors.clientId}
                                />

                                <CustomSelect
                                    isSearchable
                                    isClearable
                                    id={'studyId'}
                                    label="Educación"
                                    containerClassName='md:col-span-4'
                                    value={{ value: formik.values.studyId, label: getEducationList()?.find((g: any) => g.value === formik.values.studyId)?.label }}
                                    options={getEducationList()}
                                    onChange={(e: any) => { formik.setFieldValue('studyId', e?.value); }}
                                    error={formik.errors.studyId}
                                />

                                <CustomSelect
                                    isSearchable
                                    isClearable
                                    id={'professionalCategoryId'}
                                    label="Categoria Profesional"
                                    containerClassName='md:col-span-4'
                                    value={{ value: formik.values.professionalCategoryId, label: getProfessionalCategoriesList()?.find((g: any) => g.value === formik.values.professionalCategoryId)?.label }}
                                    options={getProfessionalCategoriesList()}
                                    onChange={(e: any) => { formik.setFieldValue('professionalCategoryId', e?.value); }}
                                    error={formik.errors.professionalCategoryId}
                                />

                                <CustomSelect
                                    isSearchable
                                    isClearable
                                    id={'quotationGroupId'}
                                    label="Grupo de Cotización"
                                    containerClassName='md:col-span-4'
                                    value={{ value: formik.values.quotationGroupId, label: getQuotationGroupsList()?.find((g: any) => g.value === formik.values.quotationGroupId)?.label }}
                                    options={getQuotationGroupsList()}
                                    onChange={(e: any) => { formik.setFieldValue('quotationGroupId', e?.value); }}
                                    error={formik.errors.quotationGroupId}
                                />

                                <RequiredInput
                                    id='functionalArea'
                                    label="Área Funcional"
                                    className="md:col-span-4"
                                    formik={formik}
                                    required={false}
                                />

                                <RequiredInput
                                    id='alternativeShippingAddress'
                                    label="Dirección de envío alternativa"
                                    className="md:col-span-4"
                                    formik={formik}
                                    required={false}
                                />

                                <RequiredInput
                                    id='contactSchedule'
                                    label="Horario de contacto"
                                    className="md:col-span-4"
                                    formik={formik}
                                    required={false}
                                />

                                <div className='md:col-span-8'>
                                    <div className='grid md:grid-cols-3 md:mt-6'>
                                        <Switch
                                            id='disability'
                                            label="¿Tiene alguna discapacidad?"
                                            switchKnobClassName='bg-secondary'
                                            className="[&>label>span]:font-medium"
                                            onChange={formik.handleChange}
                                            checked={formik.values.disability}
                                        />

                                        <Switch
                                            id='genderViolenceVictim'
                                            label="Victima de violencia de género"
                                            switchKnobClassName='bg-secondary'
                                            className="[&>label>span]:font-medium"
                                            onChange={formik.handleChange}
                                            checked={formik.values.genderViolenceVictim}
                                        />

                                        <Switch
                                            id='terrorismVictim'
                                            label="Victima de terrorismo"
                                            switchKnobClassName='bg-secondary'
                                            className="[&>label>span]:font-medium"
                                            onChange={formik.handleChange}
                                            checked={formik.values.terrorismVictim}
                                        />
                                    </div>
                                </div>

                                <Textarea
                                    id='remark'
                                    label="Observaciones"
                                    className="[&>label>span]:font-medium md:col-span-12"
                                    onChange={formik.handleChange}
                                    value={formik.values.remark}
                                    error={formik.errors.remark}
                                    required={false}
                                />
                            </FormGroup>
                        </div>
                    </form>
                </div>
            </>
        );
    };

    return (
        <>
            <Page container="fluid">
                {(mode === "Editar" && data) && getContent()}
                {mode === "Crear" && getContent()}
            </Page>
            <FormFooter
                submitBtnText={mode + ' Empleado'}
                handleCancelBtn={() => navigate(-1)}
                handleSubmitBtn={formik.submitForm} isLoading={isLoading}
            />
        </>
    );
};

export default StudentForm;