import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import { useFormik } from 'formik';
import { FC, useEffect, useRef, useState } from 'react';
import { FaArrowLeft, FaArrowRight, FaEye, FaSearchMinus, FaSearchPlus, FaTimes } from 'react-icons/fa';
import { Document, Page, pdfjs } from 'react-pdf';
import { useNavigate, useParams } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import { Button, Text } from 'rizzui';
import { toast } from 'sonner';
import fullLogo from '../../../assets/logo_completo.png';
import Spinner from '../../../components/bootstrap/Spinner';
import { ContractService } from "../../../services/contract/contractService";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`; // Configura el worker para la última versión estable disponible

const SignContractMobile: FC = () => {

    // HOOKS

    const navigate = useNavigate();
    const { hash } = useParams<{ hash: string }>();

    // STATES

    const [signatureHash, setSignatureHash] = useState<string | undefined>();
    const [contractLoaded, setContractLoaded] = useState(false);
    const [showSignature, setShowSignature] = useState(false);
    const signatureRef = useRef<SignatureCanvas>(null);
    const [numPages, setNumPages] = useState<number | null>(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [pdfBlob, setPdfBlob] = useState<any | null>(null);
    const [contractData, setContractData] = useState<any | null>(null);
    const [showModal, setShowModal] = useState(false);
    const [zoomLevel, setZoomLevel] = useState(1.0);
    const [isLoading, setIsLoading] = useState(false);
    const [safeTop, setSafeTop] = useState(0);
    const [signingContract, setSigningContract] = useState(false);

    // FUNCTIONS

    const handleObtainContractInfo = async (hash: string | undefined) => {
        try {
            const response = await (await (new ContractService()).findContractByHash(hash, null)).getResponseData();
            if (response?.data) {
                setContractData(response.data);
            }
        } catch (error) {
            console.error('Error al obtener la información del contrato:', error);
            toast.error('Hubo un problema al obtener la información del contrato.');
        }
    };

    const handlePreviewContract = async (signatureHash: string | undefined) => {
        try {
            const response = await (new ContractService()).visualizeContractPdf(signatureHash, undefined);
            if (response?.data) {
                let base64String;
                const fileData = response.data;
                // Verificar si el contenido del blob es diferente antes de actualizar el estado
                if (pdfBlob && pdfBlob.size === fileData.byteLength) {
                    return; // Si el blob es el mismo, no actualizar
                }

                const blob = new Blob([fileData]);

                let reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                    base64String = reader.result;
                    setPdfBlob(base64String);
                };
            }
        } catch (error) {
            console.error(error);
        } finally {
            setContractLoaded(true);
        }
    };

    function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
        setNumPages(numPages);
    };

    const handleSignContract = async (signatureHash: string, contract: any) => {
        setIsLoading(true);
        setSigningContract(true);

        if (signatureRef.current?.isEmpty()) {
            toast.error('Por favor, realice su firma');
            setIsLoading(false);
            return;
        }

        const signatureImage = signatureRef.current?.toDataURL(); // Obtiene la firma en formato base64

        try {
            const response = await (await (new ContractService()).signContractPDF(signatureHash, signatureImage)).getResponseData();
            if (!response.success) {
                toast.error(response.message);
                return;
            }
            toast.success('Contrato firmado correctamente');
            navigate('/signed-contract/' + contract?.id);
        } catch (error) {
            toast.error('Error al firmar el contrato');
        } finally {
            setIsLoading(false);
        }
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            signature: null
        },
        //validationSchema: TutorResetPasswordSchema,
        validateOnChange: false,
        onSubmit: () => {
            if (signatureHash && signatureHash.length > 4) {
                handleSignContract(signatureHash, contractData);
            } else {
                toast.error('Hash inválido o nulo');
            }
        },
    });

    // USE EFFECTS

    useEffect(() => {
        const updateSafeArea = () => {
            setSafeTop(window.visualViewport?.offsetTop || 0);
        };
        window.visualViewport?.addEventListener("resize", updateSafeArea);
        updateSafeArea();
        return () => { window.visualViewport?.removeEventListener("resize", updateSafeArea) };
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            if (hash && !contractData) {
                setSignatureHash(hash);
                await handleObtainContractInfo(hash);
                await handlePreviewContract(hash);
            }
        };
        fetchData();
    }, [hash, contractData]);

    // RENDER

    return (
        <div className="relative min-h-screen bg-white overflow-hidden">
            {/* Botón de ojo en la parte superior derecha */}
            <div className="fixed top-4 right-4 z-50 flex items-center space-x-2">
                <button
                    onClick={() => setShowModal(true)}
                    className="p-2 bg-gray-800 text-white rounded-full hover:bg-gray-700"
                    title="Ver contrato"
                >
                    <FaEye size={20} />
                </button>
                <span className="text-gray-700 text-sm font-medium bg-gray-100 px-3 py-1 rounded-lg shadow-md">
                    Previsualiza tu contrato aquí
                </span>
            </div>

            <div className="w-full h-[100dvh] grid grid-cols-1 xl:grid-cols-3 overflow-hidden">
                {/* Formulario de firma */}
                <div className="mx-auto w-full max-w-md px-4 py-9 flex flex-col justify-center">
                    <div className="text-center mb-5">
                        <img src={fullLogo} className="h-10 mx-auto" alt="Logo de Attiva" />
                        <Text className="text-2xl font-bold mt-4">Firma de contrato</Text>
                    </div>

                    {!showSignature && (
                        <Button type="button" onClick={() => setShowSignature(true)} className="w-full" size="lg">
                            Firmar contrato
                        </Button>
                    )}

                    {showSignature && !signingContract && (
                        <form onSubmit={formik.handleSubmit} className="space-y-5 mt-5">
                            <div className="border border-gray-300 rounded-md p-2">
                                <SignatureCanvas
                                    ref={signatureRef}
                                    canvasProps={{
                                        width: 375,
                                        height: 150,
                                        className: 'signature-canvas',
                                    }}
                                />
                                <div className="mt-2 flex justify-between">
                                    <Button
                                        type="button"
                                        onClick={() =>
                                            signatureRef.current?.clear() &&
                                            formik.setFieldValue('signature', null)
                                        }
                                        variant="outline">
                                        Limpiar
                                    </Button>
                                    <Button
                                        type="submit"
                                        onClick={() => {
                                            if (signatureRef.current?.isEmpty()) {
                                                toast.error('Por favor, realice su firma');
                                                return;
                                            }
                                            const signatureImage = signatureRef.current?.toDataURL();
                                            formik.setFieldValue('signature', signatureImage);

                                        }}
                                        isLoading={isLoading}
                                        disabled={isLoading}
                                    >
                                        Firmar
                                    </Button>
                                </div>
                            </div>
                        </form>
                    )}

                    {signingContract && (
                        <div className="flex flex-col justify-center items-center text-center">
                            <DotLottieReact
                                src="https://lottie.host/e7d7459a-de1c-4374-9631-3e03d9d81b11/S0RljqPaCz.lottie"
                                loop={false}
                                autoplay
                                speed={0.6}
                                className="mb-4"
                            />
                            <p className="text-gray-600">Firmando tu contrato...</p>
                        </div>
                    )}
                </div>
            </div>

            {/* Modal para previsualizar el contrato */}
            {showModal && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white w-screen h-screen overflow-hidden relative">
                        <button
                            onClick={() => setShowModal(false)}
                            className="absolute top-2 right-2 p-2 text-gray-600 hover:text-gray-900 z-10"
                            style={{ top: `${safeTop + 16}px` }}
                        >
                            <FaTimes size={20} />
                        </button>
                        <div className="p-4 h-[100dvh] flex flex-col overflow-hidden">
                            <h2 className="text-xl font-semibold mb-4 text-center">Previsualización del contrato</h2>
                            <div className="flex-grow overflow-auto"> {/* Overflow horizontal y vertical */}
                                <div className="inline-block"> {/* Esto asegura que el PDF no se corte */}
                                    {pdfBlob && contractLoaded ? (
                                        <Document
                                            file={pdfBlob}
                                            onLoadSuccess={onDocumentLoadSuccess}
                                            loading={<Spinner />}
                                        >
                                            <Page
                                                pageNumber={pageNumber}
                                                renderMode="canvas"
                                                renderTextLayer={false}
                                                renderAnnotationLayer={false}
                                                scale={zoomLevel}
                                            />
                                        </Document>
                                    ) : (
                                        <div className="flex justify-center items-center h-full">
                                            <Spinner />
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* Controles de zoom y navegación de páginas */}
                            <div className="mt-4 flex justify-between items-center">
                                <Button
                                    onClick={() => setPageNumber((prevPage) => Math.max(prevPage - 1, 1))}
                                    disabled={pageNumber <= 1}
                                >
                                    <FaArrowLeft />
                                </Button>

                                <div className="flex items-center space-x-2">
                                    <Button onClick={() => setZoomLevel((prev) => Math.max(prev - 0.1, 0.5))}>
                                        <FaSearchMinus />
                                    </Button>
                                    <span className="text-gray-600">Zoom: {(zoomLevel * 100).toFixed(0)}%</span>
                                    <Button onClick={() => setZoomLevel((prev) => Math.min(prev + 0.1, 2.0))}>
                                        <FaSearchPlus />
                                    </Button>
                                </div>

                                <Button
                                    onClick={() => setPageNumber((prevPage) => Math.min(prevPage + 1, numPages || 1))}
                                    disabled={pageNumber >= (numPages || 1)}
                                >
                                    <FaArrowRight />
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SignContractMobile;