import React from 'react';
import ReactSelect from 'react-select';
import { cn } from 'rizzui';
import useProvinces from '../../hooks/api-calls/useProvinces';

interface SelectProvinceProps {
    label?: string;
    id: string;
    formik: any;
    required?: boolean;
    className?: string;
}

const SelectProvince: React.FC<SelectProvinceProps> = ({ label = 'Provincia', id, formik, required, className }) => {

    const { getProvincesList } = useProvinces();
    const formikValue = formik.values[id];
    const error = formik.errors[id];

    return (
        <div className={cn('flex flex-col', className)}>
            <label className="rizzui-input-label block text-sm font-medium">{label} {required && <span className="text-red-500">*</span>}
                <ReactSelect
                    isClearable
                    options={getProvincesList()}
                    onChange={(e: any) => formik.setFieldValue(id, e ? e.value : '')}
                    value={formikValue
                        ? { value: formikValue, label: getProvincesList().find((c: any) => c.value === formikValue)?.label }
                        : null
                    }
                    placeholder="Elegir ..."
                    styles={{
                        control: (base: any, state: any) => ({
                            ...base,
                            border: error ? '1.4px solid rgb(244 63 94) !important' : '1px rgb(229 231 235) !important',
                            borderRadius: '0.375rem',
                            borderColor: 'rgb(229 231 235) !important',
                            boxShadow: state.isFocused ? '0 0 0 2px rgb(0, 172, 216)' : '0 0 0 1px rgba(0,0,0, 0.2) !important',
                        }),
                        option: (provided: any, state: any) => ({
                            ...provided,
                            backgroundColor: state.isFocused ? 'rgb(0, 172, 216)' : 'white',
                            color: state.isFocused ? 'white' : 'black',
                            '&:hover': {
                                backgroundColor: 'rgb(0, 172, 216)',
                                color: 'white',
                                borderColor: '#000000 !important'
                            }
                        }),
                    }}
                    className='mt-2'
                />
            </label>

            {error && <p className="text-red text-[13px] mt-0.5 rizzui-input-error-text">{error}</p>}
        </div>
    );
};

export default SelectProvince;