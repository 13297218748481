import { useFormik } from 'formik';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Password, Text } from 'rizzui';
import { toast } from 'sonner';
import * as yup from 'yup';
import fullLogo from '../../../assets/logo_completo.png';
import Spinner from '../../../components/bootstrap/Spinner';
import { RootState } from '../../../redux/store';
import { menuRoutes } from '../../../router/menu';
import { LoginService } from '../../../services/auth/loginService';
import cn from '../../../utils/classNames';

const TutorResetPasswordSchema = yup.object({
    password: yup.string().required('La contraseña es obligatoria').min(8, 'La contraseña debe tener al menos 8 caracteres').max(30, 'Lacontraseña no puede excederse de 30 caracteres').matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
        'La contraseña debe contener al menos una letra mayúscula, una minúscula y un número'
    ),
    password_confirmation: yup.string().required('La confirmación de contraseña obligatoria').oneOf([yup.ref('password'), ''], 'Contraseñas no coinciden'),
});

const TutorResetPassword: FC = () => {

    const navigate = useNavigate();
    const userToken = new URLSearchParams(useLocation().search).get('token');
    const { isAuthenticated } = useSelector((state: RootState) => state.tutor);

    const [isLoading, setIsLoading] = useState(false);

    if (isAuthenticated) {
        setTimeout(() => {
            navigate(menuRoutes.tutor_panel.tutorials, { replace: true })
        }, 100);
    }

    const handleResetPassword = async (userToken: string, password: string, password_confirmation: string) => {
        setIsLoading(true);
        const response = await (await (new LoginService()).resetTutorForgotPassword(userToken, password, password_confirmation)).getResponseData();

        if (!response.success) {
            toast.error(response.message);
            setIsLoading(false);
            return;
        }

        if (response.success) {
            try {
                navigate(menuRoutes.tutor_panel.login, { replace: true });
                toast.success('Contraseña actualizada con éxito');
            } catch (error) {
                toast.error('Error al actualizar la contraseña');
                return;
            } finally {
                setIsLoading(false);
            }
        }
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            password: '',
            password_confirmation: ''
        },
        validationSchema: TutorResetPasswordSchema,
        validateOnChange: false,
        onSubmit: (values) => {
            if (userToken && userToken.length > 10) {
                handleResetPassword(userToken, values.password, values.password_confirmation);
            } else {
                toast.error('Token inválido o nulo.');
            }
        },
    });

    return (
        <div className="relative min-h-screen">
            <div className='w-full h-[100vh] grid grid-cols-3'>
                <div className='col-span-2 bg-gray-100 hidden lg:block'>
                    <div className='flex flex-col justify-center items-center h-full'>
                        <img src={fullLogo} height={'30vh'} alt="Logo de Attiva" />
                        <Text className="text-2xl font-bold text-gray-800 mt-5">Bienvenido Tutor</Text>
                    </div>
                </div>

                <div className={cn('mx-auto w-full max-w-md rounded-xl px-4 py-9 flex flex-col justify-center',)}>
                    <div className='flex flex-col justify-center text-center mb-5'>
                        <Text className="text-2xl font-bold text-gray-800 mt-5 mb-3">Actualiza tu contraseña</Text>
                        <Text className="text-sm text-gray-500">Por favor, introduce tu nueva contraseña.</Text>
                    </div>

                    <form onSubmit={formik.handleSubmit}>
                        <div className="space-y-5">
                            <Password
                                id='password'
                                label="Contraseña"
                                placeholder="Introduce tu contraseña"
                                size="lg"
                                className="[&>label>span]:font-medium"
                                inputClassName="text-sm"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.errors.password}
                            />

                            <Password
                                id='password_confirmation'
                                label="Confirmar Contraseña"
                                placeholder="Confirma tu contraseña"
                                size="lg"
                                className="[&>label>span]:font-medium"
                                inputClassName="text-sm"
                                value={formik.values.password_confirmation}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.errors.password_confirmation}
                            />

                            <Button className="w-full" type="submit" size="lg" disabled={isLoading}>
                                {isLoading ? <Spinner /> : 'Resetear contraseña'}
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default TutorResetPassword;