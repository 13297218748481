import { useEffect } from "react";
import CustomSelect from "../../../components/forms/CustomSelect";
import RequiredInput from "../../../components/forms/InputHOC";
import { usePrivilege } from "../../../components/priviledge/PriviledgeProvider";
import useContractCampaign from "../../../hooks/api-calls/contract/useContractCampaign";
import useContractCommercial from "../../../hooks/api-calls/contract/useContractCommercial";
import useCorporations from "../../../hooks/api-calls/useCorporations";
import useUserNetworks from "../../../hooks/api-calls/useUserNetworks";
import FormGroup from "../../../layout/shared/form-group";
import cn from "../../../utils/classNames";

interface ContractDataFormProps {
    formik: any;
}
const ContractDataForm = ({ formik }: ContractDataFormProps) => {

    const { userCan } = usePrivilege();
    const { getContractCampaignList } = useContractCampaign({ active: true });
    const { getContractCommercialList } = useContractCommercial({ active: true });
    const { getCorporationsList } = useCorporations({ active: true });
    const { getUserNetworksList } = useUserNetworks({ active: true });

    /**
     * Set legalRepresentativeExist to true if legalRepresentativeName or legalRepresentativeNif is not empty
     */
    useEffect(() => {
        if (formik.values.legalRepresentativeName || formik.values.legalRepresentativeNif) {
            formik.setFieldValue('legalRepresentativeExist', true);
        } else {
            formik.setFieldValue('legalRepresentativeExist', false);
        }
    }, [formik.values.legalRepresentativeName, formik.values.legalRepresentativeNif]);

    return (
        <div className="rounded-md border-solid border-2 col-span-7 p-6 divide-y-2 divide-dashed divide-gray-200 gap-7">
            <div className='flex flex-col md:flex-row md:justify-between items-center'>
                <h3 className='font-bold text-lg pb-2'>Datos Generales del Contrato</h3>
            </div>

            <FormGroup
                title="Información General"
                description="Datos asociados al contrato."
                className={cn("py-4 @3xl:grid-cols-12 ")}
                titleCols="@md:col-span-2"
                childCols="@md:col-span-10 md:grid-cols-4"
            >
                <div className="grid grid-cols-12 col-span-8 gap-4">
                    <CustomSelect
                        isSearchable
                        isClearable
                        id={'campaignId'}
                        label="Campaña"
                        containerClassName="col-span-12 md:col-span-4"
                        value={{
                            value: formik.values.campaignId,
                            label: getContractCampaignList()?.find((g: any) => g.value === formik.values.campaignId)?.label
                        }}
                        defaultValue={''}
                        options={getContractCampaignList()}
                        onChange={(e: any) => { formik.setFieldValue('campaignId', e?.value); }}
                        error={formik.errors.campaignId}
                        required={false}
                    />

                    <CustomSelect
                        isSearchable
                        isClearable
                        id={'commercialId'}
                        label="Comercial"
                        containerClassName="col-span-12 md:col-span-4"
                        value={{
                            value: formik.values.commercialId,
                            label: getContractCommercialList()?.find((g: any) => g.value === formik.values.commercialId)?.label
                        }}
                        defaultValue={''}
                        options={getContractCommercialList()}
                        onChange={(e: any) => { formik.setFieldValue('commercialId', e?.value); }}
                        error={formik.errors.commercialId}
                        className={cn(!userCan('admin_all_commercial_network_contracts', 'contracts')) && 'hidden'}
                    />

                    <CustomSelect
                        isSearchable
                        isClearable
                        required={formik.values.saveAsContract && formik.values.productTypeId === '26a41eb6-a9d1-4d2f-88e5-1bb59bdb9ae1'}
                        id={'organisingCorporationId'}
                        label="Empresa Organizadora"
                        containerClassName="col-span-12 md:col-span-4"
                        value={{
                            value: formik.values.organisingCorporationId,
                            label: getCorporationsList()?.find((g: any) => g.value === formik.values.organisingCorporationId)?.label
                        }}
                        defaultValue={''}
                        options={getCorporationsList()}
                        onChange={(e: any) => { formik.setFieldValue('organisingCorporationId', e?.value); }}
                        error={formik.errors.organisingCorporationId}
                    />

                    <CustomSelect
                        isSearchable
                        isClearable
                        required={formik.values.saveAsContract && formik.values.productTypeId === '26a41eb6-a9d1-4d2f-88e5-1bb59bdb9ae1'}
                        id={'providingCorporationId'}
                        label="Empresa Impartidora"
                        containerClassName="col-span-12 md:col-span-4"
                        value={{
                            value: formik.values.providingCorporationId,
                            label: getCorporationsList()?.find((g: any) => g.value === formik.values.providingCorporationId)?.label
                        }}
                        defaultValue={''}
                        options={getCorporationsList()}
                        onChange={(e: any) => { formik.setFieldValue('providingCorporationId', e?.value); }}
                        error={formik.errors.providingCorporationId}
                    />

                    {(userCan('admin_all_commercial_network_contracts', 'commercial_network') || getUserNetworksList()?.length >= 2 || formik.values.commercialNetworkId <= 4) && (
                        <CustomSelect
                            required={true}
                            isSearchable
                            isClearable
                            id={'commercialNetworkId'}
                            label="Red Comercial"
                            containerClassName="col-span-12 md:col-span-4"
                            value={{
                                value: formik.values.commercialNetworkId,
                                label: getUserNetworksList()?.find((g: any) => g.value === formik.values.commercialNetworkId)?.label
                            }}
                            defaultValue={{
                                value: formik.values.commercialNetworkId,
                                label: getUserNetworksList()?.find((g: any) => g.value === formik.values.commercialNetworkId)?.label
                            }}
                            options={getUserNetworksList()}
                            onChange={(e: any) => { formik.setFieldValue('commercialNetworkId', e?.value); }}
                            error={formik.errors.commercialNetworkId}
                            className={cn(!userCan('admin_all_commercial_network_contracts', 'contracts')) && 'hidden'}
                        />
                    )}

                    <RequiredInput
                        id='groupings'
                        required={formik.values.saveAsContract && formik.values.productTypeId === '26a41eb6-a9d1-4d2f-88e5-1bb59bdb9ae1'}
                        type="text"
                        label="Agrupaciones"
                        className="col-span-12 md:col-span-4"
                        formik={formik}
                    />

                    {userCan('modify_contract_end_date', 'contracts') && (
                        <RequiredInput
                            id='draftExpirationDate'
                            required={false}
                            type="date"
                            label="Fecha de Expiración del Borrador"
                            className="col-span-12 md:col-span-4"
                            formik={formik}
                        />
                    )}

                    <RequiredInput
                        id='contractStartDate'
                        required={formik.values.saveAsContract}
                        type="date"
                        label="Fecha de Inicio del Contrato"
                        className="col-span-12 md:col-span-4"
                        formik={formik}
                    />

                    <RequiredInput
                        id='contractEndDate'
                        required={false}
                        type="date"
                        label="Fecha de Fin del Contrato"
                        className="col-span-12 md:col-span-4"
                        formik={formik}
                    />
                </div>
            </FormGroup>

            <FormGroup
                title="Representante Legal"
                description="Datos del representante legal."
                className={cn("py-4 @3xl:grid-cols-12 ")}
                titleCols="@md:col-span-2"
                childCols="@md:col-span-10 md:grid-cols-4"
            >
                <div className="grid grid-cols-12 col-span-8 gap-4">
                    <RequiredInput
                        id='legalRepresentativeName'
                        type="text"
                        label="Nombre y Apellidos"
                        className="col-span-12 md:col-span-4"
                        required={formik.values.saveAsContract && formik.values.legalRepresentativeExist}
                        formik={formik}
                    />

                    <RequiredInput
                        id='legalRepresentativeNif'
                        type="text"
                        label="CIF/NIF"
                        className="col-span-12 md:col-span-4"
                        required={formik.values.saveAsContract && formik.values.legalRepresentativeExist}
                        formik={formik}
                    />

                    <RequiredInput
                        id='legalRepresentativeEmail'
                        type="email"
                        label="Correo electrónico"
                        className="col-span-12 md:col-span-4"
                        required={formik.values.saveAsContract && formik.values.legalRepresentativeExist}
                        formik={formik}
                    />

                    <RequiredInput
                        id='legalRepresentativePosition'
                        type="text"
                        label="Cargo"
                        className="col-span-12 md:col-span-6"
                        required={formik.values.saveAsContract && formik.values.legalRepresentativeExist}
                        formik={formik}
                    />

                    <RequiredInput
                        id='legalRepresentativeSchedule'
                        type="text"
                        label="Horario de contacto"
                        className="col-span-12 md:col-span-6"
                        required={formik.values.saveAsContract && formik.values.legalRepresentativeExist}
                        formik={formik}
                    />
                </div>
            </FormGroup>
        </div>
    );
}

export default ContractDataForm;