import { useCallback, useEffect, useState } from 'react';
import { StudentService } from '../../services/student/StudentService';

/*----------------------------------------------------------------------------------------
* EN: Hook to manage students fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de empleados.
----------------------------------------------------------------------------------------*/

const useStudents = (initialFilters = {}) => {

    const [data, setData] = useState<any[]>([]);
    const [filters, setFilters] = useState(initialFilters);

    const fetchStudents = useCallback(async () => {
        try {
            const service = new StudentService();
            const response = await service.listStudents({ limit: 999999 });
            const fetchedData = response.getResponseData().data;

            if (fetchedData && fetchedData?.data) {
                const mappedData = fetchedData.data?.map((data: { id: string; name: string; firstName: string; lastName?: string; nif?: string; }) => ({
                    value: data.id,
                    label: data.name + ' ' + data.firstName + ' ' + (data.lastName ? data.lastName : '') + (data.nif ? ' (' + data.nif + ')' : ''),
                }));
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching students:', error);
        }
    }, []);

    useEffect(() => {
        fetchStudents();
    }, []);

    const updateStudentsHookFilters = (newFilters: any) => {
        setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
    };

    const getStudentsList = () => {
        return data;
    };

    return { fetchStudents, getStudentsList, updateStudentsHookFilters };
}

export default useStudents;