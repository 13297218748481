import { FC, useEffect, useState } from 'react';
import { IoCloudDownloadOutline } from "react-icons/io5";
import { pdfjs } from 'react-pdf';
import { useNavigate } from "react-router";
import { Link, useParams } from 'react-router-dom';
import { Button, Text } from 'rizzui';
import { toast } from 'sonner';
import fullLogo from '../../../assets/logo_completo.png';
import { ContractService } from "../../../services/contract/contractService";
import cn from "../../../utils/classNames";
import successLogo from './../../../assets/images/success-icon.png';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`; // Configura el worker para la última versión estable disponible

const SignedContract: FC = () => {

    // HOOKS

    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();

    // STATES

    const [contractId, setContractId] = useState<string | undefined>();
    const [isVerified, setIsVerified] = useState<boolean>(false);

    // FUNCTIONS

    const handleVerifyContractExistence = async (contractId: string | any) => {
        try {
            const response = await (await (new ContractService()).findContractByHash(null, contractId)).getResponseData();

            if (response?.success && response?.data.isSigned) {
                setIsVerified(true);
            } else {
                toast.error('No se ha encontrado el contrato');
                // Redirigir a la página de inicio de búsqueda de contratos
            }
        } catch (error) {
            console.error('Error al verificar la existencia del contrato:', error);
            toast.error('Hubo un problema al verificar la existencia del contrato.');
            navigate('');
        }
    };

    const handleDownloadContract = async (contractId: string | any) => {
        try {
            const response = await (new ContractService()).visualizeContractPdf(undefined, contractId);

            const url = window.URL.createObjectURL(new Blob([response?.data], { type: 'application/pdf' }));
            const link = document.createElement('a');

            link.href = url;
            link.setAttribute('download', `contrato_attiva.pdf`); // Nombre del archivo
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Error al descargar el contrato:', error);
            toast.error('Hubo un problema al descargar el contrato.');
        }
    };

    // USE EFFECTS

    useEffect(() => {
        if (id) {
            handleVerifyContractExistence(id);
            setContractId(id);
        }
    }, [id]);

    // RENDER

    if (!isVerified) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <img
                    src="/media/loading-name-lg.svg"
                    alt="Cargando contrato"
                    className="w-25 h-25"
                />
            </div>
        );
    }

    return (
        <div className="relative min-h-screen">
            <div className=' w-full flex-col justify-center p-4 md:p-12 lg:p-28'>
                <div className={cn('mx-auto w-full max-w-md rounded-xl px-4 py-9 flex flex-col justify-center',)}>
                    <div className='flex flex-col justify-center text-center mb-5'>
                        <Link to='/find-contract' className={'flex justify-center items-center mb-[6vh] '}>
                            <img src={fullLogo} height={'30vh'} alt="Logo de Attiva" />
                        </Link>

                        <img src={successLogo} className="w-24 mx-auto " alt="Contrato firmado" />

                        <Text className="text-xl font-bold text-gray-800 mt-10 ">Su contrato se ha firmado correctamente</Text>
                        <Text className="text-gray-600">Si lo desea puede descargarlo pulsando en el siguiente botón</Text>

                        <div className="flex flex-col justify-center text-center mb-10 mt-10">
                            <Button
                                type="button"
                                onClick={() => handleDownloadContract(contractId)}
                                className="w-auto"
                                size="lg"
                            >
                                <IoCloudDownloadOutline className="mr-2" /> Descargar contrato
                            </Button>

                            <Text className="text-gray-600 mt-10">O vuelve a la <Link to="/find-contract" className="font-bold">página de inicio</Link> de busqueda de contratos</Text>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignedContract;