import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Text } from 'rizzui';
import { CustomPieGraph } from '../../../../../../components/charts/CustomPieChart';
import { Loader } from '../../../../../../components/loader/SpinnerLogo';
import useFetch from '../../../../../../hooks/useFetch';
import { menuRoutes } from '../../../../../../router/menu';
import { OrderService } from '../../../../../../services/order/orderService';

interface ContractStatusesChartsProps { }

const ContractStatusesCharts: React.FC<ContractStatusesChartsProps> = () => {

    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();

    // Estado único para todos los gráficos
    const [contractData, setContractData] = useState<{ contractStatuses: any; contractProductStatuses: any; contractInvoicingStatuses: any; contractCommissionStatuses: any }>({
        contractStatuses: { series: [], labels: [], colors: [], ids: [] },
        contractProductStatuses: { series: [], labels: [], colors: [], ids: [] },
        contractInvoicingStatuses: { series: [], labels: [], colors: [], ids: [] },
        contractCommissionStatuses: { series: [], labels: [], colors: [], ids: [] },
    });
    const [statusId, setStatusId] = useState<string | null>(null);

    const [data, loading] = useFetch(useCallback(async () => {
        if (!id) return null;
        const response = (await (new OrderService).getOrderContractsByContractStatuses(id as string)).getResponseData();
        return response;
    }, [id]));

    const handleClick = (id: string) => {
        setStatusId(id);
    };

    // Efecto para manejar los datos cuando llegan
    useEffect(() => {
        if (data) {
            setContractData({
                contractStatuses: {
                    series: data.contract?.map((item: any) => item.contractsNumber),
                    labels: data.contract?.map((item: any) => item.name),
                    colors: data.contract?.map((item: any) => item.color),
                    ids: data.contract?.map((item: any) => item.id),
                },
                contractProductStatuses: {
                    series: data.contract_product?.map((item: any) => item.contractsNumber),
                    labels: data.contract_product?.map((item: any) => item.name),
                    colors: data.contract_product?.map((item: any) => item.color),
                    ids: data.contract_product?.map((item: any) => item.id),
                },
                contractInvoicingStatuses: {
                    series: data.contract_invoicing?.map((item: any) => item.contractsNumber),
                    labels: data.contract_invoicing?.map((item: any) => item.name),
                    colors: data.contract_invoicing?.map((item: any) => item.color),
                    ids: data.contract_invoicing?.map((item: any) => item.id),
                },
                contractCommissionStatuses: {
                    series: data.contract_commission?.map((item: any) => item.contractsNumber),
                    labels: data.contract_commission?.map((item: any) => item.name),
                    colors: data.contract_commission?.map((item: any) => item.color),
                    ids: data.contract_commission?.map((item: any) => item.id),
                },
            });
        }
    }, [data]);

    useEffect(() => {
        if (statusId) {
            // Obtener la entity buscando la id en los ids de los datos
            const entity = contractData.contractStatuses.ids.includes(statusId)
                ? 'contract'
                : contractData.contractProductStatuses.ids.includes(statusId)
                    ? 'contract_product'
                    : contractData.contractInvoicingStatuses.ids.includes(statusId)
                        ? 'contract_invoicing'
                        : contractData.contractCommissionStatuses.ids.includes(statusId)
                            ? 'contract_commission'
                            : null;
            navigate(menuRoutes.contracts.path, { state: { contractStatusId: statusId, contractStatusEntity: entity } });
        }
    }, [statusId]);

    // Si los datos están cargando, mostrar el loader
    if (loading) return <Loader height="75vh" />;

    // Componente reutilizable para cada sección de gráfico
    const renderChartSection = (
        title: string,
        chartData: { series: number[]; labels: string[]; colors: string[]; ids: string[] }
    ) => {
        return (
            <div>
                <Text className="text-center font-bold mb-5">{title}</Text>
                {chartData.series.length > 0 && chartData.series.some((item) => item !== 0)
                    ? <CustomPieGraph data={chartData} onClick={handleClick} />
                    : <Text className="text-center text-muted500 pb-5">No hay contratos en estado satisfactorio</Text>
                }
            </div>
        );
    };

    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-10">
            {renderChartSection('Contratos por estado de contrato', contractData.contractStatuses)}
            {renderChartSection('Contratos por estado de producto', contractData.contractProductStatuses)}
            {renderChartSection('Contratos por estado de facturación', contractData.contractInvoicingStatuses)}
            {renderChartSection('Contratos por estado de comisión', contractData.contractCommissionStatuses)}
        </div>
    );
};

export default ContractStatusesCharts;