import { FC, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";
import AttivaCard from "../../../components/card/AttivaCard";
import { Loader } from "../../../components/loader/SpinnerLogo";
import { usePrivilege } from "../../../components/priviledge/PriviledgeProvider";
import useFetch from "../../../hooks/useFetch";
import PageHeader from "../../../layout/shared/page-header";
import { menuRoutes } from "../../../router/menu";
import { RoleService } from "../../../services/role/roleService";
import { Role, RolePermission } from "../../../type/entities/role-type";
import RoleForm from "../RoleForm";

const pageHeader = {
    title: "Editar Rol",
    breadcrumb: [
        {
            href: menuRoutes.roles.path,
            name: "Roles",
        },
        {
            name: "Editar",
        },
    ],
};

const RoleEdit: FC = () => {

    const { id = "" } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const { userCan } = usePrivilege();
    const service = new RoleService();

    const [editPermissions, setEditPermissions] = useState<boolean>(false);
    const [rolePermissions, setRolePermissions] = useState<RolePermission[] | null>(null);

    const [data, loading, error] = useFetch(useCallback(async () => {
        const response = await service.getRoleById(id as string);
        return response.getResponseData() as Role;
    }, [id]));

    const handleSubmit = async (values: any, permissions: number[]) => {
        values.permissions = permissions;

        try {
            const response = await service.editRole(values);
            const responseData = response.getResponseData();

            if (responseData.success) {
                navigate(-1);
                toast.success("Rol editado correctamente");
            } else {
                responseData.data.errors.forEach((error: any) => {
                    toast.error(error.message);
                });
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    if (loading) return <Loader />;

    return (
        <>
            <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb}>
            </PageHeader>

            <AttivaCard title="Datos Principales" className="mb-4">
                {(data !== null && data !== undefined) && <RoleForm data={data} submit={handleSubmit} isLoading={loading} />}
            </AttivaCard>
        </>
    );
};

export default RoleEdit;