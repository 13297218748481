import { useEffect } from 'react';
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from 'react-router-dom';
import AppLayout from '../pages/_layout/AppLayout';
import ForgotPassword from '../pages/auth/ForgotPassword';
import Login from '../pages/auth/Login';
import Page404 from '../pages/auth/Page404';
import ResetPassword from '../pages/auth/ResetPassword';
import ForgotPasswordTutor from '../pages/auth/tutor/TutorForgotPassword';
import TutorLogin from '../pages/auth/tutor/TutorLogin';
import TutorResetPassword from '../pages/auth/tutor/TutorResetPassword';
import { RootState } from "../redux/store";
import { UserService } from '../services/user/userService';
import { menuRoutes } from './menu';
import ProtectedRoute from './ProtectedRoute';
import contents from './routes';
import SignContract from "../pages/contracts/sign/SignContract";
import FindContract from "../pages/contracts/sign/FindContract";
import ErrorContract from "../pages/contracts/sign/ErrorContract";
import SignedContract from "../pages/contracts/sign/SignedContract";

const AppRouter = () => {

  const user = useSelector((state: RootState) => state.auth);
  const tutor = useSelector((state: RootState) => state.tutor);

  const renderRoute = (page: any, index: number, access: boolean = true) => {
    page.element = <ProtectedRoute component={page.element} access={access && page.access} />;
    return (
      <Route key={index} {...page}>
        {page.sub?.map((subPage: any, index: number) => renderRoute(subPage, index))}
      </Route>
    );
  };

  return (
    <Routes>
      <Route element={<AppLayout />}>
        {contents.map((page, index) => renderRoute(page, index))}
      </Route>

      <Route path={menuRoutes.auth.login} element={<Login />} />
      <Route path={menuRoutes.auth.forgot_password} element={<ForgotPassword />} />
      <Route path={menuRoutes.auth.reset_password} element={<ResetPassword />} />
      <Route path={menuRoutes.tutor_panel.login} element={<TutorLogin />} />
      <Route path={menuRoutes.tutor_panel.forgot_password} element={<ForgotPasswordTutor />} />
      <Route path={menuRoutes.tutor_panel.reset_password} element={<TutorResetPassword />} />
      <Route path={menuRoutes.contract_public.sign} element={<SignContract />} />
      <Route path={menuRoutes.contract_public.find} element={<FindContract />} />
      <Route path={menuRoutes.contract_public.error} element={<ErrorContract />} />
      <Route path={menuRoutes.contract_public.signed} element={<SignedContract />} />
      {user.isAuthenticated && <Route path="/" element={<Navigate to={menuRoutes.dashboard.path} />} />}
      {tutor.isAuthenticated && <Route path="/" element={<Navigate to={menuRoutes.tutor_panel.profile} />} />}
      {(!user.isAuthenticated && !tutor.isAuthenticated) && <Route path="*" element={<Navigate to={menuRoutes.auth.login} />} />}
      {(user.isAuthenticated || tutor.isAuthenticated) && <Route path="*" element={<Page404 />} />}
    </Routes>
  );
};

export default AppRouter;