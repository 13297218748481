import { useEffect, useState } from 'react';
import { OrderService } from "../../../services/order/orderService";

/*----------------------------------------------------------------------------------------
* EN: Hook to manage order statuses fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de estados de pedidos.
----------------------------------------------------------------------------------------*/

const useOrderStatuses = () => {

    const [data, setData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const response = await (new OrderService()).getOrderStatutes();
            const fetchedData = response.getResponseData();

            if (fetchedData && fetchedData?.data) {
                const mappedData = fetchedData.data?.map((data: any) => ({
                    value: data.id,
                    label: data.name,
                    color: data.color,
                    initialStatus: data.initialStatus,
                    draftStatus: data.draftStatus,
                }));
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching order statuses:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setIsLoading(true);
        fetchData();
    }, []);

    const getList = () => {
        return data;
    };

    const getEntity = (id: string) => {
        return data.find((entity: any) => entity.id === id);
    };

    return {
        fetchOrderStatusesList: fetchData,
        getOrderStatusesList: getList,
        getOrderStatus: getEntity,
        isLoadingOrderStatuses: isLoading
    };
}

export default useOrderStatuses;