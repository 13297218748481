import { useEffect, useState } from "react";
import { IoHomeOutline, IoSearch } from "react-icons/io5";
import { MdMailOutline } from "react-icons/md";
import { isValidPhoneNumber } from "react-phone-number-input";
import { Text } from 'rizzui';
import { toast } from "sonner";
import { CNAEModalSelector } from "../../../components/forms/CNAEModalSelector";
import RequiredInput from "../../../components/forms/InputHOC";
import PhoneInput from "../../../components/forms/PhoneInputWrapper";
import SelectProvince from "../../../components/forms/SelectProvince";
import FormGroup from "../../../layout/shared/form-group";
import { ContractService } from "../../../services/contract/contractService";
import cn from "../../../utils/classNames";
import { useContractProvider } from "../providers/ContractProvider";
import SelectorModal from "./SelectorModal";

interface ClientContractFormProps {
    formik: any;
}

const ClientContractForm = ({ formik }: ClientContractFormProps) => {

    const { setOriginalClientData } = useContractProvider();

    const [openSelectorModal, setOpenSelectorModal] = useState(false);
    const [CNAESelectorVisible, setCNAESelectorVisible] = useState<boolean>(false);
    const [clientData, setClientData] = useState<any[]>([]);

    /**
     * Fetch the client data from the API with the CIF/NIF or the telephone number provided and open the modal
     */
    const fetchClientData = async (finder: string) => {
        try {
            const service = new ContractService();
            const response = await service.findClientsOnContract({ filter_filters: { finder: finder, active: true } });
            if (response.getResponseData()?.success) {
                setClientData(response.getResponseData()?.data?.data);
                response.getResponseData()?.data?.data?.length > 0 && setOpenSelectorModal(true);
            }
        } catch (error) {
            toast.error('Error al buscar el cliente en la base de datos');
        }
    };

    /**
     * Fill the form with the client data selected from the modal
     */
    const fillClientData = (client: any) => {
        let clientData: any = {
            clientId: client?.id,
            clientCifNif: client?.cifNif,
            clientCompanyName: client?.companyName,
            clientCnae: client?.cnae,
            clientActivity: client?.activityDescription,
            clientIban: client?.iban,
            clientEmail: client?.email,
            clientCompanyCreationDate: client.companyCreationDate?.date?.toString().split(' ')[0],
            clientSocialSecurityNumber: client?.socialSecurityNumber,
            clientWorkersNumber: client?.workersNumber,
            clientTelephone: client?.telephone,
            clientTelephoneSecondary: client?.telephoneSecondary,
            clientProvince: client?.registeredOfficeProvince,
            clientCity: client?.registeredOfficeTown,
            clientAddress: client?.registeredOfficeAddress,
            clientPostalCode: client?.registeredOfficePostalCode,
        };

        // Save the original client data to compare it later
        setOriginalClientData(clientData);

        // Fill the form with the client data
        Object.keys(clientData).forEach(key => {
            formik.setFieldValue(key, clientData[key]);
        });

        // Validate the form after filling the data and close the modal
        formik.validateForm();
        setOpenSelectorModal(false);
    };

    /**
     * Fetch the client data when the CIF/NIF or the telephone number is filled and valid
     */
    useEffect(() => {
        const clientCifNif = formik.values.clientCifNif;
        const clientTelephone = formik.values.clientTelephone;

        if (clientCifNif?.length > 0 && clientCifNif.length < 8) return; // CIF/NIF is not valid
        if (clientTelephone?.length > 0 && !isValidPhoneNumber(clientTelephone)) return; // Telephone is not valid

        // If CIF/NIF or Telephone is filled and the other is empty
        if (
            (formik.dirty && clientCifNif?.length > 0 && (!clientTelephone || clientTelephone === undefined || clientTelephone?.length === 0))
            || (formik.dirty && clientTelephone?.length > 0 && (!clientCifNif || clientCifNif?.length === 0 || clientCifNif === undefined))
        ) {
            setTimeout(() => {
                fetchClientData(clientCifNif || clientTelephone);
            }, 800);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.clientCifNif, formik.values.clientTelephone]);

    /**
     * Validate form when the cnae and the activity are filled
     */
    useEffect(() => {
        if (formik.values.clientCnae && formik.values.clientActivity) {
            formik.validateForm();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.clientCnae, formik.values.clientActivity]);

    return (
        <>
            <div className="rounded-md border-solid border-2 col-span-7 p-6 divide-y-2 divide-dashed divide-gray-200 gap-7">
                <div className='flex flex-col md:flex-row md:justify-between items-center'>
                    <h3 className='font-bold text-lg pb-2'>Datos del Cliente</h3>
                </div>

                <FormGroup
                    title="Información General"
                    description="Cliente asociado al contrato."
                    className={cn("py-4 @3xl:grid-cols-12 ")}
                    titleCols="@md:col-span-2"
                    childCols="@md:col-span-10 md:grid-cols-4"
                >
                    <div className="grid grid-cols-12 col-span-8 gap-4">
                        <RequiredInput
                            id='clientCifNif'
                            label="CIF/NIF"
                            className="col-span-12 md:col-span-3"
                            formik={formik}
                            inputClassName="upperCase"
                            onChange={(e) => formik.setFieldValue('clientCifNif', e.target?.value?.toUpperCase())}
                            value={formik.values?.clientCifNif?.toUpperCase()}
                        />

                        <PhoneInput
                            required={formik.values.saveAsContract}
                            id='clientTelephone'
                            label='Teléfono'
                            className="[&>label>span]:font-medium col-span-12 md:col-span-6 lg:col-span-4"
                            onBlur={formik.handleBlur}
                            onChange={e => { formik.setFieldValue('clientTelephone', e) }}
                            value={formik.values.clientTelephone}
                            error={formik.errors.clientTelephone}
                        />

                        <RequiredInput
                            required={formik.values.saveAsContract}
                            id='clientCompanyName'
                            label="Razón Social"
                            className="col-span-12 md:col-span-5"
                            formik={formik}
                        />

                        <RequiredInput
                            required={formik.values.saveAsContract}
                            id='clientIban'
                            label="IBAN"
                            className="col-span-12 md:col-span-4"
                            formik={formik}
                        />

                        <RequiredInput
                            required={formik.values.saveAsContract}
                            id='clientSocialSecurityNumber'
                            type="text"
                            label="Nº Seguridad Social"
                            className="col-span-12 md:col-span-3"
                            formik={formik}
                        />

                        <RequiredInput
                            required={formik.values.saveAsContract}
                            id='clientEmail'
                            label="Email"
                            className="col-span-12 md:col-span-5"
                            prefix={<MdMailOutline />}
                            formik={formik}
                        />

                        <RequiredInput
                            required={formik.values.saveAsContract}
                            id='clientCnae'
                            readOnly
                            label="CNAE"
                            className="col-span-12 md:col-span-2"
                            suffix={<IoSearch className="cursor-pointer" size={'20px'} onClick={() => { setCNAESelectorVisible(true) }} />}
                            formik={formik}
                        />

                        <RequiredInput
                            required={formik.values.saveAsContract}
                            id='clientActivity'
                            readOnly
                            label="Descripción de la Actividad"
                            className="col-span-12 md:col-span-10"
                            formik={formik}
                        />

                        <RequiredInput
                            required={formik.values.saveAsContract}
                            id='clientCompanyCreationDate'
                            type="date"
                            label="Fecha de creación de la empresa"
                            className="col-span-12 md:col-span-4"
                            formik={formik}
                        />

                        <RequiredInput
                            required={formik.values.saveAsContract}
                            id='clientWorkersNumber'
                            type="number"
                            label="Nº trabajadores"
                            className="col-span-12 md:col-span-2"
                            formik={formik}
                            onChange={(e) => formik.setFieldValue('clientWorkersNumber', e.target.value ? parseInt(e.target.value) : null)}
                        />

                        <PhoneInput
                            required={false}
                            id='clientTelephoneSecondary'
                            label='Teléfono adicional 1'
                            className="[&>label>span]:font-medium col-span-12 md:col-span-6 lg:col-span-3"
                            onBlur={formik.handleBlur}
                            onChange={e => { formik.setFieldValue('clientTelephoneSecondary', e) }}
                            value={formik.values.clientTelephoneSecondary}
                            error={formik.errors.clientTelephoneSecondary}
                        />

                        <PhoneInput
                            required={false}
                            id='clientTelephoneThird'
                            label='Teléfono adicional 2'
                            className="[&>label>span]:font-medium col-span-12 md:col-span-6 lg:col-span-3"
                            onBlur={formik.handleBlur}
                            onChange={e => { formik.setFieldValue('clientTelephoneThird', e) }}
                            value={formik.values.clientTelephoneThird}
                            error={formik.errors.clientTelephoneThird}
                        />
                    </div>
                </FormGroup>

                <FormGroup
                    title="Domilicio Social"
                    description="Dirección del cliente asociada al contrato."
                    className={cn("py-4 @3xl:grid-cols-12 ")}
                    titleCols="@md:col-span-2"
                    childCols="@md:col-span-10 md:grid-cols-4"
                >
                    <div className="grid grid-cols-12 col-span-8 gap-4">
                        <RequiredInput
                            required={formik.values.saveAsContract}
                            id='clientAddress'
                            label="Dirección"
                            className="col-span-12 md:col-span-5"
                            prefix={<IoHomeOutline />}
                            formik={formik}
                        />

                        <RequiredInput
                            required={formik.values.saveAsContract}
                            id='clientCity'
                            label="Población"
                            className="col-span-12 md:col-span-3"
                            formik={formik}
                        />

                        <SelectProvince
                            required={formik.values.saveAsContract}
                            id='clientProvince'
                            className="col-span-12 md:col-span-3"
                            formik={formik}
                        />

                        <RequiredInput
                            required={formik.values.saveAsContract}
                            id='clientPostalCode'
                            label="C.P."
                            className="col-span-12 md:col-span-1"
                            formik={formik}
                        />
                    </div>
                </FormGroup>
            </div>

            <CNAEModalSelector
                visible={CNAESelectorVisible}
                setVisible={setCNAESelectorVisible}
                onSelect={(cnae: string, description: string) => {
                    formik.setFieldValue('clientCnae', cnae);
                    formik.setFieldValue('clientActivity', description);
                    setCNAESelectorVisible(false);
                }}
            />

            <SelectorModal
                isOpen={openSelectorModal}
                onSubmit={fillClientData}
                onClose={() => { setOpenSelectorModal(false) }}
                data={clientData}
                title="Obtener los datos del cliente"
                renderItem={(client) => {
                    const formattedPhone = client.telephone ? client.telephone.replace(/(\d{2})(\d{3})(\d{3})(\d{3})/, '($1) $2 $3 $4') : '';
                    return (
                        <>
                            <Text>
                                <span className="font-bold">{client.companyName}</span> {formattedPhone}{' - '}{client.email}
                            </Text>
                            <Text className="text-muted500 italic">
                                {client.registeredOfficeAddress}, {client.registeredOfficePostalCode},{' '}
                                {client.registeredOfficeTown}, {client.registeredOfficeProvince}
                            </Text>
                        </>
                    )
                }}
                selectedLabel="Cliente seleccionado"
                buttonText="Obtener datos"
            />
        </>
    );
}

export default ClientContractForm;