import { Fragment, useContext } from "react";
import { useNavigate } from "react-router";
import { Button } from "rizzui";
import { PrivilegeContext } from "../../../../components/priviledge/PriviledgeProvider";
import { FiltersProvider } from "../../../../components/providers/FiltersProvider";
import PageHeader from "../../../../layout/shared/page-header";
import { menuRoutes } from "../../../../router/menu";
import cn from "../../../../utils/classNames";
import OrderTypesList from "./OrderTypesList";

const OrderTypesListWrapper = () => {

    const { userCan } = useContext(PrivilegeContext);
    const navigate = useNavigate();

    const breadCrumb = [
        { name: 'Administración' },
        { name: 'Control de Errores' },
        { name: 'Tipos de Pedidos', href: menuRoutes.orderTypes.path },
    ];

    return (
        <Fragment>
            <FiltersProvider>
                <PageHeader title="Listado de tipos de pedidos" breadcrumb={breadCrumb}>
                    {
                        userCan('create_order_types', 'order_types') &&
                        (
                            <Button
                                color="primary"
                                onClick={() => { navigate(menuRoutes.orderTypes.create) }}
                                className={cn(['mt-4 md:mt-0'])}
                            >
                                Crear Tipo de pedido
                            </Button>
                        )
                    }

                </PageHeader>
                <OrderTypesList />
            </FiltersProvider>
        </Fragment>
    );
}

export default OrderTypesListWrapper;