import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import { FC } from 'react';
import { RiPhoneFindLine } from "react-icons/ri";
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'rizzui';
import fullLogo from '../../../assets/logo_completo.png';
import cn from "../../../utils/classNames";

const ErrorContract: FC = () => {

    // HOOKS

    const navigate = useNavigate();

    // FUNCTIONS

    const handleGoToFinder = async () => {
        navigate('/find-contract');
    };

    // RENDER

    return (
        <div className="relative min-h-screen">
            <div className=' w-full flex-col justify-center p-4 md:p-12 lg:p-28'>
                <div className={cn('mx-auto w-full max-w-md rounded-xl px-4 py-9 flex flex-col justify-center',)}>
                    <div className='flex flex-col justify-center text-center mb-5'>
                        <Link to='/find-contract' className={'flex justify-center items-center mb-5'}>
                            <img src={fullLogo} height={'30vh'} alt="Logo de Attiva" />
                        </Link>
                        <div className="flex flex-col justify-center items-center text-center">
                            <DotLottieReact
                                src="https://lottie.host/1930723c-677e-4db3-8f79-3dff14df06bf/DsEyqd5aWJ.lottie"
                                loop={false}
                                autoplay
                                speed={0.6}
                                className="mb-4"
                            />
                            <p className="text-gray-600">El contrato introducido ya ha sido firmado o no existe</p>
                        </div>
                    </div>

                    <Button className="w-full" type="submit" size="lg" onClick={handleGoToFinder}>
                        <RiPhoneFindLine className="mr-2" /> Buscar otro contrato
                    </Button>

                </div>
            </div>
        </div>
    );
};

export default ErrorContract;