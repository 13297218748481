import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import AttivaCard from '../../../components/card/AttivaCard';
import PageHeader from '../../../layout/shared/page-header';
import { menuRoutes } from '../../../router/menu';
import { StateService } from '../../../services/state/stateService';
import StateForm from '../StateForm';

const pageHeader = {
    title: "Crear Estado",
    breadcrumb: [
        {
            href: menuRoutes.states.path,
            name: "Estados",
        },
        {
            name: "Crear",
        },
    ],
};

const StateCreate = () => {

    const navigate = useNavigate();
    const service = new StateService();

    const [loading, setLoading] = useState<boolean>(false);

    const handleSubmit = async (values: any) => {
        setLoading(true);

        try {
            const response = await service.createState(values);
            const responseData = response.getResponseData();

            if (responseData.success) {
                navigate(-1);
                toast.success("Estado creado correctamente");
            } else {
                responseData.data.errors.forEach((error: any) => {
                    toast.error(error.message);
                });
            }
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb} />

            <AttivaCard title="Datos Principales" className="mb-4">
                <StateForm submit={handleSubmit} isLoading={loading} />
            </AttivaCard>
        </>
    );
};

export default StateCreate;