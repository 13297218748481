import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useWindowScroll } from "react-use";
import logo from "../../../assets/attiva_logo.png";
import fullLogo from "../../../assets/logo_completo.png";
import { useIsMounted } from "../../../hooks/use-is-mounted";
import { RootState } from "../../../redux/store";
import { tutorMenuItems } from "../../../router/menu";
import cn from "../../../utils/classNames";
import Sidebar from "../_sidebar/sidebar";
import HamburgerButton from "../settings/hamburger-button";
import ProfileTutorMenu from "../settings/profile-tutor-menu";
import HeaderMenuLeft from "./header-menu-left";


type StickyHeaderProps = {
    className?: string;
    offset?: number;
};

function StickyHeader({
    offset = 2,
    className,
    children,
}: React.PropsWithChildren<StickyHeaderProps>) {
    const isMounted = useIsMounted();
    const windowScroll = useWindowScroll();
    return (
        <header
            className={cn(
                "sticky top-0 z-[9999] flex items-center bg-gray-0/80 p-4 backdrop-blur-xl dark:bg-gray-50/50 md:px-5 lg:px-6",
                ((isMounted && windowScroll.y) as number) > offset ? "card-shadow" : "",
                className
            )}
        >
            {children}
        </header>
    );
}

function HeaderMenuRight() {
    return (
        <div className="ms-auto flex shrink-0 items-center gap-2 text-gray-700 xs:gap-3 xl:gap-4">
            <ProfileTutorMenu
                buttonClassName="w-auto sm:w-auto p-1"
                avatarClassName="!w-7 !h-7 sm:!h-8 sm:!w-8"
            />
        </div>
    );
}

export default function TutorHeader() {

    const [lastTutorId, setLastTutorId] = useState("");
    const tutor = useSelector((state: RootState) => state.tutor.tutor);
    const location = useLocation();

    useEffect(() => {
        if (tutor && tutor?.id !== lastTutorId) {
            setLastTutorId(tutor.id);
        }
        return () => {
            setLastTutorId("");
        }
    }, [tutor]);

    return (
        <StickyHeader className={"z-[990] justify-between 2xl:py-5 2xl:pl-6  3xl:px-8"}>
            <div className="hidden items-center gap-3 xl:flex">
                <Link
                    aria-label="Site Logo"
                    to={"/"}
                    className="me-4 hidden w-[155px] shrink-0 text-gray-800 hover:text-gray-900 lg:me-5 xl:block"
                >
                    <img src={fullLogo} width={"130px"} alt="Logo de Attiva" />
                </Link>
                <HeaderMenuLeft pathname={location.pathname} key={lastTutorId} menuItems={tutorMenuItems} data={tutor} />
            </div>
            <div className="flex w-full items-center gap-5 xl:w-auto 3xl:gap-6">
                <div className="flex w-full max-w-2xl items-center xl:w-auto">
                    <HamburgerButton view={<Sidebar className="static w-full 2xl:w-full" tutor />} />
                    <Link
                        aria-label="Site Logo"
                        to={"/"}
                        className="me-4 w-9 shrink-0 text-gray-800 hover:text-gray-900 lg:me-5 xl:hidden"
                    >
                        <img src={logo} width={"80px"} alt="Logo de Attiva" />
                    </Link>
                </div>
                <HeaderMenuRight />
            </div>
        </StickyHeader>
    );
}