import React from 'react';
import CustomerGrowthSummary from './components/CustomerGrowth';
import OrderCard from './components/OrderCard';
import SalesAnalytics from './components/SalesAnalytics';
import CRMStats from './components/CRMStats';

const Dashboard: React.FC = () => {
    return (
        <div className="@container/crm px-6">
            <div className="grid grid-cols-1 gap-6 @3xl/crm:grid-cols-12 @7xl/crm:gap-7">
                <CRMStats className="@3xl/crm:col-span-full" />
                <OrderCard className="@3xl/crm:col-span-full @7xl/crm:col-span-8 dark:bg-[#181818]" />
                <SalesAnalytics className="@3xl/crm:col-span-6 @7xl/crm:col-span-4 dark:bg-[#181818]" />
                <CustomerGrowthSummary className="@3xl/crm:col-span-full @7xl/crm:col-span-12 dark:bg-[#181818]" />
            </div>
        </div>
    );
};

export default Dashboard;