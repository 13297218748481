import { FC, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";
import AttivaCard from "../../../components/card/AttivaCard";
import { Loader } from "../../../components/loader/SpinnerLogo";
import useFetch from "../../../hooks/useFetch";
import useHandleErrors from "../../../hooks/useHandleErrors";
import PageHeader from "../../../layout/shared/page-header";
import { menuRoutes } from "../../../router/menu";
import { OrderService } from "../../../services/order/orderService";
import { OrdersApiResponse } from "../../../type/entities/order-type";
import OrderForm from "../OrderForm";


const OrderEdit: FC = () => {

    const { id = "" } = useParams<{ id: string }>();
    const { handleErrors } = useHandleErrors();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [data, loading] = useFetch(useCallback(async () => {
        const response = await (new OrderService()).getOrderById(id as string);
        return response.getResponseData() as OrdersApiResponse;
    }, [id]));

    const pageHeader = {
        title: `Editar Pedido ( ${data?.code} )`,
        breadcrumb: [
            { name: 'Ventas' },
            { name: 'Gestión de pedidos', href: menuRoutes.orders.path + menuRoutes.orders.list },
            { name: 'Editar Pedido' },
        ],
    };

    const handleSubmit = async (values: any) => {
        setIsLoading(true);
        try {
            const response = (await (new OrderService()).editOrder(values)).getResponseData();
            if (response.success) {
                navigate(menuRoutes.orders.path + menuRoutes.orders.list);
                toast.success("Pedido editado correctamente");
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error("Error al editar el pedido");
        } finally {
            setIsLoading(false);
        }
    };

    if (loading) return <Loader />;

    return (
        <>
            <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb} />

            <AttivaCard title="Datos Principales">
                {(data !== null && data !== undefined) && <OrderForm data={data} submit={handleSubmit} isLoading={isLoading} />}
            </AttivaCard>
        </>
    );
}

export default OrderEdit;