import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';
import { IncidenceFieldsModel } from '../../type/entities/incidence-type';
import { FilterOptions } from '../../hooks/useFilters';

const ENDPOINT = '/incidence';

export class IncidenceService extends RestServiceConnection {

    listIncidences = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/list',
            data: filters,
        }, true) as AxiosResponse;
        return this;
    }

    getIncidenceById = async (incidenceId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get',
            data: { incidenceId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    toggleIncidenceState = async (incidenceId: string, statusId: string, comment: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/change-status',
            data: {
                incidenceId,
                statusId,
                comment
            },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    createIncidence = async (incidence: IncidenceFieldsModel) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/create',
            data: incidence,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    editIncidence = async (incidence: IncidenceFieldsModel) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/edit',
            data: incidence,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    deleteIncidence = async (incidenceId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/delete',
            data: { incidenceId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    deleteMultiIncidences = async (incidenceIds: string[]) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/delete-multi',
            data: { incidenceIds }
        }, true);
        return this;
    }

    getIncidenceComment = async (incidenceCommentId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get-comment',
            data: { incidenceCommentId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    listIncidenceComments = async (filters: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/list-comments',
            data: filters,
        }, true) as AxiosResponse;
        return this;
    }

    createIncidenceComment = async (incidenceId: string, comment: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/add-comment',
            data: { incidenceId, comment },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    editIncidenceComment = async (incidenceId: string, incidenceCommentId: string, comment: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/edit-comment',
            data: { incidenceId, incidenceCommentId, comment },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    deleteIncidenceComment = async (incidenceCommentId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/delete-comment',
            data: { incidenceCommentId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    multiDeleteIncidenceComments = async (incidenceCommentIds: string[]) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/delete-multi-incidence-comments',
            data: { incidenceCommentIds },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    addIncidenceDocuments = async (incidenceId: string, documents: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/add-documents',
            data: { incidenceId, documents },
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        }, true);
        return this;
    }

    listIncidenceDocuments = async (filters: FilterOptions) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/list-documents',
            data: filters,
        }, true);
        return this;
    }

    getIncidenceDocuments = async (incidenceId: string, maxResults?: number | string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get-documents',
            data: { incidenceId, maxResults },
        }, true);
        return this;
    }

    downloadIncidenceDocument = async (documentId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/download-document',
            responseType: 'arraybuffer', // to avoid string conversion
            data: { documentId },
        }, true);
        return this;
    }

    deleteIncidenceDocument = async (documentId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/delete-document',
            data: { documentId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    getIncidenceStatutes = async (entity: 'contract_incidence' | 'incidence') => {
        this.response = await this.makeRequest({
            method: 'POST',
            data: {
                filter_filters: {
                    entity: entity
                }
            },
            url: ENDPOINT + '/get-statuses',
        }, true);
        return this;
    }

    getIncidenceStatusTracking = async (incidenceId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get-statuses-tracking',
            data: { incidenceId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    getIncidenceTypes = async () => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get-types',
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }


}