import { useEffect, useState } from 'react';
import { ContractService } from '../../../services/contract/contractService';
import { State } from '../../../type/entities/state-type';

/*----------------------------------------------------------------------------------------
* EN: Hook to manage Contract incidence states fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de estados de incidencias en contratos.
----------------------------------------------------------------------------------------*/

const useContractIncidenceStates = (initialFilters = {}) => {

    const [data, setData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [filters, setFilters] = useState<Record<string, any>>({
        limit: 999999, 
        filter_filters: { ...initialFilters } 
    });

    const fetchContractIncidenceStates = async () => {
        setIsLoading(true);
        try {
            const service = new ContractService();
            const response = await service.getContractIncidenceStatuses(filters);
            const fetchedData = response.getResponseData().data;

            if (fetchedData && fetchedData?.data) {
                const mappedData = fetchedData.data?.map((data: State) => ({
                    value: data.id,
                    label: data.name,
                    color: data.color,
                    statusOrder: data.entityOrder,
                    initialStatus: data.initialStatus,
                    draftStatus: data.draftStatus,
                }));
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching contract incidence states:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchContractIncidenceStates();
    }, [filters]);

    const getContractIncidenceStatesList = (entity?: string|null, notShowStatusID?: string|null,  showDraftStatus: (boolean|null) = true
    ) => {
        let filteredData = data;
        if (!showDraftStatus) filteredData = filteredData.filter((state: any) => !state.draftStatus);
        if (entity) return filteredData.filter((state: any) => state.entity === entity);
        if (notShowStatusID) return filteredData.filter((state: any) => state.value !== notShowStatusID);
        return filteredData;
    };

    const addContractIncidenceStatesFilter = (key: string, value: any) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            filter_filters: {
                ...prevFilters.filter_filters,
                [key]: value
            }
        }));
    };
    return { fetchContractIncidenceStates, getContractIncidenceStatesList, addContractIncidenceStatesFilter, isLoadingContractIncidenceStates: isLoading };
}

export default useContractIncidenceStates;