import classNames from "classnames";
import { Fragment, useContext } from "react";
import { useNavigate } from "react-router";
import { Button } from "rizzui";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import { FiltersProvider } from "../../../components/providers/FiltersProvider";
import PageHeader from "../../../layout/shared/page-header";
import { menuRoutes } from "../../../router/menu";
import OrganisationsList from "./OrganisationsList";

const OrganisationsListWrapper = () => {

    const { userCan } = useContext(PrivilegeContext);
    const navigate = useNavigate();

    const breadCrumb = [
        { name: 'Organizaciones', href: menuRoutes.organisations.path },
        { name: 'Listado', href: menuRoutes.organisations.path },
    ];

    return (
        <Fragment>
            <FiltersProvider>
                <PageHeader title="Listado de organizaciones" breadcrumb={breadCrumb}>
                    <Button
                        color="primary"
                        onClick={() => { navigate(menuRoutes.organisations.create) }}
                        className={classNames({ 'hidden': !userCan('create_company', 'companies') })}
                    >
                        Crear organización
                    </Button>
                </PageHeader>
                <OrganisationsList />
            </FiltersProvider>
        </Fragment>
    );
}

export default OrganisationsListWrapper;