import { Fragment, useState } from "react";
import { Button } from "rizzui";
import { usePrivilege } from "../../../components/priviledge/PriviledgeProvider";
import { FiltersProvider } from "../../../components/providers/FiltersProvider";
import PageHeader from "../../../layout/shared/page-header";
import { menuRoutes } from "../../../router/menu";
import CorporationsList from "./CorporationsList";

const breadCrumb = [
    { name: 'Administración' },
    { name: 'Empresas', href: menuRoutes.corporations.path },
];

const CorporationsListWrapper = () => {

    const { userCan } = usePrivilege();

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [nodeId, setNodeId] = useState<string | undefined>(undefined);
    const [openFilters, setOpenFilters] = useState<boolean>(false);

    return (
        <Fragment>
            <FiltersProvider>
                <PageHeader title="Listado de empresas" breadcrumb={breadCrumb}>
                    {userCan('create_corporation', 'corporation') && (
                        <Button
                            onClick={() => { setIsOpen(true); setNodeId(undefined) }}
                            color='primary'
                        >
                            Crear empresa
                        </Button>
                    )}
                </PageHeader>
                <CorporationsList isOpen={isOpen} setIsOpen={setIsOpen} nodeId={nodeId} setNodeId={setNodeId} openFilters={openFilters} setOpenFilters={setOpenFilters} />
            </FiltersProvider>
        </Fragment>
    );
}

export default CorporationsListWrapper;