import { Outlet } from "react-router-dom";
import { FiltersProvider } from "../../../components/providers/FiltersProvider";
import OrdersSidebar from "../components/OrdersSidebar";

const OrdersListWrapper = () => {
    return (
        <div className="flex flex-grow flex-col 3xl:pt-4 4xl:px-10 animate-slide-down">
            <div className="page-container flex flex-col lg:flex-row flex-nowrap h-[calc(100vh-110px)] w-full">
                <div className="sidebar w-full lg:w-[20vw] overflow-hidden">
                    <OrdersSidebar />
                </div>
                <div className="content w-full h-full lg:w-[80vw] p-4 pt-0 me-8">
                    <FiltersProvider>
                        <Outlet />
                    </FiltersProvider>
                </div>
            </div>
        </div>
    );
}

export default OrdersListWrapper;