import chroma from "chroma-js";
import React from "react";
import ReactApexChart from "react-apexcharts";

interface CustomPieGraphProps {
    data: {
        series: number[];
        labels: string[];
        colors: string[];
        ids?: string[];
    };
    height?: string;
    onClick?: (e: any) => void;
}

export const CustomPieGraph: React.FC<CustomPieGraphProps> = ({ data, height, onClick }) => {

    const filteredData = data.series.reduce<{ series: number[], labels: string[], colors: string[], ids: string[] }>(
        (acc, value, index) => {
            if (value > 0) {
                acc.series.push(value);
                acc.labels.push(data.labels[index]);
                acc.colors.push(data.colors[index]);
                acc.ids?.push(data.ids ? data.ids[index] : "");
            }
            return acc;
        },
        { series: [], labels: [], colors: [], ids: [] }
    );

    return (
        <div className="flex flex-col items-center">
            {/* Gráfico */}
            <div style={{ height: height || "275px", display: "flex", justifyContent: "center" }}>
                <ReactApexChart
                    series={filteredData.series}
                    type="pie"
                    width={"100%"}
                    height={"100%"}
                    options={{
                        labels: filteredData.labels,
                        legend: { show: false },
                        plotOptions: {
                            pie: {
                                donut: { labels: { show: false } },
                            },
                        },
                        fill: { opacity: 0.8 },
                        colors: filteredData.colors,
                        tooltip: {
                            enabled: true,
                            custom: ({ seriesIndex, w }) => {
                                const label = filteredData.labels[seriesIndex];
                                const value = filteredData.series[seriesIndex];
                                const backgroundColor = filteredData.colors[seriesIndex] || "#ffffff";
                                const textColor = chroma(backgroundColor).luminance() < 0.5 ? "#ffffff" : "#000000";

                                return `
                                    <div style="
                                        background: ${backgroundColor};
                                        color: ${textColor};
                                        padding: 6px 10px;
                                        border-radius: 4px;
                                        text-align: center;
                                        font-size: 12px;
                                        font-weight: bold;
                                    ">
                                        ${label}: ${value}
                                    </div>
                                `;
                            }
                        },
                        chart: {
                            events: {
                                dataPointSelection: (event, chartContext, config) => {
                                    const dataPointIndex = config.dataPointIndex;
                                    const id = filteredData.ids ? filteredData.ids[dataPointIndex] : "";
                                    if (onClick) onClick(id);
                                }
                            }
                        }
                    }}
                />
            </div>

            {/* Contenedor de leyenda con scroll si es necesario */}
            <div style={{
                maxHeight: "150px",
                overflowY: "auto",
                width: "100%",
                textAlign: "center",
            }}>
                {filteredData.labels.map((label, index) => (
                    <div key={index} className="flex items-center justify-center gap-2">
                        <span
                            style={{
                                display: "inline-block",
                                width: "12px",
                                height: "12px",
                                backgroundColor: filteredData.colors[index],
                                borderRadius: "50%",
                            }}
                        ></span>
                        <span className="text-sm">{label}: {filteredData.series[index]}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};