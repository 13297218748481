import moment from "moment";
import { IoHomeOutline, IoSearch } from "react-icons/io5";
import { MdMailOutline } from "react-icons/md";
import { Input } from "rizzui";
import PhoneInput from "../../../../../components/forms/PhoneInputWrapper";
import FormGroup from "../../../../../layout/shared/form-group";
import { Contract } from "../../../../../type/entities/contract-type";
import cn from "../../../../../utils/classNames";

interface ClientContractFormInfoProps {
    data: Contract;
}

const ClientContractFormInfo = ({ data }: ClientContractFormInfoProps) => {
    return (
        <>
            <div className="rounded-md border-solid border-2 col-span-7 p-6 divide-y-2 divide-dashed divide-gray-200 gap-7">
                <div className='flex flex-col md:flex-row md:justify-between items-center'>
                    <h3 className='font-bold text-lg pb-2'>Datos del Cliente</h3>
                </div>

                <FormGroup
                    title="Información General"
                    description="Cliente asociado al contrato."
                    className={cn("py-4 @3xl:grid-cols-12 ")}
                    titleCols="@md:col-span-2"
                    childCols="@md:col-span-10 md:grid-cols-4"
                >
                    <div className="grid grid-cols-12 col-span-8 gap-4">
                        <Input
                            disabled
                            id='clientCifNif'
                            label="CIF/NIF"
                            className="col-span-12 md:col-span-3"
                            value={data?.clientCifNif ?? ''}
                        />

                        <PhoneInput
                            disabled
                            id='clientTelephone'
                            label='Teléfono'
                            className="[&>label>span]:font-medium col-span-12 md:col-span-6 lg:col-span-4"
                            value={data?.clientTelephone}
                            required={false}
                        />

                        <Input
                            disabled
                            id='clientCompanyName'
                            label="Razón Social"
                            className="col-span-12 md:col-span-5"
                            value={data?.clientCompanyName}
                        />

                        <Input
                            disabled
                            id='clientIban'
                            label="IBAN"
                            className="col-span-12 md:col-span-4"
                            value={data?.clientIban ?? ''}
                        />

                        <Input
                            disabled
                            id='clientSocialSecurityNumber'
                            type="text"
                            label="Nº Seguridad Social"
                            className="col-span-12 md:col-span-3"
                            value={data?.clientSocialSecurityNumber ?? ''}
                        />

                        <Input
                            disabled
                            id='clientEmail'
                            label="Email"
                            className="col-span-12 md:col-span-5"
                            prefix={<MdMailOutline />}
                            value={data?.clientEmail}
                        />

                        <Input
                            disabled
                            id='clientCnae'
                            readOnly
                            label="CNAE"
                            className="col-span-12 md:col-span-2"
                            suffix={<IoSearch className="cursor-pointer" size={'20px'} onClick={() => { }} />}
                            value={data?.clientCnae ?? ''}
                        />

                        <Input
                            disabled
                            id='clientActivity'
                            readOnly
                            label="Descripción de la Actividad"
                            className="col-span-12 md:col-span-10"
                            value={data?.clientActivity}
                        />

                        <Input
                            disabled
                            id='clientCompanyCreationDate'
                            label="Fecha de creación de la empresa"
                            className="col-span-12 md:col-span-4"
                            value={moment(data?.clientCompanyCreationDate?.date.split(' ')[0]).format('DD/MM/YYYY')}
                        />

                        <Input
                            disabled
                            id='clientWorkersNumber'
                            type="number"
                            label="Nº trabajadores"
                            className="col-span-12 md:col-span-2"
                            value={data?.clientWorkersNumber}
                        />

                        <PhoneInput
                            disabled
                            id='clientTelephoneSecondary'
                            label='Teléfono adicional 1'
                            className="[&>label>span]:font-medium col-span-12 md:col-span-6 lg:col-span-3"
                            value={data?.clientTelephoneSecondary ?? ''}
                            required={false}
                        />

                        <PhoneInput
                            disabled
                            id='clientTelephoneThird'
                            label='Teléfono adicional 2'
                            className="[&>label>span]:font-medium col-span-12 md:col-span-6 lg:col-span-3"
                            value={data?.clientTelephoneThird ?? ''}
                            required={false}
                        />
                    </div>
                </FormGroup>

                <FormGroup
                    title="Domilicio Social"
                    description="Dirección del cliente asociada al contrato."
                    className={cn("py-4 @3xl:grid-cols-12")}
                    titleCols="@md:col-span-2"
                    childCols="@md:col-span-10 md:grid-cols-4"
                >
                    <div className="grid grid-cols-12 col-span-8 gap-4">
                        <Input
                            disabled
                            id='clientAddress'
                            label="Dirección"
                            className="col-span-12 md:col-span-5"
                            prefix={<IoHomeOutline />}
                            value={data?.clientAddress}
                        />

                        <Input
                            disabled
                            id='clientCity'
                            label="Población"
                            className="col-span-12 md:col-span-3"
                            value={data?.clientCity}
                        />

                        <Input
                            disabled
                            id='clientProvince'
                            label="Provincia"
                            className="col-span-12 md:col-span-3"
                            value={data?.clientProvince}
                        />

                        <Input
                            disabled
                            id='clientPostalCode'
                            label="C.P."
                            className="col-span-12 md:col-span-1"
                            value={data?.clientPostalCode}
                        />
                    </div>
                </FormGroup>
            </div>
        </>
    );
}

export default ClientContractFormInfo;