import { isEqual } from 'lodash';
import React from 'react';
import { PiTrashDuotone } from 'react-icons/pi';
import { Button, Input } from 'rizzui';
import CustomSelect from '../../../components/forms/CustomSelect';
import DateField from '../../../components/forms/DateField';
import useOrderStatuses from "../../../hooks/api-calls/orders/useOrderStatuses";
import useOrderTypes from "../../../hooks/api-calls/orders/useOrderTypes";
import { FilterOptions } from '../../../hooks/useFilters';

interface OrdersFiltersProps {
    updateFilters: (filters: any) => void
    resetFilters: (limit: any) => void
    filters: FilterOptions
}

const OrdersFilters: React.FC<OrdersFiltersProps> = ({ filters, updateFilters, resetFilters }) => {

    const { getOrderTypesList } = useOrderTypes();
    const { getOrderStatusesList } = useOrderStatuses();

    return (
        <>
            <Input
                id="code"
                label="Código"
                placeholder="Código ..."
                className="w-full"
                value={filters.filter_filters?.code || ""}
                onChange={(e) => {
                    const code = e.target.value;
                    if (code !== filters.filter_filters?.code) {
                        updateFilters({ code });
                    }
                }}
                onClear={() => {
                    if (filters.filter_filters?.code) {
                        updateFilters({ code: "" });
                    }
                }}
            />

            <CustomSelect
                id="order_type"
                label="Tipo"
                isMulti={true}
                options={getOrderTypesList()}
                value={filters.filter_filters?.order_type ?
                    getOrderTypesList().filter((type) => filters.filter_filters?.order_type.includes(type.value)) : []}
                onChange={(value: { value: string, label: string }[]) => {
                    const orderTypes = value.map((type) => type.value);
                    if (orderTypes !== filters.filter_filters?.order_type) {
                        updateFilters({ order_type: orderTypes });
                    }
                }}
            />

            <CustomSelect
                id="current_status"
                label="Estado actual"
                isMulti={true}
                options={getOrderStatusesList()}
                value={filters.filter_filters?.current_status ?
                    getOrderStatusesList().filter((status) => filters.filter_filters?.current_status.includes(status.value)) : []}
                onChange={(value: { value: string, label: string }[]) => {
                    const statuses = value.map((status) => status.value);
                    if (statuses !== filters.filter_filters?.current_status) {
                        updateFilters({ current_status: statuses });
                    }
                }}
            />

            <DateField
                isClearable
                className="w-full"
                label="Fecha de Pedido"
                placeholderText="Selecciona las fechas"
                selectedDateField={filters.filter_filters?.between_dates?.type || "startDate"}
                dateTypesOptions={[
                    { label: "Fecha de inicio", value: "startDate" },
                    { label: "Fecha de fin", value: "endDate" },
                    { label: "Día de cobro", value: "collectionDate" },
                    { label: "Fecha de pedido", value: "orderDate" },
                ]}
                startDate={filters.filter_filters?.between_dates?.startDate ? new Date(filters.filter_filters.between_dates.startDate) : null}
                endDate={filters.filter_filters?.between_dates?.endDate ? new Date(filters.filter_filters.between_dates.endDate) : null}
                onChange={(date: any) => {
                    const filters_between_dates = filters.filter_filters?.between_dates;
                    const between_dates = date ? { startDate: date[0], endDate: date[1] } : null;

                    if (between_dates && !isEqual(filters_between_dates, between_dates)) {
                        updateFilters({ between_dates: { startDate: date[0], endDate: date[1], type: date[2] } });
                    } else if (!between_dates && filters_between_dates) {
                        updateFilters({ between_dates: null });
                    }
                }}
            />

            <Button
                size="sm"
                onClick={() => { resetFilters(50) }}
                variant="flat"
                className="h-9 bg-gray-200/70"
            >
                <PiTrashDuotone className="me-1.5 h-[17px] w-[17px]" /> Limpiar
            </Button>
        </>
    );
};

export default OrdersFilters;