import React, { useCallback, useEffect } from 'react';
import { Text } from 'rizzui';
import useHandleDownloadDocument from '../../hooks/useDownloadDocument';
import useFetch from '../../hooks/useFetch';
import { getIconByExtension } from '../../hooks/useGetIconByExtension';
import { DocumentsService } from '../../services/document/documentsService';
import { Loader } from '../loader/SpinnerLogo';

interface RecentDocumentsProps {
    updateList: boolean;
    setUpdateList: Function;
    service: Function;
}

const RecentDocuments: React.FC<RecentDocumentsProps> = ({ updateList, setUpdateList, service }) => {

    const { handleDownloadDocumentNewTab } = useHandleDownloadDocument();
    const documentService = new DocumentsService();

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = await service();
        return response.getResponseData();
    }, []));

    useEffect(() => {
        if (updateList) {
            refetch();
            setUpdateList(false);
        }
    }, [updateList])
    
    if (loading) return <Loader />;

    return (
        <>
            <Text className='pt-7 pb-4 text-lg font-semibold'>Recientes</Text>

            {data?.map((document: any) => (
                <div
                    key={document.id}
                    className="p-2 flex items-center justify-between mb-1 cursor-pointer rounded hover:bg-sky-100"
                    onClick={() => handleDownloadDocumentNewTab(documentService.downloadDocument, document.id, document.originalName, document.extension)}
                >
                    <div className="flex items-center">
                        <span className="text-2xl mr-3">
                            {getIconByExtension(document.extension)}
                        </span>
                        <div>
                            <p className="text-sm text-gray-800">{document.originalName}</p>
                            <p className="text-sm text-gray-500">Directorio: {document.subdirectory}</p>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
};

export default RecentDocuments;