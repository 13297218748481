import { useFormik } from 'formik';
import { FC, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Input, Text } from 'rizzui';
import { toast } from 'sonner';
import fullLogo from '../../../assets/logo_completo.png';
import { ContractService } from "../../../services/contract/contractService";
import cn from "../../../utils/classNames";

const FindContract: FC = () => {

    // HOOKS

    const navigate = useNavigate();

    // STATES

    const [isLoading, setIsLoading] = useState(false);

    // FUNCTIONS

    const handleFindContract = async (signatureHash: string) => {
        setIsLoading(true);
        try {
            const response = await (await (new ContractService()).findContractByHash(signatureHash, null)).getResponseData();
            if (response?.success && !response?.data.isSigned) {
                navigate('/sign-contract' + `/${signatureHash}`);
                toast.info('Contrato encontrado');
            }
            else if (response?.success && response?.data.isSigned) {
                toast.error('El contrato ya ha sido firmado');
            }
            else {
                navigate('/find-contract/not-found');
            }
        } catch (error) {
            toast.error('No se ha encontrado el contrato');
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: { signatureHash: '', },
        validate: (values) => {
            const errors: { signatureHash?: string; } = {};
            if (!values.signatureHash) {
                errors.signatureHash = 'Requerido';
            }
            return errors;
        },
        validateOnChange: false,
        onSubmit: (values) => { handleFindContract(values.signatureHash) },
    });

    // RENDER

    return (
        <div className="relative min-h-screen">
            <div className=' w-full flex-col justify-center p-4 md:p-12 lg:p-28'>
                <div className={cn('mx-auto w-full max-w-md rounded-xl px-4 py-9 flex flex-col justify-center',)}>
                    <div className='flex flex-col justify-center text-center mb-5'>
                        <Link to='/find-contract' className={'flex justify-center items-center mb-5'}>
                            <img src={fullLogo} height={'30vh'} alt="Logo de Attiva" />
                        </Link>

                        <Text className="text-2xl font-bold text-gray-800">Buscador de contratos</Text>
                    </div>

                    <form onSubmit={formik.handleSubmit}>
                        <div className="space-y-5">
                            <Input
                                id='signatureHash'
                                type="text"
                                size="lg"
                                label="Código de firma"
                                placeholder="Introduce el código de la firma"
                                className="[&>label>span]:font-medium"
                                inputClassName="text-sm"
                                onChange={formik.handleChange}
                                error={formik.errors.signatureHash}
                            />

                            <Button className="w-full" type="submit" size="lg" disabled={isLoading} isLoading={isLoading}>
                                Buscar contrato
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default FindContract;