export default function DeliveryTruckIcon({
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 10"
      {...props}
    >
      <path
        fill="#3872FA"
        d="M3.43 7.336H1.415l-.08.407c-.027.147.013.3.107.413.093.113.24.18.387.18h1.527a2.207 2.207 0 0 1 .072-1ZM10.55.523a.483.483 0 0 0-.388-.187h-7a.505.505 0 0 0-.493.407l-.046.26h1.54c.64 0 1.167.527 1.167 1.167 0 .427-.233.793-.573 1 .34.207.573.573.573 1 0 .427-.233.793-.573 1a1.2 1.2 0 0 1 .473.54 2.336 2.336 0 0 1 2.766 2.293c0 .113-.007.227-.027.333h.693a.5.5 0 0 0 .487-.393l1.5-7a.48.48 0 0 0-.1-.42Zm2.285 1.813h-1.427c-.24 0-.44.167-.493.393l-1.067 5c-.033.147 0 .3.1.42.093.12.233.187.387.187h.36a1.826 1.826 0 0 1-.027-.333 2.336 2.336 0 0 1 2.333-2.333 2.336 2.336 0 0 1 2.333 2.333c0 .073 0 .14-.013.213a.436.436 0 0 0 .167-.273l.5-2.333a.571.571 0 0 0 .014-.107 3.17 3.17 0 0 0-3.167-3.167Z"
      />
      <path
        fill="#608EFB"
        d="M4.17 2.664H1.836a.5.5 0 0 1 0-1h2.333a.5.5 0 0 1 0 1Zm-.006 2h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 1 0 1Zm.003 2H.5a.5.5 0 0 1 0-1h3.667a.5.5 0 0 1 0 1Zm8.836 2.998a1.668 1.668 0 0 1-1.667-1.667c0-.92.748-1.667 1.667-1.667s1.667.747 1.667 1.667-.748 1.667-1.667 1.667Zm-7.336 0A1.67 1.67 0 0 1 4 7.995a1.67 1.67 0 0 1 1.667-1.667c.919 0 1.667.747 1.667 1.667s-.748 1.667-1.667 1.667Z"
      />
    </svg>
  );
}
