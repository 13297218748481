import { format } from 'date-fns';

import React, { JSXElementConstructor, ReactElement, ReactNode, ReactPortal, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'sonner';
import WidgetCard from "../../../components/card/WidgetCard";
import { CustomBadge } from '../../../components/forms/CustomBadge';
import CustomTable from '../../../components/table/CustomTable';
import useFilters from '../../../hooks/useFilters';
import { menuRoutes } from '../../../router/menu';
import { DashboardService } from '../../../services/dashboard/dashboardService';
import cn from "../../../utils/classNames";

const columnOrder = [
    'id',
    'code',
    'orderTypes',
    'startDate',
    'endDate',
    'contracts',
    'currentStatus'
];

interface OrderCardProps {
    className?: string
}

export default function OrdersList({ className }: OrderCardProps) {
    const { filters } = useFilters();
    const [isLoading, setIsLoading] = useState(true);
    const [orders, setOrders] = useState([]);

    const fetchOrders = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await new DashboardService().getNearOrdersInfo(filters);
            const data = response.getResponseData().data;
            if (data && data.data) {
                setOrders(data.data.map((order: {
                    id: any;
                    code: any;
                    orderTypes: any;
                    currentStatusDate: { date: any; };
                    startDate: { date: any; };
                    endDate: { date: any; };
                    contracts: any;
                    currentStatus: any;
                }) => ({
                    id: order.id,
                    code: order.code,
                    orderTypes: order.orderTypes,
                    currentStatusDate: order.currentStatusDate.date,
                    startDate: order.startDate.date,
                    endDate: order.endDate.date,
                    contracts: order.contracts,
                    currentStatus: order.currentStatus,
                })));
            }
        } catch (error) {
            toast.error('Error al cargar las órdenes');
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }, [filters]);

    useEffect(() => {
        fetchOrders();
    }, [fetchOrders]);

    // @ts-ignore
    return (
        <WidgetCard
            className={cn('@container', className)}
            title="Pedidos recientes"
        >
            <br />
            <CustomTable
                largeTable
                id={'orders-table'}
                columnOrder={columnOrder}
                data={orders}
                isLoading={isLoading}
                viewFilters={false}
                isSortable={false}
                columnsNotShown={["currentStatusDate", "id"]}
                overrideColumns={[
                    {
                        key: 'code',
                        render: (row: {
                            row: {
                                original: {
                                    id: any;
                                    code: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined;
                                };
                            };
                        }) => (
                            <Link
                                to={`${menuRoutes.orders.path}/${row.row.original.id}/profile${menuRoutes.orders.profile.info}`}>
                                <span className='font-bold text-primary'>{row.row.original.code}</span>
                            </Link>
                        ),
                    },
                    {
                        key: 'orderTypes',
                        render: (row: { row: { original: { orderTypes: { color: any; name: any; }; }; }; }) => (
                            <span className="px-3 py-1 text-sm font-semibold rounded-full text-white text-nowrap" style={{ backgroundColor: row.row.original.orderTypes.color }}>
                                {row.row.original.orderTypes.name}
                            </span>
                        ),
                    },
                    {
                        key: 'startDate',
                        render: (row: {
                            row: { original: { startDate: string | number | Date; }; };
                        }) => format(new Date(row.row.original.startDate), 'dd/MM/yyyy'),
                    },
                    {
                        key: 'endDate',
                        render: (row: {
                            row: { original: { endDate: string | number | Date; }; };
                        }) => format(new Date(row.row.original.endDate), 'dd/MM/yyyy'),
                    },
                    {
                        key: 'contracts',
                        render: (row: { row: { original: { contracts: any; }; }; }) => row.row.original.contracts,
                    },
                    {
                        key: 'currentStatus',
                        render: (row: {
                            row: {
                                original: {
                                    currentStatus: { color: any; name: any; };
                                    currentStatusDate: string | number | Date;
                                };
                            };
                        }) => (
                            <CustomBadge
                                className="cursor-pointer m-auto text-nowrap text-center px-3"
                                rounded='md'
                                size='lg'
                                customColor={row.row.original.currentStatus?.color || 'primary'}
                            >
                                {row.row.original.currentStatus?.name || 'N/A'}
                                <br />
                                {format(new Date(row.row.original.currentStatusDate), 'dd/MM/yyyy HH:mm')}
                            </CustomBadge>
                        ),
                    },
                ]}
                filters={filters}
            />
        </WidgetCard>
    );
}
