import { formatDistance } from 'date-fns';
import { es } from 'date-fns/locale';
import { capitalize } from 'lodash';
import React, { useCallback, useState } from 'react';
import { FaCommentAlt } from 'react-icons/fa';
import { HiOutlineEye } from 'react-icons/hi2';
import { MdOutlineModeEditOutline } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ActionIcon, Button, Text, Tooltip } from 'rizzui';
import { toast } from 'sonner';
import DeletePopover from '../../../../../components/buttons/DeletePopover';
import StatusDropdown from '../../../../../components/forms/StatusDropdown';
import { Loader } from '../../../../../components/loader/SpinnerLogo';
import { useFiltersPR } from '../../../../../components/providers/FiltersProvider';
import { FilterDrawerView } from '../../../../../components/table/components/TableFilter';
import CustomTable from '../../../../../components/table/CustomTable';
import AvatarCard from '../../../../../components/ui/AvatarCard';
import useIncidenceContractStatuses from '../../../../../hooks/api-calls/Incidences/useIncidenceContractStatuses';
import useFetch from '../../../../../hooks/useFetch';
import useHandleErrors from '../../../../../hooks/useHandleErrors';
import Page from '../../../../../layout/Page/Page';
import { setContractId } from '../../../../../redux/contractProfileSlice';
import { AppDispatch } from '../../../../../redux/store';
import { menuRoutes } from '../../../../../router/menu';
import { ContractService } from '../../../../../services/contract/contractService';
import { IncidenceService } from '../../../../../services/incidence/incidenceService';
import { IncidencesApiResponse } from '../../../../../type/entities/incidence-type';
import { AddCommentModal } from '../../../../clients/list/components/AddCommentModal';
import ContractIncidencesFilters from './ContractIncidencesFilters';
import { UserApiResponse } from '../../../../../type/entities/user-type';

interface ContractIncidenceProps { }

const columnOrder = [
    'id',
    'code',
    'manager',
    'subject',
    'description',
    'incidenceType',
    'currentStatus',
    'currentStatusDate',
    'updatedAt',
    'createdAt'
];

const ContractIncidence: React.FC<ContractIncidenceProps> = ({ }) => {

    const { id = '' } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const service = new ContractService();
    const incidenceService = new IncidenceService();
    const { handleErrors } = useHandleErrors();

    const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters } = useFiltersPR();
    const [openFilters, setOpenFilters] = useState<boolean>(false);
    const [openCommentModal, setOpenCommentModal] = useState<boolean>(false);
    const [rowId, setRowId] = useState<string>('');

    const { getIncidenceContractStatusesList } = useIncidenceContractStatuses();

    const [contract] = useFetch(useCallback(async () => {
        if (!id || id === '') return null;
        const response = await (new ContractService()).getContractById(id);
        return response.getResponseData() as UserApiResponse;
    }, [id]));

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = await service.listContractIncidences(filters);
        return response.getResponseData() as IncidencesApiResponse;
    }, [filters]));

    /**
     * Order the data to match the column order
     */
    const orderedData = () => data?.data.map((row: any) => {
        const orderedRow: any = {};
        columnOrder.forEach((key) => {
            orderedRow[key] = row[key];
        });
        return orderedRow;
    });



    const handleDelete = async (id: string) => {
        try {
            const response = await (await incidenceService.deleteIncidence(id)).getResponseData();
            if (response.success) {
                refetch();
                toast.success('Incidencia eliminado correctamente');
            } else {
                handleErrors(response);
            }
        } catch (error) {
            handleErrors(error);
        }
    };

    const handleMultiDelete = async (ids: string[]) => {
        try {
            const response = await (await incidenceService.deleteMultiIncidences(ids)).getResponseData();
            if (response.success) {
                refetch();
                toast.success('Incidencias eliminados correctamente');
            } else {
                handleErrors(response);
            }
        } catch (error) {
            handleErrors(error);
        }
    };

    const handleChangeStatus = async (incidenceId: string, statusId: string, comment: string) => {
        try {
            const response = await (await incidenceService.toggleIncidenceState(incidenceId, statusId, comment)).getResponseData();
            if (response.success) {
                toast.success('Estado de la incidencia cambiado correctamente');
            } else {
                handleErrors(response);
            }
        } catch (error) {
            handleErrors(error);
        } finally {
            refetch();
        }
    }

    return (
        <Page container='fluid'>
            <CustomTable
                id={'incidence-types-table'}
                columnOrder={columnOrder}
                data={orderedData()}
                isLoading={loading}
                columnsNotShown={['currentStatusDate']}
                overrideColumns={[
                    {
                        key: 'manager',
                        render: (row: any) => {
                            const fullName = row.row.original?.manager?.name + (row.row.original?.manager?.lastName ? ` ${row.row.original?.manager?.lastName}` : '');
                            return (
                                <figure className={'flex items-center gap-3'}>
                                    {row.row.original?.manager ? (
                                        <>

                                            <AvatarCard src={'default-avatar.png'}
                                                name={fullName}
                                                hideText={true}
                                                className='gap-0'
                                            />
                                            <figcaption className="grid gap-0.5">
                                                <Text className="font-lexend text-sm font-medium text-gray-900 dark:text-gray-700">
                                                    {fullName}
                                                </Text>
                                            </figcaption>
                                        </>
                                    ) : (
                                        <Text className="font-lexend text-sm font-medium text-gray-900 dark:text-gray-700">
                                            Sin asignar
                                        </Text>
                                    )}
                                </figure>
                            )
                        }
                    },
                    {
                        key: 'incidenceType',
                        render: (row: any) => {
                            return row.row.original?.incidenceType?.name || 'N/A';
                        }
                    },
                    {
                        key: 'currentStatus',
                        render: (row: any) => {
                            const incidenceStatusDate = row.row.original?.currentStatusDate?.date;
                            const incidenceStatus = row.row.original?.currentStatus;
                            const incidenceId = row.row.original?.id;
                            return (
                                <div className='flex flex-col gap-2 justify-content-center text-center' key={row.row.original.id + incidenceStatus.id}>
                                    {(incidenceId && incidenceStatusDate && incidenceStatus) ? (
                                        <StatusDropdown
                                            entityId={row.row.original?.id}
                                            title="¿Estas seguro de cambiar el estado de la incidencia?"
                                            statesOptions={getIncidenceContractStatusesList(false, incidenceStatus?.id)}
                                            currentState={incidenceStatus}
                                            currentStateDate={incidenceStatusDate}
                                            handleStateChange={(entityId: string, statusId: string, comment: string) =>
                                                handleChangeStatus(incidenceId, statusId, comment)
                                            }
                                        />
                                    ) : "N/A"}
                                </div>
                            );
                        }
                    },
                    {
                        key: 'Actualización',
                        render: (row: any) => {
                            const date = row.row.original?.updatedAt?.date;
                            return date ? capitalize(formatDistance(new Date(date), new Date(), { addSuffix: true, locale: es })) : 'Nunca';
                        },
                    },
                    {
                        key: 'Creación',
                        render: (row: any) => {
                            const date = row.row.original?.createdAt?.date;
                            return date ? capitalize(formatDistance(new Date(date), new Date(), { addSuffix: true, locale: es })) : 'Nunca';
                        },
                    },

                ]}
                actions={[
                    {
                        label: 'Add Comment',
                        permissions: { group: 'incidences', permission: 'admin_incidence_comments' },
                        render: (row: any) => {
                            return (
                                <Tooltip
                                    size="sm"
                                    content={'Añadir comentario'}
                                    placement="top"
                                    color="invert"
                                >
                                    <ActionIcon
                                        as="span"
                                        size="sm"
                                        variant="outline"
                                        className="hover:!border-gray-900 hover:text-gray-700 cursor-pointer"
                                        onClick={() => { setRowId(row.id); setOpenCommentModal(true) }}
                                    >
                                        <FaCommentAlt className="h-4 w-4" size={20} />
                                    </ActionIcon>
                                </Tooltip>
                            );
                        },
                    },
                    {
                        label: 'View',
                        permissions: { group: 'incidences', permission: 'get_incidences' },
                        render: (row: any) => {
                            return (
                                <Tooltip
                                    size="sm"
                                    content={'Ver detalles'}
                                    placement="top"
                                    color="invert"
                                >
                                    <Link
                                        to={`${menuRoutes.incidences.path}/${row.id}/profile${menuRoutes.incidences.profile.info}`}>
                                        <ActionIcon
                                            as="span"
                                            size="sm"
                                            variant="outline"
                                            className="hover:!border-gray-900 hover:text-gray-700"
                                        >
                                            <HiOutlineEye className="h-4 w-4" size={20} />
                                        </ActionIcon>
                                    </Link>
                                </Tooltip>
                            );
                        },
                    },
                    {
                        label: 'Edit',
                        permissions: { group: 'incidences', permission: 'edit_incidences' },
                        render: (row: any) => {
                            return (
                                <Tooltip
                                    size="sm"
                                    content={'Editar incidencia'}
                                    placement="top"
                                    color="invert"
                                >
                                    <Link to={`${menuRoutes.incidences.path}/${row.id}/edit`}>
                                        <ActionIcon
                                            as="span"
                                            size="sm"
                                            variant="outline"
                                            className="hover:!border-gray-900 hover:text-gray-700"
                                        >
                                            <MdOutlineModeEditOutline className="h-4 w-4" size={20} />
                                        </ActionIcon>
                                    </Link>
                                </Tooltip>
                            );
                        },
                    },
                    {
                        label: 'Delete',
                        permissions: { group: 'incidences', permission: 'delete_incidences' },
                        render: (row: any) => {
                            return (
                                <Tooltip
                                    size="sm"
                                    content={'Eliminar'}
                                    placement="top"
                                    color="invert"
                                >
                                    <div>
                                        <DeletePopover
                                            title={`Eliminar Incidencia`}
                                            description={`¿Estás seguro de que deseas eliminar ${row.code}?`}
                                            onDelete={() => handleDelete(row.id)}
                                        />
                                    </div>
                                </Tooltip>
                            );
                        },
                    },
                ]}
                handleMultipleDelete={handleMultiDelete}
                multiDeletePermission={{ group: 'incidences', action: 'delete_incidences' }}
                filters={filters}
                updateFilters={updateFilters}
                updateFilterOrder={updateFilterOrder}
                defaultOrder={filters.filter_order || undefined}
                paginationData={{
                    pageSize: filters.limit,
                    currentPage: filters.page,
                    pageCount: (data as IncidencesApiResponse) ? data.lastPage : 1,
                    totalCount: data?.totalRegisters,
                    handlePagination: updatePage,
                    handlePerPage: updatePageSize,
                }}
                toggleFilters={() => setOpenFilters(!openFilters)}
                children={
                    <Button
                        onClick={() => {
                            dispatch(setContractId(contract?.code));
                            navigate(`${menuRoutes.incidences.path}/create`);
                        }}
                    >
                        Crear Incidencia
                    </Button>
                }
            />

            {loading && (
                <Loader height='60vh' />
            )}

            <AddCommentModal service={(new IncidenceService()).createIncidenceComment} id={rowId} isOpen={openCommentModal} onClose={() => setOpenCommentModal(false)} refetch={refetch} />

            <FilterDrawerView isOpen={openFilters} setOpenDrawer={setOpenFilters} drawerTitle={'Filtros de Incidencias'}>
                <ContractIncidencesFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} />
            </FilterDrawerView>
        </Page>
    );

}
export default ContractIncidence;