import React, { useCallback } from "react";
import { useParams } from "react-router-dom";
import { StatCard } from "../../../../../../components/charts/StatCard";
import useFetch from "../../../../../../hooks/useFetch";
import { OrderService } from "../../../../../../services/order/orderService";

// Función auxiliar para formatear valores monetarios
const formatCurrency = (amount: number | undefined): string => `${(amount ?? 0).toFixed(2)} €`;

const EconomicMetrics: React.FC = () => {

    const { id } = useParams<{ id: string }>();

    const [data] = useFetch(useCallback(async () => {
        if (!id) return null;
        const response = (await (new OrderService).getEconomicMetrics(id as string)).getResponseData();
        return response;
    }, [id]));

    const metrics = [
        {
            title: "Importe total",
            value: formatCurrency(data?.totalAmount),
            span: "col-span-2 sm:col-span-1",
            valueFontSize: "text-lg sm:text-xl lg:text-3xl text-nowrap"
        },
        {
            title: "Importe KO",
            value: formatCurrency(data?.koAmount),
            span: "col-span-2 sm:col-span-1",
            valueFontSize: "text-lg sm:text-xl lg:text-3xl text-nowrap"
        },
        {
            title: "Balance",
            value: formatCurrency(data?.balance),
            span: "col-span-4 sm:col-span-2 row-span-3",
            valueFontSize: "text-4xl md:text-6xl text-nowrap",
        },
        {
            title: "Ingreso total",
            value: formatCurrency(data?.totalIncome),
            span: "col-span-4 sm:col-span-2 row-span-1 row-start-2",
            valueFontSize: "text-2xl md:text-4xl text-nowrap",
        },
        {
            title: "Coste plataforma",
            value: formatCurrency(data?.platformCosts),
            span: "col-span-2 sm:col-span-1 row-start-3",
            valueFontSize: "text-lg sm:text-xl lg:text-3xl text-nowrap"
        },
        {
            title: "Tutores externos",
            value: formatCurrency(data?.tutorCosts),
            span: "col-span-2 sm:col-span-1 row-start-3",
            valueFontSize: "text-lg sm:text-xl lg:text-3xl text-nowrap"
        },
    ];

    return (
        <div className="grid grid-cols-4 grid-rows-3 gap-4 mt-10 pb-10">
            {metrics.map(({ title, value, span, valueFontSize }, index) => (
                <div key={index} className={span}>
                    <StatCard statItem={{ title, value }} valueFontSize={valueFontSize} />
                </div>
            ))}
        </div>
    );
};

export default EconomicMetrics;