import dayjs from 'dayjs';

export function getFormattedDateString(date: number | string | null | Date) {
    if (!date) return date as string;
    return dayjs(date).format('DD-MMM-YYYY');
}

export function getDateRangeStateValues(state: string | null) {
    if (!state) {
        return null;
    }
    return new Date(state);
}

export default function getFormattedDateRangeString(dates: Date[]) {
    const [start, end] = Array.isArray(dates) ? dates : [];
    if (start && end) {
        return `${getFormattedDateString(start)};${getFormattedDateString(end)}`;
    }
    return getFormattedDateString(start);
}

/**
 * Resta 7 días a la fecha proporcionada
 * @param date
 * @returns
 */
export const subtractSevenDays = (date: Date): string => {
    // Restamos 7 días directamente a la fecha
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() - 7);

    // Formatear la fecha en YYYY-MM-DD
    return newDate.toISOString().split("T")[0];
};

/**
 * Obtiene el último día del mes siguiente a la fecha proporcionada
 * @param date 
 * @returns 
 */
export const getLastDayOfNextMonth = (date: Date): string => {
    const year = date.getFullYear();
    const month = date.getMonth(); // getMonth() devuelve 0 para enero, 1 para febrero, etc.

    // Crear una nueva fecha con el primer día del mes siguiente
    const firstDayNextMonth = new Date(year, month + 1, 1);

    // Restar un día para obtener el último día del mes siguiente
    const lastDayNextMonth = new Date(firstDayNextMonth.getFullYear(), firstDayNextMonth.getMonth() + 1, 1);

    // Formatear la fecha en YYYY-MM-DD
    return lastDayNextMonth.toISOString().split("T")[0];
};