import React from 'react';
import { FiltersProvider } from '../../../../../components/providers/FiltersProvider';
import ContractProfileLayout from '../../ContractProfileLayout';
import ContractActivityHistory from './ContractActivityHistory';

interface ContractActivityHistoryWrapperProps { }

const ContractActivityHistoryWrapper: React.FC<ContractActivityHistoryWrapperProps> = () => {

    return (
        <FiltersProvider>
            <ContractProfileLayout>
                <ContractActivityHistory />
            </ContractProfileLayout>
        </FiltersProvider>
    );
};

export default ContractActivityHistoryWrapper;