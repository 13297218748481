import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import AttivaCard from "../../../components/card/AttivaCard";
import useHandleErrors from "../../../hooks/useHandleErrors";
import PageHeader from "../../../layout/shared/page-header";
import { menuRoutes } from "../../../router/menu";
import IncidenceForm from "../IncidenceForm";
import { IncidenceService } from "../../../services/incidence/incidenceService";

const pageHeader = {
    title: "Crear Incidencia",
    breadcrumb: [
        { name: 'Administración' },
        { name: 'Control de errores' },
        { href: menuRoutes.incidences.path, name: "Incidencia" },
        { name: "Crear" },
    ],
}

const IncidenceCreate: FC = () => {

    const { handleErrors } = useHandleErrors();
    const navigate = useNavigate();
    const service = new IncidenceService();

    const [loading, setLoading] = useState<boolean>(false);

    const handleSubmit = async (values: any) => {
        setLoading(true);
        try {
            const response = (await service.createIncidence(values)).getResponseData();

            if (response.success) {
                navigate(-1);
                toast.success("Incidencia creado correctamente");
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error("Error al crear la incidencia");
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb} />

            <AttivaCard title="Datos Principales" className="mb-4">
                <IncidenceForm submit={handleSubmit} isLoading={loading} />
            </AttivaCard>
        </>
    );
}

export default IncidenceCreate;
