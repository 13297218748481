import { Fragment, useContext } from "react";
import { useNavigate } from "react-router";
import { Button } from "rizzui";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import { FiltersProvider } from "../../../components/providers/FiltersProvider";
import PageHeader from "../../../layout/shared/page-header";
import { menuRoutes } from "../../../router/menu";
import cn from "../../../utils/classNames";
import IncidencesList from "./IncidencesList";

const IncidencesListWrapper = () => {

    const { userCan } = useContext(PrivilegeContext);
    const navigate = useNavigate();

    const breadCrumb = [
        { name: 'Administración' },
        { name: 'Control de Errores' },
        { name: 'Incidencias', href: menuRoutes.incidences.path },
    ];

    return (
        <Fragment>
            <FiltersProvider>
                <PageHeader title="Listado de incidencias" breadcrumb={breadCrumb}>
                    {
                        userCan('create_incidences', 'incidences') &&
                        (
                            <Button
                                color="primary"
                                onClick={() => { navigate(menuRoutes.incidences.create) }}
                                className={cn(['mt-4 md:mt-0'])}
                            >
                                Crear incidencia
                            </Button>
                        )
                    }

                </PageHeader>
                <IncidencesList />
            </FiltersProvider>
        </Fragment>
    );
}

export default IncidencesListWrapper;