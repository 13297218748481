import { Fragment, useContext } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { Button } from "rizzui";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import { FiltersProvider } from "../../../components/providers/FiltersProvider";
import PageHeader from "../../../layout/shared/page-header";
import { menuRoutes } from "../../../router/menu";
import cn from "../../../utils/classNames";
import StatesList from "./StatesList";

const StatesListWrapper = () => {

    const location = useLocation();
    const { userCan } = useContext(PrivilegeContext);
    const navigate = useNavigate();
    const entity = location.pathname.split('/')[2]?.split('_').slice(0, -1).join('_');

    const breadCrumb = [
        { name: 'Administración' },
        { name: 'Estados', href: menuRoutes.states.path },
    ];

    const defaultFilterFilters = {
        active: true,
    };

    return (
        <Fragment >
            <FiltersProvider defaultFilterFilters={defaultFilterFilters}>
                <PageHeader title="Listado de estados" breadcrumb={breadCrumb}>
                    {userCan('create_statuses', 'statuses') && (
                        <Button
                            color="primary"
                            onClick={() => { navigate(`${menuRoutes.states.path}/${entity}/create`) }}
                            className={cn(['mt-4 md:mt-0'])}
                        >
                            Crear estado
                        </Button>
                    )}
                </PageHeader>
                <StatesList />
            </FiltersProvider>
        </Fragment>
    );
}

export default StatesListWrapper;