import { useEffect, useState } from 'react';
import { IncidenceTypeService } from '../../../services/incidence-type/incidenceTypeService';
import { IncidenceType } from '../../../type/entities/incidence-type';

/*----------------------------------------------------------------------------------------
* EN: Hook to manage incidence types fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de tipos de incidencias.
----------------------------------------------------------------------------------------*/


const useIncidenceTypes = (initialFilters = {}, initialOrders = {}, limit = 99999) => {

    const [data, setData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [filters, setFilters] = useState<Record<string, any>>({
        limit: limit,
        filter_filters: { ...initialFilters },
        filter_orders: { ...initialOrders, createdAt: 'DESC' },
    });

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const service = new IncidenceTypeService();
            const response = await service.listIncidenceTypes(filters);
            const fetchedData = response.getResponseData().data;

            if (fetchedData && fetchedData?.data) {
                const mappedData = fetchedData.data?.map((data: IncidenceType) => ({
                    value: data.id,
                    label: data.name,
                }));
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching incidence types:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [])
    

    const getList = (entity?: string) => {
        if (entity) return data.filter((state: any) => state.entity === entity);
        return data;
    };

    const addFilter = (key: string, value: any) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            filter_filters: {
                ...prevFilters.filter_filters,
                [key]: value
            }
        }));
    };

    const modifyLimit = (amount = 5) => {
        if (filters.limit + amount >= 5) {
            setFilters(prevFilters => ({
                ...prevFilters,
                limit: prevFilters.limit + amount
            }));
        }
    }

    const getEntity = (id: string) => {
        return data.find((entity: any) => entity.id === id);
    }

    return { 
        fetchIncidenceTypesList: fetchData, 
        getIncidenceTypesList: getList, 
        modifyLimitIncidenceTypes: modifyLimit, 
        addIncidenceTypesFilter: addFilter, 
        getIncidenceType: getEntity, 
        isLoadingIncidenceTypes: isLoading 
    };
}

export default useIncidenceTypes;