import React from 'react';
import VerticalTabNav, { MenuItem } from '../../../../components/navigation/VerticalTabNav';
import { menuRoutes } from '../../../../router/menu';

const menuItems: MenuItem[] = [
    {
        label: 'Contrato',
        path: menuRoutes.states.list.contract,
        children: [
            {
                label: 'Producto del contrato',
                path: menuRoutes.states.list.contract_product,
                permission: {
                    group: 'statuses',
                    action: 'admin_statuses',
                },
            },
            {
                label: 'Facturación del contrato',
                path: menuRoutes.states.list.contract_invoicing,
                permission: {
                    group: 'statuses',
                    action: 'admin_statuses',
                },
            },
            {
                label: 'Incidencia del contrato',
                path: menuRoutes.states.list.contract_incidence,
                permission: {
                    group: 'statuses',
                    action: 'admin_statuses',
                },
            },
            {
                label: 'Comisión del contrato',
                path: menuRoutes.states.list.contract_commission,
                permission: {
                    group: 'statuses',
                    action: 'admin_statuses',
                },
            }
        ],
        permission: {
            group: 'statuses',
            action: 'admin_statuses',
        },
    },
    {
        label: 'Vencimiento',
        path: menuRoutes.states.list.expiration,
        permission: {
            group: 'statuses',
            action: 'admin_statuses',
        },
    },
    {
        label: 'Facturas',
        path: menuRoutes.states.list.invoice,
        permission: {
            group: 'statuses',
            action: 'admin_statuses',
        },
    },
    {
        label: 'Incidencia',
        path: menuRoutes.states.list.incidence,
        permission: {
            group: 'statuses',
            action: 'admin_statuses',
        },
    },
    {
        label: 'Renovación',
        path: menuRoutes.states.list.renovation,
        permission: {
            group: 'statuses',
            action: 'admin_statuses',
        },
    },
    {
        label: 'Pedidos',
        path: menuRoutes.states.list.order,
        permission: {
            group: 'statuses',
            action: 'admin_statuses',
        }
    }
];

export default function StatesListLayout({ children }: { children: React.ReactNode }) {
    return (
        <div className="grid grid-cols-1 lg:grid-cols-5 gap-5">
            <div className="lg:col-span-1 col-span-5">
                <VerticalTabNav menuItems={menuItems} />
            </div>
            <div className="lg:col-span-4 col-span-5 ps-5">
                {children}
            </div>
        </div>
    );
};