import { SVGProps } from 'react';

const SvgMasks = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
			<path fill='none' d='M0 0h24v24H0z' />
			<path
				d='M14.34 8.78C13.52 8.33 12.93 8 12 8c-.93 0-1.52.33-2.34.77-.71.39-1.54.85-2.66 1.08v1.65c0 2.76 2.24 5 5 5s5-2.24 5-5V9.85c-1.12-.23-1.95-.69-2.66-1.07zm.66 2.47c-.75-.21-1.26-.51-1.71-.78-.46-.27-.8-.47-1.29-.47s-.84.2-1.31.48c-.44.26-.96.56-1.69.76V10.2c.48-.17.84-.38 1.18-.58C10.72 9.3 11.23 9 12 9s1.27.3 1.8.62c.34.2.71.42 1.2.59v1.04z'
				opacity={0.3}
			/>
			<path d='M19.5 6c-1.31 0-2.37 1.01-2.48 2.3-1.88-.5-2.84-1.8-5.02-1.8-2.19 0-3.14 1.3-5.02 1.8C6.87 7.02 5.81 6 4.5 6A2.5 2.5 0 002 8.5V9c0 6 3.6 7.81 6.52 7.98C9.53 17.62 10.72 18 12 18s2.47-.38 3.48-1.02C18.4 16.81 22 15 22 9v-.5A2.5 2.5 0 0019.5 6zm-16 3v-.5c0-.55.45-1 1-1s1 .45 1 1v3c0 1.28.38 2.47 1.01 3.48C4.99 14.27 3.5 12.65 3.5 9zM7 11.5V9.85c1.12-.23 1.95-.69 2.66-1.08C10.48 8.33 11.07 8 12 8c.93 0 1.52.33 2.34.78.71.39 1.54.84 2.66 1.08v1.65c0 2.76-2.24 5-5 5s-5-2.25-5-5.01zM20.5 9c0 3.65-1.49 5.27-3.01 5.98.64-1.01 1.01-2.2 1.01-3.48v-3c0-.55.45-1 1-1s1 .45 1 1V9zm-9.81 1.48c-.44.26-.96.56-1.69.76V10.2c.48-.17.84-.38 1.18-.58C10.72 9.3 11.23 9 12 9s1.27.3 1.8.62c.34.2.71.42 1.2.59v1.04c-.75-.21-1.26-.51-1.71-.78-.46-.27-.8-.47-1.29-.47s-.84.2-1.31.48z' />
		</svg>
	);
};

export default SvgMasks;
