import { Fragment, useContext } from "react";
import { useNavigate } from "react-router";
import { Button } from "rizzui";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import { FiltersProvider } from "../../../components/providers/FiltersProvider";
import PageHeader from "../../../layout/shared/page-header";
import { menuRoutes } from "../../../router/menu";
import cn from "../../../utils/classNames";
import UsersList from "./UsersList";

const UsersListWrapper = () => {

    const { userCan } = useContext(PrivilegeContext);
    const navigate = useNavigate();

    const breadCrumb = [
        { name: 'Administración' },
        { name: 'Control de acceso' },
        { name: 'Usuarios', href: menuRoutes.users.path },
    ];


    return (
        <Fragment>
            <FiltersProvider>
                <PageHeader title="Listado de usuarios" breadcrumb={breadCrumb}>
                    {
                        userCan('create_user', 'user') &&
                        (
                            <Button
                                color="primary"
                                onClick={() => { navigate(menuRoutes.users.create) }}
                                className={cn(['mt-4 md:mt-0'])}
                            >
                                Crear usuario
                            </Button>
                        )
                    }

                </PageHeader>
                <UsersList />
            </FiltersProvider>
        </Fragment>
    );
}

export default UsersListWrapper;