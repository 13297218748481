import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { View } from 'react-big-calendar';

interface OrdersState {
    selectedView: View | undefined;
    selectedTab: string;
    selectedInfoTab: string;
}

const initialState: OrdersState = {
    selectedView: 'month',
    selectedTab: 'list',
    selectedInfoTab: '',
};

const ordersSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        setSelectedView(state, action: PayloadAction<View | undefined>) {
            state.selectedView = action.payload;
        },
        setSelectedTab(state, action: PayloadAction<string>) {
            state.selectedTab = action.payload;
        },
        setSelectedInfoTab(state, action: PayloadAction<string>) {
            state.selectedInfoTab = action.payload;
        },
    },
});

export const { setSelectedView, setSelectedTab, setSelectedInfoTab } = ordersSlice.actions;
export default ordersSlice.reducer;