import React, { useEffect, useState } from 'react';
import CustomSelect from '../../../components/forms/CustomSelect';
import useProductTypes from '../../../hooks/api-calls/useProductTypes';
import { FilterOptions } from '../../../hooks/useFilters';

interface StatesFiltersProps {
    updateFilters: (filters: any) => void
    resetFilters: (limit: any) => void
    filters: FilterOptions
}

const StatesFilters: React.FC<StatesFiltersProps> = ({ filters, updateFilters, resetFilters }) => {

    const { getProductTypesList } = useProductTypes();

    const [filter_filters, setFilterFilters] = useState<any>({});
    const [loaded, setLoaded] = useState<boolean>(false);

    useEffect(() => {
        if (loaded && filter_filters !== filters.filter_filters) {
            const delaySearch = setTimeout(() => {
                updateFilters({ ...filter_filters });
            }, 1000);
            return () => clearTimeout(delaySearch);
        }
    }, [filter_filters, loaded]);

    useEffect(() => {
        setFilterFilters(filters.filter_filters || '');
    }, [filters]);

    useEffect(() => {
        setLoaded(true);
    }, []);

    return (
        <>
            <CustomSelect
                isSearchable
                id={'active'}
                label="Estado"
                options={[
                    { label: 'Todos', value: 'null' },
                    { label: 'Activos', value: true },
                    { label: 'Desactivados', value: false }
                ]}
                onChange={(e: any) => {
                    if (e.value === 'null') {
                        updateFilters({ active: null });
                        return;
                    }
                    updateFilters({ active: e.value });
                }}
                value={filters.filter_filters?.active !== null
                    ? { label: filters.filter_filters?.active ? 'Activo' : 'Desactivado', value: filters.filter_filters?.active }
                    : { label: 'Todos', value: 'null' }}
            />

            <CustomSelect
                isSearchable
                isMulti
                id={'product_type'}
                label="Tipo de Producto"
                options={getProductTypesList()}
                onChange={(e: any) => {
                    const selectedValues = e.map((item: any) => item.value);
                    updateFilters({ product_type: selectedValues.length > 0 ? selectedValues : null });
                }}
                value={
                    filters.filter_filters?.product_type
                        ? getProductTypesList().filter((item: any) => filters.filter_filters?.product_type.includes(item.value))
                        : null
                }
            />
        </>
    );
};

export default StatesFilters;