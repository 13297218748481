import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';
import { IncidenceTypeFieldsModel } from '../../type/entities/incidence-type';

const ENDPOINT = '/incidence-types';

export class IncidenceTypeService extends RestServiceConnection {

    listIncidenceTypes = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/list',
            data: filters,
        }, true) as AxiosResponse;
        return this;
    }

    getIncidenceTypeById = async (incidenceTypeId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get',
            data: { incidenceTypeId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    createIncidenceType = async (incidenceType: IncidenceTypeFieldsModel) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/create',
            data: incidenceType,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    editIncidenceType = async (incidenceType: IncidenceTypeFieldsModel) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/edit',
            data: incidenceType,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    deleteIncidenceType = async (incidenceTypeId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/delete',
            data: { incidenceTypeId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    deleteMultiIncidenceTypes = async (incidenceTypesIds: string[]) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/delete-multi',
            data: { incidenceTypesIds }
        }, true);
        return this;
    }
}