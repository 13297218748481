

export default function AlertCautionBulbIcon({ ...props }: React.SVGProps<SVGSVGElement>) {

    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        id="Capa_1"
        width="512"
        height="512"
        viewBox="0 0 512 512"
        {...props}
      >
        <path
          fill="#ff6a4d"
          d="M376 452H136c-8.291 0-15-6.709-15-15V256c0-74.443 60.557-135 135-135 74.681 0 135 60.701 135 135v181c0 8.291-6.709 15-15 15"
        ></path>
        <path
          fill="#f23030"
          d="M376 452c8.291 0 15-6.709 15-15V256c0-74.299-60.319-135-135-135v331z"
        ></path>
        <path
          fill="#5b5b8c"
          d="M406 497H106c-8.291 0-15-6.709-15-15v-45c0-8.291 6.709-15 15-15h300c8.291 0 15 6.709 15 15v45c0 8.291-6.709 15-15 15"
        ></path>
        <path
          fill="#443d66"
          d="M406 422H256v75h150c8.291 0 15-6.709 15-15v-45c0-8.291-6.709-15-15-15"
        ></path>
        <g id="Alert_2_">
          <circle cx="256" cy="346" r="15" fill="#ffdf40"></circle>
          <path
            fill="#ffdf40"
            d="M256 301c-8.291 0-15-6.709-15-15v-60c0-8.291 6.709-15 15-15s15 6.709 15 15v60c0 8.291-6.709 15-15 15M46 271H15c-8.291 0-15-6.709-15-15s6.709-15 15-15h31c8.291 0 15 6.709 15 15s-6.709 15-15 15"
          ></path>
          <path
            fill="#ffbe40"
            d="M497 271h-31c-8.291 0-15-6.709-15-15s6.709-15 15-15h31c8.291 0 15 6.709 15 15s-6.709 15-15 15"
          ></path>
          <path
            fill="#ffdf40"
            d="M256 61c-8.291 0-15-6.709-15-15V15c0-8.291 6.709-15 15-15s15 6.709 15 15v31c0 8.291-6.709 15-15 15M96.889 118.114 75.678 96.903c-5.859-5.859-5.859-15.352 0-21.211s15.352-5.859 21.211 0L118.1 96.903c5.859 5.859 5.859 15.352 0 21.211-5.86 5.859-15.352 5.86-21.211 0"
          ></path>
          <path
            fill="#ffbe40"
            d="M393.9 118.114c-5.859-5.859-5.859-15.352 0-21.211l21.211-21.211c5.859-5.859 15.352-5.859 21.211 0s5.859 15.352 0 21.211l-21.211 21.211c-5.859 5.859-15.351 5.86-21.211 0"
          ></path>
        </g>
        <path
          fill="#443d66"
          d="M466 512H46c-8.291 0-15-6.709-15-15s6.709-15 15-15h420c8.291 0 15 6.709 15 15s-6.709 15-15 15"
        ></path>
        <path
          fill="#ffbe40"
          d="M271 46V15c0-8.291-6.709-15-15-15v61c8.291 0 15-6.709 15-15"
        ></path>
        <path
          fill="#322d59"
          d="M466 482H256v30h210c8.291 0 15-6.709 15-15s-6.709-15-15-15"
        ></path>
        <g fill="#ffbe40">
          <path d="M271 346c0-8.286-6.716-15-15-15v30c8.284 0 15-6.716 15-15M271 286v-60c0-8.291-6.709-15-15-15v90c8.291 0 15-6.709 15-15"></path>
        </g>
      </svg>
    );
}