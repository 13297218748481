import { useFormik } from 'formik';
import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Checkbox, Input, Password, Text } from 'rizzui';
import { toast } from 'sonner';
import fullLogo from '../../../assets/logo_completo.png';
import Spinner from '../../../components/bootstrap/Spinner';
import { AppDispatch, RootState } from '../../../redux/store';
import { Tutor, tutorLogin } from '../../../redux/tutorSlice';
import { menuRoutes } from '../../../router/menu';
import { LoginService } from '../../../services/auth/loginService';
import cn from '../../../utils/classNames';

const TutorLogin: FC = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { isAuthenticated } = useSelector((state: RootState) => state.tutor);

  const [isLoading, setIsLoading] = useState(false);
  const [remindMe, setRemindMe] = useState(false);

  if (isAuthenticated) {
    setTimeout(() => {
      navigate(menuRoutes.tutor_panel.tutorials, { replace: true });
    }, 100);
  };

  const handleLogin = async (username: string, password: string) => {
    setIsLoading(true);
    const response = await (await (new LoginService()).authTutorCredentials(username, password, remindMe)).getResponseData();

    if (!response?.success) {
      toast.error(response.message);
      setIsLoading(false);
      dispatch(tutorLogin({
        isAuthenticated: false,
        loading: false,
        error: response.message,
        tutor: null
      }))
      return;
    }

    if (response && response.success) {
      // store data in indexDb for service worker.
      try {
        //saveUser(response.data);
        const tutor: Tutor = {
          id: response.data.user.id,
          email: response.data.user.email,
          token: response.data.token,
          name: response.data.user.name,
          lastName: response.data.user.lastName,
          platformUser: response.data.user.platformUser,
          roles: response.data.user.roles,
          tutorType: response.data.user.tutorType,
          company: response.data.user.company,
          companyName: response.data.user.companyName,
        };

        // dispatch login action
        dispatch(tutorLogin({
          isAuthenticated: true,
          loading: false,
          error: null,
          tutor: tutor
        }))
      } catch (error) {
        toast.error('Error saving tutor to indexDb');
        return;
      } finally {
        setIsLoading(false);
      }
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      loginUsername: '',
      loginPassword: '',
    },
    validate: (values) => {
      const errors: { loginUsername?: string; loginPassword?: string } = {};

      if (!values.loginUsername) {
        errors.loginUsername = 'Requerido';
      }

      if (!values.loginPassword) {
        errors.loginPassword = 'Requerido';
      }

      return errors;
    },
    validateOnChange: false,
    onSubmit: (values) => { handleLogin(values.loginUsername, values.loginPassword) },
  });

  return (
    <div className="relative min-h-screen">
      <div className='w-full h-[100vh] grid grid-cols-1 xl:grid-cols-3'>
        <div className='col-span-2 bg-gray-100 hidden lg:block'>
          <div className='flex flex-col justify-center items-center h-full'>
            <img src={fullLogo} height={'30vh'} alt="Logo de Attiva" />
            <Text className="text-2xl font-bold text-gray-800 mt-5">Bienvenido Tutor</Text>
          </div>
        </div>

        <div className={cn('mx-auto w-full max-w-md rounded-xl px-4 py-9 flex flex-col justify-center',)}>
          <div className='flex flex-col justify-center text-center mb-5'>
            <Text className="text-2xl font-bold text-gray-800">Entra a tu panel de tutor</Text>
          </div>

          <form onSubmit={formik.handleSubmit}>
            <div className="space-y-5">
              <Input
                id='loginUsername'
                type="email"
                size="lg"
                label="Email"
                placeholder="Introducte tu email"
                className="[&>label>span]:font-medium"
                inputClassName="text-sm"
                onChange={formik.handleChange}
                error={formik.errors.loginUsername}
              />

              <Password
                id='loginPassword'
                label="Contraseña"
                placeholder="Introduce tu contraseña"
                size="lg"
                className="[&>label>span]:font-medium"
                inputClassName="text-sm"
                value={formik.values.loginPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.errors.loginPassword}
              />

              <Checkbox
                id='remindMe'
                label="Recuérdame"
                checked={remindMe}
                onChange={() => setRemindMe(!remindMe)}
                className="text-sm"
              />

              <Button className="w-full" type="submit" size="lg" disabled={isLoading}>
                {isLoading ? <Spinner color={'dark'} /> : 'Iniciar Sesión'}
              </Button>
            </div>
          </form>

          <Text className="mt-5 text-center text-[15px] leading-loose text-gray-500 md:mt-7 lg:mt-9 lg:text-base">
            ¿No recuerdas la contraseña?{' '}
            <Link to={menuRoutes.tutor_panel.forgot_password} className="font-semibold text-gray-700 transition-colors hover:text-gray-1000">
              Recupérala
            </Link>
          </Text>

          {/* Acceder al login de tutor */}
          <div className='flex flex-col justify-center items-center'>
            <Text className="text-sm text-gray-700 mt-5">
              ¿Eres administrador?{' '}
              <Link to='/login' className='font-bold'>
                Accede a tu panel
              </Link>
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TutorLogin;