// src/store/slices/incidenceProfileSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IncidenceProfileState {
    selectedView: string;
}

const initialState: IncidenceProfileState = {
    selectedView: '/info',
};

const incidenceProfileSlice = createSlice({
    name: 'incidenceProfile',
    initialState,
    reducers: {
        setSelectedView(state, action: PayloadAction<string>) {
            state.selectedView = action.payload;
        },
    },
});

export const { setSelectedView } = incidenceProfileSlice.actions;
export default incidenceProfileSlice.reducer;