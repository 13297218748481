import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { FaPrint } from 'react-icons/fa6';
import { ActionIcon, Tooltip } from 'rizzui';
import { CustomBadge } from '../../../components/forms/CustomBadge';
import { Loader } from '../../../components/loader/SpinnerLogo';
import { FilterDrawerView } from '../../../components/table/components/TableFilter';
import CustomTable from '../../../components/table/CustomTable';
import useHandleDownloadDocument from '../../../hooks/useDownloadDocument';
import useFetch from '../../../hooks/useFetch';
import useFilters from '../../../hooks/useFilters';
import { TutorService } from '../../../services/tutor/tutorService';
import { InvoicesApiResponse } from '../../../type/entities/invoice-type';
import { fixNumber } from '../../../utils/formatNumber';
import MyInvoicesFilters from './MyInvoicesFilters';

interface MyInvoicesProps { }

const columnOrder = [
    'id',
    'number',
    'taxableAmount',
    'tax',
    'totalTaxesAmount',
    'totalProducts',
    'currentStatus',
    'totalPrice',
    'corporation',
    'invoiceDate',
    'paymentDate',
    'expirationDate',
    'createdAt',
    'updatedAt',
    'invoiceDates',
];

const MyInvoices: React.FC<MyInvoicesProps> = ({ }) => {

    const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters } = useFilters({}, [{ field: 'created_at', order: 'desc' }], 1, 15);
    const { handleDownloadDocumentNewTab } = useHandleDownloadDocument();

    const [openFilters, setOpenFilters] = useState<boolean>(false);

    const [data, loading] = useFetch(useCallback(async () => {
        const response = await (new TutorService()).listMyInvoices(filters);
        return response.getResponseData() as InvoicesApiResponse;
    }, [filters]));

    /**
     * Order the data to match the column order
     */
    const orderedData = data?.data.map((row: any) => {
        const orderedRow: any = {};
        columnOrder.forEach((key) => {
            orderedRow[key] = row[key];
        });
        return orderedRow;
    });

    if (loading) return <Loader height='70vh' />;

    return (
        <>
            <div className="container mx-auto">
                {data !== undefined && data && (
                    <CustomTable
                        id={'my-invoices-table'}
                        data={orderedData}
                        columnOrder={columnOrder}
                        isLoading={loading}
                        columnsNotShown={['id', 'company', 'lines', 'invoiceDate', 'paymentDate', 'expirationDate', 'invoiceDates']}
                        additionalColumns={[
                            {
                                id: 'invoiceDates',
                                header: 'Fechas Factura',
                                render: (row: any) => {
                                    const invoiceDate = row.row.original?.invoiceDate.date;
                                    const paymentDate = row.row.original?.paymentDate.date;
                                    const expirationDate = row.row.original?.expirationDate.date;
                                    return (
                                        <ul className='gap-x-4'>
                                            <li className='flex flex-nowrap pb-1'>
                                                <h6 className='text-nowrap text-gray-400'>Factura:&nbsp;</h6>
                                                {invoiceDate ? moment(invoiceDate).format('DD/MM/YYYY') : 'N/A'}
                                            </li>
                                            <li className='flex flex-nowrap pb-1'>
                                                <h6 className='text-nowrap text-gray-400'>Pago:&nbsp;</h6>
                                                {paymentDate ? moment(paymentDate).format('DD/MM/YYYY') : 'N/A'}
                                            </li>
                                            <li className='flex flex-nowrap pb-1'>
                                                <h6 className='text-nowrap text-gray-400'>Vencimiento:&nbsp;</h6>
                                                {expirationDate ? moment(expirationDate).format('DD/MM/YYYY') : 'N/A'}
                                            </li>
                                        </ul>
                                    );
                                }
                            },
                        ]}
                        overrideColumns={[
                            {
                                key: 'currentStatus',
                                render: (row: any) => {
                                    return (
                                        <Tooltip
                                            size="sm"
                                            content={row.row.original?.currentStatus?.description}
                                            placement="top"
                                            color="invert"
                                        >
                                            <CustomBadge customColor={row.row.original?.currentStatus?.color} rounded='md'>
                                                {row.row.original?.currentStatus?.name}
                                            </CustomBadge>
                                        </Tooltip>
                                    );
                                }
                            },
                            {
                                key: 'corporation',
                                render: (row: any) => {
                                    return row.row.original?.corporation?.name || 'N/A';
                                },
                            },
                            {
                                key: 'tax',
                                render: (row: any) => {
                                    return (
                                        row.row.original?.tax ? `${row.row.original?.tax}%` : 'N/A'
                                    );
                                },
                            },
                            {
                                key: 'totalTaxesAmount',
                                render: (row: any) => {
                                    return (
                                        <div className="text-center">
                                            {row.row.original?.totalTaxesAmount ? `${fixNumber(row.row.original?.totalTaxesAmount)}€` : 'N/A'}
                                        </div>
                                    )

                                },
                            },
                            {
                                key: 'totalProducts',
                                render: (row: any) => {
                                    return (
                                        <div className="text-center">
                                            {row.row.original?.totalProducts ? `${row.row.original?.totalProducts}` : 'N/A'}
                                        </div>
                                    )
                                },
                            },
                            {
                                key: 'totalPrice',
                                render: (row: any) => {
                                    return (
                                        <div className="text-center">
                                            {row.row.original?.totalPrice ? `${fixNumber(row.row.original?.totalPrice)}€` : 'N/A'}
                                        </div>
                                    )
                                },
                            },
                            {
                                key: 'number',
                                columnNameKey: 'invoiceNumber',
                                render: (row: any) => {
                                    return row.row.original?.number || 'N/A';
                                },
                            },
                        ]}
                        actions={[
                            {
                                label: 'Print PDF',
                                render: (row: any) => {
                                    return (
                                        <Tooltip
                                            size="sm"
                                            content={'Imprimir PDF'}
                                            placement="top"
                                            color="invert"
                                        >
                                            <ActionIcon
                                                as="span"
                                                size="sm"
                                                variant="outline"
                                                className="hover:!border-gray-900 hover:text-gray-700 cursor-pointer"
                                                onClick={() => {
                                                    handleDownloadDocumentNewTab((new TutorService).generateInvoice, row.id, moment(row.invoiceDate?.date).format('DD-MM-YYYY') + '_factura.pdf')
                                                }}
                                            >
                                                <FaPrint className="h-4 w-4" size={20} />
                                            </ActionIcon>
                                        </Tooltip>
                                    );
                                },
                            },
                        ]}
                        filters={filters}
                        updateFilters={updateFilters}
                        updateFilterOrder={updateFilterOrder}
                        defaultOrder={filters.filter_order || undefined}
                        paginationData={{
                            pageSize: filters.limit,
                            currentPage: filters.page,
                            pageCount: (data as InvoicesApiResponse) ? data.lastPage : 1,
                            totalCount: data?.totalRegisters,
                            handlePagination: updatePage,
                            handlePerPage: updatePageSize,
                        }}
                        toggleFilters={() => setOpenFilters(!openFilters)}
                    />
                )}
            </div>
            <FilterDrawerView isOpen={openFilters} setOpenDrawer={setOpenFilters} drawerTitle={'Filtros Facturas'}>
                <MyInvoicesFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} />
            </FilterDrawerView>
        </>
    );
};

export default MyInvoices;