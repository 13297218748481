import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Popover, Text, Title } from 'rizzui';
import AvatarCard from '../../../components/ui/AvatarCard';
import { AppDispatch, RootState } from '../../../redux/store';
import { tutorLogout } from '../../../redux/tutorSlice';
import { menuRoutes } from '../../../router/menu';
import cn from '../../../utils/classNames';

export default function ProfileTutorMenu({
    buttonClassName,
    avatarClassName,
    username = false,
}: {
    buttonClassName?: string;
    avatarClassName?: string;
    username?: boolean;
}) {
    const user = useSelector((state: RootState) => state.tutor.tutor);

    return (
        <ProfileTutorMenuPopover>
            <Popover.Trigger>
                <button
                    className={cn(
                        'w-9 shrink-0 rounded-full outline-none focus-visible:ring-[1.5px] focus-visible:ring-gray-400 focus-visible:ring-offset-2 active:translate-y-px sm:w-10',
                        buttonClassName
                    )}
                >
                    <AvatarCard src={'default-avatar.png'}
                        name={user?.name + (user?.lastName ? ` ${user.lastName}` : '')}
                        hideText={true}
                        className='gap-0'
                    />
                    {!!username && (
                        <span className="username hidden text-gray-200 dark:text-gray-700 md:inline-flex">
                            Hola, tutor
                        </span>
                    )}
                </button>
            </Popover.Trigger>

            <Popover.Content className="z-[9999] p-0 dark:bg-gray-100 [&>svg]:dark:fill-gray-100">
                <DropdownMenu />
            </Popover.Content>
        </ProfileTutorMenuPopover>
    );
}

function ProfileTutorMenuPopover({ children }: React.PropsWithChildren<{}>) {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setIsOpen(false);
    }, []);

    return (
        <Popover
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            shadow="sm"
            placement="bottom-end"
        >
            {children}
        </Popover>
    );
}

function DropdownMenu() {
    const dispatch = useDispatch<AppDispatch>();
    const user = useSelector((state: RootState) => state.tutor.tutor);

    const menuItems = [
        {
            name: 'Mi Perfil',
            href: menuRoutes.tutor_panel.profile,
        },
    ];

    return (
        <div className="w-64 text-left rtl:text-right">
            <div className="flex items-center border-b border-gray-300 px-3 pb-5 pt-5">
                <div className="w-full flex flex-nowrap flex-col ps-2">
                    <Title as="h6" className="font-semibold">
                        {user?.name}
                    </Title>
                    <Text className="text-gray-600">
                        {user?.email}
                    </Text>
                </div>
            </div>

            <div className="grid px-3.5 py-3.5 font-medium text-gray-700">
                {menuItems.map((item) => (
                    <Link
                        key={item.name}
                        to={item.href}
                        className="group my-0.5 flex items-center rounded-md px-2.5 py-2 hover:bg-gray-100 focus:outline-none hover:dark:bg-gray-50/50"
                    >
                        {item.name}
                    </Link>
                ))}
            </div>

            <div className="border-t border-gray-300 px-2 py-2">
                <Button
                    className="h-auto w-full justify-start p-3 font-medium text-gray-700 outline-none focus-within:text-gray-600 hover:bg-gray-100 hover:text-gray-900 focus-visible:ring-0"
                    variant="text"
                    onClick={() => { dispatch(tutorLogout()) }}
                >
                    Cerrar Sesión
                </Button>
            </div>
        </div>
    );
}