import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

interface IConfirmationAlert {
    title: string;
    icon: 'warning' | 'success' | 'error' | 'info' | 'question';
    onConfirm: (result: any) => void;
    text?: string;
    html?: JSX.Element;
    preConfirm?: () => void;
}

const MySwal = withReactContent(Swal);

export const handleConfirmationAlert = async (props: IConfirmationAlert) => {
    const options: any = {
        heightAuto: false,
        title: props.title,
        icon: props.icon,
        showCancelButton: true,
        focusCancel: true,
        confirmButtonColor: '#009737',
        cancelButtonColor: '#e5133d',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
    };

    if (props.text) {
        options.text = props.text;
    }

    if (props.html) {
        options.html = props.html;
    }

    if (props.preConfirm) {
        options.preConfirm = props.preConfirm;
    }

    return MySwal.fire(options).then((result: any) => {
        if (result.isConfirmed) {
            props.onConfirm(result);
        }
    });
};