import React from 'react';
import ReactSelect from 'react-select';
import { cn } from 'rizzui';
import useProvinces from '../../hooks/api-calls/useProvinces';

interface SelectProvinceFilterProps {
    label?: string;
    id: string;
    filters: any;
    updateFilters: (filters: any) => void;
    className?: string;
}

const SelectProvinceFilter: React.FC<SelectProvinceFilterProps> = ({ label = 'Provincia', id, filters, updateFilters, className }) => {

    const { getProvincesList } = useProvinces();

    return (
        <div className={cn('flex flex-col', className)}>
            <span className="rizzui-input-label block text-sm mb-1.5 font-medium">{label}</span>
            <ReactSelect
                isClearable
                options={getProvincesList()}
                onChange={(e: any) => updateFilters({ [id]: e ? e.value : '' })}
                value={filters?.filter_filters?.[id]
                    ? { value: filters.filter_filters[id], label: getProvincesList().find((c: any) => c.value === filters.filter_filters[id])?.label }
                    : null
                }
                placeholder="Elegir ..."
                styles={{
                    control: (base: any, state: any) => ({
                        ...base,
                        border: '1px !important',
                        borderRadius: '0.375rem',
                        borderColor: 'rgb(229 231 235) !important',
                        boxShadow: state.isFocused ? '0 0 0 2px rgb(0, 172, 216)' : '0 0 0 1px rgba(0,0,0, 0.2) !important',
                    }),
                    option: (provided: any, state: any) => ({
                        ...provided,
                        backgroundColor: state.isFocused ? 'rgb(0, 172, 216)' : 'white',
                        color: state.isFocused ? 'white' : 'black',
                        '&:hover': {
                            backgroundColor: 'rgb(0, 172, 216)',
                            color: 'white',
                            borderColor: '#000000 !important'
                        }
                    }),
                }}
            />
        </div>
    );
};

export default SelectProvinceFilter;