import { useFormik } from 'formik';
import React from 'react';
import { IoClose } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import { ActionIcon, Button, Modal, Text } from 'rizzui';
import { toast } from 'sonner';
import * as yup from 'yup';
import CustomSelect from '../../../../../components/forms/CustomSelect';
import useContract from '../../../../../hooks/api-calls/contract/useContracts';
import useHandleErrors from '../../../../../hooks/useHandleErrors';
import FormGroup from '../../../../../layout/shared/form-group';
import { OrderService } from '../../../../../services/order/orderService';

interface AddContractModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const schema = yup.object({
    orderId: yup.string().required('Este campo es obligatorio'),
    contractIds: yup.array().of(yup.string()).required('Este campo es obligatorio'),
});

const AddContractModal: React.FC<AddContractModalProps> = ({ isOpen, onClose }) => {

    // HOOKS

    const { id } = useParams<{ id: string }>();
    const { handleErrors } = useHandleErrors();
    const { getContractsList } = useContract({ contracts_without_order: true });

    // STATES

    const [isLoading, setIsLoading] = React.useState(false);

    //-------------------------------------------------------------------------------------------------------------------------------
    /**
     * @ES ENVÍA LA SOLICITUD DE ASIGNACIÓN DE CONTRATO
     * @EN SENDS THE CONTRACT ASSIGNMENT REQUEST
     */
    //-------------------------------------------------------------------------------------------------------------------------------
    const submit = async (values: any) => {
        setIsLoading(true);
        try {
            const response = await (new OrderService).addContractsToOrder(values);
            const responseData = response.getResponseData();

            if (responseData.success) {
                toast.success(responseData.message);
                onClose();
            } else {
                handleErrors(responseData);
                onClose();
            }
        } catch (error: any) {
            toast.error('Error al añadir el contrato');
        } finally {
            setIsLoading(false);
        }
    };
    //-------------------------------------------------------------------------------------------------------------------------------

    // FORMIK

    const formik = useFormik({
        initialValues: {
            contractIds: [] as string[],
            orderId: id as string,
        },
        validationSchema: schema,
        validateOnBlur: false,
        onSubmit: values => { submit(values); },
    });

    // RENDER

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => {
                onClose();
                formik.resetForm();
            }}
            size='sm'
            overlayClassName='backdrop-blur'
            containerClassName='!max-w-xl !shadow-xl'
            className='z-[999] [&_.pointer-events-none]:overflow-visible'
        >
            <div className='m-auto px-7 pt-6 pb-4'>
                <div className='mb-7 flex items-center justify-between'>
                    <Text className="text-xl">Añadir</Text>
                    <ActionIcon size='sm' variant='text' onClick={() => {
                        onClose();
                        formik.resetForm();
                    }}>
                        <IoClose className='h-auto w-6' strokeWidth={1.8} />
                    </ActionIcon>
                </div>

                <form onSubmit={formik.handleSubmit}>
                    <FormGroup
                        title="Añadir contrato"
                        description="Añade un contrato al pedido"
                        className="py-4"
                        titleCols="@md:col-span-4"
                        childCols="@md:col-span-8 md:grid-cols-2"
                    >
                        <CustomSelect
                            isSearchable
                            isMulti
                            id={'contractIds'}
                            label="Contrato"
                            options={getContractsList()}
                            value={formik.values.contractIds ? getContractsList().filter((c: any) => formik.values.contractIds.includes(c.value)) : []}
                            onChange={(e: any) => {
                                const selectedIds = e ? e.map((option: any) => option.value) : [];
                                formik.setFieldValue('contractIds', selectedIds);
                            }}
                            containerClassName='col-span-2'
                            error={formik.errors.contractIds}
                        />
                    </FormGroup>

                    <div className='flex justify-center p-7'>
                        <Button type='submit' size='md' isLoading={isLoading} disabled={isLoading}>
                            Añadir
                        </Button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default AddContractModal;