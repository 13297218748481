import { Fragment, useState } from "react";
import { Button } from "rizzui";
import { usePrivilege } from "../../../components/priviledge/PriviledgeProvider";
import { FiltersProvider } from "../../../components/providers/FiltersProvider";
import PageHeader from "../../../layout/shared/page-header";
import { menuRoutes } from "../../../router/menu";
import NetworksList from "./NetworksList";

const breadCrumb = [
    { name: 'Administración' },
    { name: 'Redes comerciales y departamentos', href: menuRoutes.networks.path },
];

const NetworksListWrapper = () => {

    const { userCan } = usePrivilege();

    const [isDepartmentOpen, setIsDepartmentOpen] = useState<boolean>(false);
    const [isNetworkOpen, setIsNetworkOpen] = useState<boolean>(false);
    const [nodeId, setNodeId] = useState<string | undefined>(undefined);
    const [openFilters, setOpenFilters] = useState<boolean>(false);

    return (
        <Fragment>
            <FiltersProvider>
                <PageHeader title="Árbol de redes comerciales y departamentos" breadcrumb={breadCrumb}>
                    <div>
                        {userCan('create_commercial_network', 'commercial_network') && (
                            <Button
                                onClick={() => { setIsDepartmentOpen(false); setIsNetworkOpen(true); setNodeId(undefined) }}
                                className="me-4"
                                color="secondary"
                                variant="outline"
                            >
                                Crear red comercial
                            </Button>
                        )}
                        {userCan('create_departments', 'departments') && (
                            <Button
                                onClick={() => { setIsNetworkOpen(false); setIsDepartmentOpen(true); setNodeId(undefined) }}
                                color="secondary"
                                variant="outline"
                            >
                                Crear departamento
                            </Button>
                        )}
                    </div>
                </PageHeader>
                <NetworksList
                    isDepartmentOpen={isDepartmentOpen} setIsDepartmentOpen={setIsDepartmentOpen}
                    isNetworkOpen={isNetworkOpen} setIsNetworkOpen={setIsNetworkOpen}
                    nodeId={nodeId} setNodeId={setNodeId}
                    openFilters={openFilters} setOpenFilters={setOpenFilters}
                />
            </FiltersProvider>
        </Fragment>
    );
}

export default NetworksListWrapper;