import React, { useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { ActionIcon, Button, Modal, Select, Text, Textarea } from 'rizzui';
import { toast } from 'sonner';
import CustomSelect from '../../../../../components/forms/CustomSelect';
import useContractCommissionStates from '../../../../../hooks/api-calls/contract/useContractCommissionStates';
import useContractProductsStates from '../../../../../hooks/api-calls/contract/useContractProductsStates';
import useContractStates from '../../../../../hooks/api-calls/contract/useContractStates';
import useHandleErrors from '../../../../../hooks/useHandleErrors';
import { ContractService } from '../../../../../services/contract/contractService';

interface ContractStatusModalProps {
    ids: string[];
    isOpen: boolean;
    onClose: () => void;
    refetch: () => void;
}

const statusTypesList = [
    { label: 'Estado de contrato', value: 'contract' },
    { label: 'Estado de producto', value: 'product' },
    { label: 'Estado de comisión', value: 'commission' },
];

const ContractStatusModal: React.FC<ContractStatusModalProps> = ({ ids, isOpen, onClose, refetch }) => {

    const { handleErrors } = useHandleErrors();
    const { getContractStatesList, isLoadingContractStates } = useContractStates({ active: true, action: 'edit' });
    const { getContractProductsStatesList, isLoadingContractProductsStates } = useContractProductsStates({ active: true, action: 'edit' });
    const { getContractCommissionStatesList, isLoadingContractCommissionStates } = useContractCommissionStates({ active: true, action: 'edit' });

    const [statusType, setStatusType] = useState<string>('');
    const [statusId, setStatusId] = useState<string>('');
    const [comment, setComment] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleStateChange = async (ids: string[], stateId: string, comment: string, entityName: string, entityId: string) => {
        setIsLoading(true);
        var response = { success: false };
        switch (entityName) {
            case 'contract':
                try {
                    response = await (await (new ContractService).changeMultiContractStatus(ids, stateId, comment)).getResponseData();
                    if (response.success) {
                        toast.success('Estado del contrato actualizado correctamente');
                    } else {
                        handleErrors(response);
                    }
                } catch (error) {
                    toast.error('Error al actualizar el estado');
                }
                break;
            case 'product':
                try {
                    response = await (await (new ContractService).changeMultiContractProductStatus(ids, stateId, entityId, comment)).getResponseData();
                    if (response.success) {
                        toast.success('Estado del producto actualizado correctamente');
                    } else {
                        handleErrors(response);
                    }
                } catch (error) {
                    toast.error('Error al actualizar el estado del producto');
                }
                break;
            case 'commission':
                try {
                    response = await (await (new ContractService).changeMultiContractCommissionStatus(ids, stateId, comment)).getResponseData();
                    if (response.success) {
                        toast.success('Estado de la comisión actualizado correctamente');
                    } else {
                        handleErrors(response);
                    }
                } catch (error) {
                    toast.error('Error al actualizar el estado de la comisión');
                }
                break;
            default:
                break;
        }
        setIsLoading(false);
        refetch();
        onClose();
        setStatusType('');
        setStatusId('');
        setComment('');
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => {
                onClose();
                setStatusType('');
                setStatusId('');
                setComment('');
            }}
            size='sm'
            overlayClassName='backdrop-blur'
            containerClassName='!max-w-xl !shadow-xl'
            className='z-[999] [&_.pointer-events-none]:overflow-visible'
        >
            <div className='m-auto px-7 pt-6 pb-4'>
                <div className='mb-7 flex items-center justify-between'>
                    <Text className="text-xl">Cambiar el estado</Text>
                    <ActionIcon size='sm' variant='text' onClick={() => {
                        onClose();
                        setStatusType('');
                        setStatusId('');
                        setComment('');
                    }}>
                        <IoClose className='h-auto w-6' strokeWidth={1.8} />
                    </ActionIcon>
                </div>

                <Select
                    label='Tipo de Estado'
                    placeholder='Seleccionar tipo'
                    options={statusTypesList}
                    value={statusType ? { label: statusTypesList.find((item) => item.value === statusType)?.label || '', value: statusType } : undefined}
                    onChange={(e: any) => {
                        setStatusType(e.value);
                        setStatusId('');
                    }}
                    className={'mb-4'}
                />

                {!isLoadingContractStates && !isLoadingContractProductsStates && !isLoadingContractCommissionStates && (
                    <CustomSelect
                        id='status'
                        label={'Estado'}
                        placeholder={'estado'}
                        options={statusType === 'contract' ? getContractStatesList() : statusType === 'product' ? getContractProductsStatesList() : statusType === 'commission' ? getContractCommissionStatesList() : []}
                        value={
                            statusId
                                ? {
                                    label: statusType === 'contract' ? getContractStatesList().find((item) => item.value === statusId)?.label || '' :
                                        statusType === 'product' ? getContractProductsStatesList().find((item) => item.value === statusId)?.label || '' :
                                            statusType === 'commission' ? getContractCommissionStatesList().find((item) => item.value === statusId)?.label || '' :
                                                '',
                                    value: statusId
                                } : undefined
                        }
                        onChange={(e: any) => setStatusId(e.value)}
                        isDisabled={!statusType}
                    />
                )}

                <Textarea
                    label='Comentario'
                    placeholder='Comentario'
                    className='mb-4'
                    value={comment}
                    onChange={(e: any) => setComment(e.target.value)}
                    labelClassName='mt-4'
                />
            </div>

            <div className='flex justify-center p-7'>
                <Button
                    isLoading={isLoading}
                    disabled={!statusType || !statusId || isLoading}
                    onClick={() => handleStateChange(ids, statusId, comment, statusType, '')}
                    color='primary'
                >
                    Cambiar estado
                </Button>
            </div>
        </Modal>
    );
};

export default ContractStatusModal;