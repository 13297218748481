import { useFormik } from "formik";
import React, { Fragment } from "react";
import { FaRegClock } from "react-icons/fa";
import { FaEuroSign } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import { Switch, Textarea } from "rizzui";
import * as yup from "yup";
import CustomSelect from "../../components/forms/CustomSelect";
import RequiredInput from "../../components/forms/InputHOC";
import useCorporations from "../../hooks/api-calls/useCorporations";
import useProductTypes from "../../hooks/api-calls/useProductTypes";
import Page from "../../layout/Page/Page";
import FormGroup from "../../layout/shared/form-group";
import { Product } from "../../type/entities/product-type";
import FormFooter from "../_layout/_footers/form-footer";
import ComplementsForm from "./components/ComplementsForm";

interface CreateFormProps {
    isLoading: boolean;
    submit: Function;
    data?: Product | undefined;
}

const schema = yup.object({
    name: yup.string().min(1, 'Demasiado corto').max(200, 'Demasiado largo').required('Campo obligatorio'),
    productTypeId: yup.string().required('Campo obligatorio'),
    code: yup.string().min(1, 'Demasiado corto').max(200, 'Demasiado largo')
        .when('productTypeId', {
            is: '26a41eb6-a9d1-4d2f-88e5-1bb59bdb9ae1',
            then: schema => schema.required('Campo obligatorio para productos bonificados'),
            otherwise: schema => schema.notRequired().nullable(),
        }),
    providingCorporations: yup.array().when('productTypeId', {
        is: '26a41eb6-a9d1-4d2f-88e5-1bb59bdb9ae1',
        then: schema => schema.required('Campo obligatorio para productos bonificados').length(1, 'Debe seleccionar al menos una empresa'),
        otherwise: schema => schema.notRequired().nullable(),
    }),
    weeklyHours: yup.number().min(0, 'Debe ser mayor que 0')
        .when('productTypeId', {
            is: '26a41eb6-a9d1-4d2f-88e5-1bb59bdb9ae1',
            then: schema => schema.required('Campo obligatorio para productos bonificados'),
            otherwise: schema => schema.notRequired().nullable(),
        }),
    amount: yup.number()
        .typeError('Debe ser un número válido')
        .positive('Debe ser un número positivo')
        .test(
            'max-2-decimals',
            'El número no puede tener más de 2 decimales',
            (value) => {
                if (value === undefined || value === null) return true;
                return /^\d+(\.\d{1,2})?$/.test(value.toString());
            }
        )
        .required('Campo obligatorio'),
    cost: yup.number()
        .typeError('Debe ser un número válido')
        .positive('Debe ser un número positivo')
        .test(
            'max-2-decimals',
            'El número no puede tener más de 2 decimales',
            (value) => {
                if (value === undefined || value === null) return true;
                return /^\d+(\.\d{1,2})?$/.test(value.toString());
            }
        )
        .required('Campo obligatorio'),
    complements: yup.array().of(yup.object().shape({
        productId: yup.string().required('Campo obligatorio'),
        name: yup.string().required('Campo obligatorio'),
        amount: yup.number().typeError('Debe ser un número válido').positive('Debe ser un número positivo').test(
            'max-2-decimals',
            'El número no puede tener más de 2 decimales',
            (value) => {
                if (value === undefined || value === null) return true;
                return /^\d+(\.\d{1,2})?$/.test(value.toString());
            }
        ).required('Campo obligatorio'),
    })),
});

const ProductForm: React.FC<CreateFormProps> = ({ isLoading, submit, data }) => {

    const { id = '' } = useParams<{ id: string }>();
    const { getProductTypesList } = useProductTypes({ active: true });
    const { getCorporationsList } = useCorporations({ corporation_function: 'delivering', active: true });
    const mode = data ? 'Editar' : 'Crear';
    const navigate = useNavigate();

    const [complement, setComplement] = React.useState<{ productId: string, name: string, amount: string }>({ productId: '', name: '', amount: '' });

    const formik = useFormik({
        initialValues: {
            productId: data?.id ?? '',
            name: data?.name ?? '',
            productTypeId: data?.productType?.id ?? '26a41eb6-a9d1-4d2f-88e5-1bb59bdb9ae1',
            code: data?.code ?? '',
            providingCorporations: data?.productHasCorporations?.map(item => item.corporation.id) ?? [],
            weeklyHours: data?.weeklyHours ?? null,
            amount: data?.amount ?? '',
            cost: data?.cost ?? 0,
            active: data?.active ?? true,
            description: data?.description ?? '',
            complements: data?.complements ? data.complements.map((p: any) => ({ productId: p.productComplement.id, name: p.name, amount: p.amount })) : [],
        },
        validationSchema: schema,
        validateOnBlur: false,
        // validateOnChange: false,
        onSubmit: values => { submit(values) },
    });

    const getContent = () => {
        return (
            <>
                <div className="@container">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="grid gap-7 divide-y-2 divide-dashed divide-gray-200 font-medium">
                            <FormGroup
                                title="Información General"
                                description="Datos principales"
                                className='pt-3 pb-4'
                                titleCols="@md:col-span-2"
                                childCols="@md:col-span-10 md:grid-cols-4"
                            >
                                <RequiredInput
                                    id='name'
                                    label="Nombre"
                                    formik={formik}
                                />

                                <CustomSelect
                                    isSearchable
                                    id={'productType'}
                                    label="Tipo de producto"
                                    value={{ value: formik.values.productTypeId, label: getProductTypesList()?.find((g: any) => g.value === formik.values.productTypeId)?.label }}
                                    options={getProductTypesList()}
                                    onChange={(e: any) => { formik.setFieldValue('productTypeId', e?.value); }}
                                    required
                                    error={formik.errors.productTypeId}
                                />

                                <RequiredInput
                                    id='code'
                                    label="Código de Acción Formativa"
                                    className={`[&>label>span]:font-medium ${!(formik.values.productTypeId !== null && formik.values.productTypeId === '26a41eb6-a9d1-4d2f-88e5-1bb59bdb9ae1') ? 'hidden' : ''}`}
                                    inputClassName="text-sm"
                                    onChange={formik.handleChange}
                                    value={formik.values.code}
                                    error={formik.errors.code}
                                    required={formik.values.productTypeId !== null && formik.values.productTypeId === '26a41eb6-a9d1-4d2f-88e5-1bb59bdb9ae1'}
                                />

                                <CustomSelect
                                    isSearchable
                                    isMulti
                                    id={'corporations'}
                                    label="Empresas Impartidoras"
                                    display={!(formik.values.productTypeId !== null && formik.values.productTypeId === '26a41eb6-a9d1-4d2f-88e5-1bb59bdb9ae1') ? false : true}
                                    options={getCorporationsList()}
                                    onChange={(selectedOptions: any) => {
                                        const selectedIds = selectedOptions ? selectedOptions.map((option: any) => option.value) : [];
                                        formik.setFieldValue('providingCorporations', selectedIds);
                                    }}
                                    value={
                                        formik.values.providingCorporations ?
                                            getCorporationsList().filter((corporation: any) => formik.values.providingCorporations.includes(corporation.value))
                                            : []
                                    }
                                    error={formik.errors.providingCorporations}
                                    required={formik.values.productTypeId !== null && formik.values.productTypeId === '26a41eb6-a9d1-4d2f-88e5-1bb59bdb9ae1'}
                                />

                                <RequiredInput
                                    id='amount'
                                    type="number"
                                    step={0.01}
                                    label="Importe"
                                    suffix={<FaEuroSign />}
                                    formik={formik}
                                />

                                <RequiredInput
                                    id='cost'
                                    type="number"
                                    step={0.01}
                                    label="Coste"
                                    suffix={<FaEuroSign />}
                                    formik={formik}
                                />

                                <RequiredInput
                                    id="weeklyHours"
                                    type="number"
                                    label="Horas semanales"
                                    formik={formik}
                                    className={`[&>label>span]:font-medium ${!(formik.values.productTypeId !== null && formik.values.productTypeId === '26a41eb6-a9d1-4d2f-88e5-1bb59bdb9ae1') ? 'hidden' : ''}`}
                                    required={formik.values.productTypeId !== null && formik.values.productTypeId === '26a41eb6-a9d1-4d2f-88e5-1bb59bdb9ae1'}
                                    suffix={<FaRegClock />}
                                />

                                <Switch
                                    id={id}
                                    label={formik.values.active ? 'Activo' : 'Desactivado'}
                                    checked={formik.values.active}
                                    onChange={() => formik.setFieldValue('active', !formik.values.active)}
                                    className='md:mt-6'
                                    switchKnobClassName='bg-secondary'
                                />
                            </FormGroup>

                            <ComplementsForm formik={formik} complement={complement} setComplement={setComplement} />

                            <FormGroup
                                title="Descripción"
                                description="Información adicional"
                                className='pt-6'
                                titleCols="@md:col-span-2"
                                childCols="@md:col-span-10 md:grid-cols-4"
                            >
                                <Textarea
                                    id='description'
                                    className="[&>label>span]:font-medium lg:col-span-4"
                                    onChange={formik.handleChange}
                                    value={formik.values.description}
                                    error={formik.errors.description}
                                />
                            </FormGroup>
                        </div>
                    </form>
                </div>
            </>
        )
    };

    return (
        <Fragment>
            <Page container="fluid">
                {(mode === "Editar" && data) && getContent()}
                {mode === "Crear" && getContent()}
            </Page>
            <FormFooter
                submitBtnText={mode + ' Producto'}
                handleCancelBtn={() => navigate(-1)}
                handleSubmitBtn={formik.submitForm} isLoading={isLoading}
            />
        </Fragment>
    )
};

export default ProductForm;