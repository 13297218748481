import TutorsLayoutWrapper from "../pages/_tutor-layout";
import MyCalendar from "../pages/_tutor-layout/calendar/MyCalendar";
import ListMyDocumentsWrapper from "../pages/_tutor-layout/documents/ListMyDocumentsWrapper";
import ListMyInvoicesWrapper from "../pages/_tutor-layout/invoices/ListMyInvoicesWrapper";
import TutorProfile from "../pages/_tutor-layout/profile/TutorProfile";
import MyTutorials from "../pages/_tutor-layout/tutorials/MyTutorials";
import ClientsWrapper from "../pages/clients";
import ClientCreate from "../pages/clients/create/ClientCreate";
import ClientEdit from "../pages/clients/edit/ClientEdit";
import ClientsListWrapper from "../pages/clients/list/ClientsListWrapper";
import ClientActivityHistoryWrapper from "../pages/clients/profile/views/activity-history/ClientActivityHistoryWrapper";
import ClientCalls from "../pages/clients/profile/views/calls/ClientCalls";
import ClientComments from "../pages/clients/profile/views/comments/ClientComments";
import ClientConsultancy from "../pages/clients/profile/views/consultancy/ClientConsultancy";
import ClientContractsWrapper from "../pages/clients/profile/views/contracts/ClientContractsWrapper";
import ClientCreditHistory from "../pages/clients/profile/views/credit-history/ClientCreditHistory";
import ClientInfo from "../pages/clients/profile/views/info/ClientInfo";
import ClientInvoicesWrapper from "../pages/clients/profile/views/invoices/ClientInvoicesWrapper";
import ClientProducts from "../pages/clients/profile/views/products/ClientProducts";
import ClientStudents from "../pages/clients/profile/views/students/ClientStudents";
import ConsultanciesWrapper from "../pages/consultancy";
import CreateConsultancy from "../pages/consultancy/create/CreateConsultancy";
import EditConsultancy from "../pages/consultancy/edit/EditConsultancy";
import ConsultanciesListWrapper from "../pages/consultancy/list/ConsultanciesListWrapper";
import ContractsLayoutWrapper from "../pages/contracts";
import ContractCreate from "../pages/contracts/create/ContractCreate";
import ContractEdit from "../pages/contracts/edit/ContractEdit";
import ContractsListWrapper from "../pages/contracts/list/ContractListWrapper";
import ContractChangelogsWrapper from "../pages/contracts/profile/views/activity-history/ContractActivityHistoryWrapper";
import ContractComments from "../pages/contracts/profile/views/comments/ContractComments";
import ContractDocuments from "../pages/contracts/profile/views/documents/ContractDocuments";
import ContractIncidenceWrapper from "../pages/contracts/profile/views/incidence/ContractIncidenceWrapper";
import ContractInfo from "../pages/contracts/profile/views/info/ContractInfo";
import ContractInvoicing from "../pages/contracts/profile/views/invoicing/ContractInvoicing";
import ContractStatesHistory from "../pages/contracts/profile/views/states-history/ContractStatesHistory";
import CorporationsWrapper from "../pages/corporations";
import CorporationsListWrapper from "../pages/corporations/list/CorporationsListWrapper";
import Dashboard from "../pages/dashboard/Dashboard";
import DepartmentsWrapper from "../pages/departments";
import DepartmentsListWrapper from "../pages/departments/list/DepartmentsListWrapper";
import GeneralConfigurationWrapper from "../pages/general-configuration";
import ConfigListWrapper from "../pages/general-configuration/ConfigListWrapper";
import IncidencesWrapper from "../pages/incidences";
import IncidenceCreate from "../pages/incidences/create/IncidenceCreate";
import IncidenceEdit from "../pages/incidences/edit/IncidenceEdit";
import IncidencesListWrapper from "../pages/incidences/list/IncidencesListWrapper";
import IncidenceComments from "../pages/incidences/profile/views/comments/IncidenceComments";
import IncidenceDocuments from "../pages/incidences/profile/views/documents/IncidenceDocuments";
import IncidenceInfo from "../pages/incidences/profile/views/info/IncidenceInfo";
import IncidenceTypesWrapper from "../pages/incidences/types";
import IncidenceTypeCreate from "../pages/incidences/types/create/IncidenceTypeCreate";
import IncidenceTypeEdit from "../pages/incidences/types/edit/IncidenceTypeEdit";
import IncidenceTypesListWrapper from "../pages/incidences/types/list/IncidenceTypesListWrapper";
import InvoicesWrapper from "../pages/invoicing";
import InvoiceEdit from "../pages/invoicing/edit/InvoiceEdit";
import InvoicesListWrapper from "../pages/invoicing/list/InvoicesListWrapper";
import InvoiceActivityHistoryWrapper from "../pages/invoicing/profile/views/activity-history/InvoiceActivityHistoryWrapper";
import InvoiceInfo from "../pages/invoicing/profile/views/info/InvoiceInfo";
import NetworksWrapper from "../pages/networks";
import NetworksListWrapper from "../pages/networks/list/NetworksListWrapper";
import OrderWrapper from "../pages/orders";
import OrdersCalendarPage from "../pages/orders/calendar/OrdersCalendarPage";
import OrderCreate from "../pages/orders/create/OrderCreate";
import OrderEdit from "../pages/orders/edit/OrderEdit";
import OrdersList from "../pages/orders/list/OrdersList";
import OrdersListWrapper from "../pages/orders/list/OrdersListWrapper";
import ContractAssignment from "../pages/orders/profile/views/assignments/ContractAssignment";
import OrderContracts from "../pages/orders/profile/views/contracts/OrderContracts";
import ContractExports from "../pages/orders/profile/views/exports/ContractExports";
import OrderInfo from "../pages/orders/profile/views/info/OrderInfo";
import OrderStats from "../pages/orders/profile/views/stats/OrderStats";
import OrderTypesWrapper from "../pages/orders/types";
import OrderTypeCreate from "../pages/orders/types/create/OrderTypeCreate";
import OrderTypeEdit from "../pages/orders/types/edit/OrderTypeEdit";
import OrderTypesListWrapper from "../pages/orders/types/list/OrderTypesListWrapper";
import OrganisationWrapper from "../pages/organisations";
import OrganisationCreate from "../pages/organisations/create/OrganisationCreate";
import OrganisationEdit from "../pages/organisations/edit/OrganisationEdit";
import OrganisationsListWrapper from "../pages/organisations/list/OrganisationsListWrapper";
import ProductsWrapper from "../pages/products";
import ProductCreate from "../pages/products/create/ProductCreate";
import ProductEdit from "../pages/products/edit/ProductEdit";
import ProductsListWrapper from "../pages/products/list/ProductsListWrapper";
import RolesWrapper from "../pages/roles";
import RoleCreate from "../pages/roles/create/RoleCreate";
import RoleEdit from "../pages/roles/edit/RoleEdit";
import RolesListWrapper from "../pages/roles/list/RolesListWrapper";
import SettingsWrapper from "../pages/settings";
import ContractsSettingsPage from "../pages/settings/ContractsSettingsPage";
import GeneralsSettingsPage from "../pages/settings/GeneralsSettingsPage";
import InvoicingsSettingsPage from "../pages/settings/InvoicingsSettingsPage";
import TutorSettingsPage from "../pages/settings/TutorSettingsPage";
import HolidaysSettingsPage from "../pages/settings/holidays/HolidaysSettingsPage";
import StatesWrapper from "../pages/states";
import StateCreate from "../pages/states/create/StateCreate";
import StateEdit from "../pages/states/edit/StateEdit";
import StatesListWrapper from "../pages/states/list/StatesListWrapper";
import StudentsWrapper from "../pages/students";
import StudentCreate from "../pages/students/create/StudentCreate";
import StudentEdit from "../pages/students/edit/StudentEdit";
import StudentsListWrapper from "../pages/students/list/StudentsListWrapper";
import StudentActivityHistoryWrapper from "../pages/students/profile/views/activity-history/StudentActivityHistoryWrapper";
import StudentInfo from "../pages/students/profile/views/info/StudentInfo";
import TutorsWrapper from "../pages/tutors";
import TutorCreate from "../pages/tutors/create/TutorCreate";
import TutorEdit from "../pages/tutors/edit/TutorEdit";
import TutorsListWrapper from "../pages/tutors/list/TutorsListWrapper";
import TutorContracts from "../pages/tutors/profile/views/contracts/TutorContracts";
import TutorDocuments from "../pages/tutors/profile/views/documents/TutorDocuments";
import TutorInfo from "../pages/tutors/profile/views/info/TutorInfo";
import TutorInvoices from "../pages/tutors/profile/views/invoices/TutorInvoices";
import TutorStats from "../pages/tutors/profile/views/stats/TutorStats";
import UsersWrapper from "../pages/users";
import UserCreate from "../pages/users/create/UserCreate";
import UserEdit from "../pages/users/edit/UserEdit";
import UserEditPermissions from "../pages/users/edit/UserEditPermissions";
import UsersListWrapper from "../pages/users/list/UsersListWrapper";
import UserChangelogsWrapper from "../pages/users/profile/views/activity-history/UserActivityHistoryWrapper";
import UserContractsWrapper from "../pages/users/profile/views/contracts/UserContractsWrapper";
import UserDocuments from "../pages/users/profile/views/documents/UserDocuments";
import UserInfo from "../pages/users/profile/views/info/UserInfo";
import UserPermissions from "../pages/users/profile/views/permissions/UserPermissions";
import UserStats from "../pages/users/profile/views/stats/UserStats";
import { menuRoutes } from "./menu";

const protectedRoutes = [
	/******************************************************
	 * Dashboard
	 ******************************************************/
	{
		path: menuRoutes.dashboard.path,
		element: <Dashboard />,
		access: {
			group: "dashboard",
			action: "get_dashboard_information"
		},
	},

	/******************************************************
	 * Organisations
	 ******************************************************/
	{
		path: menuRoutes.organisations.path,
		element: <OrganisationWrapper />,
		sub: [
			{
				element: <OrganisationsListWrapper />,
				access: {
					group: "companies",
					action: "list_company",
				},
				index: true,
			},
			{
				element: <OrganisationCreate />,
				path: menuRoutes.organisations.create,
				access: {
					group: "companies",
					action: "create_company"
				}
			},
			{
				element: <OrganisationEdit />,
				path: ':id/edit',
				access: {
					group: "companies",
					action: "edit_company"
				}
			}
		],
	},

	/******************************************************
	 * Roles
	 ******************************************************/
	{
		path: menuRoutes.roles.path,
		element: <RolesWrapper />,
		sub: [
			{
				element: <RolesListWrapper />,
				access: {
					group: 'roles',
					action: 'list_roles'
				},
				index: true,
			},
			{
				element: <RoleCreate />,
				path: menuRoutes.roles.create,
				access: {
					group: 'roles',
					action: 'create_roles'
				}
			},
			{
				element: <RoleEdit />,
				path: menuRoutes.roles.edit,
				access: {
					group: 'roles',
					action: 'edit_roles'
				}
			},
		]
	},

	/******************************************************
	 * Users
	 ******************************************************/
	{
		path: menuRoutes.users.path,
		element: <UsersWrapper />,
		sub: [
			{
				element: <UsersListWrapper />,
				access: {
					group: 'user',
					action: 'list_user'
				},
				index: true,
			},
			{
				element: <UserInfo />,
				path: menuRoutes.users.profile.path + menuRoutes.users.profile.info,
				access: {
					group: 'user',
					action: 'get_user'
				},
			},
			{
				element: <UserStats />,
				path: menuRoutes.users.profile.path + menuRoutes.users.profile.stats,
				access: {
					group: 'user',
					action: 'user_profile_stats'
				},
			},
			{
				element: <UserDocuments />,
				path: menuRoutes.users.profile.path + menuRoutes.users.profile.documents,
				access: {
					group: 'user',
					action: 'user_profile_documents'
				},
			},
			{
				element: <UserPermissions />,
				path: menuRoutes.users.profile.path + menuRoutes.users.profile.permissions,
				access: {
					group: 'user',
					action: 'user_profile_permissions'
				},
			},
			{
				element: <UserContractsWrapper />,
				path: menuRoutes.users.profile.path + menuRoutes.users.profile.contract_history,
				access: {
					group: 'user',
					action: 'user_profile_contract_history'
				},
			},
			{
				element: <UserChangelogsWrapper />,
				path: menuRoutes.users.profile.path + menuRoutes.users.profile.activity,
				access: {
					group: 'user',
					action: 'user_profile_activity'
				},
			},
			{
				element: <UserCreate />,
				path: menuRoutes.users.create,
				access: {
					group: 'user',
					action: 'create_user'
				}
			},
			{
				element: <UserEdit />,
				path: menuRoutes.users.edit,
				access: {
					group: 'user',
					action: 'edit_user'
				}
			},
			{
				element: <UserEditPermissions />,
				path: menuRoutes.users.edit_permissions,
				access: {
					group: 'user',
					action: 'edit_user'
				}
			},
		]
	},

	/******************************************************
	 * Tutors
	 ******************************************************/
	{
		path: menuRoutes.tutors.path,
		element: <TutorsWrapper />,
		sub: [
			{
				element: <TutorsListWrapper />,
				access: {
					group: 'tutors',
					action: 'list_tutors'
				},
				index: true,
			},
			{
				element: <TutorInfo />,
				path: menuRoutes.tutors.profile.path + menuRoutes.tutors.profile.info,
				access: {
					group: 'tutors',
					action: 'get_tutors'
				},
			},
			{
				element: <TutorStats />,
				path: menuRoutes.tutors.profile.path + menuRoutes.tutors.profile.stats,
				access: {
					group: 'tutors',
					action: 'tutors_stats'
				},
			},
			{
				element: <TutorDocuments />,
				path: menuRoutes.tutors.profile.path + menuRoutes.tutors.profile.documents,
				access: {
					group: 'tutors',
					action: 'tutors_documentation'
				},
			},
			{
				element: <TutorInvoices />,
				path: menuRoutes.tutors.profile.path + menuRoutes.tutors.profile.invoices,
				access: {
					group: 'tutors',
					action: 'tutors_invoices'
				},
			},
			{
				element: <TutorContracts />,
				path: menuRoutes.tutors.profile.path + menuRoutes.tutors.profile.contracts,
				access: {
					group: 'tutors',
					action: 'tutors_pending_contracts'
				},
			},
			{
				element: <TutorCreate />,
				path: menuRoutes.tutors.create,
				access: {
					group: 'tutors',
					action: 'create_tutors'
				}
			},
			{
				element: <TutorEdit />,
				path: menuRoutes.tutors.edit,
				access: {
					group: 'tutors',
					action: 'edit_tutors'
				}
			},
		]
	},

	/******************************************************
	 * Products
	 ******************************************************/
	{
		path: menuRoutes.products.path,
		element: <ProductsWrapper />,
		sub: [
			{
				element: <ProductsListWrapper />,
				access: {
					group: 'products',
					action: 'list_products'
				},
				index: true,
			},
			{
				element: <ProductCreate />,
				path: menuRoutes.products.create,
				access: {
					group: 'products',
					action: 'create_products'
				}
			},
			{
				element: <ProductEdit />,
				path: menuRoutes.products.edit,
				access: {
					group: 'products',
					action: 'edit_products'
				}
			}
		]
	},

	/******************************************************
	 * States
	 ******************************************************/
	{
		path: menuRoutes.states.path,
		element: <StatesWrapper />,
		sub: [
			{
				element: <StatesListWrapper />,
				access: {
					group: 'statuses',
					action: 'admin_statuses'
				},
				index: true,
			},
			{
				element: <StatesListWrapper />,
				path: menuRoutes.states.path + menuRoutes.states.list.contract,
			},
			{
				element: <StatesListWrapper />,
				path: menuRoutes.states.path + menuRoutes.states.list.contract_product,
			},
			{
				element: <StatesListWrapper />,
				path: menuRoutes.states.path + menuRoutes.states.list.contract_invoicing,
			},
			{
				element: <StatesListWrapper />,
				path: menuRoutes.states.path + menuRoutes.states.list.contract_incidence,
			},
			{
				element: <StatesListWrapper />,
				path: menuRoutes.states.path + menuRoutes.states.list.contract_commission,
			},
			{
				element: <StatesListWrapper />,
				path: menuRoutes.states.path + menuRoutes.states.list.expiration,
			},
			{
				element: <StatesListWrapper />,
				path: menuRoutes.states.path + menuRoutes.states.list.invoice,
			},
			{
				element: <StatesListWrapper />,
				path: menuRoutes.states.path + menuRoutes.states.list.incidence,
			},
			{
				element: <StatesListWrapper />,
				path: menuRoutes.states.path + menuRoutes.states.list.renovation,
			},
			{
				element: <StatesListWrapper />,
				path: menuRoutes.states.path + menuRoutes.states.list.order,
			},
			{
				element: <StateCreate />,
				path: menuRoutes.states.create,
				access: {
					group: 'statuses',
					action: 'create_statuses'
				}
			},
			{
				element: <StateEdit />,
				path: menuRoutes.states.edit,
				access: {
					group: 'statuses',
					action: 'edit_statuses'
				}
			},
		]
	},

	/******************************************************
	 * Invoices
	 ******************************************************/
	{
		path: menuRoutes.invoices.path,
		element: <InvoicesWrapper />,
		sub: [
			{
				element: <InvoicesListWrapper />,
				access: {
					group: 'invoices',
					action: 'list_invoices'
				},
				index: true,
			},
			{
				element: <InvoiceEdit />,
				path: menuRoutes.invoices.edit,
				access: {
					group: 'invoices',
					action: 'edit_invoices'
				}
			},
			{
				element: <InvoiceInfo />,
				path: menuRoutes.invoices.profile.path + menuRoutes.invoices.profile.info,
				access: {
					group: 'invoices',
					action: 'get_invoices'
				},
			},
			{
				element: <InvoiceActivityHistoryWrapper />,
				path: menuRoutes.invoices.profile.path + menuRoutes.invoices.profile.activityHistory,
				access: {
					group: 'invoices',
					action: 'invoice_changes_log',
				},
			}
		]
	},

	/******************************************************
	 * Corporations
	 ******************************************************/
	{
		path: menuRoutes.corporations.path,
		element: <CorporationsWrapper />,
		sub: [
			{
				element: <CorporationsListWrapper />,
				access: {
					group: 'corporation',
					action: 'list_corporation'
				},
				index: true,
			},
		]
	},

	/******************************************************
	 * Networks
	 ******************************************************/
	{
		path: menuRoutes.networks.path,
		element: <NetworksWrapper />,
		sub: [
			{
				element: <NetworksListWrapper />,
				access: {
					group: 'commercial_network',
					action: 'list_commercial_network'
				},
				index: true,
			},
		]
	},

	/******************************************************
	 * Departments
	 * ****************************************************/
	{
		path: menuRoutes.departments.path,
		element: <DepartmentsWrapper />,
		sub: [
			{
				element: <DepartmentsListWrapper />,
				access: {
					group: 'departments',
					action: 'list_departments'
				},
				index: true,
			},
		]
	},

	/******************************************************
	 * Clients
	 ******************************************************/
	{
		path: menuRoutes.clients.path,
		element: <ClientsWrapper />,
		sub: [
			{
				element: <ClientsListWrapper />,
				access: {
					group: 'clients',
					action: 'list_clients'
				},
				index: true,
			},
			{
				element: <ClientInfo />,
				path: menuRoutes.clients.profile.path + menuRoutes.clients.profile.info,
				access: {
					group: 'clients',
					action: 'get_clients'
				},
			},
			{
				element: <ClientStudents />,
				path: menuRoutes.clients.profile.path + menuRoutes.clients.profile.students,
				access: {
					group: 'clients',
					action: 'get_clients_students'
				},
			},
			{
				element: <ClientProducts />,
				path: menuRoutes.clients.profile.path + menuRoutes.clients.profile.products,
				access: {
					group: 'clients',
					action: 'get_clients_products'
				},
			},
			{
				element: <ClientContractsWrapper />,
				path: menuRoutes.clients.profile.path + menuRoutes.clients.profile.contracts,
				access: {
					group: 'clients',
					action: 'get_clients_contracts'
				},
			},
			{
				element: <ClientConsultancy />,
				path: menuRoutes.clients.profile.path + menuRoutes.clients.profile.consultancy,
				access: {
					group: 'clients',
					action: 'get_clients_consultancy'
				},
			},
			{
				element: <ClientInvoicesWrapper />,
				path: menuRoutes.clients.profile.path + menuRoutes.clients.profile.invoices,
				access: {
					group: 'clients',
					action: 'get_clients_invoicing'
				},
			},
			{
				element: <ClientCalls />,
				path: menuRoutes.clients.profile.path + menuRoutes.clients.profile.calls,
				access: {
					group: 'clients',
					action: 'get_clients_calls'
				},
			},
			{
				element: <ClientComments />,
				path: menuRoutes.clients.profile.path + menuRoutes.clients.profile.comments,
				access: {
					group: 'clients',
					action: 'get_clients_comments'
				},
			},
			{
				element: <ClientCreditHistory />,
				path: menuRoutes.clients.profile.path + menuRoutes.clients.profile.creditHistory,
				access: {
					group: 'clients',
					action: 'get_clients_credit_history'
				},
			},
			{
				element: <ClientActivityHistoryWrapper />,
				path: menuRoutes.clients.profile.path + menuRoutes.clients.profile.activityHistory,
				access: {
					group: 'clients',
					action: 'get_clients_activity_history'
				},
			},
			{
				element: <ClientCreate />,
				path: menuRoutes.clients.create,
				access: {
					group: 'clients',
					action: 'create_clients'
				}
			},
			{
				element: <ClientEdit />,
				path: menuRoutes.clients.edit,
				access: {
					group: 'clients',
					action: 'edit_clients'
				}
			},
		]
	},

	/******************************************************
	 * Students
	 ******************************************************/
	{
		path: menuRoutes.students.path,
		element: <StudentsWrapper />,
		sub: [
			{
				element: <StudentsListWrapper />,
				access: {
					group: 'students',
					action: 'list_students'
				},
				index: true,
			},
			{
				element: <StudentInfo />,
				path: menuRoutes.students.profile.path + menuRoutes.students.profile.info,
				access: {
					group: 'students',
					action: 'get_students'
				},
			},
			{
				element: <StudentActivityHistoryWrapper />,
				path: menuRoutes.students.profile.path + menuRoutes.students.profile.activityHistory,
				access: {
					group: 'students',
					action: 'student_changes_log'
				},
			},
			{
				element: <StudentCreate />,
				path: menuRoutes.students.create,
				access: {
					group: 'students',
					action: 'create_students'
				}
			},
			{
				element: <StudentEdit />,
				path: menuRoutes.students.edit,
				access: {
					group: 'students',
					action: 'edit_students'
				}
			}
		]
	},

	/******************************************************
	 * Consultancies
	 ******************************************************/
	{
		path: menuRoutes.consultancies.path,
		element: <ConsultanciesWrapper />,
		sub: [
			{
				element: <ConsultanciesListWrapper />,
				access: {
					group: 'consultancies',
					action: 'list_consultancies'
				},
				index: true,
			},
			{
				element: <CreateConsultancy />,
				path: menuRoutes.consultancies.create,
				access: {
					group: 'consultancies',
					action: 'create_consultancies'
				}
			},
			{
				element: <EditConsultancy />,
				path: menuRoutes.consultancies.edit,
				access: {
					group: 'consultancies',
					action: 'edit_consultancies'
				}
			}
		]
	},

	/******************************************************
	 * General configuration
	 ******************************************************/
	{
		path: '',
		element: <GeneralConfigurationWrapper />,
		sub: [
			{
				element: <ConfigListWrapper />,
				path: menuRoutes.generalConfiguration.path + menuRoutes.generalConfiguration.educationalLevels,
			},
			{
				element: <ConfigListWrapper />,
				path: menuRoutes.generalConfiguration.path + menuRoutes.generalConfiguration.professionalCategories,
			},
			{
				element: <ConfigListWrapper />,
				path: menuRoutes.generalConfiguration.path + menuRoutes.generalConfiguration.quotationGroups,
			},
			{
				element: <ConfigListWrapper />,
				path: menuRoutes.generalConfiguration.path + menuRoutes.generalConfiguration.campaigns,
			},
		]
	},

	/******************************************************
	 * Contracts
	 ******************************************************/
	{
		path: menuRoutes.contracts.path,
		element: <ContractsLayoutWrapper />,
		sub: [
			{
				element: <ContractsListWrapper />,
				path: menuRoutes.contracts.path,
				access: {
					group: 'contracts',
					action: 'list_contracts'
				},
				index: true,
			},
			{
				element: <ContractCreate />,
				path: menuRoutes.contracts.create,

			},
			{
				element: <ContractEdit />,
				path: menuRoutes.contracts.edit,
				access: {
					group: 'contracts',
					action: 'edit_contracts'
				}
			},
			{
				element: <ContractInfo />,
				path: menuRoutes.contracts.profile.path + menuRoutes.contracts.profile.info,
				access: {
					group: 'contracts',
					action: 'get_contracts'
				},
			},
			{
				element: <ContractInvoicing />,
				path: menuRoutes.contracts.profile.path + menuRoutes.contracts.profile.invoicing,
				access: {
					group: 'contracts',
					action: 'get_contract_invoicing'
				},
			},
			{
				element: <ContractDocuments />,
				path: menuRoutes.contracts.profile.path + menuRoutes.contracts.profile.documents,
				access: {
					group: 'contracts',
					action: 'get_contracts'
				},
			},
			{
				element: <ContractIncidenceWrapper />,
				path: menuRoutes.contracts.profile.path + menuRoutes.contracts.profile.incidences,
				access: {
					group: 'contracts',
					action: 'list_contract_incidences'
				},
			},
			{
				element: <ContractStatesHistory />,
				path: menuRoutes.contracts.profile.path + menuRoutes.contracts.profile.statesHistory,
				access: {
					group: 'contracts',
					action: 'show_contract_status_history'
				},
			},
			{
				element: <ContractChangelogsWrapper />,
				path: menuRoutes.contracts.profile.path + menuRoutes.contracts.profile.changelogs,
				access: {
					group: 'contracts',
					action: 'contract_changes_log'
				},
			},
			{
				element: <ContractComments />,
				path: menuRoutes.contracts.profile.path + menuRoutes.contracts.profile.comments,
				access: {
					group: 'contracts',
					action: 'get_contract_comments'
				},
			},
		]
	},

	/******************************************************
	 * Settings
	 ******************************************************/
	{
		path: menuRoutes.settings.path,
		element: <SettingsWrapper />,
		sub: [
			{
				element: <GeneralsSettingsPage />,
				path: menuRoutes.settings.path,
			},
			{
				element: <ContractsSettingsPage />,
				path: menuRoutes.settings.path + menuRoutes.settings.contracts,
				// access: {
				//     group: 'settings',
				//     action: 'admin_settings_contracts'
				// },
			},
			{
				element: <InvoicingsSettingsPage />,
				path: menuRoutes.settings.path + menuRoutes.settings.invoices,
				// access: {
				//     group: 'settings',
				//     action: 'admin_settings_invoices'

			},
			{
				element: <TutorSettingsPage />,
				path: menuRoutes.settings.path + menuRoutes.settings.tutors,
				// access: {
				//     group: 'settings',
				//     action: 'admin_settings_tutors'

			},
			{
				element: <HolidaysSettingsPage />,
				path: menuRoutes.settings.path + menuRoutes.settings.holidays,
				// access: {
				//     group: 'settings',
				//     action: 'admin_settings_holidays'
				// },
			}
		]
	},

	/******************************************************
	 * Incidences
	 ******************************************************/
	{
		path: menuRoutes.incidences.path,
		element: <IncidencesWrapper />,
		sub: [
			{
				element: <IncidencesListWrapper />,
				access: {
					group: 'incidences',
					action: 'list_incidences'
				},
				index: true,
			},
			{
				element: <IncidenceCreate />,
				path: menuRoutes.incidences.path + menuRoutes.incidences.create,
				access: {
					group: 'incidences',
					action: 'create_incidences'
				}
			},
			{
				element: <IncidenceEdit />,
				path: menuRoutes.incidences.path + menuRoutes.incidences.edit,
				access: {
					group: 'incidences',
					action: 'edit_incidences'
				}
			},
			{
				element: <IncidenceInfo />,
				path: menuRoutes.incidences.profile.path + menuRoutes.incidences.profile.info,
				access: {
					group: 'incidences',
					action: 'get_incidences'
				},
			},
			{
				element: <IncidenceDocuments />,
				path: menuRoutes.incidences.profile.path + menuRoutes.incidences.profile.documents,
				access: {
					group: 'incidences',
					action: 'admin_incidence_documents'
				},
			},
			{
				element: <IncidenceComments />,
				path: menuRoutes.incidences.profile.path + menuRoutes.incidences.profile.comments,
				access: {
					group: 'incidences',
					action: 'admin_incidence_comments'
				},
			}
		]
	},

	/******************************************************
	 * Incidences Types
	 ******************************************************/
	{
		path: menuRoutes.incidenceTypes.path,
		element: <IncidenceTypesWrapper />,
		sub: [
			{
				element: <IncidenceTypesListWrapper />,
				access: {
					group: 'incidence_types',
					action: 'list_incidence_types'
				},
				index: true,
			},
			{
				element: <IncidenceTypeCreate />,
				path: menuRoutes.incidenceTypes.create,
				access: {
					group: 'incidence_types',
					action: 'create_incidence_types'
				}
			},
			{
				element: <IncidenceTypeEdit />,
				path: menuRoutes.incidenceTypes.edit,
				access: {
					group: 'incidence_types',
					action: 'edit_incidence_types'
				}
			}
		]
	},

	/******************************************************
	 * Orders
	 ******************************************************/
	{
		path: menuRoutes.orders.path,
		element: <OrderWrapper />,
		sub: [
			{
				element: <OrdersListWrapper />,
				access: {
					group: 'orders',
					action: 'list_orders'
				},
				sub: [
					{
						element: <OrdersList />,
						path: menuRoutes.orders.path + menuRoutes.orders.list,
					},
					{
						element: <OrdersCalendarPage />,
						path: menuRoutes.orders.path + menuRoutes.orders.calendar,
					},
				]
			},
			{
				element: <OrderCreate />,
				path: menuRoutes.orders.path + menuRoutes.orders.create,
				access: {
					group: 'orders',
					action: 'create_orders'
				}
			},
			{
				element: <OrderEdit />,
				path: menuRoutes.orders.path + menuRoutes.orders.edit,
				access: {
					group: 'orders',
					action: 'edit_orders'
				}
			},
			{
				element: <OrderInfo />,
				path: menuRoutes.orders.profile.path + menuRoutes.orders.profile.info,
				access: {
					group: 'orders',
					action: 'get_orders'
				},
			},
			{
				element: <OrderStats />,
				path: menuRoutes.orders.profile.path + menuRoutes.orders.profile.stats,
				access: {
					group: 'orders',
					action: 'orders_stats'
				},
			},
			{
				element: <OrderContracts />,
				path: menuRoutes.orders.profile.path + menuRoutes.orders.profile.contracts,
				access: {
					group: 'orders',
					action: 'orders_contracts'
				},
			},
			{
				element: <ContractAssignment />,
				path: menuRoutes.orders.profile.path + menuRoutes.orders.profile.assignments,
				access: {
					group: 'orders',
					action: 'assign_order_contracts_tutor'
				},
			},
			{
				element: <ContractExports />,
				path: menuRoutes.orders.profile.path + menuRoutes.orders.profile.exports,
				access: {
					group: 'orders',
					action: 'export_order_contracts'
				},
			},
		]
	},

	/******************************************************
	 * Order Types
	 ******************************************************/
	{
		path: menuRoutes.orderTypes.path,
		element: <OrderTypesWrapper />,
		sub: [
			{
				element: <OrderTypesListWrapper />,
				access: {
					group: 'order_types',
					action: 'list_order_types'
				},
				index: true,
			},
			{
				element: <OrderTypeCreate />,
				path: menuRoutes.orderTypes.create,
				access: {
					group: 'order_types',
					action: 'create_order_types'
				}
			},
			{
				element: <OrderTypeEdit />,
				path: menuRoutes.orderTypes.edit,
				access: {
					group: 'order_types',
					action: 'edit_order_types'
				}
			}
		]
	},

	/******************************************************
	 * Tutors admin panel
	 ******************************************************/
	{
		path: '',
		element: <TutorsLayoutWrapper />,
		sub: [
			{
				element: <MyTutorials />,
				path: menuRoutes.tutor_panel.tutorials,
			},
			{
				element: <ListMyInvoicesWrapper />,
				path: menuRoutes.tutor_panel.invoices,
			},
			{
				element: <MyCalendar />,
				path: menuRoutes.tutor_panel.calendar,
			},
			{
				element: <ListMyDocumentsWrapper />,
				path: menuRoutes.tutor_panel.documents,
			},
			{
				element: <TutorProfile />,
				path: menuRoutes.tutor_panel.profile,
				index: true,
			},
		]
	},
];

const contents = [...protectedRoutes];
export default contents;