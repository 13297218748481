import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Text } from 'rizzui';
import { CustomDonutGraph } from '../../../../../../components/charts/CustomDonutChart';
import useFetch from '../../../../../../hooks/useFetch';
import { OrderService } from '../../../../../../services/order/orderService';
import { generateColor } from '../OrderStats';
import TreeMapChart from './TreeMapChart';

interface ProductsTreeChartsProps { }

const ProductsTreeCharts: React.FC<ProductsTreeChartsProps> = ({ }) => {

    // HOOKS

    const { id } = useParams<{ id: string }>();

    // STATES

    const [series, setSeries] = useState<{ series: number[], labels: string[], colors: string[], ids: string[], totalNonAssignedProducts: number }>({ series: [], labels: [], colors: [], ids: [], totalNonAssignedProducts: 0 });
    const [productId, setProductId] = useState<string | undefined>(undefined);
    const [totalValue, setTotalValue] = useState<{ value: number, label: string } | undefined>(undefined);

    const [treeData] = useFetch(useCallback(async () => {
        if (!id) return null;
        const response = (await (new OrderService).getContractQtyByProduct(id as string)).getResponseData();
        return response;
    }, [id]));

    const [pieData] = useFetch(useCallback(async () => {
        if (!id) return null;
        const response = (await (new OrderService).getNonAssignedProducts(id as string)).getResponseData();
        return response;
    }, [id]));

    // FUNCTIONS

    const handleClick = (value: number, label: string, id?: string) => {
        setProductId(id);
        setTotalValue({
            value: (totalValue?.value && totalValue?.value !== pieData?.totalNonAssignedProducts && productId === id) ? pieData?.totalNonAssignedProducts : value,
            label: (totalValue?.label && totalValue?.label !== 'No asignados' && productId === id) ? 'No asignados' : label,
        });
    };

    // USE EFFECT

    useEffect(() => {
        if (pieData) {
            setSeries({
                series: pieData.products?.map((item: any) => item.productQuantity),
                labels: pieData.products?.map((item: any) => item.productName),
                colors: pieData.products?.map((item: any) => generateColor(item.productName)),
                ids: pieData.products?.map((item: any) => item.productId),
                totalNonAssignedProducts: pieData.totalNonAssignedProducts,
            });
            setTotalValue({ value: pieData.totalNonAssignedProducts, label: 'No asignados' });
        }
    }, [pieData]);

    // RENDER

    return (
        <div className="grid grid-cols-6 gap-4 mt-10">
            <div className='col-span-6 md:col-span-4'>
                {treeData?.length > 0 && (
                    <>
                        <Text className="font-bold">Acciones formativas en este pedido</Text>
                        <TreeMapChart data={treeData} />
                    </>
                )}
            </div>

            <div className='col-span-6 md:col-span-2'>
                {series.series?.length > 0 && (
                    <>
                        <Text className="text-center font-bold mb-5">Acciones formativas con tutor pendiente</Text>
                        <CustomDonutGraph data={series} total={totalValue} onClick={handleClick} />
                    </>
                )}
            </div>
        </div>
    );
};

export default ProductsTreeCharts;