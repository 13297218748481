import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "sonner";
import * as yup from "yup";
import ButtonPopover from "../../components/buttons/ButtonPopover";
import CustomSelect from "../../components/forms/CustomSelect";
import RequiredInput from "../../components/forms/InputHOC";
import { Loader } from "../../components/loader/SpinnerLogo";
import { usePrivilege } from "../../components/priviledge/PriviledgeProvider";
import useDepartments, { useUnassignedDepartments } from "../../hooks/api-calls/useDepartments";
import useNetworks from "../../hooks/api-calls/useNetworks";
import useUsers from "../../hooks/api-calls/useUsers";
import useFetch from "../../hooks/useFetch";
import useHandleErrors from "../../hooks/useHandleErrors";
import Page from "../../layout/Page/Page";
import FormGroup from "../../layout/shared/form-group";
import { NetworkService } from "../../services/network/networkService";
import { Network } from "../../type/entities/network-type";
import FormFooter from "../_layout/_footers/form-footer";

interface CreateFormProps {
    id: string | undefined;
    setNodeId: (value: string | undefined) => void;
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    refetch: () => void;
}

const schema = yup.object({
    name: yup.string().min(1, 'Demasiado corto').max(50, 'Demasiado largo').required('Campo obligatorio'),
    code: yup.string().min(1, 'Demasiado corto').max(50, 'Demasiado largo').required('Campo obligatorio'),
    ipAddress: yup.string().min(1, 'Demasiado corto').max(50, 'Demasiado largo').nullable(),
    users: yup.array().of(yup.string()).nullable(),
    departments: yup.array().of(yup.string()).nullable(),
    parentCommercialNetworkId: yup.string().nullable(),
});

const NetworkForm: React.FC<CreateFormProps> = ({ id, setNodeId, isOpen, setIsOpen, refetch }) => {

    const { userCan } = usePrivilege();
    const { handleErrors } = useHandleErrors();
    const { getNetworksList } = useNetworks({ active: true });
    const { getUsersList } = useUsers({ active: true });
    const { getDepartmentsList } = useDepartments({ active: true });
    const { getUnassignedDepartmentsList } = useUnassignedDepartments({ active: true });
    const service = new NetworkService();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [data] = useFetch(useCallback(async () => {
        if (!id) return null;
        const response = await service.getNetworkById(id as string);
        return response.getResponseData() as Network;
    }, [id]));

    const mode = data ? 'Editar' : 'Crear';

    const submit = async (values: any) => {
        setIsLoading(true);

        if (mode === 'Crear') {
            try {
                const response = await service.createNetwork(values);
                const responseData = response.getResponseData();

                if (responseData.success) {
                    toast.success("Red comercial creada correctamente");
                    refetch();
                    setIsOpen(false);
                } else {
                    responseData.data.errors.forEach((error: any) => {
                        toast.error(error.message);
                    });
                }
            } catch (error: any) {
                toast.error(error.message);
            } finally {
                setIsLoading(false);
            }
        } else {
            try {
                const response = await service.editNetwork(values);
                const responseData = response.getResponseData();

                if (responseData.success) {
                    toast.success("Red comercial editada correctamente");
                    refetch();
                    setIsOpen(false);
                } else {
                    responseData.data.errors.forEach((error: any) => {
                        toast.error(error.message);
                    });
                }
            } catch (error: any) {
                toast.error(error.message);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleDelete = async (id: string) => {
        const response = await (await service.deleteNetwork(id)).getResponseData();
        if (response.success) {
            toast.success('Red comercial eliminada correctamente');
            setIsOpen(false);
            setNodeId(undefined);
            refetch();
        } else {
            handleErrors(response);
        }
    };

    const formik = useFormik({
        initialValues: {
            commercialNetworkId: id ?? '',
            name: data?.name ?? '',
            code: data?.code ?? '',
            ipAddress: data?.ipAddress ?? '',
            users: data?.user?.map((u: any) => u.id) ?? [],
            departments: data?.department?.map((d: any) => d.id) ?? [],
            parentCommercialNetworkId: data?.parentCommercialNetwork?.id ?? null,
        },
        validationSchema: schema,
        validateOnBlur: false,
        //validateOnChange: false,
        onSubmit: values => { submit(values); },
    });

    useEffect(() => {
        if (data) {
            formik.setValues({
                commercialNetworkId: data.id,
                name: data.name,
                code: data.code,
                ipAddress: data.ipAddress,
                users: data.user?.map((u: any) => u.id),
                departments: data.departments?.map((d: any) => d.department?.id),
                parentCommercialNetworkId: data.parentCommercialNetwork?.id ?? null,
            });
        }
    }, [data]);

    useEffect(() => {
        if (id === undefined) {
            formik.resetForm();
        }
    }, [id]);

    if (isOpen && id && !data) return <Loader />;

    const getContent = () => {
        return (
            <div className="@container">
                <form onSubmit={formik.handleSubmit}>
                    <div className="mb-10 grid gap-3 @2xl:gap-9 @3xl:gap-11 font-medium">
                        {id && isOpen && userCan('delete_commercial_network', 'commercial_network') && (
                            <div className="flex justify-end gap-10 pt-2">
                                <ButtonPopover
                                    title='Eliminar red comercial'
                                    description='Esto eliminará todo lo que dependa de esta red comercial'
                                    onClick={() => handleDelete(id)}
                                    variant='text'
                                    mobileView
                                    btnClassName=" px-4 py-2 gap-2"
                                />
                            </div>
                        )}

                        <FormGroup
                            title="Información General"
                            description="Datos de la red comercial"
                            className="pt-4"
                            titleCols="@md:col-span-2"
                            childCols="@md:col-span-10"
                        >
                            <RequiredInput
                                id='name'
                                label="Nombre"
                                formik={formik}
                            />

                            <CustomSelect
                                isMulti
                                isSearchable
                                id={'users'}
                                label="Usuarios"
                                value={formik.values.users ? getUsersList().filter((u: any) => formik.values.users.includes(u.value)) : []}
                                options={getUsersList()}
                                onChange={(e: any) => {
                                    const selectedIds = e ? e.map((option: any) => option.value) : [];
                                    formik.setFieldValue('users', selectedIds);
                                }}
                            />

                            <CustomSelect
                                isMulti
                                isSearchable
                                id={'departments'}
                                label="Departamentos"
                                value={formik.values.departments ? getDepartmentsList().filter((d: any) => formik.values.departments.includes(d.value)) : []}
                                options={getUnassignedDepartmentsList()}
                                onChange={(e: any) => {
                                    const selectedIds = e ? e.map((option: any) => option.value) : [];
                                    formik.setFieldValue('departments', selectedIds);
                                }}
                            />

                            <CustomSelect
                                isSearchable
                                isClearable
                                id={'parentCommercialNetworkId'}
                                label="Red Comercial Padre"
                                value={{ value: formik.values.parentCommercialNetworkId, label: getNetworksList()?.find((g: any) => g.value === formik.values.parentCommercialNetworkId)?.label }}
                                options={getNetworksList().filter((n: any) => n.value !== id)}
                                onChange={(e: any) => { formik.setFieldValue('parentCommercialNetworkId', e?.value); }}
                            />

                            <RequiredInput
                                id='code'
                                label="Código"
                                formik={formik}
                            />

                            <RequiredInput
                                id='ipAddress'
                                label="Dirección IP"
                                required={false}
                                formik={formik}
                            />
                        </FormGroup>
                    </div>
                </form>
            </div>
        )
    };

    return (
        <>
            {isOpen && (
                <>
                    <Page container="fluid">
                        {(mode === "Editar" && data) && getContent()}
                        {mode === "Crear" && getContent()}
                        <FormFooter
                            customClassName={"border-0 py-1"}
                            customBg={"bg-transparent"}
                            submitBtnText={mode + ' Red Comercial'}
                            handleCancelBtn={() => { setIsOpen(false); setNodeId(undefined); formik.resetForm(); }}
                            handleSubmitBtn={formik.submitForm} isLoading={isLoading}
                        />
                    </Page>
                </>
            )}
        </>
    )
};

export default NetworkForm;