import { Autocomplete, TextField } from "@mui/material";
import { useState } from "react";

interface YearSelectorProps {
    defaultValue?: number;
    onChange: (newValue: number) => void;
}

const YearSelector: React.FC<YearSelectorProps> = ({ defaultValue, onChange }) => {
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 61 }, (_, i) => currentYear - 50 + i);

    const [selectedYear, setSelectedYear] = useState<number | undefined>(defaultValue || undefined);

    return (
        <div className="w-28">
            <Autocomplete
                options={years}
                value={selectedYear}
                onChange={(_, newValue) => { onChange(newValue); setSelectedYear(newValue) }}
                renderInput={(params) => <TextField {...params} />}
                disableClearable
                openOnFocus
                noOptionsText="No hay opciones"
                getOptionLabel={(option) => option.toString()}
            />
        </div>
    );
};

export default YearSelector;