import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ContractProfileState {
    selectedView: string;
    contractId: string | null;
}

const initialState: ContractProfileState = {
    selectedView: '/info',
    contractId: '',
};

const contractProfileSlice = createSlice({
    name: 'contractProfile',
    initialState,
    reducers: {
        setSelectedView(state, action: PayloadAction<string>) {
            state.selectedView = action.payload;
        },
        setContractId(state, action: PayloadAction<string | null>) {
            state.contractId = action.payload;
        }
    },
});

export const { setSelectedView, setContractId } = contractProfileSlice.actions;
export default contractProfileSlice.reducer;