import { formatDistance } from "date-fns";
import { es } from "date-fns/locale";
import { capitalize } from "lodash";
import moment from "moment";
import { useCallback, useContext, useState } from "react";
import { HiOutlineEye } from "react-icons/hi";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { ActionIcon, Button, Text, Tooltip } from "rizzui";
import { toast } from "sonner";
import DeletePopover from "../../../components/buttons/DeletePopover";
import CustomSelect from "../../../components/forms/CustomSelect";
import StatusDropdown from "../../../components/forms/StatusDropdown";
import CustomModal from "../../../components/modal/CustomModal";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import { useFiltersPR } from "../../../components/providers/FiltersProvider";
import CustomTable from "../../../components/table/CustomTable";
import { FilterDrawerView } from "../../../components/table/components/TableFilter";
import useOrderStatuses from "../../../hooks/api-calls/orders/useOrderStatuses";
import useFetch from "../../../hooks/useFetch";
import useHandleErrors from "../../../hooks/useHandleErrors";
import Page from '../../../layout/Page/Page';
import { menuRoutes } from "../../../router/menu";
import { OrderService } from "../../../services/order/orderService";
import { IncidencesApiResponse } from "../../../type/entities/incidence-type";
import cn from "../../../utils/classNames";
import OrdersFilters from "./OrdersFilters";

const columnOrder = [
    'id',
    'code',
    'orderTypes',
    'currentStatus',
    'currentStatusDate',
    'startDate',
    'endDate',
    'collectionDate',
    'orderDate',
    'calendarDays',
    'workingDays',
    'createdAt',
    'updatedAt',
];

const OrdersList = () => {

    const service = new OrderService();
    const { handleErrors } = useHandleErrors();
    const { userCan } = useContext(PrivilegeContext);
    const navigate = useNavigate();
    const { getOrderStatusesList } = useOrderStatuses();

    const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters } = useFiltersPR();
    const [openFilters, setOpenFilters] = useState<boolean>(false);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [selectedRows, setSelectedRows] = useState<string[]>([]);
    const [selectedStatus, setSelectedStatus] = useState<string>('');

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = await service.listOrders(filters);
        return response.getResponseData() as IncidencesApiResponse;
    }, [filters]));

    /**
     * Order the data to match the column order
     */
    const orderedData = () => data?.data.map((row: any) => {
        const orderedRow: any = {};
        columnOrder.forEach((key) => {
            orderedRow[key] = row[key];
        });
        return orderedRow;
    });

    const handleDelete = async (id: string) => {
        try {
            const response = await (await service.deleteOrder(id)).getResponseData();
            if (response.success) {
                refetch();
                toast.success('Pedido eliminado correctamente');
            } else {
                handleErrors(response);
            }
        } catch (error) {
            toast.error('Error al eliminar el pedido');
        }
    };

    const handleMultiDelete = async (ids: string[]) => {
        try {
            const response = await (await service.deleteMultiOrders(ids)).getResponseData();
            if (response.success) {
                refetch();
                toast.success('Pedidos eliminados correctamente');
            } else {
                handleErrors(response, refetch);
            }
        } catch (error) {
            toast.error('Error al eliminar los pedidos');
        }
    };

    const handleChangeStatus = async (orderId: string, statusId: string) => {
        try {
            const response = await (await service.toggleOrderState(orderId, statusId)).getResponseData();
            if (response.success) {
                toast.success('Estado del pedido cambiado correctamente');
            } else {
                handleErrors(response);
            }
        } catch (error) {
            handleErrors(error);
        } finally {
            refetch();
        }
    };

    const handleMultiStatusesChange = async (selectedRows: any) => {
        try {
            const response = await (await service.toggleMultiOrderState(selectedRows, selectedStatus)).getResponseData();
            if (response.success) {
                refetch();
                toast.success('Estado de los pedidos cambiado correctamente');
            } else {
                handleErrors(response, refetch);
            }
        } catch (error) {
            toast.error('Error al cambiar el estado de los pedidos');
        } finally {
            setOpenModal(false);
        }
    };

    return (
        <Page container='fluid'>
            {data !== undefined && (
                <CustomTable
                    id={'incidence-types-table'}
                    columnOrder={columnOrder}
                    data={orderedData()}
                    isLoading={loading}
                    search={false}
                    searchNumber
                    columnsNotShown={['currentStatusDate']}
                    overrideColumns={[
                        {
                            key: 'code',
                            render: (row: any) => {
                                return (
                                    userCan('get_orders', 'orders')
                                        ? (
                                            <Link to={`${menuRoutes.orders.path}/${row.row.original.id}/profile${menuRoutes.orders.profile.info}`}>
                                                <Text className='font-bold text-primary'>{row.row.original.code}</Text>
                                            </Link>
                                        )
                                        : <Text className='font-bold text-primary'>{row.row.original.code}</Text>
                                );
                            }
                        },
                        {
                            key: 'orderTypes',
                            render: (row: any) => {
                                return row.row.original?.orderTypes?.name || 'N/A';
                            }
                        },
                        {
                            key: 'currentStatus',
                            render: (row: any) => {
                                const orderStatusDate = row.row.original?.currentStatusDate?.date;
                                const orderStatus = row.row.original?.currentStatus;
                                const orderId = row.row.original?.id;
                                return (
                                    <div className='flex flex-col gap-2 justify-content-center text-center' key={row.row.original.id + orderStatus.id}>
                                        {(orderId && orderStatusDate && orderStatus) ? (
                                            <StatusDropdown
                                                entityId={row.row.original?.id}
                                                title="¿Estas seguro de cambiar el estado del pedido?"
                                                statesOptions={getOrderStatusesList()}
                                                currentState={orderStatus}
                                                currentStateDate={orderStatusDate}
                                                handleStateChange={(entityId: string, statusId: string) => handleChangeStatus(orderId, statusId)}
                                                hasComments={false}
                                            />
                                        ) : "N/A"}
                                    </div>
                                );
                            }
                        },
                        {
                            key: 'startDate',
                            render: (row: any) => {
                                const date = row.row.original?.startDate?.date;
                                return date ? moment(date).format('DD/MM/YY') : 'N/A';
                            },
                        },
                        {
                            key: 'endDate',
                            render: (row: any) => {
                                const date = row.row.original?.endDate?.date;
                                return date ? moment(date).format('DD/MM/YY') : 'N/A';
                            },
                        },
                        {
                            key: 'collectionDate',
                            render: (row: any) => {
                                const date = row.row.original?.collectionDate?.date;
                                return date ? moment(date).format('DD/MM/YY') : 'N/A';
                            },
                        },
                        {
                            key: 'orderDate',
                            render: (row: any) => {
                                const date = row.row.original?.orderDate?.date;
                                return date ? moment(date).format('DD/MM/YY') : 'N/A';
                            },
                        },
                        {
                            key: 'Actualización',
                            render: (row: any) => {
                                const date = row.row.original?.updatedAt?.date;
                                return date ? capitalize(formatDistance(new Date(date), new Date(), { addSuffix: true, locale: es })) : 'Nunca';
                            },
                        },
                        {
                            key: 'Creación',
                            render: (row: any) => {
                                const date = row.row.original?.createdAt?.date;
                                return date ? capitalize(formatDistance(new Date(date), new Date(), { addSuffix: true, locale: es })) : 'Nunca';
                            },
                        },

                    ]}
                    actions={[
                        {
                            label: 'View',
                            permissions: { group: 'orders', permission: 'get_orders' },
                            render: (row: any) => {
                                return (
                                    <Tooltip
                                        size="sm"
                                        content={'Ver detalles'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <Link
                                            to={`${menuRoutes.orders.path}/${row.id}/profile${menuRoutes.orders.profile.info}`}>
                                            <ActionIcon
                                                as="span"
                                                size="sm"
                                                variant="outline"
                                                className="hover:!border-gray-900 hover:text-gray-700"
                                            >
                                                <HiOutlineEye className="h-4 w-4" size={20} />
                                            </ActionIcon>
                                        </Link>
                                    </Tooltip>
                                );
                            },
                        },
                        {
                            label: 'Edit',
                            permissions: { group: 'orders', permission: 'edit_orders' },
                            render: (row: any) => {
                                return (
                                    <Tooltip
                                        size="sm"
                                        content={'Editar pedido'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <Link to={`${menuRoutes.orders.path}/${row.id}/edit`}>
                                            <ActionIcon
                                                as="span"
                                                size="sm"
                                                variant="outline"
                                                className="hover:!border-gray-900 hover:text-gray-700"
                                            >
                                                <MdOutlineModeEditOutline className="h-4 w-4" size={20} />
                                            </ActionIcon>
                                        </Link>
                                    </Tooltip>
                                );
                            },
                        },
                        {
                            label: 'Delete',
                            permissions: { group: 'orders', permission: 'delete_orders' },
                            render: (row: any) => {
                                return (
                                    <Tooltip
                                        size="sm"
                                        content={'Eliminar'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <div>
                                            <DeletePopover
                                                title={`Eliminar Pedido`}
                                                description={`¿Estás seguro de que deseas eliminar el pedido ${row.code}?`}
                                                onDelete={() => handleDelete(row.id)}
                                            />
                                        </div>
                                    </Tooltip>
                                );
                            },
                        },
                    ]}
                    multiActions={[
                        {
                            label: 'Cambiar estado',
                            render: (selectedRows: any) => {
                                return (
                                    <Button
                                        onClick={() => { setOpenModal(true); setSelectedRows(selectedRows) }}
                                        variant="outline"
                                        color="secondary"
                                    >
                                        Cambiar estado
                                    </Button>
                                );
                            }
                        },
                    ]}
                    handleMultipleDelete={handleMultiDelete}
                    multiDeletePermission={{ group: 'orders', action: 'delete_orders' }}
                    filters={filters}
                    updateFilters={updateFilters}
                    updateFilterOrder={updateFilterOrder}
                    defaultOrder={filters.filter_order || undefined}
                    paginationData={{
                        pageSize: filters.limit,
                        currentPage: filters.page,
                        pageCount: (data as IncidencesApiResponse) ? data.lastPage : 1,
                        totalCount: data?.totalRegisters,
                        handlePagination: updatePage,
                        handlePerPage: updatePageSize,
                    }}
                    toggleFilters={() => setOpenFilters(!openFilters)}
                >
                    {userCan('create_orders', 'orders') && (
                        <Button
                            color="primary"
                            onClick={() => { navigate(`${menuRoutes.orders.path}/create`, { replace: true }) }}
                            className={cn(['mt-4 md:mt-0'])}
                        >
                            Crear pedido
                        </Button>
                    )}
                </CustomTable>
            )}

            <FilterDrawerView isOpen={openFilters} setOpenDrawer={setOpenFilters} drawerTitle={'Filtros de Pedidos'}>
                <OrdersFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} />
            </FilterDrawerView>

            <CustomModal
                isOpen={openModal}
                onClose={() => { setOpenModal(false); setSelectedStatus('') }}
                onConfirm={() => { handleMultiStatusesChange(selectedRows); setSelectedStatus('') }}
                name='Estado de los pedidos'
                confirmText='Cambiar estado'
                cancelText='Cancelar'
                size="md"
            >
                <Text className='mb-7'>Se cambiará el estado de los pedidos seleccionados. ¿Estás seguro de que deseas continuar?</Text>
                <CustomSelect
                    id="current_status"
                    label="Estado"
                    options={getOrderStatusesList()}
                    value={getOrderStatusesList().filter((status) => status.value === selectedStatus)}
                    onChange={(e: any) => setSelectedStatus(e.value)}
                    className="mb-7"
                />
            </CustomModal>
        </Page>
    );
};

export default OrdersList;