import { PiCaretRightBold } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import { Text, Title, cn } from "rizzui";

type StatCardProps = {
    statItem: any;
    className?: string;
    valueFontSize?: string;
};

export function StatCard({ statItem, className, valueFontSize = 'text-3xl' }: StatCardProps) {

    const { title, value, route } = statItem;
    const navigate = useNavigate();

    return (
        <div
            className={cn(
                'space-y-4 rounded-lg border border-muted p-3 md:p-5 py-5 dark:bg-[#181818] h-full',
                className
            )}
        >
            <div className='flex justify-between align-middle'>
                <Text className={cn("font-semibold text-gray-900")}>{title}</Text>
                {route && (<PiCaretRightBold className="size-3.5 rtl:rotate-180 cursor-pointer" onClick={() => navigate(route)} />)}
            </div>

            <div className='flex items-center justify-end gap-2 h-full !mt-0 mb-2'>
                <Title className={cn(valueFontSize, "font-normal leading-none")}>
                    {value}
                </Title>
            </div>
        </div>
    );
}