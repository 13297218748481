import { motion } from 'framer-motion';
import { ForwardedRef, forwardRef, useEffect, useState } from 'react';
import { AiFillCaretRight } from 'react-icons/ai';
import { PiCaretDownBold } from 'react-icons/pi';
import { Link, useLocation } from 'react-router-dom';
import { useActivePathname } from '../../../hooks/use-pathname-active';
import { DropdownMenuItemsType, DropdownMenuItemType } from '../../../type/menu-type';
import { CheckMenuItemAccess } from '../../../utils/CheckMenuPermissions';
import cn from '../../../utils/classNames';
import NavMenu from '../nav-menu/nav-menu';
import { lithiumMenuIcons, LithiumMenuIconType } from './lithium-menu-icons';

function EnhancedMenuItems({
    items,
    currentState,
    columns = "2",
    className = '',
}: {
    items: DropdownMenuItemType[];
    currentState: number;
    columns?: string;
    className?: string;
}) {
    const location = useLocation();
    const [pathname, setpathname] = useState(location.pathname);

    useEffect(() => {
        setpathname(location.pathname);
    }, [location]);

    return (
        <div
            className={cn(
                'w-[calc(100%_-_200px)] rounded-lg bg-white pl-3 dark:bg-gray-100',
                className
            )}
        >
            <ul className={cn(`grid list-none grid-cols-${columns}  gap-x-10 gap-y-4 p-7`)}>
                {items &&
                    items[currentState] &&
                    items[currentState]?.subMenuItems &&
                    items[currentState]?.subMenuItems?.map((item, index) => {
                        const isActive = pathname === item.href;
                        const Icon = item.icon ? lithiumMenuIcons?.[item.icon as LithiumMenuIconType] : null;
                        const ReactIcon = item.react_icon ? item.react_icon : null;
                        return (
                            CheckMenuItemAccess(item.permissions_required) &&
                            <motion.li
                                style={{ transformOrigin: 'center' }}
                                initial={{ opacity: 0, scale: 0.75 }}
                                animate={{ opacity: 1, scale: 1 }}
                                transition={{ duration: 0.2, delay: index * 0.075 }}
                                className="basis-1/2 text-left"
                                key={`enhance-menu-item-${item.name}-${index}`}
                            >
                                <Link
                                    to={item.href ?? '/'}
                                    className="group/submenu-link flex items-center gap-3 font-medium text-gray-900"
                                >
                                    <span
                                        className={cn(
                                            'text-gray-500 duration-200 group-hover/submenu-link:text-gray-900',
                                            { 'text-gray-900': isActive, }
                                        )}
                                    >
                                        {/* {Icon ? <Icon className="h-5 w-5" /> : (ReactIcon ? <ReactIcon /> : <BulletIcon className="h-3 w-3" />)} */}
                                    </span>
                                    <span
                                        className={cn('duration-200 text-nowrap', {
                                            'group-hover/submenu-link:translate-x-1': !isActive,
                                            underline: isActive,
                                        })}
                                    >
                                        {item.name}
                                    </span>
                                </Link>
                            </motion.li>
                        );
                    })}
            </ul>
        </div>
    );
}

export const EnhancedMenu = forwardRef(
    (
        {
            items,
            columns = "2",
            className = '',
        }: {
            items: DropdownMenuItemType[];
            columns?: string;
            className?: string;
        },
        ref: ForwardedRef<HTMLDivElement>
    ) => {
        let location = useLocation();
        const [pathname, setpathname] = useState(location.pathname);
        const [currentState, setState] = useState<number>(0);

        useEffect(() => {
            setpathname(location.pathname);
        }, [location]);

        useEffect(() => {
            if (items.some((item) => item.subMenuItems?.some((subItem: any) => subItem.href === pathname))) {
                setState(items.findIndex((item) => item.subMenuItems?.some((subItem: any) => subItem.href === pathname)));
            }
        }, [items, pathname]);
        return (
            <motion.div
                key="enhanced-menu"
                initial={{ opacity: 0, scale: 0.75 }}
                transition={{ duration: 0.1 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, transition: { duration: 0.2 } }}
                className={cn('flex gap-x-2 p-1 pb-1.5 pe-1.5', className)}
            >
                <div className="col-span-3 flex w-[200px] flex-col gap-2 pe-0">
                    {items.map((item, index) => {
                        const Icon = lithiumMenuIcons?.[item.icon as LithiumMenuIconType];
                        return (
                            CheckMenuItemAccess(item.permissions_required, [item], 'Enhanced Menu') &&
                            <button
                                key={`link-menu-${item.name}-${index}`}
                                className={cn(
                                    'relative cursor-pointer rounded-lg p-3 pb-2.5 text-left font-medium text-gray-900 duration-200',
                                    { 'bg-white dark:bg-gray-100': currentState === index, }
                                )}
                                onClick={() => setState(index)}
                            >
                                <div className="mb-2 flex items-center gap-2 text-nowrap">
                                    <Icon className="h-5 w-5" />
                                    <span>{item.name}</span>
                                </div>
                                <p className="text-xs font-normal leading-5 text-gray-500">
                                    {item?.description}
                                </p>
                                <span
                                    className={cn(
                                        'absolute -end-[9px]  top-1/2 -translate-y-1/2 text-white opacity-0 duration-200 dark:text-gray-100 rtl:rotate-180',
                                        { 'opacity-100': currentState === index, }
                                    )}
                                >
                                    <AiFillCaretRight className="h-auto w-3.5" />
                                </span>
                                <span
                                    className={cn(
                                        'absolute -end-[17px] top-1/2 -translate-y-1/2 text-gray-50  opacity-0 duration-200 dark:text-gray-0 rtl:rotate-180',
                                        { 'opacity-100': currentState === index, }
                                    )}
                                >
                                    <AiFillCaretRight className="h-auto w-3.5" />
                                </span>
                            </button>
                        );
                    })}
                </div>
                <EnhancedMenuItems items={items} currentState={currentState} columns={columns} />
            </motion.div>
        );
    }
);

EnhancedMenu.displayName = 'EnhancedMenu';

export function LinkMenu({
    items,
    className = '',
}: {
    items: DropdownMenuItemsType;
    className?: string;
}) {
    let location = useLocation();
    const [pathname, setpathname] = useState(location.pathname);

    useEffect(() => {
        setpathname(location.pathname);
    }, [location]);

    return (
        <ul className={cn('w-auto h-auto', className, 'bg-gray-0 dark:bg-gray-100')}>
            {items && items.map((item: DropdownMenuItemType, index: number) => {

                const Icon = lithiumMenuIcons?.[item.icon as LithiumMenuIconType];
                const isActive = item.path === pathname;
                return (
                    CheckMenuItemAccess(item.permissions_required, undefined, 'Link Menu') ?
                        <li
                            key={`link-menu-${item.name}-${index}`}
                            className="relative my-0.5 "
                        >
                            <Link
                                to={item.path ?? '/'}
                                className={cn(
                                    'flex items-center gap-3 whitespace-nowrap rounded-md bg-gray-100/0 px-3 py-2 font-medium text-gray-900 duration-200 hover:bg-gray-100 hover:dark:bg-gray-50/50',
                                    { 'bg-gray-100 dark:bg-gray-50/50': isActive }
                                )}
                            >
                                <Icon className="h-5 w-5" />
                                <span className="relative block">{item.name}</span>
                            </Link>
                        </li>
                        : <></>
                );
            })}
        </ul>
    );
}

interface HeaderMenuLeftProps {
    pathname: string;
    menuItems: any;
    data?: any;
}

export default function HeaderMenuLeft({ pathname, menuItems, data }: HeaderMenuLeftProps) {
    return (
        <>
            <NavMenu
                menuClassName="pb-5 top-3 gap-8 relative"
                menuContentClassName="mt-2 border border-gray-200 dark:border-gray-300"
            >
                {Object.entries(menuItems).map(([key, menu]: any) => {
                    if (menu.path === '/my-invoices' && data?.tutorType === 'internal') return;
                    return (
                        CheckMenuItemAccess(menu.permissions_required, menu.dropdownItems, 'Header Menu Left') &&
                        <NavMenu.Item key={`item-menu-${menu.name}-${key}`} >
                            <NavMenu.Trigger className="flex items-center gap-1 duration-200 ">
                                <MenuTriggerButton name={menu.name} path={menu.path} dropdownItems={menu.dropdownItems} />
                            </NavMenu.Trigger>
                            {menu.type === "link" && menu.dropdownItems && (
                                <NavMenu.Content className="border border-[red] bg-white dark:bg-gray-100">
                                    <div className="w-fit">
                                        <LinkMenu
                                            className="flex flex-col p-3 dark:bg-gray-100"
                                            items={menu.dropdownItems}
                                        />
                                    </div>
                                </NavMenu.Content>
                            )}
                            {menu.type === "enhance" && menu.dropdownItems && (
                                <NavMenu.Content>
                                    <div className="w-[670px]">
                                        <EnhancedMenu
                                            className="min-h-[336px] w-[670px] bg-gray-50 dark:bg-gray-0"
                                            items={menu.dropdownItems}
                                            columns={menu?.columns || '2'}
                                        />
                                    </div>
                                </NavMenu.Content>
                            )}
                        </NavMenu.Item>
                    )
                })}
            </NavMenu>
        </>
    );
}

function MenuTriggerButton({ name, path, dropdownItems }: { name: string, path: string | undefined, dropdownItems?: DropdownMenuItemsType }) {
    const { isActive } = useActivePathname();
    return (
        <>
            {path && dropdownItems === undefined && (
                <Link to={path} className={cn( isActive(path) ? 'text-primary ' : 'group-hover:text-gray-900' )}>
                    {name}
                </Link>
            )}

            {dropdownItems && (
                <>
                    <span
                        className={cn(
                            'inline-block w-full overflow-hidden whitespace-nowrap pe-1.5 ps-0 text-sm font-medium leading-5 text-gray-900 transition-all duration-200',
                            isActive(dropdownItems)
                                ? 'text-primary '
                                : 'group-hover:text-gray-900'
                        )}
                    >
                        {name}
                    </span>
                    <span
                        className={cn(
                            'text-gray-900 duration-200',
                            isActive(dropdownItems!) && 'text-primary'
                        )}
                    >
                        <PiCaretDownBold />
                    </span>
                </>

            )}
        </>
    );
}