import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import useFilters, { FilterOptions, FilterOrders } from "../../../hooks/useFilters";

type OrdersProviderProps = {
    children: React.ReactNode
    defaultFilters?: FilterOptions
    defaultOrders?: FilterOrders,
    defaultPage?: number,
    defaultPageSize?: number,
}

type OrdersContextData = {
    filters: FilterOptions,
    updateFilters: (filters: any) => void,
    updatePage: (page: any) => void,
    updatePageSize: (pageSize: number) => void,
    updateFilterOrder: (keyvalue: string, order: "asc" | "desc") => void,
    resetFilters: () => void,
    scrollToCurrentDate: () => void,
    isLoading: boolean,
    setIsLoading: (loading: boolean) => void,
    fromDate: string,
    setFromDate: (date: string) => void,
    toDate: string,
    setToDate: (date: string) => void,
    year: number,
    setYear: (year: number) => void,
}

const OrdersContext: React.Context<OrdersContextData> = React.createContext<OrdersContextData>({
    filters: {} as FilterOptions,
    updateFilters: () => { },
    updatePage: (page: any) => { },
    updatePageSize: (pageSize: number) => { },
    updateFilterOrder: (keyvalue: string, order: "asc" | "desc") => { },
    resetFilters: () => { },
    scrollToCurrentDate: () => { },
    isLoading: false,
    setIsLoading: (loading: boolean) => { },
    fromDate: '',
    setFromDate: (date: string) => { },
    toDate: '',
    setToDate: (date: string) => { },
    year: new Date().getFullYear(),
    setYear: (year: number) => { },
});

const OrdersProvider: React.FC<OrdersProviderProps> = ({ children, defaultFilters = {} as FilterOptions, defaultOrders = [] as FilterOrders, defaultPage = 1, defaultPageSize = 9999999 }) => {

    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(defaultFilters, defaultOrders, defaultPage, defaultPageSize);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [fromDate, setFromDate] = useState<string>(moment().startOf('year').format('YYYY-MM-DD'));
    const [toDate, setToDate] = useState<string>(moment().endOf('year').format('YYYY-MM-DD'));
    const [year, setYear] = useState<number>(new Date().getFullYear());

    // Scroll the timeline to the current date
    const scrollToCurrentDate = () => {
        setTimeout(() => {
            let todayTimeline = document.querySelector('.timeline_today');
            let scrollContainer = document.querySelector('.scheduler_default_scrollable');
            if (scrollContainer) {
                const { x } = todayTimeline?.getBoundingClientRect() || { x: 0 };
                scrollContainer.scrollTo({ left: x - 950, behavior: 'smooth' });
            }
        }, 200);
    };

    // Update fromDate and toDate when filters change
    useEffect(() => {
        if (filters.filter_filters?.between_dates) {
            setFromDate(filters.filter_filters?.between_dates.startDate);
            setToDate(filters.filter_filters?.between_dates.endDate);
        }
    }, [filters]);

    // Update filters when year changes
    useEffect(() => {
        if (year) {
            const startDate = moment().year(year).startOf('year').format('YYYY-MM-DD');
            const endDate = moment().year(year).endOf('year').format('YYYY-MM-DD');
            updateFilters({ between_dates: { startDate, endDate, type: 'startAndEndDate' } });
        }
    }, [year]);

    return (
        <OrdersContext.Provider value={{
            filters,
            updateFilters,
            resetFilters,
            updateFilterOrder,
            updatePage,
            updatePageSize,
            scrollToCurrentDate,
            isLoading,
            setIsLoading,
            fromDate,
            setFromDate,
            toDate,
            setToDate,
            year,
            setYear,
        }}>
            {children}
        </OrdersContext.Provider>
    );
};

export { OrdersContext, OrdersProvider };

export function useOrdersProvider() {
    return useContext(OrdersContext);
}