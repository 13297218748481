import { Fragment, useContext } from "react";
import { useNavigate } from "react-router";
import { Button } from "rizzui";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import { FiltersProvider } from "../../../components/providers/FiltersProvider";
import PageHeader from "../../../layout/shared/page-header";
import { menuRoutes } from "../../../router/menu";
import cn from "../../../utils/classNames";
import ClientsList from "./ClientsList";

const ClientsListWrapper = () => {

    const { userCan } = useContext(PrivilegeContext);
    const navigate = useNavigate();

    const breadCrumb = [
        { name: 'Ventas' },
        { name: 'Gestión de clientes' },
        { name: 'Clientes', href: menuRoutes.clients.path },
    ];

    const defaultFilters = {
        active: true,
    };

    return (
        <Fragment>
            <FiltersProvider defaultFilterFilters={defaultFilters}>
                <PageHeader title="Listado de clientes" breadcrumb={breadCrumb}>
                    {userCan('create_clients', 'clients') && (
                        <Button
                            color="primary"
                            onClick={() => { navigate(menuRoutes.clients.create) }}
                            className={cn(['mt-4 md:mt-0'])}
                        >
                            Crear cliente
                        </Button>
                    )}

                </PageHeader>
                <ClientsList />
            </FiltersProvider>
        </Fragment>
    );
}

export default ClientsListWrapper;