import React from 'react';
import { FiltersProvider } from '../../../../../components/providers/FiltersProvider';
import ClientProfileLayout from '../../ClientProfileLayout';
import ClientContractsList from './ClientContracts';

interface ClientContractsWrapperProps { }

const ClientContractsWrapper: React.FC<ClientContractsWrapperProps> = ({ }) => {
    return (
        <FiltersProvider>
            <ClientProfileLayout>
                <ClientContractsList />
            </ClientProfileLayout>
        </FiltersProvider>
    );
};

export default ClientContractsWrapper;