import _ from 'lodash';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomCalendar from '../../../components/calendar/Calendar';
import PageHeader from '../../../layout/shared/page-header';
import { setSelectedView } from '../../../redux/settingsSlice';
import { RootState } from '../../../redux/store';
import { menuRoutes } from '../../../router/menu';
import FormFooter from '../../_layout/_footers/form-footer';
import { SettingsContext } from '../provider/SettingsProvider';
import HolidaysModal from './HolidaysModal';


const HolidaysSettingsPage: React.FC = () => {

    const dispatch = useDispatch();
    const { selectedView } = useSelector((state: RootState) => state.settings);

    const { companyConfig, hasCompanyConfigChanges, isLoading, updateCompanyConfig, discardCompanyConfigChanges, setShowModal } = useContext(SettingsContext);
    const [showCalendarModal, setShowCalendarModal] = React.useState<boolean>(false);
    const [selectedEvent, setSelectedEvent] = React.useState<any>(null);
    const [selectedSlot, setSelectedSlot] = React.useState<any>(null);

    const pageHeader = {
        title: 'Ajustes Días Festivos',
        breadcrumb: [
            { name: 'Inicio', href: menuRoutes.dashboard.path },
            { name: 'Configuración' },
            { name: 'Días Festivos', href: menuRoutes.settings.path + menuRoutes.settings.holidays },
        ]
    }

    useEffect(() => {
        if (!showCalendarModal) setSelectedEvent(null);
    }, [showCalendarModal]);

    //////////////////////////////////////////////////////////////////////////////////
    // FUNCTION TO CHANGE FESTIVE DAY WITH DRAG AND DROP
    //////////////////////////////////////////////////////////////////////////////////
    const handleEventDrop = (data: any) => {
        const newFestiveDays = _.cloneDeep(companyConfig.festiveDays);
        newFestiveDays.forEach((day: any) => {
            if (day.id === data.event.id) {
                day.date = new Date(data.start + 86000).toISOString().split('T')[0];
            }
        });
        updateCompanyConfig('festiveDays', newFestiveDays);
    };

    //////////////////////////////////////////////////////////////////////////////////
    // FUNCTION SHOW EVENT DETAILS AND EDIT IT
    //////////////////////////////////////////////////////////////////////////////////
    const handleSelectEvent = (event: any) => {
        setSelectedEvent(event);
        setShowCalendarModal(true);
    };

    //////////////////////////////////////////////////////////////////////////////////
    // FUNCTION SHOW CREATE NEW FESTIVE DAY MODAL
    //////////////////////////////////////////////////////////////////////////////////
    const handleSelectSlot = (slotInfo: any) => {
        setSelectedSlot(slotInfo);
        setShowCalendarModal(true);
    };

    //////////////////////////////////////////////////////////////////////////////////
    // FUNCTION TO ADD NEW FESTIVE DAY TO COMPANY CONFIG
    //////////////////////////////////////////////////////////////////////////////////
    const handleSubmit = (newDay: any, update: boolean) => {
        setShowCalendarModal(false);
        const newFestiveDays = _.cloneDeep(companyConfig.festiveDays);
        if (update) {
            newFestiveDays.map((day: any) => {
                if (day.id === newDay.id) {
                    day.date = newDay.date;
                    day.concept = newDay.concept;
                }
            })
        } else {
            newFestiveDays.push(newDay);
        }
        updateCompanyConfig('festiveDays', newFestiveDays);
    };

    //////////////////////////////////////////////////////////////////////////////////
    // FUNCTION TO REMOVE FESTIVE DAY FROM COMPANY CONFIG
    //////////////////////////////////////////////////////////////////////////////////
    const handleRemove = (id: string) => {
        const newFestiveDays = _.cloneDeep(companyConfig.festiveDays);
        newFestiveDays.forEach((dayItem: any, index: number) => {
            if (dayItem.id === id) {
                newFestiveDays.splice(index, 1);
            }
        });
        updateCompanyConfig('festiveDays', newFestiveDays);
        setShowCalendarModal(false);
    };

    const getFestiveDays = () => {
        const fetiveDaysOptions = companyConfig?.festiveDays?.map((day: any) => {
            let startDate = new Date(day.date).setHours(20, 59, 59, 999);
            let endDate = new Date(day.date).setHours(23, 59, 59, 999);
            return {
                id: day.id,
                title: day.concept,
                start: startDate,
                end: endDate,
                allDay: true,
            }
        }) ?? [];
        return fetiveDaysOptions;
    };

    return (
        <div className='h-full overflow-auto scrollbar '>
            <PageHeader
                title={pageHeader.title}
                breadcrumb={pageHeader.breadcrumb}
                className='border-b-4 border-dotted border-grey-500 top-0'
            />
            <CustomCalendar
                selectable={true}
                events={getFestiveDays()}
                className='h-[calc(100vh-170px)]'
                handleSelectEvent={handleSelectEvent}
                handleSelectSlot={handleSelectSlot}
                handleEventDrop={handleEventDrop}
                handleView={(view: string) => dispatch(setSelectedView(view))}
                defaultView={selectedView}
            />
            {showCalendarModal && (
                <HolidaysModal
                    isOpen={showCalendarModal}
                    setOpen={setShowCalendarModal}
                    handleSubmit={handleSubmit}
                    handleRemove={handleRemove}
                    selectedSlotData={selectedSlot}
                    selectedEvent={selectedEvent}
                />
            )}

            {hasCompanyConfigChanges && (
                <FormFooter
                    customClassName={"w-full bg-white md:z-[100]"}
                    submitBtnText={"Aplicar Cambios"}
                    cancelBtnText={"Descartar Cambios"}
                    handleCancelBtn={() => discardCompanyConfigChanges()}
                    handleSubmitBtn={() => setShowModal(true)}
                    isLoading={isLoading}
                />

            )}
        </div>
    );
};

export default HolidaysSettingsPage;