import React from 'react';
import { PiTrashDuotone } from 'react-icons/pi';
import { Button } from 'rizzui';
import CustomSearchableEntities from '../../../../../components/forms/CustomSearchableEntities';
import CustomSelect from '../../../../../components/forms/CustomSelect';
import useContractStates from '../../../../../hooks/api-calls/contract/useContractStates';
import useClients from '../../../../../hooks/api-calls/useClients';
import useProducts from '../../../../../hooks/api-calls/useProducts';
import useStudents from '../../../../../hooks/api-calls/useStudents';
import useTutors from '../../../../../hooks/api-calls/useTutors';
import { FilterOptions } from '../../../../../hooks/useFilters';

interface ContractAssignmentFiltersProps {
    updateFilters: (filters: any) => void
    resetFilters: (limit: any) => void
    filters: FilterOptions
}

const ContractAssignmentFilters: React.FC<ContractAssignmentFiltersProps> = ({ filters, updateFilters, resetFilters }) => {

    const { getContractStatesList } = useContractStates({ active: true });
    const { getProductsList, updateProductsHookFilters } = useProducts();
    const { getTutorsList, updateTutorsHookFilters } = useTutors();
    const { getClientsList, updateClientsHookFilters } = useClients();
    const { getStudentsList, updateStudentsHookFilters } = useStudents();

    return (
        <>
            <CustomSelect
                id="current_status"
                label="Estado de contrato"
                isMulti={true}
                options={getContractStatesList()}
                onChange={(value: { value: string, label: string }[]) => {
                    const statuses = value.map((status) => status.value);
                    if (statuses !== filters.filter_filters?.custom_status?.statuses) {
                        updateFilters({
                            custom_status: {
                                startDate: null,
                                endDate: null,
                                current: 1,
                                entity: "contract",
                                statuses: statuses,
                            }
                        });
                    }
                }}
                value={filters.filter_filters?.custom_status?.statuses ?
                    getContractStatesList().filter((status) => filters.filter_filters?.custom_status?.statuses.includes(status.value)) : []}
            />

            <CustomSearchableEntities
                id="product"
                label="Producto"
                filters={filters}
                updateFilters={updateFilters}
                fetchOptions={async (searchText: string | null) => {
                    updateProductsHookFilters({ finder: searchText });
                    return getProductsList();
                }}
                permissions={[
                    {
                        group: 'contracts',
                        action: 'admin_contracts'
                    },
                    {
                        group: 'products',
                        action: 'list_products'
                    }
                ]}
            />

            <CustomSearchableEntities
                id="tutor"
                label="Tutor"
                filters={filters}
                updateFilters={updateFilters}
                fetchOptions={async (searchText: string | null) => {
                    updateTutorsHookFilters({ finder: searchText });
                    return getTutorsList();
                }}
                permissions={[
                    {
                        group: 'contracts',
                        action: 'admin_contracts'
                    },
                    {
                        group: 'tutors',
                        action: 'list_tutors'
                    }
                ]}
            />

            <CustomSearchableEntities
                id="client"
                label="Cliente"
                filters={filters}
                updateFilters={updateFilters}
                fetchOptions={async (searchText: string | null) => {
                    updateClientsHookFilters({ finder: searchText });
                    return getClientsList();
                }}
                permissions={[
                    {
                        group: 'contracts',
                        action: 'admin_contracts'
                    },
                    {
                        group: 'clients',
                        action: 'list_clients'
                    }
                ]}
            />

            <CustomSearchableEntities
                id="student"
                label="Alumno"
                filters={filters}
                updateFilters={updateFilters}
                fetchOptions={async (searchText: string | null) => {
                    updateStudentsHookFilters({ finder: searchText });
                    return getStudentsList();
                }}
                permissions={[
                    {
                        group: 'contracts',
                        action: 'admin_contracts'
                    },
                    {
                        group: 'students',
                        action: 'list_students'
                    }
                ]}
            />

            <Button
                size="sm"
                onClick={() => {
                    resetFilters(50);
                }}
                variant="flat"
                className="h-9 bg-gray-200/70"
            >
                <PiTrashDuotone className="me-1.5 h-[17px] w-[17px]" /> Limpiar
            </Button>
        </>
    );
};

export default ContractAssignmentFilters;