import React, {useEffect, useRef, useState} from 'react';
import { HiOutlineInformationCircle } from 'react-icons/hi2';
import HorizontalFormBlockWrapper from '../../components/forms/HorizontalFormBlockWrapper';
import RequiredInput from '../../components/forms/InputHOC';
import PageHeader from '../../layout/shared/page-header';
import { menuRoutes } from '../../router/menu';
import FormFooter from '../_layout/_footers/form-footer';
import { useSettingsProvider } from './provider/SettingsProvider';
import { useLocation } from 'react-router-dom';
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import SignatureCanvas from "react-signature-canvas";
import {Button} from "rizzui";
import {toast} from "sonner";
import {UserService} from "../../services/user/userService";
import {Document, Page} from "react-pdf";
import {FaArrowLeft, FaArrowRight} from "react-icons/fa"; // Estilos de Quill


const ContractsSettingsPage: React.FC = () => {

    const { formik, hasCompanyConfigChanges, isLoading, companyConfig, updateCompanyConfig, setShowModal, discardCompanyConfigChanges } = useSettingsProvider();
    const location = useLocation();
    const [legalText, setLegalText] = useState('');
    const [smsText, setSmsText] = useState('');
    const [pdfBlob, setPdfBlob] = useState<any | null>(null);
    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [showPreview, setShowPreview] = useState(false);
    const signatureRef = useRef<SignatureCanvas>(null);

    const pageHeader = {
        title: 'Ajustes en Contratos',
        breadcrumb: [
            { name: 'Inicio', href: menuRoutes.dashboard.path },
            { name: 'Configuración' },
            { name: 'Contratos', href: menuRoutes.settings.path + menuRoutes.settings.contracts },
        ]
    }

    useEffect(() => {
        if (location.hash) {
            const id = location.hash.replace('#', '');
            const element = document.getElementById(id);

            if (element) {
              element.scrollIntoView({ behavior: 'smooth' });
            }
          }
    }, [location]);


    useEffect(() => {
        if (companyConfig) {
            setLegalText(companyConfig.legalText || '');
            setSmsText(companyConfig.smsText || '');
        }

        if(showPreview)
        {
            handlePreviewContractSignature();
        }
    }, [companyConfig, showPreview]);

    const handleQuillChange = (field: string, value: string) => {
        if (field === 'legalText') {
            setLegalText(value);
        } else if (field === 'smsText') {
            setSmsText(value);
        }
        updateCompanyConfig(field, value);
        formik.setFieldValue(field, value);
    };

    const handleRegisterSign = async (signatureImage: any) => {
        try {
            const response = await(await (new UserService()).updateCompanySignature(signatureImage)).getResponseData();

            if (!response.success) {
                toast.error(response.message);
                return;
            }

            toast.success('Firma registrada correctamente');
        } catch (error) {
            toast.error('Error al registrar la firma');
        }
    };

    const handleRegisterBackgroundSignature = async (backgroundSignatureImage: any) => {
        try {
            const response = await(await (new UserService()).updateCompanyBackgroundSignature(backgroundSignatureImage)).getResponseData();

            if (!response.success) {
                toast.error(response.message);
                return;
            }

            toast.success('Fondo de firma registrado correctamente');
        } catch (error) {

            toast.error('Error al registrar el fondo de firma');
        }
    }

    const handlePreviewContractSignature = async () => {
        try {
            const response = await (new UserService()).visualizeSignatureTemplatePdf();

            if (response?.data)
            {
                let base64String;
                const fileData = response.data;
                // Verificar si el contenido del blob es diferente antes de actualizar el estado
                if (pdfBlob && pdfBlob.size === fileData.byteLength) {
                    return; // Si el blob es el mismo, no actualizar
                }

                const blob = new Blob([fileData]);

                let reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                    base64String = reader.result;
                    setPdfBlob(base64String);
                };
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
        setNumPages(numPages);
    };

    return (
        <div className='h-full overflow-auto scrollbar'>
            <PageHeader
                title={pageHeader.title}
                breadcrumb={pageHeader.breadcrumb}
                className='border-b-4 border-dotted border-grey-500 top-0'
            />

            <HorizontalFormBlockWrapper
                title="Creación de los Contratos"
                description="Para personalizar los campos, ten en cuenta que las variables se encuentran de la siguiente manera: {{VARIABLE}}."
                descriptionClassName="max-w-[70%]"
                childrenWrapperClassName="@3xl:grid-cols-1 max-w-[100%] w-full"
            >
                <div className='grid grid-cols-12 pb-4 gap-4'>
                    <RequiredInput
                        required={true}
                        type="number"
                        id="daysPeriodToEndContract"
                        label="Tiempo BORRADOR"
                        className='col-span-12 md:col-span-4 lg:col-span-3 xl:col-span-3 3xl:col-span-2'
                        suffix='Días'
                        value={companyConfig?.daysPeriodToEndContract}
                        onChange={(e) => {
                            updateCompanyConfig('daysPeriodToEndContract', e.target.value);
                            formik.setFieldValue('daysPeriodToEndContract', e.target.value);
                        }}
                        error={formik.errors.daysPeriodToEndContract}
                    />

                    <RequiredInput
                        required={true}
                        id="contractCodeFormat"
                        contentTooltipText="Para personalizar el nombre, utiliza las siguientes variables: {{YEAR}}, {{MONTH}} Y {{NUMBER}}."
                        label={<>Nombre del Contrato &nbsp;<HiOutlineInformationCircle size={20} color={'primary'} /></>}
                        className='col-span-12 lg:col-span-3'
                        placeholder='{{YEAR}}{{MONTH}}{{NUMBER}}'
                        value={companyConfig?.contractCodeFormat}
                        onChange={(e) => {
                            updateCompanyConfig('contractCodeFormat', e.target.value);
                            formik.setFieldValue('contractCodeFormat', e.target.value);
                        }}
                        error={formik.errors.contractCodeFormat}
                    />

                    <RequiredInput
                        required={true}
                        id="daysNeededToGenerateContract"
                        contentTooltipText="Asigna el numero de días (mínimo) de antigüedad de una empresa para que el sistema le permita generar un contrato."
                        label={<>Antiguedad de la empresa &nbsp;<HiOutlineInformationCircle size={20} color={'primary'} /></>}
                        className='col-span-12 lg:col-span-3'
                        placeholder='30'
                        value={companyConfig?.daysNeededToGenerateContract}
                        onChange={(e) => {
                            updateCompanyConfig('daysNeededToGenerateContract', e.target.value);
                            formik.setFieldValue('daysNeededToGenerateContract', e.target.value);
                        }}
                        error={formik.errors.daysNeededToGenerateContract}
                    />
                </div>
            </HorizontalFormBlockWrapper>

            <HorizontalFormBlockWrapper
                title="Texto legal de contrato"
                description="Personalización del texto legal de contrato. (Nota: Para añadir un salto de página, introducir {{PAGE_BREAK}})."
                descriptionClassName="max-w-[100%]"
                childrenWrapperClassName="max-w-[100%] w-full pb-[6vh]"
            >
                <div className="h-60 resize-y overflow-auto border rounded-md">
                    <ReactQuill
                        theme="snow"
                        id="legalText"
                        className="h-full"
                        value={legalText}
                        onChange={(value) => handleQuillChange('legalText', value)}
                    />
                </div>
            </HorizontalFormBlockWrapper>

            <HorizontalFormBlockWrapper
                title="Texto de SMS"
                description="Personalización del texto de SMS para la firma de contrato por parte del cliente."
                descriptionClassName="max-w-[70%]"
                childrenWrapperClassName="max-w-[100%] w-full"
                className='border-b border-dashed border-muted py-2 @5xl:grid @5xl:grid-cols-6 pb-[6vh]'
            >
                <div className="h-40 resize-y overflow-auto border rounded-md">
                    <ReactQuill
                        theme="snow"
                        id="smsText"
                        className="h-full"
                        value={smsText}
                        onChange={(value) => handleQuillChange('smsText', value)}
                    />
                </div>
            </HorizontalFormBlockWrapper>

            <HorizontalFormBlockWrapper
                title="Firma en el contrato"
                description="Personalización de la firma que se incluirá en el contrato."
                descriptionClassName="max-w-[70%]"
                childrenWrapperClassName="max-w-[100%] w-full"
                className="border-b border-dashed border-muted py-2 @5xl:grid @5xl:grid-cols-6 pb-[6vh]"
            >
                {/* Sección para dibujar la firma */}
                <div className="flex flex-col lg:flex-row justify-between gap-6">
                    <div className="flex-1">
                        <h3 className="text-base font-medium mb-2">Dibujar Firma</h3>
                        <div className="border border-gray-300 rounded-md p-2 w-full sm:w-3/4 md:w-1/2 lg:w-full overflow-hidden">
                            <SignatureCanvas
                                ref={signatureRef}
                                canvasProps={{
                                    width: 600,
                                    height: 150,
                                    className: 'signature-canvas'
                                }}
                            />
                            <div className="mt-2 flex justify-between">
                                <Button type="button" onClick={() => signatureRef.current?.clear()} variant="outline">
                                    Limpiar
                                </Button>
                                <Button
                                    type="button"
                                    onClick={() => {
                                        if (signatureRef.current?.isEmpty()) {
                                            toast.error('Por favor, realice su firma');
                                            return;
                                        }
                                        const signatureImage = signatureRef.current?.toDataURL();
                                        handleRegisterSign(signatureImage);
                                    }}
                                >
                                    Guardar
                                </Button>
                            </div>
                        </div>
                    </div>

                    {/* Sección para subir imagen de fondo */}
                    <div className="flex-1">
                        <h3 className="text-base font-medium mb-2">Subir Imagen de Fondo</h3>
                        <div className="border border-gray-300 rounded-md p-4">
                            <p className="text-sm text-muted mb-2">
                                Sube una imagen para usarla como fondo de la firma.
                            </p>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={(e) => {
                                    const file = e.target.files?.[0];
                                    if (file) {
                                        handleRegisterBackgroundSignature(file);
                                    }
                                }}
                                className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
                            />
                        </div>
                    </div>
                </div>
            </HorizontalFormBlockWrapper>

            <div className="flex justify-center my-4">
                <Button
                    type="button"
                    onClick={() => setShowPreview(!showPreview)} // Cambiar el estado de la previsualización
                >
                    {showPreview ? 'Ocultar Previsualización' : 'Ver Previsualización'}
                </Button>
            </div>

            {/* Modal de Previsualización */}
            {showPreview && (
                <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-[1000] overflow-auto">
                    <div className="bg-white p-4 rounded-md max-w-4xl w-auto overflow-hidden flex flex-col items-center justify-center position-relative">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-lg font-semibold mr-3 z-[1001]">Previsualización del Contrato</h2>
                            <Button
                                variant="outline"
                                onClick={() => setShowPreview(false)} // Cerrar el modal
                                className={`z-[1001]`}
                            >
                                Cerrar
                            </Button>
                        </div>

                        {pdfBlob ? (
                            <>
                                <Document
                                    file={pdfBlob}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                    onLoadError={(error) => console.error(error)}
                                    className={`w-full h-[80vh] flex flex-col items-center justify-center`}
                                >
                                    {numPages && (
                                        <Page
                                            pageNumber={pageNumber}
                                            renderMode="canvas" // Para asegurarte de que el texto esté renderizado correctamente
                                            renderTextLayer={false}  // Desactiva la capa de texto
                                            renderAnnotationLayer={false}  // Desactiva las anotaciones
                                        />
                                    )}
                                </Document>
                                <div className="position-absolute float bottom-0 m-auto flex justify-center space-x-4 z-[1001]">
                                    <button
                                        onClick={() => setPageNumber((prevPage) => Math.max(prevPage - 1, 1))}
                                        disabled={pageNumber <= 1}
                                    >
                                        <FaArrowLeft />
                                    </button>
                                    <button
                                        onClick={() => setPageNumber((prevPage) => Math.min(prevPage + 1, numPages))}
                                        disabled={pageNumber >= numPages}
                                    >
                                        <FaArrowRight />
                                    </button>
                                </div>
                            </>
                        ) : (
                            <p>Cargando contrato...</p>
                        )}
                    </div>
                </div>
            )}

            {hasCompanyConfigChanges && (
                <FormFooter
                    customClassName="w-full"
                    submitBtnText="Aplicar Cambios"
                    cancelBtnText="Descartar Cambios"
                    handleCancelBtn={discardCompanyConfigChanges}
                    handleSubmitBtn={() => setShowModal(true)}
                    isLoading={isLoading}
                    isDisabled={Object.keys(formik.errors).length > 0}
                />
            )}
        </div>
    );
};

export default ContractsSettingsPage;