import { useEffect } from "react";
import { FaEuroSign } from "react-icons/fa6";
import { Checkbox, Input } from "rizzui";
import CustomSelect from "../../../components/forms/CustomSelect";
import RequiredInput from "../../../components/forms/InputHOC";
import useProductComplements from "../../../hooks/api-calls/useProductComplements";
import useProductTypes from "../../../hooks/api-calls/useProductTypes";
import { useFindProducts } from "../../../hooks/api-calls/useProducts";
import FormGroup from "../../../layout/shared/form-group";
import cn from "../../../utils/classNames";

interface ProductContractFormProps {
    formik: any;
}

const ProductContractForm = ({ formik }: ProductContractFormProps) => {

    const { getProductTypesList } = useProductTypes({ active: true });
    const { getFoundProductsList, findProducts } = useFindProducts({ product_type: [formik.values.productTypeId], active: true });
    const { getProductComplementsList, fetchProductComplements } = useProductComplements();

    /**
     * Fetch products when product type is selected
     * If product type is not selected, reset product and product complements
     */
    useEffect(() => {
        if (formik.values.productTypeId) {
            findProducts({ product_type: [formik.values.productTypeId] });
        } else {
            formik.setFieldValue('productId', '');
            formik.setFieldValue('productAmount', 0);
            formik.setFieldValue('totalAmount', 0);
            formik.setFieldValue('complements', []);
        }
    }, [formik.values.productTypeId]);

    /**
     * Fetch product complements when product is selected
     * Set default values for product amount
     */
    useEffect(() => {
        if (formik.values.productId) {
            fetchProductComplements(formik.values.productId);

            if (getFoundProductsList()?.length) {
                if (formik.values.productAmount === 0) formik.setFieldValue('productAmount', getFoundProductsList().find((g: any) => g.value === formik.values.productId)?.amount);
            }
        }
    }, [formik.values.productId, getFoundProductsList()]);

    /**
     * Set default values for complements and total amount
     */
    useEffect(() => {
        if (formik.values.productAmount && formik.values.productAmount !== 0) {
            formik.setFieldValue('totalAmount', parseFloat((Number(formik.values.productAmount)).toFixed(2)));
        }
    }, [formik.values.productAmount]);

    /**
     * Update total amount when complements are updated
     */
    useEffect(() => {
        if (formik.values.complements.length) {
            const totalAmount = formik.values.complements.reduce((acc: number, complement: any) => acc + complement.amount, Number(formik.values.productAmount));
            formik.setFieldValue('totalAmount', parseFloat(totalAmount.toFixed(2)));
        } else {
            formik.setFieldValue('totalAmount', formik.values.productAmount);
        }
    }, [formik.values.complements]);

    return (
        <div className="rounded-md border-solid border-2 col-span-7 p-6 divide-y-2 divide-dashed divide-gray-200 gap-7">
            <div className='flex flex-col md:flex-row md:justify-between items-center'>
                <h3 className='font-bold text-lg pb-2'>Datos del Producto</h3>
            </div>

            <FormGroup
                title="Información General"
                description="Producto asociado al contrato."
                className={cn("py-4 @3xl:grid-cols-12 ")}
                titleCols="@md:col-span-2"
                childCols="@md:col-span-10 md:grid-cols-4"
            >
                <div className="grid grid-cols-12 col-span-8 gap-4">
                    <CustomSelect
                        isSearchable
                        isClearable
                        required
                        id={'productTypeId'}
                        label="Tipo de producto"
                        containerClassName="col-span-12 md:col-span-3"
                        value={{ value: formik.values.productTypeId, label: getProductTypesList()?.find((g: any) => g.value === formik.values.productTypeId)?.label }}
                        options={getProductTypesList()}
                        onChange={(e: any) => {
                            formik.setFieldValue('productTypeId', e?.value);
                            formik.setFieldValue('productId', '');
                            formik.setFieldValue('productAmount', 0);
                            formik.setFieldValue('totalAmount', 0);
                            formik.setFieldValue('complements', []);
                        }}
                        error={(formik.dirty && formik.errors.productTypeId) ? formik.errors.productTypeId : undefined}
                    />

                    <CustomSelect
                        isSearchable
                        isClearable
                        required={formik.values.saveAsContract}
                        id={'productId'}
                        label="Producto"
                        containerClassName="col-span-12 md:col-span-5"
                        value={{ value: formik.values.productId, label: getFoundProductsList()?.find((g: any) => g.value === formik.values.productId)?.label }}
                        options={getFoundProductsList()}
                        onChange={(e: any) => {
                            formik.setFieldValue('productId', e?.value);
                            formik.setFieldValue('productAmount', e?.amount);
                            formik.setFieldValue('complements', []);
                        }}
                        error={formik.errors.productId}
                        isDisabled={!formik.values.productTypeId || !getFoundProductsList()?.length}
                    />

                    <RequiredInput
                        label="Importe"
                        id="productAmount"
                        type="number"
                        className={cn("col-span-12 md:col-span-2", !formik.values.productId && 'hidden')}
                        suffix={<FaEuroSign />}
                        formik={formik}
                    />
                </div>
            </FormGroup>

            <FormGroup
                title="Complementos"
                description="Complementos del producto."
                className={cn("py-4", (getProductComplementsList().length === 0 || !formik.values.productId) && 'hidden')}
                titleCols="@md:col-span-2"
                childCols="@md:col-span-10 md:grid-cols-2"
            >
                {getProductComplementsList()?.map((complement: any) => (
                    <div key={complement.value} className="col-span-4 md:col-span-1 flex flex-col">
                        <Checkbox
                            size='md'
                            label={<span className="text-wrap">{complement.label} <span className="text-gray-400">({complement.amount}€)</span></span>}
                            checked={formik.values.complements.find((c: any) => c.complementId === complement.value)}
                            onChange={(e: any) => {
                                if (e && !formik.values.complements.find((c: any) => c.complementId === complement.value)) {
                                    formik.setFieldValue('complements', [...formik.values.complements, { complementId: complement.value, amount: complement.amount }]);
                                    formik.setFieldValue('totalAmount', parseFloat((Number(formik.values.totalAmount) + complement.amount).toFixed(2)));
                                } else {
                                    const complementIndex = formik.values.complements.findIndex((c: any) => c.complementId === complement.value);
                                    if (complementIndex !== -1) {
                                        formik.setFieldValue('complements', formik.values.complements.filter((c: any, i: number) => i !== complementIndex));
                                        formik.setFieldValue('totalAmount', parseFloat((Number(formik.values.totalAmount) - complement.amount).toFixed(2)));
                                    }
                                }
                            }}
                            labelClassName="pl-2 text-sm font-medium !text-gray-900"
                        />

                        <Input
                            id={`complement_${complement.value}`}
                            label="Nuevo Importe"
                            type="number"
                            suffix={<FaEuroSign />}
                            value={formik.values.complements.find((c: any) => c.complementId === complement.value)?.amount}
                            onChange={(e: any) => {
                                if (e) {
                                    const complementIndex = formik.values.complements.findIndex((c: any) => c.complementId === complement.value);
                                    if (complementIndex !== -1) {
                                        formik.setFieldValue(`complements[${complementIndex}].amount`, Number(e.target.value));
                                    }
                                }
                            }}
                            className={cn("md:w-48 mt-3", !formik.values.complements.find((c: any) => c.complementId === complement.value) && 'hidden')}
                            required={false}
                        />
                    </div>
                ))}
            </FormGroup>

            <div className='flex flex-col md:flex-row md:justify-end items-center'>
                <span className={cn("text-md font-medium mt-3", !formik.values.productId && 'hidden')}>
                    Total: {formik.values.totalAmount || formik.values.productAmount}€
                </span>
            </div>
        </div>
    );
}

export default ProductContractForm;