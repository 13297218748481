import React, { useEffect } from 'react';
import { HiCalendarDays } from 'react-icons/hi2';
import { IoDocumentText } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import { setSelectedTab } from '../../../redux/ordersSlice';
import { RootState } from '../../../redux/store';
import { menuRoutes } from '../../../router/menu';
import cn from '../../../utils/classNames';

const menuLinks = [
    { label: 'Listado', path: menuRoutes.orders.path + menuRoutes.orders.list, icon: <IoDocumentText /> },
    { label: 'Calendario', path: menuRoutes.orders.path + menuRoutes.orders.calendar, icon: <HiCalendarDays /> },
];

const OrdersSidebar: React.FC = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { selectedTab } = useSelector((state: RootState) => state.orders);
    const location = useLocation();
    const pathname = location.pathname;

    useEffect(() => {
        if (pathname !== selectedTab) navigate(selectedTab);
    }, [selectedTab]);

    return (
        <SimpleBar className="lg:pt-6 lg:ps-6">
            <div className="flex flex-col items-left justify-start h-full p-4">
                <h2 className="uppercase font-bold text-gray-400 mb-4">Pedidos</h2>
                <div className="flex flex-row lg:flex-col text-nowrap flex-nowrap overflow-auto gap-1 scrollbar-thin">
                    {menuLinks.map((link) => {
                        let isActive = (pathname === (link.path as string));
                        return (
                            <Link to={link.path}
                                key={link.label}
                                className={cn("flex flex-row flex-nowrap items-center gap-2 py-2 px-4 text-gray-500 hover:text-gray-800 hover:bg-gray-100 rounded-lg",
                                    isActive ? 'bg-primary-100 text-gray-800 hover:bg-primary-300' : '',
                                )}
                                onClick={() => dispatch(setSelectedTab(link.path))}
                            >
                                {link.icon}
                                {link.label}
                            </Link>
                        )
                    })}
                </div>
            </div>
        </SimpleBar>
    );
};

export default OrdersSidebar;