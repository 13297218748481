import { Fragment, useContext } from "react";
import { useNavigate } from "react-router";
import { Button } from "rizzui";
import { PrivilegeContext } from "../../../../components/priviledge/PriviledgeProvider";
import { FiltersProvider } from "../../../../components/providers/FiltersProvider";
import PageHeader from "../../../../layout/shared/page-header";
import { menuRoutes } from "../../../../router/menu";
import cn from "../../../../utils/classNames";
import IncidencesTypesList from "./IncidenceTypesList";

const IncidenceTypesListWrapper = () => {

    const { userCan } = useContext(PrivilegeContext);
    const navigate = useNavigate();

    const breadCrumb = [
        { name: 'Administración' },
        { name: 'Control de Errores' },
        { name: 'Tipos de Incidencias', href: menuRoutes.incidenceTypes.path },
    ];

    return (
        <Fragment>
            <FiltersProvider>
                <PageHeader title="Listado de tipos de incidencias" breadcrumb={breadCrumb}>
                    {
                        userCan('create_incidence_types', 'incidence_types') &&
                        (
                            <Button
                                color="primary"
                                onClick={() => { navigate(menuRoutes.incidenceTypes.create) }}
                                className={cn(['mt-4 md:mt-0'])}
                            >
                                Crear Tipo de incidencia
                            </Button>
                        )
                    }

                </PageHeader>
                <IncidencesTypesList />
            </FiltersProvider>
        </Fragment>
    );
}

export default IncidenceTypesListWrapper;