import { formatDistance } from "date-fns";
import { es } from "date-fns/locale";
import { capitalize } from "lodash";
import { useCallback, useState } from "react";
import { HiOutlineEye } from "react-icons/hi2";
import { IoMdLogIn } from "react-icons/io";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ActionIcon, Switch, Text, Tooltip } from "rizzui";
import { toast } from "sonner";
import Spinner from "../../../components/bootstrap/Spinner";
import DeletePopover from "../../../components/buttons/DeletePopover";
import { Loader } from "../../../components/loader/SpinnerLogo";
import { usePrivilege } from "../../../components/priviledge/PriviledgeProvider";
import { useFiltersPR } from "../../../components/providers/FiltersProvider";
import { FilterDrawerView } from "../../../components/table/components/TableFilter";
import CustomTable from "../../../components/table/CustomTable";
import useFetch from "../../../hooks/useFetch";
import useHandleErrors from "../../../hooks/useHandleErrors";
import Page from "../../../layout/Page/Page";
import { logoutThunk } from "../../../redux/authSlice";
import { AppDispatch } from "../../../redux/store";
import { Tutor, loginThunk, tutorLogin } from "../../../redux/tutorSlice";
import { menuRoutes } from "../../../router/menu";
import { TutorService } from "../../../services/tutor/tutorService";
import { TutorsApiResponse } from "../../../type/entities/tutor-type";
import { availability, tutorType } from "../TutorForm";
import TutorsFilters from "./TutorsFilters";

interface TutorsListProps { }

const columnOrder = [
    'id',
    'name',
    'lastName',
    'nifCif',
    'telephone',
    'tutorType',
    'availability',
    'email',
    'town',
    'province',
    'active',
    'lastLoginAt',
    'createdAt',
    'updatedAt',
];

const TutorsList: React.FC<TutorsListProps> = () => {

    const dispatch = useDispatch<AppDispatch>();
    const { userCan } = usePrivilege();
    const { handleErrors } = useHandleErrors();
    const navigate = useNavigate();
    const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters } = useFiltersPR();
    const service = new TutorService();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [changingStatus, setChangingStatus] = useState<string[]>([]);
    const [openFilters, setOpenFilters] = useState<boolean>(false);

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = await service.listTutors(filters);
        return response.getResponseData() as TutorsApiResponse;
    }, [filters]));

    const toggleStatus = async (id: string, status: boolean) => {
        try {
            setChangingStatus([...changingStatus, id]);
            const response = (await service.toggleTutorStatus(id, status)).getResponseData();
            if (response.success) {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                refetch();
                toast.success(status ? "Se ha desactivado el tutor" : "Se ha activado el tutor");
            } else {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                toast.error(response.message);
            }
        } catch (error: any) {
            setChangingStatus(changingStatus.filter((item) => item !== id));
            toast.error(error.message);
        }
    };

    const handleLoginAsTutor = async (id: string) => {
        setIsLoading(true);
        const response = await service.loginAsTutor(id) as any;
        const responseData = response.getResponseData();

        if (response && responseData?.success) {
            try {
                const tutor: Tutor = {
                    id: responseData.data.tutor.id,
                    email: responseData.data.tutor.email,
                    token: responseData.data.token,
                    name: responseData.data.tutor.name,
                    lastName: responseData.data.tutor.lastName,
                    roles: responseData.data.tutor.roles,
                    commercialNetwork: responseData.data.tutor.commercialNetworks,
                    platformUser: responseData.data.tutor.platformUser,
                    tutorType: responseData.data.tutor.tutorType,
                    company: responseData.data.tutor.company,
                    companyName: responseData.data.tutor.companyName,
                };

                await dispatch(logoutThunk());

                await new Promise(resolve => setTimeout(resolve, 50)); // 50 ms delay to avoid overwriting the store

                await dispatch(loginThunk({
                    isAuthenticated: true,
                    loading: false,
                    error: null,
                    tutor: tutor
                }));

                navigate(menuRoutes.tutor_panel.profile, { replace: true });
                toast.success('Has accedido como ' + tutor.name);
            } catch (error) {
                toast.error('Error al intentar acceder como el tutor');
                return;
            } finally {
                setIsLoading(false);
            }
        };

        if (responseData.payload) {
            navigate('/users', { replace: true });
            setIsLoading(false);
        };

        if (!responseData?.success) {
            toast.error(response?.response?.message || 'Error al intentar acceder como el tutor');
            setIsLoading(false);
            dispatch(tutorLogin({
                isAuthenticated: false,
                loading: false,
                error: response.message,
                tutor: null
            }));
            return;
        };
    };

    const handleDelete = async (id: string) => {
        try {
            const response = await (await service.deleteTutor(id)).getResponseData();
            if (response.success) {
                toast.success('Tutor eliminado correctamente');
                refetch();
            } else {
                handleErrors(response);
            }
        } catch (error) {
            handleErrors(error);
        }
    };

    const handleMultiDelete = async (ids: string[]) => {
        try {
            const response = await (await service.deleteMultiTutors(ids)).getResponseData();
            if (response.success) {
                toast.success('Tutores eliminados correctamente');
                refetch();
            } else {
                handleErrors(response);
            }
        } catch (error) {
            handleErrors(error);
        }
    };

    /**
     * Order the data to match the column order
     */
    const orderedData = () => data?.data.map((row: any) => {
        const orderedRow: any = {};
        columnOrder.forEach((key) => {
            orderedRow[key] = row[key];
        });
        return orderedRow;
    });

    if (loading) return <Loader height="70vh" />;

    return (
        <Page container='fluid'>
            {data !== undefined && (
                <CustomTable
                    largeTable
                    id={'tutor-table'}
                    columnOrder={columnOrder}
                    data={orderedData()}
                    isLoading={loading}
                    columnsNotShown={['lastName']}
                    overrideColumns={[
                        {
                            key: 'name',
                            render: (row: any) => {
                                return (
                                    userCan('get_tutors', 'tutors')
                                        ? (
                                            <Link to={`${menuRoutes.tutors.path}/${row.row.original.id}/profile${menuRoutes.tutors.profile.info}`}>
                                                <Text className='font-bold text-primary'>{`${row.row.original.name} ${row.row.original.lastName || ''}`}</Text>
                                            </Link>
                                        )
                                        : <Text className='font-bold text-primary'>`${row.row.original.name} ${row.row.original.lastName || ''}`</Text>
                                )
                            },
                        },
                        {
                            key: 'tutorType',
                            render: (row: any) => { return tutorType.find((type) => type.value === row.row.original.tutorType)?.label },
                        },
                        {
                            key: 'availability',
                            render: (row: any) => {
                                return row.row.original.availability.map((a: any) => availability.find((av) => av.value === a)?.label).join(', ');
                            },
                        },
                        {
                            key: 'lastLogin',
                            render: (row: any) => {
                                const date = row.row.original?.lastLoginAt?.date;
                                return date ? capitalize(formatDistance(new Date(date), new Date(), { addSuffix: true, locale: es })) : 'Nunca';
                            },
                        },
                        {
                            key: 'active',
                            render: (row: any) => {
                                return (
                                    <Tooltip
                                        size="sm"
                                        content={row.row.original.active ? 'Desactivar tutor' : 'Activar tutor'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <Switch
                                            id={row.row.original.id}
                                            checked={row.row.original.active}
                                            onChange={() => toggleStatus(row.row.original.id, row.row.original.active)}
                                            switchKnobClassName='bg-secondary'
                                        />
                                    </Tooltip>
                                );
                            },
                        },
                    ]}
                    actions={[
                        {
                            label: 'Log in',
                            permissions: { group: 'user', permission: 'login_as_tutor' },
                            render: (row: any) => {
                                if (!row.active) return null;
                                return (
                                    <Tooltip
                                        size="sm"
                                        content={'Acceder como tutor'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <ActionIcon
                                            as="span"
                                            size="sm"
                                            variant="outline"
                                            className="hover:!border-gray-900 hover:text-gray-700 cursor-pointer"
                                            onClick={() => handleLoginAsTutor(row.id)}
                                            disabled={isLoading}
                                        >
                                            {isLoading ? <Spinner color={'dark'} /> : <IoMdLogIn className="h-4 w-4" size={20} />}
                                        </ActionIcon>
                                    </Tooltip>
                                );
                            }
                        },
                        {
                            label: 'View',
                            permissions: { group: 'tutors', permission: 'get_tutors' },
                            render: (row: any) => {
                                return (
                                    <Tooltip
                                        size="sm"
                                        content={'Ver perfil'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <Link
                                            to={`${menuRoutes.tutors.path}/${row.id}/profile${menuRoutes.tutors.profile.info}`}>
                                            <ActionIcon
                                                as="span"
                                                size="sm"
                                                variant="outline"
                                                className="hover:!border-gray-900 hover:text-gray-700"
                                            >
                                                <HiOutlineEye className="h-4 w-4" size={20} />
                                            </ActionIcon>
                                        </Link>
                                    </Tooltip>
                                );
                            },
                        },
                        {
                            label: 'Edit',
                            permissions: { group: 'tutors', permission: 'edit_tutors' },
                            render: (row: any) => {
                                return (
                                    <Tooltip
                                        size="sm"
                                        content={'Editar tutor'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <Link to={`${menuRoutes.tutors.path}/${row.id}/edit`}>
                                            <ActionIcon
                                                as="span"
                                                size="sm"
                                                variant="outline"
                                                className="hover:!border-gray-900 hover:text-gray-700"
                                            >
                                                <MdOutlineModeEditOutline className="h-4 w-4" size={20} />
                                            </ActionIcon>
                                        </Link>
                                    </Tooltip>
                                );
                            },
                        },
                        {
                            label: 'Delete',
                            permissions: { group: 'tutors', permission: 'delete_tutors' },
                            render: (row: any) => {
                                return (
                                    <Tooltip
                                        size="sm"
                                        content={'Eliminar'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <div>
                                            <DeletePopover
                                                title={`Eliminar tutor`}
                                                description={`¿Estás seguro de que deseas eliminar a ${row.name}?`}
                                                onDelete={() => handleDelete(row.id)}
                                            />
                                        </div>
                                    </Tooltip>
                                );
                            },
                        },
                    ]}
                    handleMultipleDelete={handleMultiDelete}
                    filters={filters}
                    updateFilters={updateFilters}
                    updateFilterOrder={updateFilterOrder}
                    defaultOrder={filters.filter_order || undefined}
                    paginationData={{
                        pageSize: filters.limit,
                        currentPage: filters.page,
                        pageCount: (data as TutorsApiResponse) ? data.lastPage : 1,
                        totalCount: data?.totalRegisters,
                        handlePagination: updatePage,
                        handlePerPage: updatePageSize,
                    }}
                    toggleFilters={() => setOpenFilters(!openFilters)}
                />
            )}

            <FilterDrawerView isOpen={openFilters} setOpenDrawer={setOpenFilters} drawerTitle={'Filtros Tutores'}>
                <TutorsFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} />
            </FilterDrawerView>
        </Page>
    );
}

export default TutorsList;