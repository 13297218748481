import React, { useCallback, useEffect, useState } from 'react';
import { FaCommentAlt } from 'react-icons/fa';
import { IoTicketSharp } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { Button, Input, Switch, Tooltip } from 'rizzui';
import { toast } from 'sonner';
import Spinner from '../../../components/bootstrap/Spinner';
import DeletePopover from '../../../components/buttons/DeletePopover';
import ModalForm from '../../../components/forms/ModalForm';
import { Loader } from '../../../components/loader/SpinnerLogo';
import TabNav, { MenuItem } from "../../../components/navigation/TabNav";
import { usePrivilege } from '../../../components/priviledge/PriviledgeProvider';
import useFetch from '../../../hooks/useFetch';
import useHandleErrors from '../../../hooks/useHandleErrors';
import PageHeader from "../../../layout/shared/page-header";
import { setSelectedView } from '../../../redux/clientProfileSlice';
import { RootState } from '../../../redux/store';
import { menuRoutes } from "../../../router/menu";
import { ClientService } from '../../../services/client/clientService';
import { ClientApiResponse } from '../../../type/entities/client-type';
import { AddCommentModal } from '../list/components/AddCommentModal';

const menuItems: MenuItem[] = [
    {
        label: 'Información',
        path: menuRoutes.clients.profile.info,
        permission: {
            group: 'clients',
            action: 'get_clients',
        },
    },
    {
        label: 'Empleados',
        path: menuRoutes.clients.profile.students,
        permission: {
            group: 'clients',
            action: 'get_clients_students',
        },
    },
    {
        label: 'Productos',
        path: menuRoutes.clients.profile.products,
        permission: {
            group: 'clients',
            action: 'get_clients_products',
        },
    },
    {
        label: 'Contratos',
        path: menuRoutes.clients.profile.contracts,
        permission: {
            group: 'clients',
            action: 'get_clients_contracts',
        },
    },
    {
        label: 'Asesoría',
        path: menuRoutes.clients.profile.consultancy,
        permission: {
            group: 'clients',
            action: 'get_clients_consultancy',
        },
    },
    {
        label: 'Facturación',
        path: menuRoutes.clients.profile.invoices,
        permission: {
            group: 'clients',
            action: 'get_clients_invoicing',
        },
    },
    {
        label: 'Llamadas',
        path: menuRoutes.clients.profile.calls,
        permission: {
            group: 'clients',
            action: 'get_clients_calls',
        },
    },
    {
        label: 'Comentarios',
        path: menuRoutes.clients.profile.comments,
        permission: {
            group: 'clients',
            action: 'get_clients_comments',
        },
    },
    {
        label: 'Créditos',
        path: menuRoutes.clients.profile.creditHistory,
        permission: {
            group: 'clients',
            action: 'get_clients_credit_history',
        },
    },
    {
        label: 'Actividad',
        path: menuRoutes.clients.profile.activityHistory,
        permission: {
            group: 'clients',
            action: 'get_clients_activity_history',
        },
    },
];

export default function ClientProfileLayout({ isLoading, children }: { isLoading?: boolean, children: React.ReactNode }) {

    const { selectedView } = useSelector((state: RootState) => state.clientProfile);
    const { id = '' } = useParams<{ id: string }>();
    const { userCan } = usePrivilege();
    const navigate = useNavigate();
    const { handleErrors } = useHandleErrors();
    const service = new ClientService();

    const [loading, setLoading] = useState<boolean>(false);
    const [rowId, setRowId] = useState<string>('');
    const [openCreditModal, setOpenCreditModal] = useState<boolean>(false);
    const [openCommentModal, setOpenCommentModal] = useState<boolean>(false);
    const [credits, setCredits] = useState<number>(0);
    const [changingStatus, setChangingStatus] = useState<string[]>([]);

    const [data, loadingClient, error, refetch] = useFetch(useCallback(async () => {
        if (!id || id === '') return null;
        const response = await service.getClientById(id);
        return response.getResponseData() as ClientApiResponse;
    }, [id]));

    const pageHeader = {
        title: `Perfil de ${data?.companyName || 'Cliente'}`,
        breadcrumb: [
            { name: 'Ventas' },
            { name: 'Gestión de clientes' },
            { name: 'Clientes', href: menuRoutes.clients.path },
            { name: 'Perfil de Cliente' },
        ],
    };

    const addCredits = async () => {
        setLoading(true);
        const response = await (await service.addCredits(rowId, credits)).getResponseData();
        if (response.success) {
            setOpenCreditModal(false);
            setCredits(0);
            toast.success('Créditos actualizados correctamente');
            refetch();
        } else {
            handleErrors(response);
        }
        setLoading(false);
    };

    const toggleState = async (id: string, status: boolean) => {
        try {
            setChangingStatus([...changingStatus, id]);
            const response = (await service.toggleClientState(id, status)).getResponseData();
            if (response.success) {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                refetch();
                toast.success(status ? "Se ha desactivado el cliente" : "Se ha activado el cliente");
            } else {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                toast.error(response.message);
            }
        } catch (error: any) {
            setChangingStatus(changingStatus.filter((item) => item !== id));
            toast.error(error.message);
        }
    };

    const handleDelete = async (id: string) => {
        try {
            const response = await (await service.deleteClient(id)).getResponseData();
            if (response.success) {
                navigate(menuRoutes.clients.path);
                toast.success('Cliente eliminado correctamente');
            } else {
                handleErrors(response);
            }
        } catch (error) {
            handleErrors(error);
        }
    };

    useEffect(() => {
        navigate(`${menuRoutes.clients.path}/${id}/profile${selectedView}`);
    }, [selectedView]);

    return (
        <>
            <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb}>
                <div className='flex flex-row flex-nowrap gap-3 mt-3 md:mt-0'>
                    {userCan('edit_clients', 'clients') && (
                        <>
                            {data && userCan('add_client_credits', 'clients') && (
                                <>
                                    <Tooltip
                                        size="sm"
                                        content={'Añadir créditos'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <Button
                                            variant="outline"
                                            className="hover:!border-gray-900 hover:text-gray-700 cursor-pointer"
                                            onClick={() => { setRowId(data.id); setOpenCreditModal(true) }}
                                            disabled={loading}
                                        >
                                            {loading ? <Spinner color={'dark'} /> : <IoTicketSharp className="h-4 w-4" size={20} />}
                                        </Button>
                                    </Tooltip>
                                    <div className='border-l border-gray-300' />
                                </>
                            )}

                            {data && userCan('create_client_comments', 'clients') && (
                                <>
                                    <Tooltip
                                        size="sm"
                                        content={'Añadir comentario'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <Button
                                            variant="outline"
                                            className="hover:!border-gray-900 hover:text-gray-700 cursor-pointer"
                                            onClick={() => { setRowId(data.id); setOpenCommentModal(true) }}
                                        >
                                            <FaCommentAlt className="h-4 w-4" size={20} />
                                        </Button>
                                    </Tooltip>
                                    <div className='border-l border-gray-300' />
                                </>
                            )}

                            {data && (
                                <Tooltip
                                    size="sm"
                                    content={data.active ? 'Desactivar cliente' : 'Activar cliente'}
                                    placement="top"
                                    color="invert"
                                >
                                    <Switch
                                        id={id}
                                        label={data.active ? 'Activo' : 'Desactivado'}
                                        checked={data.active}
                                        onChange={() => toggleState(data.id, data.active)}
                                        switchKnobClassName='bg-secondary'
                                    />
                                </Tooltip>
                            )}

                            <div className='border-l border-gray-300' />

                            <Tooltip content={'Editar cliente'} placement={'top'} color={'invert'} size={'sm'}>
                                <Button variant={'outline'} color={'primary'} size={'md'} onClick={() => {
                                    navigate(`${menuRoutes.clients.path}/${id}/edit`)
                                }}>
                                    Editar
                                </Button>
                            </Tooltip>
                        </>
                    )}

                    {userCan('delete_clients', 'clients') && (
                        <DeletePopover
                            title={`Eliminar cliente`}
                            description={`¿Estás seguro de que deseas eliminar el cliente?`}
                            size={'md'}
                            onDelete={() => handleDelete(id)}
                            actionIconClassName='h-auto w-10'
                        />
                    )}
                </div>
            </PageHeader>

            <ModalForm isOpen={openCreditModal} onClose={() => setOpenCreditModal(false)} title='Añadir actualización de créditos'>
                <Input
                    label='Créditos'
                    type='number'
                    value={credits}
                    onChange={(e) => setCredits(parseInt(e.target.value))}
                />

                <div className='flex justify-center p-7'>
                    <Button
                        disabled={isLoading}
                        onClick={() => addCredits()}
                        color='secondary'
                    >
                        Actualizar créditos
                    </Button>
                </div>
            </ModalForm>

            <AddCommentModal service={(new ClientService).addComment} id={rowId} isOpen={openCommentModal} onClose={() => setOpenCommentModal(false)} refetch={refetch} />

            <TabNav menuItems={menuItems} setSelectedView={setSelectedView} />

            {isLoading ? <Loader height='60vh' /> : children}
        </>
    );
}