import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Input } from 'rizzui';
import CustomSelect from '../../../../../components/forms/CustomSelect';
import useOrderTypes from "../../../../../hooks/api-calls/orders/useOrderTypes";
import useFetch from '../../../../../hooks/useFetch';
import FormGroup from '../../../../../layout/shared/form-group';
import { OrderService } from "../../../../../services/order/orderService";
import { OrderApiResponse } from "../../../../../type/entities/order-type";
import cn from '../../../../../utils/classNames';
import OrderProfileLayout from "../../OrderProfileLayout";

const OrderInfo = () => {

    const { id = '' } = useParams<{ id: string }>();

    const [data] = useFetch(useCallback(async () => {
        if (!id || id === '') return null;
        const response = await (new OrderService()).getOrderById(id);
        return response.getResponseData() as OrderApiResponse;
    }, [id]));

    const { getOrderTypesList } = useOrderTypes();

    return (
        <OrderProfileLayout>
            <div className="@container">
                <div className="grid grid-cols-12 gap-5 2xl:h-[75vh]">
                    <div className="col-span-12 2xl:col-span-9 overflow-y-auto h-full">
                        <FormGroup
                            title="Información General"
                            description="Datos asociados al pedido."
                            className={cn("py-4 @3xl:grid-cols-12 ")}
                            titleCols="@md:col-span-2"
                            childCols="@md:col-span-10 md:grid-cols-4"
                        >
                            <div className="grid grid-cols-12 col-span-8 gap-4">
                                <CustomSelect
                                    isDisabled={true}
                                    id={'orderTypeId'}
                                    label="Tipo de Pedido"
                                    value={getOrderTypesList()?.find((g: any) => g.value === data?.orderTypes?.id)}
                                    options={getOrderTypesList()}
                                    required
                                    containerClassName='col-span-12 md:col-span-12'
                                />

                                <Input
                                    disabled={true}
                                    id='startDate'
                                    type="date"
                                    className="col-span-12 md:col-span-3"
                                    label="Fecha de inicio"
                                    value={data?.startDate?.date.split(' ')[0]}
                                />

                                <Input
                                    disabled={true}
                                    id='endDate'
                                    type="date"
                                    className="col-span-12 md:col-span-3"
                                    label="Fecha de fin"
                                    value={data?.endDate?.date.split(' ')[0]}
                                />

                                <Input
                                    disabled={true}
                                    id='collectionDate'
                                    required={false}
                                    type="date"
                                    className="col-span-12 md:col-span-3"
                                    label="Fecha de cobro"
                                    value={data?.collectionDate?.date.split(' ')[0]}
                                />

                                <Input
                                    disabled={true}
                                    id='orderDate'
                                    required={false}
                                    type="date"
                                    className="col-span-12 md:col-span-3"
                                    label="Fecha de pedido"
                                    value={data?.orderDate?.date.split(' ')[0]}
                                />

                                <Input
                                    disabled={true}
                                    id='calendarDays'
                                    required={false}
                                    type="text"
                                    className="col-span-12 md:col-span-3"
                                    label="Dias naturales"
                                    value={data?.calendarDays}
                                />

                                <Input
                                    disabled={true}
                                    id='workingDays'
                                    required={false}
                                    type="text"
                                    className="col-span-12 md:col-span-3"
                                    label="Dias laborables"
                                    value={data?.workingDays}
                                />
                            </div>
                        </FormGroup>
                    </div>
                </div>

            </div>
        </OrderProfileLayout>
    );
};

export default OrderInfo;