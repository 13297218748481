import React, { useCallback, useState } from 'react';
import { MdOutlineModeEditOutline } from "react-icons/md";
import { Link } from 'react-router-dom';
import { ActionIcon, Switch, Tooltip } from 'rizzui';
import { toast } from 'sonner';
import DeletePopover from "../../../components/buttons/DeletePopover";
import { useFiltersPR } from '../../../components/providers/FiltersProvider';
import { FilterDrawerView } from '../../../components/table/components/TableFilter';
import CustomTable from '../../../components/table/CustomTable';
import useFetch from '../../../hooks/useFetch';
import useHandleErrors from '../../../hooks/useHandleErrors';
import Page from '../../../layout/Page/Page';
import { menuRoutes } from '../../../router/menu';
import { ProductService } from "../../../services/product/productService";
import { ProductsApiResponse } from "../../../type/entities/product-type";
import { fixNumber } from '../../../utils/formatNumber';
import ProductsFilters from './ProductsFilters';

interface ProductsListProps { }

const columnOrder = [
    'id',
    'name',
    'productType',
    'code',
    'amount',
    'weeklyHours',
    'description',
    'active',
    'createdAt',
    'updatedAt',
];

const ProductsList: React.FC<ProductsListProps> = () => {

    const { handleErrors } = useHandleErrors();
    const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters } = useFiltersPR();
    const service = new ProductService();

    const [changingStatus, setChangingStatus] = useState<string[]>([]);
    const [openFilters, setOpenFilters] = useState<boolean>(false);

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = await service.listProducts(filters);
        return response.getResponseData() as ProductsApiResponse;
    }, [filters]));

    const toggleStatus = async (id: string, status: boolean) => {
        try {
            setChangingStatus([...changingStatus, id]);
            const response = (await service.toggleProductStatus(id, status)).getResponseData();
            if (response.success) {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                refetch();
                toast.success(status ? "Se ha desactivado el producto" : "Se ha activado el producto");
            } else {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                toast.error(response.message);
            }
        } catch (error: any) {
            setChangingStatus(changingStatus.filter((item) => item !== id));
            toast.error(error.message);
        }
    };

    const handleDelete = async (id: string) => {
        const response = await (await service.deleteProduct(id)).getResponseData();
        if (response.success) {
            toast.success('Producto eliminado correctamente');
            refetch();
        } else {
            handleErrors(response);
        }
    };

    const handleMultiDelete = async (ids: string[]) => {
        const response = await (await service.deleteMultiProducts(ids)).getResponseData();
        if (response.success) {
            toast.success('Productos eliminados correctamente');
            refetch();
        } else {
            handleErrors(response);
        }
    };

    /**
     * Order the data to match the column order
     */
    const orderedData = () => data?.data.map((row: any) => {
        const orderedRow: any = {};
        columnOrder.forEach((key) => {
            orderedRow[key] = row[key];
        });
        return orderedRow;
    });


    return (
        <Page container='fluid'>
            {data !== undefined && (
                <CustomTable
                    id={'products-table'}
                    columnOrder={columnOrder}
                    data={orderedData()}
                    isLoading={loading}
                    columnsNotShown={[]}
                    overrideColumns={[
                        {
                            key: 'productType',
                            render: (row: any) => { return row.row.original.productType?.name },
                        },
                        {
                            key: 'amount',
                            render: (row: any) => { return row.row.original.amount ? fixNumber(row.row.original.amount) + '€' : 'N/A' },
                        },
                        {
                            key: 'weeklyHours',
                            render: (row: any) => { return row.row.original.weeklyHours ? row.row.original.weeklyHours + ' h' : 'N/A' },
                        },
                        {
                            key: 'active',
                            render: (row: any) => {
                                return (
                                    <Switch
                                        id={row.row.original.id}
                                        checked={row.row.original.active}
                                        onChange={() => toggleStatus(row.row.original.id, row.row.original.active)}
                                        switchKnobClassName='bg-secondary'
                                    />
                                );
                            },
                        },
                    ]}
                    actions={[
                        {
                            label: 'Edit',
                            permissions: { group: 'products', permission: 'edit_products' },
                            render: (row: any) => {
                                return (
                                    <Tooltip
                                        size="sm"
                                        content={'Editar producto'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <Link to={`${menuRoutes.products.path}/${row.id}/edit`}>
                                            <ActionIcon
                                                as="span"
                                                size="sm"
                                                variant="outline"
                                                className="hover:!border-gray-900 hover:text-gray-700"
                                            >
                                                <MdOutlineModeEditOutline className="h-4 w-4" size={20} />
                                            </ActionIcon>
                                        </Link>
                                    </Tooltip>
                                );
                            },
                        },
                        {
                            label: 'Delete',
                            permissions: { group: 'products', permission: 'delete_products' },
                            render: (row: any) => {
                                return (
                                    <Tooltip
                                        size="sm"
                                        content={'Eliminar'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <div>
                                            <DeletePopover
                                                title={`Eliminar producto`}
                                                description={`¿Estás seguro de que deseas eliminar el ${row.name}?`}
                                                onDelete={() => handleDelete(row.id)}
                                            />
                                        </div>
                                    </Tooltip>
                                );
                            },
                        },
                    ]}
                    handleMultipleDelete={handleMultiDelete}
                    filters={filters}
                    updateFilters={updateFilters}
                    updateFilterOrder={updateFilterOrder}
                    defaultOrder={filters.filter_order || undefined}
                    paginationData={{
                        pageSize: filters.limit,
                        currentPage: filters.page,
                        pageCount: (data as ProductsApiResponse) ? data.lastPage : 1,
                        totalCount: data?.totalRegisters,
                        handlePagination: updatePage,
                        handlePerPage: updatePageSize,
                    }}
                    toggleFilters={() => setOpenFilters(!openFilters)}
                />
            )}

            <FilterDrawerView isOpen={openFilters} setOpenDrawer={setOpenFilters} drawerTitle={'Filtros Productos'}>
                <ProductsFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} />
            </FilterDrawerView>
        </Page>
    );
};

export default ProductsList;