import { SVGProps } from 'react';

const SvgLocalCafe = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
			<path d='M0 0h24v24H0V0z' fill='none' />
			<path d='M8 15h6c1.1 0 2-.9 2-2V5H6v8c0 1.1.9 2 2 2z' opacity={0.3} />
			<path d='M2 19h18v2H2zm2-6c0 2.21 1.79 4 4 4h6c2.21 0 4-1.79 4-4v-3h2c1.11 0 2-.89 2-2V5c0-1.11-.89-2-2-2H4v10zm14-8h2v3h-2V5zM6 5h10v8c0 1.1-.9 2-2 2H8c-1.1 0-2-.9-2-2V5z' />
		</svg>
	);
};

export default SvgLocalCafe;
