import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import AttivaCard from '../../../components/card/AttivaCard';
import PageHeader from '../../../layout/shared/page-header';
import { menuRoutes } from '../../../router/menu';
import { ConsultancyService } from '../../../services/consultancy/consultancyService';
import ConsultancyForm from '../ConsultancyForm';

interface CreateConsultancyProps { }

const pageHeader = {
    title: 'Crear Asesoría',
    breadcrumb: [
        {
            href: menuRoutes.consultancies.path,
            name: 'Asesorías',
        },
        {
            name: 'Crear',
        },
    ],
};

const CreateConsultancy: React.FC<CreateConsultancyProps> = ({ }) => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);

    const handleSubmit = async (values: any) => {
        setLoading(true);
        try {
            const response = await (await (new ConsultancyService()).createConsultancy(values)).getResponseData();
            if (response.success) {
                navigate(-1);
                toast.success('Asesoría creada correctamente');
            } else {
                response.data.errors.forEach((error: any) => {
                    toast.error(error.message);
                });
            }
        } catch (error: any) {
            toast.error('Error al crear la asesoría');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb} />

            <AttivaCard title='Datos Principales' className='mb-4'>
                <ConsultancyForm submit={handleSubmit} isLoading={loading} />
            </AttivaCard>
        </>
    );
};

export default CreateConsultancy;