import React from 'react';
import HorizontalFormBlockWrapper from '../../components/forms/HorizontalFormBlockWrapper';
import RequiredInput from '../../components/forms/InputHOC';
import PageHeader from '../../layout/shared/page-header';
import { menuRoutes } from '../../router/menu';
import FormFooter from '../_layout/_footers/form-footer';
import { useSettingsProvider } from './provider/SettingsProvider';

const TutorSettingsPage: React.FC = () => {

    const { formik, isLoading, companyConfig, hasCompanyConfigChanges, updateCompanyConfig, setShowModal, discardCompanyConfigChanges } = useSettingsProvider();

    const pageHeader = {
        title: 'Ajustes en Tutores',
        breadcrumb: [
            { name: 'Inicio', href: menuRoutes.dashboard.path },
            { name: 'Configuración' },
            { name: 'Tutores', href: menuRoutes.settings.path + menuRoutes.settings.invoices },
        ]
    }
    return (
        <div className='h-full overflow-auto scrollbar '>
            <PageHeader
                title={pageHeader.title}
                breadcrumb={pageHeader.breadcrumb}
                className='border-b-4 border-dotted border-grey-500 top-0'
            />
            <HorizontalFormBlockWrapper
                title="Configuración de Tutores"
                description="Sección de configuración dedicada a los tutores registrados en el sistema."
                descriptionClassName="max-w-[50%]"
                childrenWrapperClassName="@3xl:grid-cols-1 max-w-[50%] w-full"
            >
                <div className='pb-4'>
                    <RequiredInput
                        required={true}
                        id="invoiceName"
                        label="Máximo de tutorias en horario de mañana"
                        value={companyConfig?.tutorMaxMorningTutorials}
                        type={"number"}
                        defaultValue={companyConfig?.tutorMaxMorningTutorials ?? 1}
                        onChange={(e) => {
                            updateCompanyConfig('tutorMaxMorningTutorials', e.target.value);
                            formik.setFieldValue('tutorMaxMorningTutorials', e.target.value);
                        }}
                        error={formik.errors.tutorMaxMorningTutorials}
                    />
                </div>
                <div className='pb-4'>
                    <RequiredInput
                        required={true}
                        id="invoiceName"
                        label="Máximo de tutorias en horario de tarde"
                        value={companyConfig?.tutorMaxAfternoonTutorials}
                        type={"number"}
                        defaultValue={companyConfig?.tutorMaxMorningTutorials ?? 1}
                        onChange={(e) => {
                            updateCompanyConfig('tutorMaxAfternoonTutorials', e.target.value);
                            formik.setFieldValue('tutorMaxAfternoonTutorials', e.target.value);
                        }}
                        error={formik.errors.tutorMaxAfternoonTutorials}
                    />
                </div>
            </HorizontalFormBlockWrapper>
            {hasCompanyConfigChanges && (
                <FormFooter
                    customClassName={"w-full"}
                    submitBtnText={"Aplicar Cambios"}
                    cancelBtnText={"Descartar Cambios"}
                    handleCancelBtn={() => discardCompanyConfigChanges()}
                    handleSubmitBtn={() => setShowModal(true)}
                    isLoading={isLoading}
                    isDisabled={Object.keys(formik.errors).length > 0}
                />

            )}
        </div>
    );
};

export default TutorSettingsPage;