import moment from 'moment';
import React, { useCallback } from 'react';
import { MdOutlineDateRange } from 'react-icons/md';
import { PiEnvelopeSimple } from 'react-icons/pi';
import { useParams } from 'react-router-dom';
import { Input, Switch, Textarea } from 'rizzui';
import PhoneInput from '../../../../../components/forms/PhoneInputWrapper';
import useFetch from '../../../../../hooks/useFetch';
import FormGroup from '../../../../../layout/shared/form-group';
import { StudentService } from '../../../../../services/student/StudentService';
import { StudentApiResponse } from '../../../../../type/entities/student-type';
import StudentProfileLayout from '../../StudentProfileLayout';

interface StudentInfoProps { }

const StudentInfo: React.FC<StudentInfoProps> = () => {

    const { id = '' } = useParams<{ id: string }>();

    const [data, loading] = useFetch(useCallback(async () => {
        if (!id || id === '') return null;
        const response = await (new StudentService()).getStudentById(id);
        return response.getResponseData() as StudentApiResponse;
    }, [id]));

    return (
        <StudentProfileLayout isLoading={loading}>
            <div className="@container">
                <div className="grid gap-7 divide-y-2 divide-dashed divide-gray-200">
                    <FormGroup
                        title="Información General"
                        description="Datos principales del empleado"
                        className='pt-6'
                        titleCols="@md:col-span-2"
                        childCols="@md:col-span-10 md:grid-cols-12"
                    >
                        <Input
                            disabled
                            id='name'
                            label="Nombre"
                            className="md:col-span-3"
                            value={data?.name ?? ""}
                        />

                        <Input
                            disabled
                            id='firstName'
                            label="Primer Apellido"
                            className="md:col-span-3"
                            value={data?.firstName ?? ""}
                        />

                        <Input
                            disabled
                            id='lastName'
                            label="Segundo Apellido"
                            className="md:col-span-3"
                            value={data?.lastName ?? ""}
                        />

                        <Input
                            disabled
                            id='nif'
                            label="NIF"
                            className="md:col-span-3"
                            value={data?.nif ?? ""}
                        />

                        <Input
                            disabled
                            id='socialSecurityNumber'
                            label="Número de Seguridad Social"
                            className="md:col-span-4"
                            value={data?.socialSecurityNumber ?? ""}
                        />

                        <Input
                            disabled
                            prefix={<PiEnvelopeSimple className="h-6 w-6 text-gray-500" />}
                            id='email'
                            type="email"
                            label="Correo Electrónico"
                            className="md:col-span-4"
                            value={data?.email ?? ""}
                        />

                        <PhoneInput
                            disabled
                            id='telephone'
                            label='Teléfono'
                            className="md:col-span-4"
                            value={data?.telephone ?? ""}
                            required={false}
                        />

                        <PhoneInput
                            disabled
                            id='telephoneSecondary'
                            label='Teléfono Secundario'
                            className="md:col-span-4"
                            value={data?.telephoneSecondary ?? ""}
                            required={false}
                        />

                        <Input
                            disabled
                            id={'gender'}
                            label="Género"
                            className="md:col-span-4"
                            value={data?.gender ? (data.gender === 'male' ? 'Masculino' : 'Femenino') : ""}
                        />

                        <Input
                            disabled
                            id='birthDate'
                            prefix={<MdOutlineDateRange className="h-6 w-6 text-gray-500" />}
                            label="Fecha de Nacimiento"
                            className="md:col-span-4"
                            value={moment(data?.birthDate?.date).format('DD/MM/YYYY') ?? ""}
                        />

                    </FormGroup>

                    <FormGroup
                        title="Datos adicionales"
                        description="Información adicional del empleado"
                        className='pt-6'
                        titleCols="@md:col-span-2"
                        childCols="@lg:col-span-10 md:grid-cols-12"
                    >
                        <Input
                            disabled
                            id={'clientId'}
                            label="Cliente"
                            className='md:col-span-4'
                            value={data?.client?.companyName ?? ""}
                        />

                        <Input
                            disabled
                            id={'studyId'}
                            label="Educación"
                            className='md:col-span-4'
                            value={data?.study?.name ?? ""}
                        />

                        <Input
                            disabled
                            id={'professionalCategoryId'}
                            label="Categoria Profesional"
                            className='md:col-span-4'
                            value={data?.professionalCategory?.name ?? ""}
                        />

                        <Input
                            disabled
                            id={'quotationGroupId'}
                            label="Grupo de Cotización"
                            className='md:col-span-4'
                            value={data?.quotationGroup?.name ?? ""}
                        />

                        <Input
                            disabled
                            id='functionalArea'
                            label="Área Funcional"
                            className="md:col-span-4"
                            value={data?.functionalArea ?? ""}
                        />

                        <Input
                            disabled
                            id='alternativeShippingAddress'
                            label="Dirección de envío alternativa"
                            className="md:col-span-4"
                            value={data?.alternativeShippingAddress ?? ""}
                        />

                        <Input
                            disabled
                            id='contactSchedule'
                            label="Horario de contacto"
                            className="md:col-span-4"
                            value={data?.contactSchedule ?? ""}
                        />

                        <div className='md:col-span-8'>
                            <div className='grid md:grid-cols-3 md:mt-6'>
                                <Switch
                                    disabled
                                    id='disability'
                                    label="¿Tiene alguna discapacidad?"
                                    switchKnobClassName='bg-secondary'
                                    className="[&>label>span]:font-medium"
                                    checked={data?.disability}
                                />

                                <Switch
                                    disabled
                                    id='genderViolenceVictim'
                                    label="Victima de violencia de género"
                                    switchKnobClassName='bg-secondary'
                                    className="[&>label>span]:font-medium"
                                    checked={data?.genderViolenceVictim}
                                />

                                <Switch
                                    disabled
                                    id='terrorismVictim'
                                    label="Victima de terrorismo"
                                    switchKnobClassName='bg-secondary'
                                    className="[&>label>span]:font-medium"
                                    checked={data?.terrorismVictim}
                                />
                            </div>
                        </div>

                        <Textarea
                            disabled
                            id='remark'
                            label="Observaciones"
                            className="[&>label>span]:font-medium md:col-span-12"
                            value={data?.remark ?? ""}
                        />
                    </FormGroup>
                </div>
            </div>
        </StudentProfileLayout>
    );
};

export default StudentInfo;