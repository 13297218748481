import moment from "moment";
import { useEffect, useState } from "react";
import { FaRegCalendar, FaRegClipboard } from "react-icons/fa";
import { PiCaretDownBold } from "react-icons/pi";
import { TbPointFilled } from "react-icons/tb";
import { Button, Collapse, Loader, Text, Tooltip } from "rizzui";
import { toast } from "sonner";
import WidgetCard from "../../../components/card/WidgetCard";
import useClientContractsHistory from "../../../hooks/api-calls/contract/useClientContracts";
import { menuRoutes } from "../../../router/menu";
import { ContractService } from "../../../services/contract/contractService";
import cn from "../../../utils/classNames";

interface ClientContractHistoryProps {
    clientId: string;
    formik: any;
}
const ClientContractHistory = ({ clientId, formik }: ClientContractHistoryProps) => {

    const rowLimit = 5;
    const [isLoading, setIsLoading] = useState(false);

    const { updateClientId, getClientContractsList, modifyLimitClientContracts, isMoreClientContracts, isLoadingClientContracts } = useClientContractsHistory({ client: clientId, is_draft: false, active: true }, {}, rowLimit);

    useEffect(() => {
        if (clientId) updateClientId(clientId);
    }, [clientId]);

    async function updateContractFormData(contractId: string) {
        const response = await (new ContractService()).getContractById(contractId);
        const contract = response.getResponseData().data;

        // CLIENTS FIELDS
        formik.setFieldValue('clientActivity', contract.clientActivity);
        formik.setFieldValue('clientAddress', contract.clientAddress);
        formik.setFieldValue('clientCifNif', contract.clientCifNif);
        formik.setFieldValue('clientCity', contract.clientCity);
        formik.setFieldValue('clientCnae', contract.clientCnae);
        formik.setFieldValue('clientCompanyCreationDate', contract.clientCompanyCreationDate?.date.split(' ')[0]);
        formik.setFieldValue('clientCompanyName', contract.clientCompanyName);
        formik.setFieldValue('clientEmail', contract.clientEmail);
        formik.setFieldValue('clientIban', contract.clientIban);
        formik.setFieldValue('clientPostalCode', contract.clientPostalCode);
        formik.setFieldValue('clientProvince', contract.clientProvince);
        formik.setFieldValue('clientSocialSecurityNumber', contract.clientSocialSecurityNumber);
        formik.setFieldValue('clientTelephone', contract.clientTelephone);
        formik.setFieldValue('clientTelephoneSecondary', contract.clientTelephoneSecondary);
        formik.setFieldValue('clientTelephoneThird', contract.clientTelephoneThird);
        formik.setFieldValue('clientWorkersNumber', contract.clientWorkersNumber);

        // CONTRACTS FIELDS
        formik.setFieldValue('campaignId', contract.campaign?.id || '');
        formik.setFieldValue('studentId', contract.student?.id || '');
        formik.setFieldValue('studentStudyId', contract.studentStudy?.id || '');
        formik.setFieldValue('studentProfessionalCategoryId', contract.studentProfessionalCategory?.id || '');
        formik.setFieldValue('productId', contract.product?.id || '');
        formik.setFieldValue('productTypeId', contract.productType?.id || '');
        formik.setFieldValue('commercialId', contract.commercial?.id || '');
        formik.setFieldValue('commercialNetworkId', contract.commercialNetwork?.id || '');
        formik.setFieldValue('organisingCorporationId', contract.organisingCorporation?.id || '');
        formik.setFieldValue('providingCorporationId', contract.providingCorporation?.id || '');
        formik.setFieldValue('totalAmount', contract.totalAmount as number);
        formik.setFieldValue('groupings', contract.groupings);
        // formik.setFieldValue('currentContractStatusId', contract.currentContractStatus?.id || '');
        formik.setFieldValue('draftExpirationDate', contract.draftExpirationDate?.date.split(' ')[0]);
        formik.setFieldValue('saveAsDraft', contract.saveAsDraft);

        // LEGAL REPRESENTATIVE FIELDS
        formik.setFieldValue('legalRepresentativeEmail', contract.legalRepresentativeEmail);
        formik.setFieldValue('legalRepresentativeName', contract.legalRepresentativeName);
        formik.setFieldValue('legalRepresentativeNif', contract.legalRepresentativeNif);
        formik.setFieldValue('legalRepresentativePosition', contract.legalRepresentativePosition);
        formik.setFieldValue('legalRepresentativeSchedule', contract.legalRepresentativeSchedule);

        // PRODUCT FIELDS
        formik.setFieldValue('productAmount', contract.productAmount as number);


        // STUDENT FIELDS
        formik.setFieldValue('studentQuotationGroupId', contract.studentQuotationGroup?.id || '');
        formik.setFieldValue('studentName', contract.studentName);
        formik.setFieldValue('studentFirstName', contract.studentFirstName);
        formik.setFieldValue('studentLastName', contract.studentLastName);
        formik.setFieldValue('studentNif', contract.studentNif);
        formik.setFieldValue('studentGender', contract.studentGender);
        formik.setFieldValue('studentTerrorismVictim', contract.studentTerrorismVictim);
        formik.setFieldValue('studentGenderViolenceVictim', contract.studentGenderViolenceVictim);
        formik.setFieldValue('studentDisability', contract.studentDisability);
        formik.setFieldValue('studentSocialSecurityNumber', contract.studentSocialSecurityNumber);
        formik.setFieldValue('studentEmail', contract.studentEmail);
        formik.setFieldValue('studentTelephone', contract.studentTelephone);
        formik.setFieldValue('studentTelephoneSecondary', contract.studentTelephoneSecondary);
        formik.setFieldValue('studentFunctionalArea', contract.studentFunctionalArea);
        formik.setFieldValue('studentAlternativeShippingAddress', contract.studentAlternativeShippingAddress);
        formik.setFieldValue('studentContactSchedule', contract.studentContactSchedule);
        formik.setFieldValue('studentBirthDate', contract.studentBirthDate?.date.split(' ')[0]);

        // CONSULTANCY FIELDS
        formik.setFieldValue('consultancyId', contract.consultancy?.id || '');
        formik.setFieldValue('consultancyName', contract.consultancyName || '');
        formik.setFieldValue('consultancyEmail', contract.consultancyEmail);
        formik.setFieldValue('consultancyDescription', contract.consultancyDescription);

        setIsLoading(false);
        toast.info('Contrato copiado correctamente');
    }

    function ContractHistoryContent(arrayData: any) {

        return (
            <div className="space-y-2 p-0.5">
                {arrayData.map((activity: any) => (
                    <div
                        key={activity.id}
                        className="group cursor-pointer space-y-1 rounded-lg bg-gray-50 p-4 transition-shadow hover:shadow dark:bg-gray-100"
                    >
                        <div className="flex items-center justify-between w-full">
                            <Text className="font-semibold group-hover:underline"
                                onClick={() => window.open(`${menuRoutes.contracts.path}?filter_filters=%7B"search_text"%3A"${activity.title}"%7D&filter_order=%5B%5D&page=1&limit=50`, '_blank')}>
                                {activity.title}
                            </Text>
                            <div className="flex flex-row flex-nowrap items-center gap-2">
                                <FaRegCalendar />
                                <Text className="text-gray-400 text-nowrap">
                                    {moment(activity.date).format('DD/MM/YYYY HH:mm')}
                                </Text>
                            </div>
                        </div>
                        <div className="flex flex-col xl:flex-row flex-nowrap justify-between gap-8">
                            <div className="w-full">
                                <div className="flex items-center mt-2">
                                    <span className="text-gray-400">Tipo Producto:&nbsp;</span>
                                    <Text className="ms-1.5 text-sm font-medium capitalize text-gray-700">{activity.productType || "N/A"}</Text>
                                </div>

                                <div className="flex items-center mt-2">
                                    <span className="text-gray-400">Producto:&nbsp;</span>
                                    <Text className="ms-1.5 text-sm font-medium capitalize text-gray-700">{activity.product || "N/A"}
                                    </Text>
                                </div>
                                <div className="flex items-center mt-2">
                                    <span className="text-gray-400">Estado:&nbsp;</span>
                                    {activity.contractStatus ?
                                        (
                                            <>
                                                <TbPointFilled className="shrink-0 text-lg" color={activity.contractStatus.color} />
                                                <Text className="ms-1.5 text-sm font-medium capitalize text-gray-700">
                                                    {activity.contractStatus.label}
                                                </Text>
                                            </>
                                        ) : (<Text className="text-gray-400">N/A</Text>)
                                    }
                                </div>
                            </div>
                            <Tooltip size="sm" content="Copiar Contrato" placement="top" color="invert">
                                <Button variant="outline"
                                    className={cn('m-auto bg-primary-500 text-white hover:text-white hover:bg-primary-700', isLoading && 'bg-gray-400')}
                                    disabled={isLoading}
                                    onClick={() => {
                                        setIsLoading(true);
                                        updateContractFormData(activity.id);
                                    }}
                                >
                                    {!isLoading ? <FaRegClipboard /> : <Loader />}
                                </Button>
                            </Tooltip>

                        </div>
                    </div>
                ))}
            </div>
        );
    }

    return (
        <div className={cn("my-3")}>
            <Collapse
                className="py-3"
                defaultOpen={true}
                header={({ open, toggle }) => (
                    <button
                        type="button"
                        onClick={toggle}
                        className="flex w-full cursor-pointer items-center justify-between text-base font-semibold text-gray-900"
                    >
                        <div>
                            Historial de Contratos
                            <span className="text-gray-400">
                                &nbsp;&nbsp;
                                {getClientContractsList().length >= 1 ?
                                    `(${getClientContractsList().length})` : '(0)'}
                            </span>
                        </div>
                        <PiCaretDownBold
                            strokeWidth={3}
                            className={cn(
                                "h-3.5 w-3.5 -rotate-90 text-gray-500 transition-transform duration-200 rtl:rotate-90",
                                open && "rotate-0 rtl:rotate-0"
                            )}
                        />
                    </button>
                )}
            >
                <WidgetCard title="" headerClassName="hidden" className="border-transparent p-2 lg:p-3">
                    {!isLoadingClientContracts && getClientContractsList().length >= 1 ? (
                        ContractHistoryContent(getClientContractsList())
                    ) : (
                        <div className="flex items-center justify-center h-18">

                            {isLoadingClientContracts ? <Loader variant="spinner" /> : <Text className="text-gray-400">Sin Resultados</Text>}
                        </div>
                    )}
                    <div className="w-full flex flex-row gap-2 justify-center">
                        <Button
                            variant="outline"
                            className={cn("border-gray-300 w-[45%] mt-2", !isMoreClientContracts && 'hidden')}
                            onClick={() => modifyLimitClientContracts(rowLimit)}
                        >
                            <Text className="text-gray-400">Ver Más</Text>
                        </Button>
                        <Button
                            variant="outline"
                            className={cn("border-gray-300 w-[45%] mt-2", getClientContractsList().length <= rowLimit && 'hidden')}
                            onClick={() => modifyLimitClientContracts(-rowLimit)}
                        >
                            <Text className="text-gray-400">Ver Menos</Text>
                        </Button>
                    </div>
                </WidgetCard>
            </Collapse>
        </div>
    )
}

export default ClientContractHistory;