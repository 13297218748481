import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { MdOutlineModeEditOutline } from "react-icons/md";
import { Link } from 'react-router-dom';
import { ActionIcon, Switch, Tooltip } from 'rizzui';
import { toast } from 'sonner';
import DeletePopover from '../../../components/buttons/DeletePopover';
import { Loader } from '../../../components/loader/SpinnerLogo';
import { useFiltersPR } from '../../../components/providers/FiltersProvider';
import { FilterDrawerView } from '../../../components/table/components/TableFilter';
import CustomTable from '../../../components/table/CustomTable';
import useFetch from '../../../hooks/useFetch';
import useHandleErrors from '../../../hooks/useHandleErrors';
import Page from '../../../layout/Page/Page';
import { menuRoutes } from '../../../router/menu';
import { OrganisationService } from '../../../services/organisation/organisationService';
import { OrganisationsApiResponse } from '../../../type/entities/organisation-type';
import OrganisationsFilters from './OrganisationsFilters';

interface OrganisationsListProps { }

const columnOrder = [
    'id',
    'logo',
    'cif',
    'address',
    'active',
    'createdAt',
    'name',
    'updatedAt',
];

const OrganisationsList: React.FC<OrganisationsListProps> = ({ }) => {

    const { handleErrors } = useHandleErrors();
    const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters } = useFiltersPR();
    const service = new OrganisationService();

    const [changingStatus, setChangingStatus] = useState<string[]>([]);
    const [openFilters, setOpenFilters] = useState<boolean>(false);

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = await service.listOrganisations(filters);
        return response.getResponseData() as OrganisationsApiResponse;
    }, [filters]));

    const toggleStatus = async (id: string, status: boolean) => {
        try {
            setChangingStatus([...changingStatus, id]);
            const response = (await service.toggleOrganisationStatus(id, status)).getResponseData();
            if (response.success) {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                refetch();
                toast.success(status ? "Se ha desactivado la organización" : "Se ha activado la organización");
            } else {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                toast.error(response.message);
            }
        } catch (error: any) {
            setChangingStatus(changingStatus.filter((item) => item !== id));
            toast.error(error.message);
        }
    };

    const handleDelete = async (id: string) => {
        try {
            const response = await (await service.deleteOrganisation(id)).getResponseData();
            if (response.success) {
                refetch();
                toast.success('Se ha eliminado correctamente');
            } else {
                handleErrors(response);
            }
        } catch (error) {
            handleErrors(error);
        }
    };

    const handleMultiDelete = async (ids: string[]) => {
        try {
            const response = await (await service.deleteMultiOrganisations(ids)).getResponseData();
            if (response.success) {
                refetch();
                toast.success('Se han eliminado correctamente');
            } else {
                handleErrors(response);
            }
        } catch (error) {
            handleErrors(error);
        }
    };

    /**
     * Ordenar las columnas según el orden definido en columnOrder
     */
    const orderedData = data?.data.map((row: any) => {
        const orderedRow: any = {};
        columnOrder.forEach((key) => {
            orderedRow[key] = row[key];
        });
        return orderedRow;
    });

    return (
        <Page container='fluid'>
            {loading
                ? <Loader height='70vh' />
                : (
                    <CustomTable
                        id={'organisations-table'}
                        data={orderedData}
                        overrideColumns={[
                            {
                                key: 'createdAt',
                                render: (row: any) => { 
                                    const date = row.row.original?.createdAt?.date;
                                    return date ? moment(date).format('DD/MM/YYYY') : 'N/A';
                                },
                            },
                            {
                                key: 'active',
                                render: (row: any) => {
                                    return (
                                        <Tooltip
                                            size="sm"
                                            content={row.row.original.active ? 'Desactivar organización' : 'Activar organización'}
                                            placement="top"
                                            color="invert"
                                        >
                                            <Switch
                                                id={row.row.original.id}
                                                checked={row.row.original.active}
                                                disabled={changingStatus.includes(row.row.original.id)}
                                                onChange={() => toggleStatus(row.row.original.id, row.row.original.active)}
                                                switchKnobClassName='bg-secondary'
                                            />
                                        </Tooltip>
                                    );
                                },
                            },
                        ]}
                        actions={[
                            {
                                label: 'Edit',
                                permissions: { group: 'companies', permission: 'edit_company' },
                                render: (row: any) => {
                                    return (
                                        <Tooltip
                                            size="sm"
                                            content={'Editar organización'}
                                            placement="top"
                                            color="invert"
                                        >
                                            <Link to={`${menuRoutes.organisations.path}/${row.id}/edit`}>
                                                <ActionIcon
                                                    as="span"
                                                    size="sm"
                                                    variant="outline"
                                                    className="hover:!border-gray-900 hover:text-gray-700"
                                                >
                                                    <MdOutlineModeEditOutline className="h-4 w-4" size={20} />
                                                </ActionIcon>
                                            </Link>
                                        </Tooltip>
                                    );
                                },
                            },
                            {
                                label: 'Delete',
                                permissions: { group: 'companies', permission: 'delete_company' },
                                render: (row: any) => {
                                    return (
                                        <Tooltip
                                            size="sm"
                                            content={'Eliminar'}
                                            placement="top"
                                            color="invert"
                                        >
                                            <div>
                                                <DeletePopover
                                                    title={`Eliminar organización`}
                                                    description={`¿Estás seguro de que deseas eliminar ${row.cif}?`}
                                                    onDelete={() => handleDelete(row.id)}
                                                />
                                            </div>
                                        </Tooltip>
                                    );
                                },
                            },
                        ]}
                        handleMultipleDelete={handleMultiDelete}
                        filters={filters}
                        filter={false}
                        updateFilters={updateFilters}
                        updateFilterOrder={updateFilterOrder}
                        defaultOrder={filters.filter_order || undefined}
                        paginationData={{
                            pageSize: filters.limit,
                            currentPage: filters.page,
                            pageCount: (data as OrganisationsApiResponse) ? data.lastPage : 1,
                            totalCount: data?.totalRegisters,
                            handlePagination: updatePage,
                            handlePerPage: updatePageSize,
                        }}
                        toggleFilters={() => setOpenFilters(!openFilters)}
                    />
                )}
            <FilterDrawerView isOpen={openFilters} setOpenDrawer={setOpenFilters} drawerTitle={'Filtros Organizaciones'}>
                <div className="grid grid-cols-1 gap-6">
                    <OrganisationsFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} />
                </div>
            </FilterDrawerView>
        </Page>
    );
};

export default OrganisationsList;