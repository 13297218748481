import { useContext } from 'react';
import { FaCircleInfo } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { Button } from 'rizzui';
import { toast } from 'sonner';
import AttivaCard from '../../../components/card/AttivaCard';
import useHandleErrors from '../../../hooks/useHandleErrors';
import PageHeader from '../../../layout/shared/page-header';
import { menuRoutes } from '../../../router/menu';
import { ContractService } from '../../../services/contract/contractService';
import ContractForm from '../ContractForm';
import { ContractContext } from '../providers/ContractProvider';

const pageHeader = {
    title: 'Crear Contrato',
    breadcrumb: [
        { name: 'Ventas' },
        { name: 'Gestión de contratos' },
        { href: menuRoutes.contracts.path, name: 'Contratos' },
        { name: 'Crear' },
    ],
};

export default function ContractCreate() {

    const navigate = useNavigate();
    const { handleErrors } = useHandleErrors();

    const { showClientAsideMenuBtn, isOpenClientHistory, setIsOpenClientHistory, setLoadingContract, setLoadingDraft } = useContext(ContractContext);

    // ----------------------------------------- rcabezas implementation -----------------------------------------------
    // const handleSubmitContract = async (values: any) => {
    //     setLoadingContract(true);
    //     try {
    //         const response = await (await (new ContractService()).saveAsContract(values)).getResponseData();
    //         if (response.success) {
    //             setLoadingContract(false);
    //             navigate(-1);
    //             setTimeout(() => { toast.success('Contrato guardado correctamente') }, 100);
    //         } else {
    //             handleErrors(response);
    //         }
    //     } catch (error: any) {
    //         toast.error('Error al guardar el contrato');
    //     } finally {
    //         setLoadingContract(false);
    //     }
    // };

    // const handleSubmitDraft = async (values: any) => {
    //     setLoadingDraft(true);
    //     try {
    //         const response = await (await (new ContractService()).saveAsDraft(values)).getResponseData();
    //         if (response.success) {
    //             navigate(-1);
    //             toast.success('Borrador guardado correctamente');
    //         } else {
    //             handleErrors(response);
    //         }
    //     } catch (error: any) {
    //         toast.error('Error al guardar el borrador');
    //     } finally {
    //         setLoadingDraft(false);
    //     }
    // };
    // -----------------------------------------------------------------------------------------------------------------

    // ----------------------------------------- dcuevas implementation ------------------------------------------------
    const handleSubmitDraft = async (values: any) => {
        setLoadingDraft(true);
        try {
            const response = await (await (new ContractService()).createDraft(values)).getResponseData();
            if (response.success) {
                navigate(-1);
                toast.success('Borrador guardado correctamente');
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error('Error al guardar el borrador');
        } finally {
            setLoadingDraft(false);
        }
    };

    const handleSubmitContract = async (values: any) => {
        setLoadingContract(true);
        try {
            const response = await (await (new ContractService()).createCompleteContract(values)).getResponseData();
            if (response.success) {
                setLoadingContract(false);
                navigate(-1);
                setTimeout(() => { toast.success('Contrato guardado correctamente') }, 100);
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error('Error al guardar el contrato');
        } finally {
            setLoadingContract(false);
        }
    };
    // -----------------------------------------------------------------------------------------------------------------

    return (
        <>
            <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb} >
                {showClientAsideMenuBtn && (
                    <div className='flex flex-row justify-end'>
                        <Button onClick={() => setIsOpenClientHistory(!isOpenClientHistory)} className='cursor-pointer'>
                            <FaCircleInfo /> &nbsp; {isOpenClientHistory ? "Cerrar Historial" : "Abrir Historial"}
                        </Button>
                    </div>
                )}
            </PageHeader>

            <AttivaCard title='Datos Principales' className='border-transparent p-1 lg:p-2'>
                <ContractForm
                    submitContract={handleSubmitContract}
                    submitDraft={handleSubmitDraft}
                />
            </AttivaCard>
        </>
    );
}