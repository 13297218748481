import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import AttivaCard from "../../../../components/card/AttivaCard";
import useHandleErrors from "../../../../hooks/useHandleErrors";
import PageHeader from "../../../../layout/shared/page-header";
import { menuRoutes } from "../../../../router/menu";
import { IncidenceTypeService } from "../../../../services/incidence-type/incidenceTypeService";
import IncidenceTypeForm from "../IncidenceTypeForm";

const pageHeader = {
    title: "Crear Tipo de Incidencia",
    breadcrumb: [
        { name: 'Administración' },
        { name: 'Control de errores' },
        { href: menuRoutes.incidenceTypes.path, name: "Tipos de Incidencia" },
        { name: "Crear" },
    ],
}

const IncidenceTypeCreate: FC = () => {

    const { handleErrors } = useHandleErrors();
    const navigate = useNavigate();
    const service = new IncidenceTypeService();

    const [loading, setLoading] = useState<boolean>(false);


    const handleSubmit = async (values: any) => {
        setLoading(true);
        try {
            const response = (await service.createIncidenceType(values)).getResponseData();

            if (response.success) {
                navigate(-1);
                toast.success("Tipo de incidencia creado correctamente");
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error("Error al crear el tipo de incidencia");
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb} />

            <AttivaCard title="Datos Principales" className="mb-4">
                <IncidenceTypeForm submit={handleSubmit} isLoading={loading} />
            </AttivaCard>
        </>
    );
}

export default IncidenceTypeCreate;
