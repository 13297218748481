import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';
import {OrderTypeFieldsModel} from "../../type/entities/order-type";

const ENDPOINT = '/order-types';

export class OrderTypeService extends RestServiceConnection {

    listOrderTypes = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/list',
            data: filters,
        }, true) as AxiosResponse;
        return this;
    }

    getOrderTypeById = async (orderTypesId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get',
            data: { orderTypesId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    createOrderType = async (orderType: OrderTypeFieldsModel) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/create',
            data: orderType,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    editOrderType = async (orderType: OrderTypeFieldsModel) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/edit',
            data: orderType,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    deleteOrderType = async (orderTypesId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/delete',
            data: { orderTypesId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    deleteMultiOrderTypes = async (orderTypesIds: string[]) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/delete-multi',
            data: { orderTypesIds }
        }, true);
        return this;
    }
}