import classNames from 'classnames';
import PropTypes from 'prop-types';
import { forwardRef, ReactElement } from 'react';
import { IPageProps } from '../Page/Page';
import { ISubHeaderProps } from '../SubHeader/SubHeader';

interface IPageWrapperProps {
	title?: string;
	description?: string;
	children:
		| ReactElement<ISubHeaderProps>[]
		| ReactElement<IPageProps>
		| ReactElement<IPageProps>[];
	className?: string;
}
const PageWrapper = forwardRef<HTMLDivElement, IPageWrapperProps>(
	({ children, className = '' }, ref) => {
		return (
			<div ref={ref} className={classNames('page-wrapper', 'container-fluid', className)}>
				{children}
			</div>
		);
	},
);
PageWrapper.propTypes = {
	// @ts-ignore
	children: PropTypes.node.isRequired,
};

export default PageWrapper;
