import { Outlet } from "react-router-dom";
import { OrdersProvider } from "./provider/OrdersProvider";

const OrderWrapper = () => {
    return (
        <OrdersProvider>
            <div className="flex flex-grow flex-col pb-4 px-1 md:px-5 lg:px-6 3xl:px-8 lg:pb-8  3xl:pt-4 4xl:px-10">
                <Outlet />
            </div>
        </OrdersProvider>
    )
}

export default OrderWrapper;