import React, { useCallback } from 'react';
import { PiEnvelopeSimple } from 'react-icons/pi';
import { useParams } from 'react-router-dom';
import { Input, Switch, Tooltip } from 'rizzui';
import AsyncImg from '../../../../../components/extras/AsyncImg';
import PlaceholderImage from '../../../../../components/extras/PlaceholderImage';
import CustomSelect from '../../../../../components/forms/CustomSelect';
import useFetch from '../../../../../hooks/useFetch';
import FormGroup from '../../../../../layout/shared/form-group';
import { UserService } from '../../../../../services/user/userService';
import { UserApiResponse } from '../../../../../type/entities/user-type';
import cn from '../../../../../utils/classNames';
import UserProfileLayout from '../../UserProfileLayout';

interface UserInfoProps { }

const UserInfo: React.FC<UserInfoProps> = () => {

    const { id = '' } = useParams<{ id: string }>();
    const service = new UserService();

    const [data] = useFetch(useCallback(async () => {
        if (!id || id === '') return null;
        const response = await service.getUserById(id);
        return response.getResponseData() as UserApiResponse;
    }, [id]));

    function getUserRolesValues() {
        return data?.userRoles.map((roleWrapper: any) => (
            { value: roleWrapper.role.id, label: roleWrapper.role.name }
        ));
    };

    return (
        <UserProfileLayout>
            <div className="@container">
                <div className="grid divide-y-2 divide-dashed divide-gray-200 gap-6">
                    <FormGroup
                        title="Datos Personales"
                        description='Información personal del usuario'
                        className='pt-3 pb-4'
                        titleCols="@md:col-span-2"
                        childCols="@md:col-span-10 md:grid-cols-4"
                    >
                        <div className='md:col-span-2 md:grid md:grid-cols-2 gap-4'>
                            <Input
                                disabled
                                label="Nombre"
                                placeholder="First Name"
                                value={data?.name ?? ''}
                                className="flex-grow"
                            />

                            <Input
                                disabled
                                label="Apellidos"
                                placeholder="Last Name"
                                value={data?.lastName ?? ''}
                                className="flex-grow"
                            />

                            <Input
                                disabled
                                prefix={<PiEnvelopeSimple className="h-6 w-6 text-gray-500" />}
                                type="email"
                                label="Email"
                                placeholder="georgia.young@attiva.com"
                                value={data?.email ?? ''}
                                className="md:col-span-2"
                            />
                        </div>

                        <div></div>

                        {data?.profileImg?.id
                            ? <AsyncImg id={data?.profileImg?.id} isBackground className="mx-auto d-block img-fluid rounded w-[220px] h-[220px] object-cover" />
                            : <PlaceholderImage width={200} height={200} className='mx-auto d-block img-fluid rounded' />
                        }
                    </FormGroup>

                    <FormGroup
                        title="Otros datos"
                        description="Información adicional de la cuenta"
                        className='pt-3 pb-4'
                        titleCols="@md:col-span-2"
                        childCols="@md:col-span-10 md:grid-cols-4"
                    >
                        <CustomSelect
                            isDisabled
                            id={'roleId'}
                            label="Rol del usuario"
                            value={getUserRolesValues()}
                        />

                        <CustomSelect
                            isDisabled
                            id={'departmentId'}
                            label="Red Comercial"
                            value={{ value: data?.commercialNetwork?.id, label: data?.commercialNetwork?.name }}
                            containerClassName={cn(data?.commercialNetwork || 'hidden')}
                        />

                        <CustomSelect
                            isDisabled
                            id={'departmentId'}
                            label="Departamento al que pertenece"
                            value={{ value: data?.department?.id, label: data?.department?.name }}
                            containerClassName={cn(data?.department || 'hidden')}
                        />

                        <Tooltip
                            size="sm"
                            content={data?.accessThrowCommercialNetworkIp ? 'El usuario tiene activo el acceso a la plataforma a través de la IP de la red comercial' : 'El usuario no tiene activo el acceso a la plataforma a través de la IP de la red comercial'}
                            placement="top"
                            color="invert"
                        >
                            <div>
                                <Switch
                                    id='accessThrowCommercialNetworkIp'
                                    label="Acceso a través de la IP de la red comercial"
                                    switchKnobClassName='bg-secondary'
                                    className="[&>label>span]:font-medium md:mt-3"
                                    checked={data?.accessThrowCommercialNetworkIp}
                                    disabled
                                />
                            </div>
                        </Tooltip>
                    </FormGroup>
                </div>
            </div>
        </UserProfileLayout>
    );
};

export default UserInfo;