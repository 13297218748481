import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';
import AttivaCard from '../../../components/card/AttivaCard';
import { Loader } from '../../../components/loader/SpinnerLogo';
import useFetch from '../../../hooks/useFetch';
import PageHeader from '../../../layout/shared/page-header';
import { menuRoutes } from '../../../router/menu';
import { ConsultancyService } from '../../../services/consultancy/consultancyService';
import { Consultancy } from '../../../type/entities/consultancy-type';
import ConsultancyForm from '../ConsultancyForm';

interface EditConsultancyProps { }

const pageHeader = {
    title: 'Editar Asesoría',
    breadcrumb: [
        {
            href: menuRoutes.consultancies.path,
            name: 'Asesorías',
        },
        {
            name: 'Editar',
        },
    ],
};

const EditConsultancy: React.FC<EditConsultancyProps> = ({ }) => {

    const { id = "" } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const service = new ConsultancyService();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [data, loading] = useFetch(useCallback(async () => {
        const response = await service.getConsultancyById(id as string);
        return response.getResponseData() as Consultancy;
    }, [id]));

    const handleSubmit = async (values: any) => {
        setIsLoading(true);
        try {
            const response = await (await service.editConsultancy(values)).getResponseData();
            if (response.success) {
                navigate(-1);
                toast.success("Asesoría editada correctamente");
            } else {
                response.data.errors.forEach((error: any) => {
                    toast.error(error.message);
                });
            }
        } catch (error: any) {
            toast.error('Error al editar la asesoría');
        } finally {
            setIsLoading(false);
        }
    };

    if (loading) return <Loader />;

    return (
        <>
            <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb} />

            <AttivaCard title="Datos Principales" className="mb-4">
                {(data !== null && data !== undefined) && <ConsultancyForm data={data} submit={handleSubmit} isLoading={isLoading} />}
            </AttivaCard>
        </>
    );
};

export default EditConsultancy;