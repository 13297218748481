import React, { useCallback } from 'react';
import { FaEuroSign } from 'react-icons/fa';
import { FaPercent } from 'react-icons/fa6';
import { PiEnvelopeSimple } from 'react-icons/pi';
import { Input, Textarea, cn } from 'rizzui';
import CustomSelect from '../../../components/forms/CustomSelect';
import PhoneInput from '../../../components/forms/PhoneInputWrapper';
import { Loader } from '../../../components/loader/SpinnerLogo';
import useFetch from '../../../hooks/useFetch';
import FormGroup from '../../../layout/shared/form-group';
import { TutorService } from '../../../services/tutor/tutorService';
import { availability, paymentMethods, tutorType } from '../../tutors/TutorForm';

interface TutorProfileProps { }

const TutorProfile: React.FC<TutorProfileProps> = ({ }) => {

    const [data, loading] = useFetch(useCallback(async () => {
        const response = await (new TutorService()).getMyProfile();
        return response.getResponseData();
    }, []));

    if (loading) return <Loader height='70vh' />;

    return (
        <div className="@container">
            <div className="grid gap-7 divide-y-2 divide-dashed divide-gray-200 @2xl:gap-9 @3xl:gap-11">
                <FormGroup
                    title="Datos personales"
                    description="Información personal del tutor"
                    className='pt-3 pb-4'
                    titleCols="@md:col-span-2"
                    childCols="@md:col-span-10 md:grid-cols-5"
                >
                    <Input
                        disabled
                        label="Nombre"
                        placeholder="First Name"
                        value={data?.name ?? ""}
                        className="flex-grow"
                    />
                    <Input
                        disabled
                        label="Apellidos"
                        placeholder="Last Name"
                        value={data?.lastName ?? ""}
                        className="flex-grow"
                    />
                    <Input
                        disabled
                        label="DNI"
                        placeholder="12345678Z"
                        value={data?.nifCif ?? ""}
                        className="flex-grow"
                    />
                    <Input
                        prefix={<PiEnvelopeSimple className="h-6 w-6 text-gray-500" />}
                        type="email"
                        label="Email"
                        placeholder="info@attiva.es"
                        value={data?.email ?? ""}
                        disabled
                    />
                    <CustomSelect
                        id="tutorType"
                        label="Tipo de Tutor"
                        value={{
                            value: data?.tutorType,
                            label: tutorType.find((type) => type.value === data?.tutorType)?.label,
                        }}
                        isDisabled
                    />
                    <CustomSelect
                        isMulti
                        id="availability"
                        label="Disponibilidad"
                        value={{
                            value: data?.availability,
                            label: data?.availability?.map((value: any) => availability.find((type) => type.value === value)?.label
                            ),
                        }}
                        isDisabled
                    />
                    <Input
                        disabled
                        label="Usuario de la Plataforma"
                        placeholder="username"
                        value={data?.platformUser ?? ""}
                        className="flex-grow"
                    />
                    <Input
                        disabled
                        label="Contraseña de la Plataforma"
                        placeholder="password"
                        value={data?.platformPassword ?? ""}
                        className="flex-grow"
                    />
                </FormGroup>

                <FormGroup
                    title="Cursos"
                    description="Cursos que el tutor puede impartir"
                    className={cn("pt-3 pb-4", data?.products?.length === 0 && "hidden")}
                    titleCols="@md:col-span-2"
                    childCols="@md:col-span-10 md:grid-cols-3"
                >
                    {data?.products?.map((product: any, index: number) => (
                        <div key={product.id} className={cn("grid grid-cols-1 md:grid-cols-3 gap-3 pb-5", index !== data.products.length - 1 && "border-b border-gray-200")}>
                            <Input
                                disabled
                                label="Curso"
                                placeholder="Curso"
                                value={product.product.name}
                                className="md:col-span-2"
                            />
                            <Input
                                disabled
                                label="Precio (€)"
                                placeholder="Precio"
                                value={product.pricePerStudent}
                                suffix={<FaEuroSign />}
                                step={0.01}
                            />
                            <Input
                                disabled
                                id="paymentMethod"
                                    label="Método de Pago"
                                    value={paymentMethods.find((type) => type.value === product.paymentMethod)?.label}
                            />
                            <Input
                                disabled
                                label="Retenciones"
                                placeholder="Retenciones"
                                suffix={<FaPercent />}
                                value={product.deductions}
                            />
                        </div>
                    ))}
                </FormGroup>

                <FormGroup
                    title="Otros datos"
                    description="Información adicional de la cuenta"
                    className='pt-3 pb-4'
                    titleCols="@md:col-span-2"
                    childCols="@md:col-span-10 md:grid-cols-5"
                >
                    <PhoneInput
                        id="telephone"
                        disabled
                        label="Teléfono"
                        placeholder="123456789"
                        value={data?.telephone ?? ""}
                        className="flex-grow"
                    />
                    <PhoneInput
                        id="telephoneSecondary"
                        disabled
                        label="Teléfono secundario"
                        placeholder="123456789"
                        value={data?.telephoneSecondary ?? ""}
                        className="flex-grow"
                    />
                    <Input
                        disabled
                        label="Dirección"
                        placeholder="Calle de la forja, 123"
                        value={data?.address ?? ""}
                        className="flex-grow"
                    />
                    <Input
                        disabled
                        label="Población"
                        placeholder="Córdoba"
                        value={data?.town ?? ""}
                        className="flex-grow"
                    />
                    <Input
                        disabled
                        label="Provincia"
                        placeholder="Madrid"
                        value={data?.province ?? ""}
                        className="flex-grow"
                    />
                    <Input
                        disabled
                        label="Código Postal"
                        placeholder="12345"
                        value={data?.postalCode ?? ""}
                        className="flex-grow"
                    />
                    <Textarea
                        id="comments"
                        disabled
                        label="Comentarios"
                        rows={3}
                        className="md:col-span-2 [&>label>span]:font-medium"
                        value={data?.comments}
                    />
                </FormGroup>
            </div>
        </div>
    );
};

export default TutorProfile;