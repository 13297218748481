import React from 'react';
import { IoClose } from 'react-icons/io5';
import { ActionIcon, Button, cn, Modal, Text } from 'rizzui';

interface SelectorModalProps {
    isOpen: boolean;
    onSubmit: (selectedItem: any) => void;
    onClose: () => void;
    data: any[];
    title: string;
    renderItem: (item: any) => React.ReactNode;
    selectedLabel: string;
    buttonText: string;
}

const SelectorModal: React.FC<SelectorModalProps> = ({
    isOpen,
    onSubmit,
    onClose,
    data,
    title,
    renderItem,
    selectedLabel,
    buttonText,
}) => {
    const [selectedItem, setSelectedItem] = React.useState<any>(null);

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => {
                onClose();
                setSelectedItem(null);
            }}
            size="sm"
            overlayClassName="backdrop-blur"
            containerClassName="!max-w-xl !shadow-xl"
            className="z-[999] [&_.pointer-events-none]:overflow-visible"
        >
            <div className="m-auto px-7 pt-6 pb-4">
                <div className="mb-7 flex items-center justify-between">
                    <Text className="text-xl">{title}</Text>
                    <ActionIcon
                        size="sm"
                        variant="text"
                        onClick={() => {
                            onClose();
                            setSelectedItem(null);
                        }}
                    >
                        <IoClose className="h-auto w-6" strokeWidth={1.8} />
                    </ActionIcon>
                </div>

                <ul className="gap-1 pl-4 mb-6 divide-y-2">
                    {data &&
                        data.map((item) => (
                            <li
                                key={item.id}
                                className={cn('w-100 cursor-pointer py-2')}
                                onClick={() => setSelectedItem(item)}
                            >
                                <div
                                    className={cn(
                                        'rounded-md p-2 hover:bg-primary-50',
                                        selectedItem?.id === item.id && 'bg-primary-50'
                                    )}
                                >
                                    {renderItem(item)}
                                </div>
                            </li>
                        ))}
                </ul>

                <div className="font-light">
                    {selectedLabel}:{' '}
                    <span className="underline underline-offset-4">
                        {selectedItem ? renderItem(selectedItem) : 'Ninguno'}
                    </span>
                </div>
            </div>

            <div className="flex justify-center pt-2 p-7">
                <Button
                    onClick={() => onSubmit(selectedItem)}
                    color="primary"
                    disabled={!selectedItem}
                >
                    {buttonText}
                </Button>
            </div>
        </Modal>
    );
};

export default SelectorModal;