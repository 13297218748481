import React, { useCallback, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Text, Tooltip } from 'rizzui';
import AsyncImg from '../../../../../components/extras/AsyncImg';
import { Loader } from '../../../../../components/loader/SpinnerLogo';
import { useFiltersPR } from '../../../../../components/providers/FiltersProvider';
import { FilterDrawerView } from '../../../../../components/table/components/TableFilter';
import CustomTable from '../../../../../components/table/CustomTable';
import AvatarCard from '../../../../../components/ui/AvatarCard';
import useFetch from '../../../../../hooks/useFetch';
import Page from '../../../../../layout/Page/Page';
import { menuRoutes } from '../../../../../router/menu';
import { InvoiceService } from '../../../../../services/invoice/invoiceService';
import { ChangelogsApiResponse } from '../../../../../type/entities/changelog-type';
import { InvoiceApiResponse } from '../../../../../type/entities/invoice-type';
import InvoiceActivityHistoryFilters from './InvoiceActivityHistoryFilters';

interface InvoiceActivityHistoryProps { }

const columnOrder = [
    'id',
    'user',
    'company',
    'comment',
    'createdAt',
];

const InvoiceActivityHistory: React.FC<InvoiceActivityHistoryProps> = ({ }) => {

    const { id = '' } = useParams<{ id: string }>();
    const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters } = useFiltersPR();
    const [openFilters, setOpenFilters] = useState<boolean>(false);

    const [data, loading] = useFetch(useCallback(async () => {
        if (!id || id === '') return null;
        const response = await (new InvoiceService()).listActivityHistory({ ...filters, filter_filters: { ...filters.filter_filters, invoice: id } });
        return response.getResponseData() as InvoiceApiResponse;
    }, [id, filters]));

    return (
        <Page container='fluid' className='mt-5'>
            {loading
                ? <Loader height='70vh' />
                : (
                    <CustomTable
                        id={'changelogs-table'}
                        columnOrder={columnOrder}
                        data={data?.data ?? []}
                        columnsNotShown={['id', 'invoice', 'updatedAt']}
                        overrideColumns={[
                            {
                                key: 'user',
                                render: (row: any) => {
                                    const entity = row.row.original.user;

                                    if (!entity.hasOwnProperty('profileImg')) {
                                        return (
                                            <AvatarCard
                                                src={'default-avatar.png'}
                                                name={entity.name + (entity.lastName ? ` ${entity.lastName}` : '')}
                                                link={`${menuRoutes.users.path}/${entity.id}/profile${menuRoutes.users.profile.info}`}
                                            />
                                        )
                                    } else if (entity) {
                                        return (
                                            <Link to={`/users/${entity.id}/edit`}>
                                                <div className="flex items-center gap-3">
                                                    <AsyncImg id={entity.id} className="mx-auto d-block rounded w-[40px] h-[40px] object-cover" />
                                                    <Text>{entity.name + (entity.lastName ? ` ${entity.lastName}` : '')}</Text>
                                                </div>
                                            </Link>
                                        )
                                    } else {
                                        return <Text>N/A</Text>
                                    }
                                },
                            },
                            {
                                key: 'company',
                                render: (row: any) => {
                                    const entity = row.row.original.company;
                                    return (
                                        <Link to={`/coorporation/${entity.id}/view`}>
                                            <div className="flex items-center gap-3">
                                                <Text>{entity.name}</Text>
                                            </div>
                                        </Link>
                                    )
                                },
                            },
                            {
                                key: 'comment',
                                render: (row: any) => {
                                    const comment = row.row.original.comment;
                                    return (
                                        <Tooltip
                                            size="md"
                                            content={<div className='tooltip-container'>{comment}</div>}
                                            placement="top"
                                            color="invert"
                                        >
                                            <div>{comment.substring(0, 100)} {comment.length > 100 && '...'}</div>
                                        </Tooltip>)
                                },
                            }
                        ]}
                        actions={[]}
                        filters={filters}
                        updateFilters={updateFilters}
                        updateFilterOrder={updateFilterOrder}
                        defaultOrder={filters.filter_order || undefined}
                        paginationData={{
                            pageSize: filters.limit,
                            currentPage: filters.page,
                            pageCount: (data as ChangelogsApiResponse) ? data.lastPage : 1,
                            totalCount: data?.totalRegisters,
                            handlePagination: updatePage,
                            handlePerPage: updatePageSize,
                        }}
                        toggleFilters={() => setOpenFilters(!openFilters)}
                    />
                )}
            <FilterDrawerView isOpen={openFilters} setOpenDrawer={setOpenFilters} drawerTitle={'Filtros Actividad'}>
                <InvoiceActivityHistoryFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} />
            </FilterDrawerView>
        </Page>
    );
};

export default InvoiceActivityHistory;