import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { Button, Tooltip } from 'rizzui';
import { toast } from 'sonner';
import DeletePopover from '../../../components/buttons/DeletePopover';
import { Loader } from '../../../components/loader/SpinnerLogo';
import TabNav, { MenuItem } from "../../../components/navigation/TabNav";
import { usePrivilege } from '../../../components/priviledge/PriviledgeProvider';
import useFetch from '../../../hooks/useFetch';
import useHandleErrors from '../../../hooks/useHandleErrors';
import PageHeader from "../../../layout/shared/page-header";
import { setSelectedView } from '../../../redux/incidenceProfileSlice';
import { RootState } from '../../../redux/store';
import { menuRoutes } from "../../../router/menu";
import { IncidenceService } from '../../../services/incidence/incidenceService';
import { IncidenceApiResponse } from '../../../type/entities/incidence-type';

const menuItems: MenuItem[] = [
    {
        label: 'Información',
        path: menuRoutes.incidences.profile.info,
        permission: {
            group: 'incidences',
            action: 'get_incidences',
        },
    },
    {
        label: 'Documentos',
        path: menuRoutes.incidences.profile.documents,
        permission: {
            group: 'incidences',
            action: 'admin_incidence_documents',
        },
    },
    {
        label: 'Comentarios',
        path: menuRoutes.incidences.profile.comments,
        permission: {
            group: 'incidences',
            action: 'get_incidences',
        },
    }

];

export default function IncidenceProfileLayout({ children, isLoading }: { children: React.ReactNode, isLoading?: boolean }) {

    const { selectedView } = useSelector((state: RootState) => state.incidenceProfile);
    const { id = '' } = useParams<{ id: string }>();
    const { userCan } = usePrivilege();
    const { handleErrors } = useHandleErrors();
    const navigate = useNavigate();

    const [data] = useFetch(useCallback(async () => {
        if (!id || id === '') return null;
        const response = await (new IncidenceService()).getIncidenceById(id);
        return response.getResponseData() as IncidenceApiResponse;
    }, [id]));

    const pageHeader = {
        title: `Datos de la Incidencia ${data?.code ? `(${data.code})` : ''}`,
        breadcrumb: [
            { name: 'Ventas' },
            { name: 'Gestión de incidencias' },
            { name: 'Incidencias', href: menuRoutes.incidences.path },
            { name: 'Detalles de la Incidencia' },
        ],
    };

    const handleDelete = async (id: string) => {
        try {
            const response = await (await (new IncidenceService()).deleteIncidence(id)).getResponseData();
            if (response.success) {
                navigate(menuRoutes.incidences.path);
                toast.success('Incidencia eliminada correctamente');
            } else {
                handleErrors(response);
            }
        } catch (error) {
            handleErrors(error);
        }
    };

    useEffect(() => {
        navigate(`${menuRoutes.incidences.path}/${id}/profile${selectedView}`);
    }, [selectedView]);

    return (
        <>
            <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb}>
                <div className='flex flex-row flex-nowrap gap-3 mt-3 md:mt-0'>
                    {userCan('edit_incidences', 'incidences') && (
                        <Tooltip content={'Editar incidencia'} placement={'top'} color={'invert'} size={'md'}>
                            <Button variant={'outline'} color={'primary'} size={'md'} onClick={() => {
                                navigate(`${menuRoutes.incidences.path}/${id}/edit`)
                            }}>
                                Editar
                            </Button>
                        </Tooltip>
                    )}

                    {userCan('delete_incidences', 'incidences') && (
                        <Tooltip content={'Eliminar incidencia'} placement={'top'} color={'invert'} size={'md'} className='text-danger'>
                            <div>
                                <DeletePopover
                                    title={`Eliminar incidencia`}
                                    description={`¿Estás seguro de que deseas eliminar la incidencia?`}
                                    size={'md'}
                                    onDelete={() => handleDelete(id)}
                                    actionIconClassName='h-auto w-10 h-10'
                                />
                            </div>
                        </Tooltip>
                    )}
                </div>
            </PageHeader>

            <TabNav menuItems={menuItems} setSelectedView={setSelectedView} />

            {isLoading && !data ? <Loader height='60vh' /> : children}
        </>
    );
}