import classNames from 'classnames';
import React, { useCallback } from 'react';
import { FaEuroSign, FaRegTrashAlt } from 'react-icons/fa';
import { MdAdd } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Button } from 'rizzui';
import CustomSelect from '../../../components/forms/CustomSelect';
import RequiredInput from '../../../components/forms/InputHOC';
import useFetch from '../../../hooks/useFetch';
import FormGroup from '../../../layout/shared/form-group';
import { RootState } from '../../../redux/store';
import { InvoiceService } from '../../../services/invoice/invoiceService';
import { InvoiceLine } from '../../../type/entities/invoice-type';
import cn from '../../../utils/classNames';

interface InvoiceLinesFormProps {
    formik: any;
    data?: any;
    isDisabled?: boolean;
}

const InvoiceLinesForm: React.FC<InvoiceLinesFormProps> = ({ formik, data, isDisabled = false }) => {

    const { user } = useSelector((state: RootState) => state.auth);

    const [newLine, setNewLine] = React.useState<InvoiceLine>({ concept: '', amount: 0, tax: 0, units: 0, totalWithoutTax: 0, totalWithTax: 0, contract: null });

    const [contractsList] = useFetch(useCallback(async () => {
        const response = await (new InvoiceService).invoicableContractsList({ filter_filters: { userId: user?.id as string, client: data?.client?.id } });
        return response.getResponseData();
    }, [user]));

    const handleChangeLineField = (index: number, field: string, value: any) => {
        const lines = formik.values.lines.map((line: InvoiceLine, i: number) => {
            if (i !== index) return line;

            if (field === 'contract') {
                return {
                    ...line,
                    [field]: value,
                    concept: null,
                    amount: null,
                    tax: null,
                    units: null,
                    totalWithoutTax: null,
                    totalWithTax: null,
                };
            }

            return {
                ...line,
                [field]: value,
            };
        });
        formik.setFieldValue('lines', lines);
    };


    const renderInvoiceLine = (item: InvoiceLine, index: number, isContract?: boolean) => {
        let lineFromData = data?.lines?.[index];
        let contractOptions = contractsList?.map((contract: any) => ({ value: contract.id, label: `${contract.code} - ${contract.clientCompanyName}` }));

        if (lineFromData?.contract && contractOptions && !contractOptions.includes(lineFromData?.contract?.id)){
            contractOptions = [...contractOptions, { value: lineFromData?.contract?.id, label: `${lineFromData?.contract?.code} - ${lineFromData?.contract?.clientCompanyName}` }];
        };

        let contractOptValue = item?.contract?.id ?? item?.contract;
        let contractOptLabel = lineFromData?.contract ? `${lineFromData.contract?.code} - ${lineFromData.contract?.clientCompanyName}` : item?.contract ? `${contractsList?.find((contract: any) => contract.id === item?.contract)?.code} - ${contractsList?.find((contract: any) => contract.id === item?.contract)?.clientCompanyName}` : '';

        return (
            <div key={`id[${item.id ?? item.contract?.id}]lines[${index}]`} className="flex flex-col md:flex-row flex-nowrap gap-4 mb-5 pt-5">
                <div className="flex flex-row flex-wrap gap-3">
                    {isContract && (
                        <CustomSelect
                            key={`id[${item.id}]lines[${index}]`}
                            id={`id[${item.id}]lines[${index}]`}
                            label='Contrato'
                            isClearable={false}
                            options={contractOptions}
                            value={
                                {
                                    value: contractOptValue,
                                    label: contractOptLabel
                                }
                            }
                            containerClassName={cn('w-[300px]', { 'hidden': !item?.contract && !item?.contract?.id })}
                            error={formik.errors.lines?.[index]?.contract}
                            isDisabled={true}
                        />
                    )}
                    <RequiredInput
                        required
                        id={`lines[${index}].concept`}
                        type="text"
                        label="Concepto"
                        className={cn('[&>label>span]:font-medium w-[300px]', { 'hidden': item?.contract && !item?.concept })}
                        onChange={(e: any) => handleChangeLineField(index, 'concept', e.target.value)}
                        value={item?.concept}
                        error={formik.errors.lines?.[index]?.concept}
                        disabled={(item?.contract && item?.concept) ? true : false}
                    />
                    <RequiredInput
                        required
                        id={`lines[${index}].amount`}
                        type="number"
                        min={0}
                        label="Cantidad"
                        className={cn('[&>label>span]:font-medium w-[100px]', { 'hidden': item?.contract && !item?.amount })}
                        inputClassName="text-sm"
                        onChange={(e: any) => handleChangeLineField(index, 'amount', e.target.value)}
                        value={item?.amount}
                        error={formik.errors.lines?.[index]?.amount}
                        disabled={(item?.contract && item?.amount) ? true : false}
                    />
                    <RequiredInput
                        required
                        id={`lines[${index}].units`}
                        type="number"
                        min={0}
                        step={1}
                        label="Unidades"
                        className={cn('[&>label>span]:font-medium w-[100px]', { 'hidden': item?.contract && !item?.units })}
                        inputClassName="text-sm"
                        onChange={(e: any) => handleChangeLineField(index, 'units', e.target.value)}
                        value={item?.units}
                        error={formik.errors.lines?.[index]?.units}
                        disabled={(item?.contract && item?.units) ? true : false}
                    />
                    <RequiredInput
                        required
                        id={`lines[${index}].totalWithoutTax`}
                        type="number"
                        label="Total sin IVA"
                        className={cn('[&>label>span]:font-medium w-[130px]', { 'hidden': item?.contract && !item?.totalWithoutTax })}
                        inputClassName="text-sm"
                        onChange={(e: any) => handleChangeLineField(index, 'totalWithoutTax', e.target.value)}
                        value={item?.totalWithoutTax}
                        suffix={<FaEuroSign />}
                        error={formik.errors.lines?.[index]?.totalWithoutTax}
                        disabled={(item?.contract && item?.totalWithoutTax) ? true : false}
                    />
                    <RequiredInput
                        required
                        id={`lines[${index}].totalWithTax`}
                        type="number"
                        min={0}
                        step={0.01}
                        label="Total con IVA"
                        className={cn('[&>label>span]:font-medium w-[130px]', { 'hidden': item?.contract && !item?.totalWithTax })}
                        inputClassName="text-sm"
                        onChange={(e: any) => handleChangeLineField(index, 'totalWithTax', e.target.value)}
                        value={item?.totalWithTax}
                        suffix={<FaEuroSign />}
                        error={formik.errors.lines?.[index]?.totalWithTax}
                        disabled={(item?.contract && item?.totalWithTax) ? true : false}
                    />
                </div>

                <div className="flex justify-center items-center m-auto md:me-4 w-fit">
                    <Button
                        color="danger"
                        size='sm'
                        onClick={() => {
                            const updatedLines = formik.values.lines.filter((line: InvoiceLine, i: number) => i !== index);
                            formik.setFieldValue('lines', updatedLines);
                        }}
                    >
                        <FaRegTrashAlt />
                    </Button>
                </div>
            </div>
        );
    };

    return (
        <FormGroup
            title="Líneas de Factura"
            description="Líneas de la factura"
            className='mt-3 pt-6 pb-4 border-top border-t-2 border-dashed'
            titleCols="@md:col-span-2"
            childCols="@md:col-span-10"
            childClassName={classNames('divide-y-2 divide-dashed pb-4', { 'border-gray-500': formik.values.lines.length > 0 })}
        >
            <div className={classNames('divide-y-2 divide-dashed pb-4', { 'border-gray-500': formik.values.lines.length > 0 })}>
                {formik.values.lines && formik.values.lines.map((item: InvoiceLine, index: number) => {
                    // let line = data?.lines?.[index];
                    // let contractOptions = contractsList?.map((contract: any) => ({ value: contract.id, label: `${contract.code} - ${contract.clientCompanyName}` }));

                    // let contractOptValue = line?.contract || item?.contract;
                    // let contractOptLabel = line !== undefined ?
                    //     `${line?.contract?.code} - ${line?.contract?.clientCompanyName}` :
                    //     item?.contract && `${contractsList?.find((contract: any) => contract.id === item?.contract)?.code} - ${contractsList?.find((contract: any) => contract.id === item?.contract)?.clientCompanyName}`
                    //     ;

                    // if (line?.contract && contractOptions) {
                    //     contractOptions = [...contractOptions, { value: line?.contract?.id, label: `${line?.contract?.code} - ${line?.contract?.clientCompanyName}` }];
                    // }

                    return renderInvoiceLine(item, index, item?.contract ? true : false);

                    // return (
                    //     <div key={`id[${item.id}]lines[${index}]`} className="flex flex-col md:flex-row flex-nowrap gap-4 mb-5 pt-5">
                    //         <div className="flex flex-row flex-wrap gap-3">
                    //             <CustomSelect
                    //                 key={`id[${item.id}]lines[${index}]`}
                    //                 id={`id[${item.id}]lines[${index}]`}
                    //                 label='Contrato'
                    //                 isClearable={false}
                    //                 options={contractOptions}
                    //                 value={
                    //                     {
                    //                         id: item?.id,
                    //                         value: contractOptValue,
                    //                         label: contractOptLabel
                    //                     }
                    //                 }
                    //                 containerClassName={cn('w-[300px]', { 'hidden': !line?.contract?.id && !item?.contract })}
                    //                 error={formik.errors.lines?.[index]?.contract}
                    //                 isDisabled={false}
                    //             />
                    //             <RequiredInput
                    //                 required
                    //                 id={`lines[${index}].concept`}
                    //                 type="text"
                    //                 label="Concepto"
                    //                 className={cn('[&>label>span]:font-medium w-[300px]', { 'hidden': item?.contract && !item?.concept })}
                    //                 onChange={(e: any) => handleChangeLineField(index, 'concept', e.target.value)}
                    //                 value={item.concept}
                    //                 error={formik.errors.lines?.[index]?.concept}
                    //                 disabled={(item?.contract && item?.concept) ? true : false}
                    //             />
                    //             <RequiredInput
                    //                 required
                    //                 id={`lines[${index}].amount`}
                    //                 type="number"
                    //                 min={0}
                    //                 label="Cantidad"
                    //                 className={cn('[&>label>span]:font-medium w-[100px]', { 'hidden': item?.contract && !item?.amount })}
                    //                 inputClassName="text-sm"
                    //                 onChange={(e: any) => handleChangeLineField(index, 'amount', e.target.value)}
                    //                 value={item.amount}
                    //                 error={formik.errors.lines?.[index]?.amount}
                    //                 suffix={<FaEuroSign />}
                    //                 disabled={(item?.contract && item?.amount) ? true : false}
                    //             />
                    //             <RequiredInput
                    //                 required
                    //                 id={`lines[${index}].units`}
                    //                 type="number"
                    //                 min={0}
                    //                 step={1}
                    //                 label="Unidades"
                    //                 className={cn('[&>label>span]:font-medium w-[100px]', { 'hidden': item?.contract && !item?.units })}
                    //                 inputClassName="text-sm"
                    //                 onChange={(e: any) => handleChangeLineField(index, 'units', e.target.value)}
                    //                 value={item.units}
                    //                 error={formik.errors.lines?.[index]?.units}
                    //                 disabled={(item?.contract && item?.units) ? true : false}
                    //             />
                    //             <RequiredInput
                    //                 required
                    //                 id={`lines[${index}].totalWithoutTax`}
                    //                 type="number"
                    //                 label="Total sin IVA"
                    //                 className={cn('[&>label>span]:font-medium w-[130px]', { 'hidden': item?.contract && !item?.totalWithoutTax })}
                    //                 inputClassName="text-sm"
                    //                 onChange={(e: any) => handleChangeLineField(index, 'totalWithoutTax', e.target.value)}
                    //                 value={item.totalWithoutTax}
                    //                 suffix={<FaEuroSign />}
                    //                 error={formik.errors.lines?.[index]?.totalWithoutTax}
                    //                 disabled={(item?.contract && item?.totalWithoutTax) ? true : false}
                    //             />
                    //             <RequiredInput
                    //                 required
                    //                 id={`lines[${index}].totalWithTax`}
                    //                 type="number"
                    //                 min={0}
                    //                 step={0.01}
                    //                 label="Total con IVA"
                    //                 className={cn('[&>label>span]:font-medium w-[130px]', { 'hidden': item?.contract && !item?.totalWithTax })}
                    //                 inputClassName="text-sm"
                    //                 onChange={(e: any) => handleChangeLineField(index, 'totalWithTax', e.target.value)}
                    //                 value={item.totalWithTax}
                    //                 suffix={<FaEuroSign />}
                    //                 error={formik.errors.lines?.[index]?.totalWithTax}
                    //                 disabled={(item?.contract && item?.totalWithTax) ? true : false}
                    //             />
                    //         </div>

                    //         <div className="flex justify-center items-center m-auto md:me-4 w-fit">
                    //             <Button
                    //                 color="danger"
                    //                 size='sm'
                    //                 onClick={() => {
                    //                     const updatedLines = formik.values.lines.filter((line: InvoiceLine, i: number) => i !== index);
                    //                     formik.setFieldValue('lines', updatedLines);
                    //                 }}
                    //             >
                    //                 <FaRegTrashAlt />
                    //             </Button>
                    //         </div>
                    //     </div>
                    // )
                })}
            </div>

            <div className='col-span-2'>
                <div className="flex flex-col md:flex-row flex-nowrap gap-4 mb-5 pt-5">
                    <div className="flex flex-row flex-wrap gap-3 mb-4">
                        <CustomSelect
                            id={`linesContract`}
                            isClearable
                            label='Contrato'
                            options={contractsList?.map((contract: any) => ({ value: contract.id, label: `${contract.code} - ${contract.clientCompanyName}` }))}
                            value={{ value: newLine.contract, label: newLine.contract && `${contractsList?.find((contract: any) => contract.id === newLine.contract)?.code} - ${contractsList?.find((contract: any) => contract.id === newLine.contract)?.clientCompanyName}` }}
                            onChange={(e: any) => setNewLine({ contract: e?.value, concept: '', amount: 0, tax: 0, units: 0, totalWithoutTax: 0, totalWithTax: 0 })}
                            containerClassName={cn('w-[300px]', { 'hidden': formik.values.lines.some((line: any) => line?.contract) || newLine.concept || newLine.amount || newLine.tax || newLine.units || newLine.totalWithoutTax || newLine.totalWithTax })}
                        />
                        <RequiredInput
                            isClearable
                            id={`linesConcept`}
                            type="text"
                            label="Concepto"
                            className={cn('[&>label>span]:font-medium w-[300px]', { 'hidden': newLine.contract })}
                            value={newLine.concept}
                            onChange={(e: any) => setNewLine({ ...newLine, concept: e.target.value })}
                        />
                        <RequiredInput
                            isClearable
                            id={`linesAmount`}
                            type="number"
                            min={0}
                            label="Cantidad"
                            className={cn('[&>label>span]:font-medium w-[100px]', { 'hidden': newLine.contract })}
                            inputClassName="text-sm"
                            value={newLine.amount}
                            onChange={(e: any) => setNewLine({ ...newLine, amount: e.target.value })}
                            suffix={<FaEuroSign />}
                        />
                        <RequiredInput
                            isClearable
                            id={`linesUnits`}
                            type="number"
                            min={0}
                            step={1}
                            label="Unidades"
                            className={cn('[&>label>span]:font-medium w-[100px]', { 'hidden': newLine.contract })}
                            inputClassName="text-sm"
                            value={newLine.units}
                            onChange={(e: any) => setNewLine({ ...newLine, units: e.target.value })}
                        />
                        <RequiredInput
                            isClearable
                            id={`linesTotalWithoutTax`}
                            type="number"
                            label="Total sin IVA"
                            className={cn('[&>label>span]:font-medium w-[130px]', { 'hidden': newLine.contract })}
                            inputClassName="text-sm"
                            value={newLine.totalWithoutTax}
                            onChange={(e: any) => setNewLine({ ...newLine, totalWithoutTax: e.target.value })}
                            suffix={<FaEuroSign />}
                        />
                        <RequiredInput
                            isClearable
                            id={`linesTotalWithTax`}
                            type="number"
                            min={0}
                            step={0.01}
                            label="Total con IVA"
                            className={cn('[&>label>span]:font-medium w-[130px]', { 'hidden': newLine.contract })}
                            inputClassName="text-sm"
                            value={newLine.totalWithTax}
                            onChange={(e: any) => setNewLine({ ...newLine, totalWithTax: e.target.value })}
                            suffix={<FaEuroSign />}
                        />
                    </div>
                    <div className="flex justify-center items-center m-auto md:me-4 w-fit">
                        <Button
                            color='secondary'
                            size='sm'
                            className="text-sm font-medium text-white bg-primary-400 border border-transparent rounded-md shadow-sm hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            onClick={() => {
                                formik.setFieldValue('lines', [...formik.values.lines, newLine]);
                                setNewLine({ concept: '', amount: 0, tax: 0, units: 0, totalWithoutTax: 0, totalWithTax: 0 });
                            }}
                        >
                            <MdAdd className='h-5 w-5' />
                        </Button>
                    </div>
                </div>
            </div>
        </FormGroup>
    );
};

export default InvoiceLinesForm;