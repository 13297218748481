import { useCallback, useContext } from "react";
import { FaCircleInfo } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "rizzui";
import { toast } from "sonner";
import AttivaCard from "../../../components/card/AttivaCard";
import { Loader } from "../../../components/loader/SpinnerLogo";
import useFetch from "../../../hooks/useFetch";
import useHandleErrors from "../../../hooks/useHandleErrors";
import PageHeader from "../../../layout/shared/page-header";
import { menuRoutes } from "../../../router/menu";
import { ContractService } from "../../../services/contract/contractService";
import { Contract } from "../../../type/entities/contract-type";
import ContractForm from "../ContractForm";
import { ContractContext } from "../providers/ContractProvider";

export default function ContractEdit() {

    const { id = "" } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const { handleErrors } = useHandleErrors();
    const service = new ContractService();

    const { showClientAsideMenuBtn, isOpenClientHistory, setIsOpenClientHistory, setLoadingContract, setLoadingDraft } = useContext(ContractContext);

    const [data, loading] = useFetch(useCallback(async () => {
        const response = await service.getContractById(id as string);
        return response.getResponseData() as Contract;
    }, [id]));

    const pageHeader = {
        title: `Editar Contrato - ${data?.code}`,
        breadcrumb: [
            { name: 'Ventas' },
            { name: 'Gestión de contratos' },
            { href: menuRoutes.contracts.path, name: "Contratos" },
            { name: "Editar" },
        ],
    };

    // ----------------------------------------- rcabezas implementation -----------------------------------------------
    // const handleSubmitContract = async (values: any) => {
    //     setLoadingContract(true);
    //     try {
    //         const response = await (await service.saveAsContract(values)).getResponseData();
    //         if (response.success) {
    //             navigate(-1);
    //             setTimeout(() => {
    //                 toast.success("Contrato guardado correctamente");
    //             }, 100);
    //         } else {
    //             handleErrors(response);
    //         }
    //     } catch (error: any) {
    //         toast.error("Error al guardar el contrato");
    //     } finally {
    //         setLoadingContract(false);
    //     }
    // };


    // const handleSubmitDraft = async (values: any) => {
    //     setLoadingDraft(true);
    //     try {
    //         const response = await (await service.saveAsDraft(values)).getResponseData();
    //         if (response.success) {
    //             navigate(0);
    //             setTimeout(() => {
    //                 toast.success("Borrador guardado correctamente");
    //             }, 200);
    //         } else {
    //             handleErrors(response);
    //         }
    //     } catch (error: any) {
    //         toast.error("Error al guardar el borrador");
    //     } finally {
    //         setLoadingDraft(false);
    //     }
    // };
    // -----------------------------------------------------------------------------------------------------------------


    // ----------------------------------------- dcuevas implementation ------------------------------------------------
    const handleSubmitDraft = async (values: any) => {
        setLoadingDraft(true);
        try {
            const response = await (await service.editDraft(values)).getResponseData();
            if (response.success) {
                navigate(0);
                setTimeout(() => {
                    toast.success("Borrador modificado correctamente");
                }, 200);
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error("Error al modificar el borrador");
        } finally {
            setLoadingDraft(false);
        }
    };

    const handleSubmitContract = async (values: any) => {
        setLoadingContract(true);
        try {
            const response = await (await service.editCompleteContract(values)).getResponseData();
            if (response.success) {
                navigate(-1);
                setTimeout(() => {
                    toast.success("Contrato guardado correctamente");
                }, 100);
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error("Error al guardar el contrato");
        } finally {
            setLoadingContract(false);
        }
    };
    // -----------------------------------------------------------------------------------------------------------------

    if (loading) return <Loader height="70vh" />;

    return (
        <>
            <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb} >
                {showClientAsideMenuBtn && (
                    <div className='flex flex-row justify-end'>
                        <Button onClick={() => setIsOpenClientHistory(!isOpenClientHistory)} className='cursor-pointer'>
                            <FaCircleInfo /> &nbsp; {isOpenClientHistory ? "Cerrar Historial" : "Abrir Historial"}
                        </Button>
                    </div>
                )}
            </PageHeader>

            <AttivaCard title="Datos Principales" className='border-transparent p-1 lg:p-2'>
                {(data !== null && data !== undefined) && <ContractForm data={data} submitContract={handleSubmitContract} submitDraft={handleSubmitDraft} />}
            </AttivaCard>
        </>
    );
}