import { useFormik } from 'formik';
import IBAN from "iban";
import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import { IoClose } from 'react-icons/io5';
import { isValidPhoneNumber } from "react-phone-number-input";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Text, cn } from 'rizzui';
import { toast } from 'sonner';
import Spinner from '../../components/bootstrap/Spinner';
import CustomModal from '../../components/modal/CustomModal';
import { usePrivilege } from '../../components/priviledge/PriviledgeProvider';
import useHandleErrors from '../../hooks/useHandleErrors';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import Page from '../../layout/Page/Page';
import { AuthState } from '../../redux/authSlice';
import { RootState } from '../../redux/store';
import { ContractService } from '../../services/contract/contractService';
import { Contract } from '../../type/entities/contract-type';
import { getDifferences, isEqual, omitKey } from '../../utils/objectFunctions';
import yup from '../../yupExtensions';
import ClientCommentHistory from './components/ClientCommentsHistory';
import ClientConsultancyHistory from './components/ClientConsultancyHistory';
import ClientContractForm from './components/ClientContractForm';
import ClientContractHistory from './components/ClientContractHistory';
import ClientStudentsHistory from './components/ClientStudentsHistory';
import ConsultancyContractForm from './components/ConsultancyContractForm';
import ContractDataForm from './components/ContractDataForm';
import ProductContractForm from './components/ProductContractForm';
import StudentContractForm from './components/StudentContractForm';
import { ContractContext } from './providers/ContractProvider';

interface ContractFormProps {
    submitContract: Function;
    submitDraft: Function;
    data?: Contract | undefined;
}

export const draftSchema = yup.object().shape({
    productTypeId: yup.string().required('Campo obligatorio'),
    clientCifNif: yup.string().required('Campo obligatorio').isValidCifNif(),
    commercialNetworkId: yup.string().required('Campo obligatorio'),
    draftExpirationDate: yup.string().notRequired(),
});

export const contractSchema = yup.object().shape({
    //////////////////////////////////////////
    // CLIENT CONTRACT DATA VALIDATION
    //////////////////////////////////////////
    clientId: yup.string().notRequired(),
    clientActivity: yup.string().min(1, 'Demasiado corto').max(300, 'Demasiado largo').required('Campo obligatorio'),
    clientAddress: yup.string().min(1, 'Demasiado corto').max(300, 'Demasiado largo').required('Campo obligatorio'),
    clientCifNif: yup.string().required('Campo obligatorio').isValidCifNif(),
    clientCity: yup.string().min(1, 'Demasiado corto').max(300, 'Demasiado largo').required('Campo obligatorio'),
    clientCnae: yup.string().matches(/^[0-9]{4}$/, 'CNAE no válido').required('Campo obligatorio'),
    clientCompanyCreationDate: yup.string().required('La fecha de creación de la empresa es obligatoria'),
    clientCompanyName: yup.string().min(1, 'Demasiado corto').max(200, 'Demasiado largo').required('Campo obligatorio'),
    clientEmail: yup.string().email('Correo electrónico no válido').required('Campo obligatorio'),
    clientIban: yup.string().test('iban', 'El formato de IBAN no es válido', (value: any) => IBAN.isValid(value)).nullable(),
    clientPostalCode: yup.string().matches(/^[0-9]{5}$/, 'Código postal no válido').required('Campo obligatorio'),
    clientProvince: yup.string().min(1, 'Demasiado corto').max(300, 'Demasiado largo').required('Campo obligatorio'),
    clientSocialSecurityNumber: yup.string().isValidSS().required('Campo obligatorio'),
    clientTelephone: yup.string().required('El teléfono es obligatorio').test("valid-phone", "El teléfono no es válido", (value) => isValidPhoneNumber(value)),
    clientTelephoneSecondary: yup.string().nullable().notRequired().nullable(),
    clientTelephoneThird: yup.string().nullable().notRequired().nullable(),
    clientWorkersNumber: yup.number().min(1, 'Valor mínimo 1').required('Campo obligatorio'),

    //////////////////////////////////////////
    // PRODUCT CONTRACT DATA VALIDATION
    //////////////////////////////////////////
    productTypeId: yup.string().required('Campo obligatorio'),
    productId: yup.string().required('Campo obligatorio'),
    complements: yup.array().of(yup.object().shape({
        complementId: yup.string().required('Campo obligatorio'),
        amount: yup.number().required('Campo obligatorio'),
    })),
    productAmount: yup.number().required('Campo obligatorio'),
    totalAmount: yup.number().required('Campo obligatorio'),

    //////////////////////////////////////////
    // CONTRACT DATA VALIDATION (GENERAL INFO)
    //////////////////////////////////////////
    campaignId: yup.string().notRequired().nullable(),
    commercialId: yup.string(),
    commercialNetworkId: yup.string().required('Campo obligatorio'),
    contractStartDate: yup.string().required('Campo obligatorio'),
    contractEndDate: yup.string().nullable().notRequired(),
    organisingCorporationId: yup.string().when('productTypeId', {
        is: '26a41eb6-a9d1-4d2f-88e5-1bb59bdb9ae1',
        then: schema => schema.required('Campo obligatorio para productos bonificados'),
        otherwise: schema => schema.notRequired().nullable(),
    }),
    providingCorporationId: yup.string().when('productTypeId', {
        is: '26a41eb6-a9d1-4d2f-88e5-1bb59bdb9ae1',
        then: schema => schema.required('Campo obligatorio para productos bonificados'),
        otherwise: schema => schema.notRequired().nullable(),
    }),
    groupings: yup.string().when('productTypeId', {
        is: '26a41eb6-a9d1-4d2f-88e5-1bb59bdb9ae1',
        then: schema => schema.required('Campo obligatorio para productos bonificados'),
        otherwise: schema => schema.notRequired().nullable(),
    }),

    //////////////////////////////////////////
    // LEGAL REPRESENTATIVE DATA VALIDATION
    //////////////////////////////////////////
    legalRepresentativeName: yup.string().min(1, 'Demasiado corto').max(200, 'Demasiado largo').nullable(),
    legalRepresentativeNif: yup.string().isValidCifNif().nullable(),
    legalRepresentativeEmail: yup.string().email('Email no válido').nullable(),
    legalRepresentativePosition: yup.string().min(1, 'Demasiado corto').max(200, 'Demasiado largo').nullable(),
    legalRepresentativeSchedule: yup.string().min(1, 'Demasiado corto').max(200, 'Demasiado largo').nullable(),

    //////////////////////////////////////////
    // STUDENT CONTRACT DATA VALIDATION
    //////////////////////////////////////////
    studentId: yup.string().notRequired(),
    studentNif: yup.string().when('productTypeId', {
        is: '26a41eb6-a9d1-4d2f-88e5-1bb59bdb9ae1',
        then: schema => schema.required('Campo obligatorio').isValidCifNif(),
        otherwise: schema => schema.isValidCifNif(),
    }),
    studentName: yup.string().min(1, 'Demasiado corto').max(200, 'Demasiado largo').when('productTypeId', {
        is: '26a41eb6-a9d1-4d2f-88e5-1bb59bdb9ae1',
        then: schema => schema.required('Campo obligatorio'),
    }),
    studentFirstName: yup.string().min(1, 'Demasiado corto').max(200, 'Demasiado largo').when('productTypeId', {
        is: '26a41eb6-a9d1-4d2f-88e5-1bb59bdb9ae1',
        then: schema => schema.required('Campo obligatorio'),
    }),
    studentLastName: yup.string().min(1, 'Demasiado corto').max(200, 'Demasiado largo').when('productTypeId', {
        is: '26a41eb6-a9d1-4d2f-88e5-1bb59bdb9ae1',
        then: schema => schema.required('Campo obligatorio'),
    }),
    studentEmail: yup.string().email('Correo electrónico no válido').when('productTypeId', {
        is: '26a41eb6-a9d1-4d2f-88e5-1bb59bdb9ae1',
        then: schema => schema.required('Campo obligatorio'),
    }),
    studentTelephone: yup.string().when('productTypeId', {
        is: '26a41eb6-a9d1-4d2f-88e5-1bb59bdb9ae1',
        then: schema => schema.required('El teléfono es obligatorio').test("student-valid-phone", "El teléfono no es válido", (value) => isValidPhoneNumber(value)),
        otherwise: schema => schema.test("student-valid-phone", "El teléfono no es válido", (value) => value === undefined || isValidPhoneNumber(value ?? '')),
    }),
    studentTelephoneSecondary: yup.string().nullable().notRequired().nullable(),
    studentGender: yup.string().notRequired().nullable(),
    studentTerrorismVictim: yup.boolean().notRequired(),
    studentGenderViolenceVictim: yup.boolean().notRequired(),
    studentDisability: yup.boolean().notRequired(),
    studentSocialSecurityNumber: yup.string().isValidSS().nullable(),
    studentStudyId: yup.string().notRequired().nullable(),
    studentProfessionalCategoryId: yup.string().notRequired().nullable(),
    studentQuotationGroupId: yup.string().notRequired().nullable(),
    studentFunctionalArea: yup.string().notRequired().nullable(),
    studentAlternativeShippingAddress: yup.string().nullable(),
    studentContactSchedule: yup.string().nullable(),

    //////////////////////////////////////////
    // CONSULTANCY CONTRACT DATA VALIDATION
    //////////////////////////////////////////
    consultancyId: yup.string().notRequired().nullable(),
    consultancyName: yup.string().notRequired().nullable(),
    consultancyEmail: yup.string().email('Correo electrónico no válido').notRequired().nullable(),
    consultancyContact: yup.string().notRequired().nullable(),
    consultancyTelephone: yup.string().notRequired().nullable(),
    consultancyDescription: yup.string().notRequired().nullable(),
}).test(
    'at-least-one-field',
    'Todos los campos del representante legal deben ser completados si se proporciona alguno',
    function (values) {
        const fields = [
            'legalRepresentativeEmail',
            'legalRepresentativeName',
            'legalRepresentativeNif',
            'legalRepresentativePosition',
            'legalRepresentativeSchedule'
        ];

        const filledFields = fields.filter(field => !!values[field as keyof typeof values]);

        if (filledFields.length > 0 && filledFields.length < fields.length) {
            const emptyFields = fields.filter(field => !values[field as keyof typeof values]);

            return new yup.ValidationError(
                emptyFields.map(field => new yup.ValidationError(
                    'Campo obligatorio',
                    values[field as keyof typeof values],
                    field
                ))
            );
        }
        return true;
    }
);

const ContractForm: React.FC<ContractFormProps> = ({ submitContract, submitDraft, data }) => {

    const { user }: AuthState = useSelector((state: RootState) => state.auth);
    const { userCan } = usePrivilege();
    const { handleErrors } = useHandleErrors();
    const navigate = useNavigate();
    const mode = data ? 'Editar' : 'Crear';
    const { width } = useWindowDimensions();
    const { setShowClientAsideMenuBtn, isOpenClientHistory, loadingContract, loadingDraft, setLoadingContract, setLoadingDraft, originalClientData, originalStudentData, originalConsultancyData } = useContext(ContractContext);

    const createInitialValues = (data: any, user: any) => ({
        clientId: data?.client?.id ?? '',
        clientActivity: data?.clientActivity ?? '',
        clientAddress: data?.clientAddress ?? '',
        clientCifNif: data?.clientCifNif ?? '',
        clientCity: data?.clientCity ?? '',
        clientCnae: data?.clientCnae ?? '',
        clientCompanyCreationDate: data?.clientCompanyCreationDate?.date.split(' ')[0] ?? '',
        clientCompanyName: data?.clientCompanyName ?? '',
        clientEmail: data?.clientEmail ?? '',
        clientIban: data?.clientIban ?? '',
        clientPostalCode: data?.clientPostalCode ?? '',
        clientProvince: data?.clientProvince ?? '',
        clientSocialSecurityNumber: data?.clientSocialSecurityNumber ?? '',
        clientTelephone: data?.clientTelephone ?? '',
        clientTelephoneSecondary: data?.clientTelephoneSecondary ?? '',
        clientTelephoneThird: data?.clientTelephoneThird ?? '',
        clientWorkersNumber: data?.clientWorkersNumber ?? 1,

        productTypeId: data?.productType?.id ?? '', // PRODUCT TYPE entity
        productId: data?.product?.id ?? '', // PRODUCT entity
        complements: data?.complements?.map((c: any) => { return { complementId: c.complements?.id, amount: c.amount } }) ?? [],
        productAmount: data?.productAmount ?? 0,
        totalAmount: data?.totalAmount ?? 0,

        contractId: data?.id ?? '',
        company: data?.company?.id ?? '',
        campaignId: data?.campaign?.id ?? '', // CAMPAIGN entity
        commercialId: data?.commercial?.id ?? '', // USER entity
        commercialNetworkId: data?.commercialNetwork?.id ??
            ((user?.commercialNetwork) ? user?.commercialNetwork?.[0] : ''), // NETWORK entity
        organisingCorporationId: data?.organisingCorporation?.id ?? '', // CORPORATION entity
        providingCorporationId: data?.providingCorporation?.id ?? '', // CORPORATION entity
        groupings: data?.groupings ?? '',
        draftExpirationDate: data?.draftExpirationDate?.date.split(' ')[0] ?? '',
        contractStartDate: data?.contractStartDate?.date.split(' ')[0] ?? moment().format('YYYY-MM-DD'),
        contractEndDate: data?.contractEndDate?.date.split(' ')[0] ?? '',
        saveAsContract: false,
        saveAsDraft: false,

        legalRepresentativeExist: false,
        legalRepresentativeEmail: data?.legalRepresentativeEmail ?? '',
        legalRepresentativeName: data?.legalRepresentativeName ?? '',
        legalRepresentativeNif: data?.legalRepresentativeNif ?? '',
        legalRepresentativePosition: data?.legalRepresentativePosition ?? '',
        legalRepresentativeSchedule: data?.legalRepresentativeSchedule ?? '',

        studentId: data?.student?.id ?? '',
        studentStudyId: data?.studentStudy?.id ?? '', // STUDENT STUDY entity
        studentQuotationGroupId: data?.studentQuotationGroup?.id ?? '', // STUDENT QUOTATION GROUP entity
        studentProfessionalCategoryId: data?.studentProfessionalCategory?.id ?? '', // STUDENT PROFESSIONAL CATEGORY entity
        studentName: data?.studentName ?? '',
        studentFirstName: data?.studentFirstName ?? '',
        studentLastName: data?.studentLastName ?? '',
        studentNif: data?.studentNif ?? '',
        studentGender: data?.studentGender ?? '',
        studentTerrorismVictim: data?.studentTerrorismVictim ?? false,
        studentGenderViolenceVictim: data?.studentGenderViolenceVictim ?? false,
        studentDisability: data?.studentDisability ?? false,
        studentSocialSecurityNumber: data?.studentSocialSecurityNumber ?? '',
        studentFunctionalArea: data?.studentFunctionalArea ?? '',
        studentEmail: data?.studentEmail ?? '',
        studentTelephone: data?.studentTelephone ?? '',
        studentTelephoneSecondary: data?.studentTelephoneSecondary ?? '',
        studentAlternativeShippingAddress: data?.studentAlternativeShippingAddress ?? '',
        studentContactSchedule: data?.studentContactSchedule ?? '',
        studentBirthDate: data?.studentBirthDate?.date.split(' ')[0] ?? '',

        consultancyId: data?.consultancy?.id ?? '',
        consultancyName: data?.consultancyName ?? '',
        consultancyEmail: data?.consultancyEmail ?? '',
        consultancyContact: data?.consultancyContact ?? '',
        consultancyTelephone: data?.consultancyTelephone ?? '',
        consultancyDescription: data?.consultancyDescription ?? '',
    });

    const [initialValues, setInitialValues] = React.useState<any>(createInitialValues(data, user));
    const [cancelContract, setCancelContract] = React.useState<boolean>(false);
    const [openConfirmClientChanges, setOpenConfirmClientChanges] = React.useState<boolean>(false);
    const [openConfirmStudentChanges, setOpenConfirmStudentChanges] = React.useState<boolean>(false);
    const [openConfirmConsultancyChanges, setOpenConfirmConsultancyChanges] = React.useState<boolean>(false);
    const [clientDifferences, setClientDifferences] = React.useState<any>({});
    const [studentDifferences, setStudentDifferences] = React.useState<any>({});
    const [consultancyDifferences, setConsultancyDifferences] = React.useState<any>({});

    const formik = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,
        validationSchema: yup.lazy(({ saveAsContract }) => saveAsContract ? contractSchema : draftSchema),
        validateOnBlur: false,
        //validateOnChange: false,
        onSubmit: values => { submit(values) }
    });

    /**
     * Submit form data (contract or draft)
     */
    const submit = (values: any) => {
        // Helper function to check if differences exist
        const hasDifferences = (differences: any) => differences && Object.keys(differences).length > 0;

        // Check for differences and show modals if needed
        if (values.saveAsContract) {
            if (hasDifferences(getClientDifferences(values))) setOpenConfirmClientChanges(true);
            if (hasDifferences(getStudentDifferences(values))) setOpenConfirmStudentChanges(true);
            if (hasDifferences(getConsultancyDifferences(values))) setOpenConfirmConsultancyChanges(true);
        }

        // Remove totalAmount from values because it's calculated on the backend
        delete values.totalAmount;

        // Submit contract or draft if there are no differences
        const noDifferences =
            !hasDifferences(getClientDifferences(values)) &&
            !hasDifferences(getStudentDifferences(values)) &&
            !hasDifferences(getConsultancyDifferences(values));

        if (noDifferences) {
            if (values.saveAsContract) submitContract(values);
            if (values.saveAsDraft) submitDraft(values);
            formik.resetForm();
        }
    };

    /**
     * Generic function to get differences between original data and new data
     */
    const getDifferencesWithPermission = (
        values: any,
        originalData: any,
        newDataKeys: string[],
        userPermission: string,
        setDifferencesCallback: (differences: any) => void
    ) => {
        if (userCan(userPermission, 'contracts') && originalData) {
            const newData = newDataKeys.reduce((acc: any, key) => {
                acc[key] = values[key];
                return acc;
            }, {});
            const differences = getDifferences(originalData, newData);
            setDifferencesCallback({ ...differences, [newDataKeys[0]]: values[newDataKeys[0]] });
            return differences;
        }
    };

    /**
     * Get differences between original client data and new client data
     */
    const getClientDifferences = (values: any) => {
        return getDifferencesWithPermission(
            values,
            originalClientData,
            [
                'clientId',
                'clientCifNif',
                'clientCompanyName',
                'clientCnae',
                'clientActivity',
                'clientIban',
                'clientEmail',
                'clientCompanyCreationDate',
                'clientSocialSecurityNumber',
                'clientWorkersNumber',
                'clientTelephone',
                'clientTelephoneSecondary',
                'clientProvince',
                'clientCity',
                'clientAddress',
                'clientPostalCode',
            ],
            'edit_client_on_contract',
            setClientDifferences
        );
    };

    /**
     * Get differences between original student data and new student data
     */
    const getStudentDifferences = (values: any) => {
        return getDifferencesWithPermission(
            values,
            originalStudentData,
            [
                'studentId',
                'studentNif',
                'studentName',
                'studentFirstName',
                'studentLastName',
                'studentEmail',
                'studentTelephone',
                'studentTelephoneSecondary',
                'studentGender',
                'studentTerrorismVictim',
                'studentGenderViolenceVictim',
                'studentDisability',
                'studentSocialSecurityNumber',
                'studentStudyId',
                'studentProfessionalCategoryId',
                'studentQuotationGroupId',
                'studentFunctionalArea',
                'studentAlternativeShippingAddress',
                'studentContactSchedule',
                'studentBirthDate',
            ],
            'edit_student_on_contract',
            setStudentDifferences
        );
    };

    /**
     * Get differences between original consultancy data and new consultancy data
     */
    const getConsultancyDifferences = (values: any) => {
        return getDifferencesWithPermission(
            values,
            originalConsultancyData,
            [
                'consultancyId',
                'consultancyName',
                'consultancyEmail',
                'consultancyContact',
                'consultancyTelephone',
                'consultancyDescription',
            ],
            'edit_consultancy_on_contract',
            setConsultancyDifferences
        );
    };

    /**
     * Modify client data on contract
     */
    const editClientData = async (values: any) => {
        formik.values.saveAsContract ?? setLoadingContract(true);
        formik.values.saveAsDraft ?? setLoadingDraft(true);
        try {
            const response = await (await (new ContractService()).editClientOnContract(values)).getResponseData();
            if (response.success) {
                toast.success("Datos del cliente actualizados correctamente");
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error("Error al actualizar los datos del cliente");
        } finally {
            if (!openConfirmStudentChanges && !openConfirmConsultancyChanges) {
                if (formik.values.saveAsContract) {
                    submitContract(formik.values);
                } else {
                    submitDraft(formik.values);
                }
            }
        }
    };

    /**
     * Modify student data on contract
     */
    const editStudentData = async (values: any) => {
        formik.values.saveAsContract ?? setLoadingContract(true);
        formik.values.saveAsDraft ?? setLoadingDraft(true);
        try {
            const response = await (await (new ContractService()).editStudentOnContract(values)).getResponseData();
            if (response.success) {
                toast.success("Datos de la asesoría actualizados correctamente");
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error("Error al actualizar los datos de la asesoría");
        } finally {
            if (!openConfirmClientChanges && !openConfirmConsultancyChanges) {
                if (formik.values.saveAsContract) {
                    submitContract(formik.values);
                } else {
                    submitDraft(formik.values);
                }
            }
        }
    };

    /**
     * Modify consultancy data on contract
     */
    const editConsultancyData = async (values: any) => {
        formik.values.saveAsContract ?? setLoadingContract(true);
        formik.values.saveAsDraft ?? setLoadingDraft(true);
        try {
            const response = await (await (new ContractService()).editConsultancyOnContract(values)).getResponseData();
            if (response.success) {
                toast.success("Datos de la asesoría actualizados correctamente");
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error("Error al actualizar los datos de la asesoría");
        } finally {
            if (!openConfirmClientChanges && !openConfirmStudentChanges) {
                if (formik.values.saveAsContract) {
                    submitContract(formik.values);
                } else {
                    submitDraft(formik.values);
                }
            }
        }
    };

    /**
     * Set initial values when data is loaded (edit mode)
     */
    useEffect(() => {
        if (data) {
            setInitialValues(createInitialValues(data, user));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, user]);

    /**
     * Show aside menu button when client is selected
     */
    useEffect(() => {
        if (formik.values.clientId?.length >= 32 || (data && data?.client?.id?.length >= 32)) {
            setShowClientAsideMenuBtn(true);
        }
        return () => setShowClientAsideMenuBtn(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.clientId, data]);

    /**
     * Prevent user from leaving the page if there are unsaved changes in the form
     */
    useEffect(() => {
        window.onbeforeunload = (e: BeforeUnloadEvent) => {
            if ((mode === 'Crear' && formik.dirty) || (mode === 'Editar' && !isEqual(omitKey(initialValues, "legalRepresentativeExist"), omitKey(formik.values, "legalRepresentativeExist")))) {
                e.preventDefault();
                e.returnValue = 'Si abandonas la página perderás los datos introducidos en el contrato';
            }
        };
        return () => window.onbeforeunload = null as any;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.dirty, mode, formik.values]);

    const getContent = () => {
        return (
            <>
                <div className="@container">
                    <form onSubmit={formik.handleSubmit} className='border-transparent'>
                        <div className="flex flex-col lg:flex-row flex-nowrap gap-5 mb-2">
                            <div className={cn(
                                'flex flex-col gap-7 w-full transition-all duration-1000 ease-in-out md:h-[calc(100vh-320px)] md:overflow-y-scroll md:relative md:pe-2 scrollbar',
                                (formik.values.clientId && isOpenClientHistory) && 'lg:w-[70%]'
                            )}>
                                <ClientContractForm formik={formik} />
                                <ProductContractForm formik={formik} />
                                <ContractDataForm formik={formik} />
                                <StudentContractForm formik={formik} />
                                <ConsultancyContractForm formik={formik} />
                            </div>

                            <div className={`slide-container ${formik.values.clientId && isOpenClientHistory ? 'slide-enter' : 'slide-exit'} `}>
                                <div className={`content-wrapper ${formik.values.clientId && isOpenClientHistory ? 'content-visible' : 'content-hidden'}`}>
                                    {formik.values.clientId && (
                                        <div className="rounded-lg border-solid border-2 col-span-3 p-4 w-full h-fit divide-y-4 divide-dotted md:h-[calc(100vh-330px)] md:overflow-y-scroll md:relative scrollbar">
                                            <ClientCommentHistory clientId={formik.values.clientId} formik={formik} />
                                            <ClientContractHistory clientId={formik.values.clientId} formik={formik} />
                                            <ClientStudentsHistory clientId={formik.values.clientId} formik={formik} />
                                            <ClientConsultancyHistory clientId={formik.values.clientId} formik={formik} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </>
        );
    };

    return (
        <>
            <Page container="fluid">
                {(mode === "Editar" && data) && getContent()}
                {mode === "Crear" && getContent()}
            </Page>

            <div className='sticky bottom-0 z-10 md:z-0 md:flex md:flex-row md:justify-end grid grid-cols-3 items-center gap-4 border-t bg-white px-4 py-4 dark:bg-gray-50 -me-4 -ms-4'>
                <Button
                    variant="outline"
                    onClick={() => {
                        if ((mode === 'Crear' && formik.dirty) || (mode === 'Editar' && (JSON.stringify(omitKey(initialValues, "legalRepresentativeExist")) !== JSON.stringify(omitKey(formik.values, "legalRepresentativeExist"))))) {
                            setCancelContract(true);
                            return;
                        }
                        navigate(-1);
                    }}
                >
                    {width > 768 ? 'Cancelar' : <IoClose size={20} />}
                </Button>
                <Button
                    name='save-draft-button'
                    type="submit"
                    className={cn(data?.isDraft === false && 'hidden')}
                    color="secondary"
                    onClick={() => {
                        formik.setFieldValue('saveAsContract', false);
                        formik.setFieldValue('saveAsDraft', true);
                        setTimeout(() => {
                            formik.submitForm();
                        }, 100);
                    }}
                    isLoading={loadingDraft}
                    disabled={loadingDraft}
                >
                    {loadingDraft ? <Spinner /> : mode + ' Borrador'}
                </Button>
                <Button
                    name='save-contract-button'
                    type="submit"
                    onClick={() => {
                        formik.setFieldValue('saveAsContract', true);
                        formik.setFieldValue('saveAsDraft', false);
                        setTimeout(() => {
                            formik.submitForm();
                        }, 100);
                    }}
                    isLoading={loadingContract}
                    disabled={loadingContract}
                >
                    {loadingContract ? <Spinner /> : ((mode === "Editar" && data?.isDraft === true) ? 'Guardar Contrato' : mode + ' Contrato')}
                </Button>
            </div>

            <CustomModal
                name='Datos de contrato'
                isOpen={cancelContract}
                onConfirm={() => { setCancelContract(false); navigate(-1); }}
                onClose={() => setCancelContract(false)}
                confirmText='Sí, salir'
                cancelText='Cancelar'
            >
                <Text className='mb-7'>Se perderán los datos introducidos en el contrato. ¿Estás seguro de que quieres salir?</Text>
            </CustomModal>

            <CustomModal
                name='Datos de cliente'
                isOpen={openConfirmClientChanges && userCan('edit_client_on_contract', 'contracts')}
                onConfirm={() => {
                    setOpenConfirmClientChanges(false);
                    editClientData(clientDifferences);
                }}
                onClose={() => {
                    setOpenConfirmClientChanges(false);
                    if (!openConfirmStudentChanges && !openConfirmConsultancyChanges) {
                        if (formik.values.saveAsContract) {
                            submitContract(formik.values);
                        } else {
                            submitDraft(formik.values);
                        }
                    }
                }}
            >
                <Text className='mb-7'>Se han modificado los datos del cliente. ¿Quieres actualizar los datos en el cliente?</Text>
            </CustomModal>

            <CustomModal
                name='Datos de empleado'
                isOpen={openConfirmStudentChanges && userCan('edit_student_on_contract', 'contracts') && !openConfirmClientChanges}
                onConfirm={() => {
                    setOpenConfirmStudentChanges(false);
                    editStudentData(studentDifferences);
                }}
                onClose={() => {
                    setOpenConfirmStudentChanges(false);
                    if (!openConfirmClientChanges && !openConfirmConsultancyChanges) {
                        if (formik.values.saveAsContract) {
                            submitContract(formik.values);
                        } else {
                            submitDraft(formik.values);
                        }
                    }
                }}
            >
                <Text className='mb-7'>Se han modificado los datos del empleado. ¿Quieres actualizar los datos en el empleado?</Text>
            </CustomModal>

            <CustomModal
                name='asesoria'
                isOpen={openConfirmConsultancyChanges && userCan('edit_consultancy_on_contract', 'contracts')}
                onConfirm={() => {
                    setOpenConfirmConsultancyChanges(false);
                    editConsultancyData(consultancyDifferences);
                }}
                onClose={() => {
                    setOpenConfirmConsultancyChanges(false);
                    if (!openConfirmClientChanges && !openConfirmStudentChanges) {
                        if (formik.values.saveAsContract) {
                            submitContract(formik.values);
                        } else {
                            submitDraft(formik.values);
                        }
                    }
                }}
            >
                <Text className='mb-7'>Se han modificado los datos de la asesoría. ¿Quieres actualizar los datos en la asesoría?</Text>
            </CustomModal>
        </>
    );
};

export default ContractForm;