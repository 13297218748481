import chroma from 'chroma-js';
import React, { useCallback, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Text } from 'rizzui';
import { usePrivilege } from '../../../../../../components/priviledge/PriviledgeProvider';
import useFetch from '../../../../../../hooks/useFetch';
import { menuRoutes } from '../../../../../../router/menu';
import { OrderService } from '../../../../../../services/order/orderService';
import { generateColor } from '../OrderStats';

interface ContractTutorsAndSchedulesProps { }

const ContractTutorsAndSchedules: React.FC<ContractTutorsAndSchedulesProps> = ({ }) => {

    // HOOKS

    const { id } = useParams<{ id: string }>();
    const { userCan } = usePrivilege();

    // STATES

    const [chartData, setChartData] = React.useState<any[]>([]);
    const [allProducts, setAllProducts] = React.useState<any[]>([]);

    const [tutorData] = useFetch(useCallback(async () => {
        if (!id) return null;
        const response = await (new OrderService).getOrderContractsByTutor(id as string);
        return response.getResponseData();
    }, [id]));

    const [schedules] = useFetch(useCallback(async () => {
        if (!id) return null;
        const response = await (new OrderService).getTutorsSchedules(id as string);
        return response.getResponseData();
    }, [id]));

    // FUNCTIONS

    /**
    * Transform contracts data to be used in the chart
    */
    const transformData = (tutors: any) =>
        tutors.map((t: any) => ({
            tutorName: t.tutorName,
            tutorId: t.tutorId,
            ...Object.fromEntries(t.products.map((p: any) => [p.productName, p.count]))
        })).sort((a: any, b: any) => (a.tutorId === null ? 1 : -1));

    // USE EFFECT

    /**
    * Set all products from all tutors
    */
    useEffect(() => {
        if (tutorData) {
            setChartData(transformData(tutorData));

            const uniqueProducts = new Map();
            tutorData.forEach((tutor: any) => {
                tutor.products.forEach((product: any) => {
                    if (!uniqueProducts.has(product.productName)) {
                        uniqueProducts.set(product.productName, {
                            ...product,
                            tutorId: tutor.tutorId
                        });
                    }
                });
            });

            setAllProducts(Array.from(uniqueProducts.values()).sort((a, b) => (a.tutorId === null ? 1 : -1)));
        }
    }, [tutorData]);

    // RENDER

    return (
        <div className="grid grid-cols-5 gap-4 mt-10">
            <div className='col-span-5 xl:col-span-3'>
                {chartData?.length > 0 && (
                    <>
                        <Text className="text-center font-bold mb-5">Contratos asignados por tutor</Text>
                        <div className="w-full h-96 pe-4">
                            <ResponsiveContainer width="100%" height="100%">
                                <BarChart layout="vertical" data={chartData} barSize={1}>
                                    <XAxis type="number" tick={{ fontSize: 12 }} tickCount={10} domain={[0, "dataMax"]} allowDecimals={false} tickLine={false} />
                                    <YAxis
                                        dataKey="tutorName"
                                        type="category"
                                        tick={{ fontSize: 14 }}
                                        tickLine={false}
                                    /* tickFormatter={(value) => {
                                        return (
                                            userCan('get_tutors', 'tutors') ? (
                                                <Link to={`${menuRoutes.tutors.path}/${chartData.find((t) => t.tutorName === value)?.tutorId}/profile${menuRoutes.tutors.profile.info}`}>
                                                    <Text className="text-primary">{value}</Text>
                                                </Link>
                                            ) : (
                                                <Text className="text-primary">{value}</Text>
                                            )
                                        );
                                    }} */
                                    />

                                    <Tooltip cursor={{ fill: "#f5f5f5" }} />
                                    {allProducts?.map((p: any) => {
                                        const barColor = generateColor(p.productName);
                                        // The text color is white or black depending on the contrast with the bar color
                                        const textColor = chroma.contrast(barColor, "white") > 2.5 ? "white" : "black";

                                        return (
                                            <Bar key={p.productName} dataKey={p.productName} stackId="a" barSize={20} fill={barColor}
                                                label={({ x, y, width, height, value }) => (
                                                    width > 30
                                                        ? ( // Only show the text if the bar is wide enough
                                                            <text
                                                                x={x + width / 2}
                                                                y={y + height / 2}
                                                                fill={textColor}
                                                                textAnchor="middle"
                                                                dominantBaseline="middle"
                                                                fontSize={12}
                                                            >
                                                                {p.productName}
                                                            </text>
                                                        )
                                                        : <></>
                                                )}
                                            />
                                        );
                                    })}
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </>
                )}
            </div>

            <div className='col-span-5 xl:col-span-2'>
                {schedules?.length > 0 && (
                    <>
                        <Text className="text-center font-bold mb-5">Horarios asignados</Text>
                        <div className="overflow-auto rounded-lg border border-gray-200">
                            <table className="w-full border-collapse">
                                <thead className="border bg-gray-100 rounded-t-lg">
                                    <tr>
                                        <th className="px-4 py-2">Tutor</th>
                                        <th className="text-nowrap px-4 py-2">Mañanas<br />11.00 - 14.00</th>
                                        <th className="text-nowrap px-4 py-2">Tardes<br />13.00 - 19.00</th>
                                    </tr>
                                </thead>
                                <tbody className="border">
                                    {schedules?.map((schedule: any, index: number) => (
                                        <tr key={schedule.tutorId} className={index === schedules.length - 1 ? "rounded-b-lg" : ""}>
                                            <td className="px-4 py-2">
                                                {userCan('get_tutors', 'tutors') ? (
                                                    <Link to={`${menuRoutes.tutors.path}/${schedule.tutorId}/profile${menuRoutes.tutors.profile.info}`}>
                                                        <Text className="font-bold text-primary">{schedule.tutorName}</Text>
                                                    </Link>
                                                ) : (
                                                    <Text className="font-bold text-primary">{schedule.tutorName}</Text>
                                                )}
                                            </td>
                                            <td className="text-center px-4 py-2">{schedule.tutorScheduleMorning}</td>
                                            <td className="text-center px-4 py-2">{schedule.tutorScheduleAfternoon}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default ContractTutorsAndSchedules;