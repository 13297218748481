import React, { useCallback, useEffect, useState } from 'react';
import { IoMdLogIn } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { Button, Switch, Tooltip } from 'rizzui';
import { toast } from 'sonner';
import Spinner from '../../../components/bootstrap/Spinner';
import DeletePopover from '../../../components/buttons/DeletePopover';
import { Loader } from '../../../components/loader/SpinnerLogo';
import TabNav, { MenuItem } from "../../../components/navigation/TabNav";
import { usePrivilege } from '../../../components/priviledge/PriviledgeProvider';
import useFetch from '../../../hooks/useFetch';
import useHandleErrors from '../../../hooks/useHandleErrors';
import PageHeader from "../../../layout/shared/page-header";
import { logoutThunk } from "../../../redux/authSlice";
import { AppDispatch, RootState } from '../../../redux/store';
import { setSelectedView } from '../../../redux/tutorProfileSlice';
import { Tutor, loginThunk, tutorLogin } from "../../../redux/tutorSlice";
import { menuRoutes } from "../../../router/menu";
import { TutorService } from '../../../services/tutor/tutorService';
import { TutorApiResponse } from '../../../type/entities/tutor-type';

const menuItems: MenuItem[] = [
    {
        label: 'Información',
        path: '/info',
        permission: {
            group: 'tutors',
            action: 'get_tutors',
        },
    },
    {
        label: 'Estadísticas',
        path: '/stats',
        permission: {
            group: 'tutors',
            action: 'tutors_stats',
        },
    },
    {
        label: 'Documentos',
        path: '/documents',
        permission: {
            group: 'tutors',
            action: 'tutors_documentation',
        },
    },
    {
        label: 'Facturas',
        path: '/invoices',
        permission: {
            group: 'tutors',
            action: 'tutors_invoices',
        },
    },
    {
        label: 'Contratos',
        path: '/contracts',
        permission: {
            group: 'tutors',
            action: 'tutors_pending_contracts',
        },
    },
];

export default function TutorProfileLayout({ children, isLoading }: { children: React.ReactNode, isLoading?: boolean }) {

    const { selectedView } = useSelector((state: RootState) => state.tutorProfile);
    const { id = '' } = useParams<{ id: string }>();
    const { userCan } = usePrivilege();
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { handleErrors } = useHandleErrors();
    const service = new TutorService();

    const [loginIsLoading, setIsLoading] = useState<boolean>(false);
    const [changingStatus, setChangingStatus] = useState<string[]>([]);

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        if (!id || id === '') return null;
        const response = await service.getTutorById(id);
        return response.getResponseData() as TutorApiResponse;
    }, [id]));

    const pageHeader = {
        title: `Perfil de ${data?.name || 'Tutor'} ${data?.lastName || ''}`,
        breadcrumb: [
            { name: 'Administración' },
            { name: 'Control de acceso' },
            { name: 'Tutores', href: menuRoutes.tutors.path },
            { name: 'Perfil de Tutor' },
        ]
    };

    const handleLoginAsTutor = async (id: string) => {
        setIsLoading(true);
        const response = await service.loginAsTutor(id) as any;
        const responseData = response.getResponseData();

        if (response && responseData?.success) {
            try {
                const tutor: Tutor = {
                    id: responseData.data.tutor.id,
                    email: responseData.data.tutor.email,
                    token: responseData.data.token,
                    name: responseData.data.tutor.name,
                    lastName: responseData.data.tutor.lastName,
                    roles: responseData.data.tutor.roles,
                    commercialNetwork: responseData.data.tutor.commercialNetworks,
                    platformUser: responseData.data.tutor.platformUser,
                    tutorType: responseData.data.tutor.tutorType,
                    company: responseData.data.tutor.company,
                    companyName: responseData.data.tutor.companyName,
                };

                await dispatch(logoutThunk());

                await new Promise(resolve => setTimeout(resolve, 50)); // 50 ms delay to avoid overwriting the store

                await dispatch(loginThunk({
                    isAuthenticated: true,
                    loading: false,
                    error: null,
                    tutor: tutor
                }));

                navigate(menuRoutes.tutor_panel.profile, { replace: true });
                toast.success('Has accedido como ' + tutor.name);
            } catch (error) {
                toast.error('Error al intentar acceder como el tutor');
                return;
            } finally {
                setIsLoading(false);
            }
        };

        if (responseData.payload) {
            navigate('/users', { replace: true });
            setIsLoading(false);
        };

        if (!responseData?.success) {
            toast.error(response?.response?.message || 'Error al intentar acceder como el tutor');
            setIsLoading(false);
            dispatch(tutorLogin({
                isAuthenticated: false,
                loading: false,
                error: response.message,
                tutor: null
            }));
            return;
        };
    };

    const toggleState = async (id: string, status: boolean) => {
        try {
            setChangingStatus([...changingStatus, id]);
            const response = (await service.toggleTutorStatus(id, status)).getResponseData();
            if (response.success) {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                refetch();
                toast.success(status ? "Se ha desactivado el tutor" : "Se ha activado el tutor");
            } else {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                toast.error(response.message);
            }
        } catch (error: any) {
            setChangingStatus(changingStatus.filter((item) => item !== id));
            toast.error(error.message);
        }
    };

    const handleDelete = async (id: string) => {
        try {
            const response = await (await service.deleteTutor(id)).getResponseData();
            if (response.success) {
                navigate(-1);
                toast.success('Tutor eliminado correctamente');
            } else {
                handleErrors(response);
            }
        } catch (error) {
            handleErrors(error);
        }
    };

    useEffect(() => {
        navigate(`${menuRoutes.tutors.path}/${id}/profile${selectedView}`);
    }, [selectedView]);

    return (
        <>
            <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb}>
                <div className='flex flex-row flex-nowrap gap-3 mt-3 md:mt-0'>
                    {userCan('edit_tutors', 'tutors') && (
                        <>
                            {data && userCan('login_as_tutor', 'user') && (
                                <>
                                    <Tooltip
                                        size="sm"
                                        content={'Acceder como tutor'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <Button
                                            variant="outline"
                                            className="hover:!border-gray-900 hover:text-gray-700 cursor-pointer"
                                            onClick={() => handleLoginAsTutor(data.id)}
                                            disabled={loginIsLoading}
                                        >
                                            {loginIsLoading ? <Spinner color={'dark'} /> : <IoMdLogIn className="h-4 w-4" size={20} />}
                                        </Button>
                                    </Tooltip>
                                    <div className='border-l border-gray-300' />
                                </>
                            )}

                            {data && (
                                <>
                                    <Tooltip
                                        size="sm"
                                        content={data.active ? 'Desactivar tutor' : 'Activar tutor'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <Switch
                                            id={id}
                                            label={data.active ? 'Activo' : 'Desactivado'}
                                            checked={data.active}
                                            onChange={() => toggleState(data.id, data.active)}
                                            switchKnobClassName='bg-secondary'
                                        />
                                    </Tooltip>
                                    <div className='border-l border-gray-300' />
                                </>
                            )}

                            <Tooltip content={'Editar tutor'} placement={'top'} color={'invert'} size={'sm'}>
                                <Button variant={'outline'} color={'primary'} size={'md'} onClick={() => {
                                    navigate(`${menuRoutes.tutors.path}/${id}/edit`)
                                }}>
                                    Editar
                                </Button>
                            </Tooltip>
                        </>
                    )}

                    {userCan('delete_tutors', 'tutors') && (
                        <DeletePopover
                            title='Eliminar tutor'
                            description={`¿Estás seguro de que deseas eliminar el tutor?`}
                            size={'md'}
                            onDelete={() => handleDelete(id)}
                            actionIconClassName='h-auto w-10'
                        />
                    )}
                </div>
            </PageHeader>

            <TabNav menuItems={menuItems} setSelectedView={setSelectedView} data={data} />

            {isLoading ? <Loader height='60vh' /> : children}
        </>
    );
}