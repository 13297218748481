import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFiltersPR } from '../../../components/providers/FiltersProvider';
import { FilterDrawerView } from '../../../components/table/components/TableFilter';
import { RootState } from '../../../redux/store';
import FilteredStatesTable from './components/FilteredStatesTable';
import StatesListLayout from './components/StatesListLayout';
import StatesFilters from './StatesFilters';

const StatesList = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const { selectedView } = useSelector((state: RootState) => state.states);
    const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters } = useFiltersPR();

    const [entity, setEntity] = useState<string>('');
    const [openFilters, setOpenFilters] = useState<boolean>(false);

    /**
     * Redirect to the selected view after the page is loaded
     */
    useEffect(() => {
        if (location.pathname === '/states') {
            setTimeout(() => {
                navigate(`/states${selectedView}`);
            }, 100);
        }
    }, []);

    /**
     * Reset filters when the view changes
     */
    useEffect(() => {
        resetFilters();
    }, [selectedView]);

    /**
     * Update filters when the location changes, getting the entity from the URL
     */
    useEffect(() => {
        const entityName = location.pathname.replace('/states/', '').replace('_states', '');
        setEntity(entityName);
        updateFilters({ entity: entityName });
    }, [location.pathname]);

    return (
        <StatesListLayout>
            <FilteredStatesTable
                entity={entity}
                filters={filters} updateFilters={updateFilters} updateFilterOrder={updateFilterOrder} updatePage={updatePage} updatePageSize={updatePageSize}
                openFilters={openFilters} setOpenFilters={setOpenFilters}
            />

            <FilterDrawerView isOpen={openFilters} setOpenDrawer={setOpenFilters} drawerTitle={'Filtros Estados'}>
                <StatesFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} />
            </FilterDrawerView>
        </StatesListLayout>
    );
};

export default StatesList;