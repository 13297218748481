import { SVGProps } from 'react';

const SvgDoNotStep = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
			<path fill='none' d='M0 0h24v24H0z' />
			<path
				d='M14.98 12.15l3.07-3.07-4.25-4.26-3.08 3.07 4.26 4.26zm-2.56 3.11l-1.67-1.68-3.33 3.32c-.78.78-2.05.78-2.83-.01l-.19-.17-.47.24c-.29.14-.59.27-.89.39l-.01.65h6.64l2.75-2.74z'
				opacity={0.3}
			/>
			<path d='M18.51 15.68l-1.41-1.41 4.48-4.48L23 11.2l-4.49 4.48zm-3.53-3.53l3.07-3.07-4.25-4.26-3.08 3.07L9.3 6.47 13.8 2l7.08 7.08-4.48 4.48-1.42-1.41zm6.2 9.05l-1.41 1.41-5.94-5.94L10.5 20H1v-2.63c0-.84.52-1.57 1.3-1.88.58-.23 1.28-.56 1.97-1.02l1.38 1.38c.09.1.22.15.35.15s.26-.05.36-.15c.2-.2.2-.51 0-.71l-1.28-1.28c.27-.24.53-.51.77-.8l1.27 1.27a.485.485 0 00.7 0c.2-.2.2-.51 0-.71l-1.4-1.4c.19-.34.34-.72.45-1.12l1.71 1.72a.485.485 0 00.7 0c.19-.2.19-.5.01-.7l-7.9-7.9 1.42-1.41L21.18 21.2zm-8.76-5.94l-1.67-1.68-3.33 3.32c-.78.78-2.05.78-2.83-.01l-.19-.17-.47.24c-.29.14-.59.27-.89.39l-.01.65h6.64l2.75-2.74z' />
		</svg>
	);
};

export default SvgDoNotStep;
