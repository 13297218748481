import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import AttivaCard from '../../../components/card/AttivaCard';
import PageHeader from '../../../layout/shared/page-header';
import { menuRoutes } from '../../../router/menu';
import { OrganisationService } from '../../../services/organisation/organisationService';
import OrganisationForm from '../OrganisationForm';

const pageHeader = {
    title: 'Crear Organización',
    breadcrumb: [
        {
            href: menuRoutes.organisations.path,
            name: 'Organizaciones',
        },
        {
            name: 'Crear',
        },
    ],
};

export default function OrganisationCreate() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);

	const handleSubmit = async (values: any) => {
		setLoading(true);

		const formData = new FormData();

		for (let value in values) {
			if (values[value]) {
				formData.append(value, values[value]);
			}
		}

		try {
			const response = await (await (new OrganisationService()).createOrganisation(formData)).getResponseData();
			if (response.success) {
				setLoading(false);
				navigate(-1);
				setTimeout(() => {
					toast.success('Organización creada');
				}, 100)
			} else {
				response.data.errors.forEach((error: any) => {
					toast.error(error.message);
				});
			}
		} catch (error: any) {
			toast.error(error.message);
		} finally {
			setLoading(false);
		}
	};

    return (
        <>
            <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb}>
            </PageHeader>

            <AttivaCard title='Datos Principales' className='mb-4'>
                <OrganisationForm submit={handleSubmit} isLoading={loading} />
            </AttivaCard>
        </>
    );
}