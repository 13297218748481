import { useCallback, useEffect, useState } from 'react';
import { OrderService } from '../../services/order/orderService';
import { TutorService } from '../../services/tutor/tutorService';

/*----------------------------------------------------------------------------------------
* EN: Hook to manage tutors fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de empresas.
----------------------------------------------------------------------------------------*/

const useTutors = (initialFilters = {}) => {

    const [data, setData] = useState<any[]>([]);
    const [assignableTutors, setAssignableTutors] = useState<any[]>([]);
    const [filters, setFilters] = useState({
        limit: 999999,
        filter_filters: { ...initialFilters }
    });

    const fetchTutors = useCallback(async () => {
        try {
            const service = new TutorService();
            const response = await service.listTutors({ limit: 999999 });
            const fetchedData = response.getResponseData().data;

            if (fetchedData && fetchedData?.data) {
                const mappedData = fetchedData.data?.map((data: { id: string; name: string; lastName: string; nifCif: string }) => ({
                    value: data.id,
                    label: data.name + ' ' + data.lastName + ' (' + data.nifCif + ')',
                }));
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching tutors:', error);
        }
    }, []);

    const fetchAssignableTutors = useCallback(async (filters: any) => {
        try {
            const service = new OrderService();
            const response = await service.getAssignablesTutors({
                limit: 999999,
                filter_filters: { ...filters }
            });
            const fetchedData = response.getResponseData().data;

            if (fetchedData) {
                const mappedData = transformTutors(fetchedData);
                setAssignableTutors(mappedData);
            }
        } catch (error) {
            console.log('Error fetching tutors:', error);
        }
    }, [filters]);

    /**
     * Transforma los datos de los tutores para mostrar en el select de asignación de contratos
     */
    const transformTutors = (data: any) => {
        const categories = ["internal_main", "internal_secondary", "external_main", "external_secondary"];

        const mappedData: { value: string; label: string; disabled?: boolean }[] = [];

        categories.forEach(category => {
            if (data[category]?.length > 0) {
                // Agrega el separador
                mappedData.push({
                    value: category,
                    label: category.replace("_", " ").replace("internal", "Internos").replace("external", "Externos").replace("main", "Principales").replace("secondary", "Secundarios"),
                    disabled: true
                });

                // Agrega los tutores dentro de la categoría
                mappedData.push(
                    ...data[category].map((tutor: { id: string; name: string; lastName: string; nifCif: string; }) => ({
                        value: tutor.id,
                        label: `${tutor.name} ${tutor.lastName} (${tutor.nifCif})`,
                    }))
                );
            }
        });

        return mappedData;
    };

    useEffect(() => {
        fetchTutors();
    }, []);

    const updateTutorsHookFilters = (newFilters: any) => {
        setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
    };

    const getTutorsList = () => {
        return data;
    };

    const getAssignableTutorsList = () => {
        return assignableTutors;
    }

    return { fetchTutors, fetchAssignableTutors, getTutorsList, getAssignableTutorsList, updateTutorsHookFilters };
}

export default useTutors;