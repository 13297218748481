import { isEqual } from 'lodash';
import React from 'react';
import { PiTrashDuotone } from 'react-icons/pi';
import { Button } from 'rizzui';
import CustomCreatableSelect from '../../../components/forms/CustomCreatableSelect';
import CustomSelect from '../../../components/forms/CustomSelect';
import CustomSelectApiHook from '../../../components/forms/CustomSelectApiHook';
import DateField from '../../../components/forms/DateField';
import useIncidenceStatuses from '../../../hooks/api-calls/Incidences/useIncidenceStatuses';
import useIncidenceTypes from '../../../hooks/api-calls/Incidences/useIncidenceTypes';
import useIncidenceUsers from '../../../hooks/api-calls/Incidences/useIncidenceUsers';
import { FilterOptions } from '../../../hooks/useFilters';
import useContractIncidenceStates from '../../../hooks/api-calls/contract/useContractIncidenceStates';

interface IncidencesFiltersProps {
    updateFilters: (filters: any) => void
    resetFilters: (limit: any) => void
    filters: FilterOptions
}

const IncidencesFilters: React.FC<IncidencesFiltersProps> = ({ filters, updateFilters, resetFilters }) => {

    const { getIncidenceTypesList } = useIncidenceTypes();
    const { getIncidenceStatusesList } = useIncidenceStatuses();
    const { getContractIncidenceStatesList } = useContractIncidenceStates();
    const { updateIncidenceUsersHookFilters, fetchIncidenceUsersData } = useIncidenceUsers();

    const contractOptions = filters?.filter_filters?.contract?.map((item: any) => ({ label: item, value: item })) || [];

    return (
        <>
            <CustomSelect
                id="incidence_type"
                label="Tipo"
                isMulti={true}
                options={getIncidenceTypesList()}
                value={filters.filter_filters?.incidence_type ?
                    getIncidenceTypesList().filter((type) => filters.filter_filters?.incidence_type.includes(type.value)) : []}
                onChange={(value: { value: string, label: string }[]) => {
                    const incidenceTypes = value.map((type) => type.value);
                    if (incidenceTypes !== filters.filter_filters?.incidence_type) {
                        updateFilters({ incidence_type: incidenceTypes });
                    }
                }}
            />

            <CustomSelectApiHook
                id="user"
                label="Responsables / Participantes"
                filters={filters}
                updateFilters={updateFilters}
                fetchOptions={async (searchText: string | null) => {
                    updateIncidenceUsersHookFilters({ search_text: searchText });
                    return await fetchIncidenceUsersData();
                }}
            />

            <CustomCreatableSelect
                id="contract"
                label="Contratos"
                isMulti={true}
                value={contractOptions}
                onChange={(value: { value: string, label: string }[]) => {
                    const contract = value.map((item) => item.value);
                    if (contract !== filters.filter_filters?.contract) {
                        updateFilters({ contract });
                    }
                }}
            />

            <CustomSelect
                id="current_status"
                label="Estado Incidencia"
                isMulti={true}
                options={getIncidenceStatusesList(false)}
                value={filters.filter_filters?.current_status ?
                    getIncidenceStatusesList(false).filter((status) => filters.filter_filters?.current_status.includes(status.value)) : []}
                onChange={(value: { value: string, label: string }[]) => {
                    const statuses = value.map((status) => status.value);
                    if (statuses !== filters.filter_filters?.current_status) {
                        updateFilters({ current_status: statuses });
                    }
                }}
            />

            <CustomSelect
                id="incidence_contract_current_status"
                label="Estado Incidencia de Contrato"
                isMulti={true}
                options={getContractIncidenceStatesList()}
                value={filters.filter_filters?.incidence_contract_current_status ?
                    getContractIncidenceStatesList().filter((status) => filters.filter_filters?.incidence_contract_current_status.includes(status.value)) : []}
                onChange={(value: { value: string, label: string }[]) => {
                    const statuses = value.map((status) => status.value);
                    if (statuses !== filters.filter_filters?.incidence_contract_current_status) {
                        updateFilters({ incidence_contract_current_status: statuses });
                    }
                }}
            />

            <DateField
                isClearable
                className="w-full"
                label="Fecha de Creación"
                placeholderText="Selecciona las fechas"
                startDate={filters.filter_filters?.between_dates?.startDate ? new Date(filters.filter_filters.between_dates.startDate) : null}
                endDate={filters.filter_filters?.between_dates?.endDate ? new Date(filters.filter_filters.between_dates.endDate) : null}
                onChange={(date: any) => {
                    const filters_between_dates = filters.filter_filters?.between_dates;
                    const between_dates = date ? { startDate: date[0], endDate: date[1] } : null;

                    if (between_dates && !isEqual(filters_between_dates, between_dates)) {
                        updateFilters({ between_dates: { startDate: date[0], endDate: date[1] } });
                    } else if (!between_dates && filters_between_dates) {
                        updateFilters({ between_dates: null });
                    }
                }}
            />

            <Button
                size="sm"
                onClick={() => {
                    resetFilters(50);
                }}
                variant="flat"
                className="h-9 bg-gray-200/70"
            >
                <PiTrashDuotone className="me-1.5 h-[17px] w-[17px]" /> Limpiar
            </Button>
        </>
    );
};

export default IncidencesFilters;