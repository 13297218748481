import React, { useEffect } from 'react';
import HorizontalFormBlockWrapper from '../../components/forms/HorizontalFormBlockWrapper';
import RequiredInput from '../../components/forms/InputHOC';
import PageHeader from '../../layout/shared/page-header';
import { menuRoutes } from '../../router/menu';
import FormFooter from '../_layout/_footers/form-footer';
import { useSettingsProvider } from './provider/SettingsProvider';
import { useLocation } from 'react-router-dom';

const GeneralsSettingsPage: React.FC = () => {

    const { formik, hasCompanyConfigChanges, isLoading, companyConfig, updateCompanyConfig, setShowModal, discardCompanyConfigChanges } = useSettingsProvider();
    const location = useLocation();

    const pageHeader = {
        title: 'Ajustes en Contratos',
        breadcrumb: [
            { name: 'Inicio', href: menuRoutes.dashboard.path },
            { name: 'Configuración' },
            { name: 'Contratos', href: menuRoutes.settings.path + '#contracts' },
        ]
    }

    const invoicePageHeader = {
        title: 'Ajustes en Facturas',
        breadcrumb: [
            { name: 'Inicio', href: menuRoutes.dashboard.path },
            { name: 'Configuración' },
            { name: 'Facturas', href: menuRoutes.settings.path + '#invoices' },
        ]
    }

    const incidencePageHeader = {
        title: 'Ajustes en Incidencias',
        breadcrumb: [
            { name: 'Inicio', href: menuRoutes.dashboard.path },
            { name: 'Configuración' },
            { name: 'Incidencias', href: menuRoutes.settings.path + '#incidences' },
        ]
    }

    useEffect(() => {
        if (location.hash) {
            const id = location.hash.replace('#', '');
            const element = document.getElementById(id);

            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);


    return (
        <div className='h-full overflow-auto scrollbar '>
            <div id="invoices" className='mb-4'>
                <PageHeader
                    title={invoicePageHeader.title}
                    breadcrumb={invoicePageHeader.breadcrumb}
                    className='border-b-4 border-dotted border-grey-500 top-0'
                    isSticky={false}
                />
                <HorizontalFormBlockWrapper
                    title="Formato de las Facturas"
                    description="Para personalizar el nombre, puedes utilizar las siguientes variables: {{NUMBER}}, {{MONTH}}, {{YEAR}} ."
                    descriptionClassName="max-w-[50%]"
                    childrenWrapperClassName="@3xl:grid-cols-1 max-w-[50%] w-full"
                >
                    <div className='pb-4'>
                        <RequiredInput
                            required={true}
                            id="invoiceName"
                            label="Nombre de la Factura"
                            value={companyConfig?.invoiceCodeFormat}
                            onChange={(e) => {
                                updateCompanyConfig('invoiceCodeFormat', e.target.value);
                                formik.setFieldValue('invoiceCodeFormat', e.target.value);
                            }}
                            error={formik.errors.invoiceCodeFormat}
                        />
                    </div>
                </HorizontalFormBlockWrapper>
                <br /> <br />
            </div>

            <div id="incidences" className='mb-4'>
            <PageHeader
                    title={incidencePageHeader.title}
                    breadcrumb={incidencePageHeader.breadcrumb}
                    className='border-b-4 border-dotted border-grey-500 top-0'
                    isSticky={false}
                />
                <HorizontalFormBlockWrapper
                    title="Formato del nombre de las Incidencias"
                    description="Las variables disponibles son las siguientes : {{NUMBER}} "
                    descriptionClassName="max-w-[50%]"
                    childrenWrapperClassName="@3xl:grid-cols-1 max-w-[50%] w-full"
                >
                    <div className='pb-4'>
                        <RequiredInput
                            id="incidenceName"
                            label="Nombre de la Incidencia"
                            value={companyConfig?.incidenceCodeFormat}
                            onChange={(e) => {
                                updateCompanyConfig('incidenceCodeFormat', e.target.value);
                                formik.setFieldValue('incidenceCodeFormat', e.target.value);
                            }}
                            error={formik.errors.incidenceCodeFormat}
                        />
                    </div>
                </HorizontalFormBlockWrapper>
                <br /> <br />
            </div>

            {hasCompanyConfigChanges && (
                <FormFooter
                    customClassName={"w-full"}
                    submitBtnText={"Aplicar Cambios"}
                    cancelBtnText={"Descartar Cambios"}
                    handleCancelBtn={() => discardCompanyConfigChanges()}
                    handleSubmitBtn={() => setShowModal(true)}
                    isLoading={isLoading}
                    isDisabled={Object.keys(formik.errors).length > 0}
                />
            )}
        </div>
    );
};

export default GeneralsSettingsPage;