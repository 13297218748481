import React, { useCallback, useEffect, useRef, useState } from 'react';
import { PermissionsRequired } from '../../type/menu-type';
import { CheckPermissions } from '../../utils/CheckPermissions';
import CustomSelect from './CustomSelect';
import { debounce } from 'lodash';

interface OptionType {
    label: string;
    value: string;
}

interface Props {
    id: string;
    label: string;
    filters: any;
    minInputLength?: number;
    permissions?: PermissionsRequired;
    containerClassName?: string;
    fetchOptions: (searchText: string | null) => Promise<OptionType[]>;
    updateFilters?: (filter: any) => void;
}

const CustomSelectApiHook: React.FC<Props> = ({
    id,
    label,
    filters,
    minInputLength = 2,
    permissions,
    containerClassName,
    fetchOptions,
    updateFilters
}) => {
    const [options, setOptions] = useState<OptionType[]>([]);
    const [inputValue, setInputValue] = useState('');
    const [filtersValue, setFiltersValue] = useState<any>([]);
    const [loading, setLoading] = useState(false);

    // Debounced fetch function
    const debouncedFetch = useRef(
        debounce(async (value: string) => {
            try {
                const fetchedOptions = await fetchOptions(value);
                setOptions(fetchedOptions);
            } catch (error) {
                console.error('Error fetching options:', error);
                setOptions([]);
            } finally {
                setLoading(false);
            }
        }, 500)
    ).current;

    // Handle input change with debounce
    const handleInputChange = useCallback((value: string) => {
        setInputValue(value);
        if (value.length >= minInputLength) {
            setLoading(true);
            debouncedFetch(value);
        } else {
            setOptions([]);
            setLoading(false);
        }
    }, [minInputLength]);

    // Handle selected values change
    const handleFiltersValue = useCallback((valueArray: OptionType[]) => {
        const values = valueArray.map((item) => item.value);
        updateFilters?.({ [id]: values });
        setFiltersValue(valueArray);
    }, [id, updateFilters]);

    // Fetch initial options if necessary
    useEffect(() => {
        if (filters.filter_filters?.[id] && options.length === 0) {
            setLoading(true);
            fetchOptions(null)
                .then((fetchedOptions) => {
                    const filteredOptions = fetchedOptions.filter((option) =>
                        filters.filter_filters[id].includes(option.value)
                    );
                    setOptions(filteredOptions);
                    setFiltersValue(filteredOptions);
                })
                .catch((error) => console.error('Error fetching options:', error))
                .finally(() => setLoading(false));
        }
    }, [filters]);

    // Clear filters value if no filter exists
    useEffect(() => {
        if (!filters.filter_filters?.[id]) setFiltersValue([]);
    }, [filters, id]);

    return (
        <>
            {((!permissions) || (permissions && CheckPermissions(permissions))) && (
                <CustomSelect
                    id={id}
                    label={label}
                    isSearchable
                    isClearable
                    isMulti
                    options={filtersValue.length > 0 || inputValue.length > minInputLength ? options: []}
                    isLoading={loading}
                    onInputChange={handleInputChange}
                    onChange={handleFiltersValue}
                    value={filtersValue}
                    noOption={() => (inputValue.length < minInputLength ? `Introduce al menos ${minInputLength} letras` : 'No se encontraron opciones')}
                    containerClassName={containerClassName}
                />)
            }
        </>
    );
};

export default CustomSelectApiHook;