import { AxiosResponse } from 'axios';
import { OrderFieldsModel } from "../../type/entities/order-type";
import { RestServiceConnection } from '../restServiceConnection';

const ENDPOINT = '/order';

export class OrderService extends RestServiceConnection {

    listOrders = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/list',
            data: filters,
        }, true) as AxiosResponse;
        return this;
    }

    getOrderById = async (orderId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get',
            data: { orderId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    toggleOrderState = async (orderId: string, statusId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/change-status',
            data: {
                orderId,
                statusId
            },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    toggleMultiOrderState = async (orderIds: string[], statusId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/multi-change-status',
            data: {
                orderIds,
                statusId
            },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    createOrder = async (order: OrderFieldsModel) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/create',
            data: order,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    editOrder = async (order: OrderFieldsModel) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/edit',
            data: order,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    deleteOrder = async (orderId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/delete',
            data: { orderId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    deleteMultiOrders = async (orderIds: string[]) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/delete-multi',
            data: { orderIds }
        }, true);
        return this;
    }

    getOrderStatutes = async () => {
        this.response = await this.makeRequest({
            method: 'GET',
            url: ENDPOINT + '/get-statuses',
        }, true);
        return this;
    }

    getOrderTypes = async () => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get-order-types',
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    getFestiveDays = async (year: number) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get-festive-days',
            data: { year },
        }, true);
        return this;
    }

    getOrderContracts = async (filters: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get-order-contracts',
            data: filters,
        }, true);
        return this;
    }

    getOrderContractsByContractStatuses = async (orderId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get-order-contracts-group-by-contracts-statuses',
            data: { orderId },
        }, true);
        return this;
    }

    getOrderContractsByTutor = async (orderId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get-order-contracts-group-by-tutors',
            data: { orderId },
        }, true);
        return this;
    }

    getContractQtyByProduct = async (orderId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get-contract-quantity-group-by-product',
            data: { orderId },
        }, true);
        return this;
    }

    getNonAssignedProducts = async (orderId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get-non-assigned-products-on-order',
            data: { orderId },
        }, true);
        return this;
    }

    getContractsByCommercialNetwork = async (orderId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get-contracts-group-by-commercial-network',
            data: { orderId },
        }, true);
        return this;
    }

    getContractsByOrganisingCorporation = async (orderId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get-contracts-group-by-organising-corporation',
            data: { orderId },
        }, true);
        return this;
    }

    getCommercialContractsByProduct = async (orderId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get-commercial-contracts-group-by-product',
            data: { orderId },
        }, true);
        return this;
    }

    getTutorsSchedules = async (orderId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get-tutor-contracts-quantity-group-by-schedule',
            data: { orderId },
        }, true);
        return this;
    }

    getEconomicMetrics = async (orderId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/get-economic-metrics',
            data: { orderId },
        }, true);
        return this;
    }

    assignToTutor = async (values: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/assign-tutor-to-order-contracts',
            data: values,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    unassignTutor = async (values: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/unassign-tutor-from-order-contracts',
            data: values,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    addContractsToOrder = async (values: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/add-contracts-to-order',
            data: values,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    removeContractsFromOrder = async (values: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/remove-contracts-from-order',
            data: values,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    getAssignablesTutors = async (filters: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ENDPOINT + '/obtain-assignable-tutors',
            data: filters,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

}