import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Textarea } from 'rizzui';
import * as yup from 'yup';
import CustomSelect from '../../components/forms/CustomSelect';
import useIncidenceContracts from '../../hooks/api-calls/Incidences/useIncidenceContracts';
import useIncidenceTypes from '../../hooks/api-calls/Incidences/useIncidenceTypes';
import useIncidenceUsers from '../../hooks/api-calls/Incidences/useIncidenceUsers';
import Page from '../../layout/Page/Page';
import FormGroup from '../../layout/shared/form-group';
import { Incidence } from '../../type/entities/incidence-type';
import FormFooter from '../_layout/_footers/form-footer';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { setContractId } from '../../redux/contractProfileSlice';
import CustomSelectApiHookForm from '../../components/forms/CustomSelectApiHookForms';
import RequiredInput from '../../components/forms/InputHOC';

interface IncidenceFormProps {
    isLoading: boolean;
    submit: Function;
    data?: Incidence | undefined;
}

const schema = yup.object({
    subject: yup.string().required('El asunto es requerido').max(500, 'El asunto no puede tener más de 500 caracteres'),
    description: yup.string().required('La descripción es requerida'),
    incidenceTypeId: yup.string().required('El tipo de incidencia es requerido'),
    contractId: yup.string().notRequired().length(8, 'El código del contrato debe tener 8 dígitos'),
    participants: yup.array().notRequired(),
    managerId: yup.string().notRequired(),
});

const IncidenceForm: React.FC<IncidenceFormProps> = ({ isLoading, submit, data }) => {

    const { contractId } = useSelector((state: RootState) => state.contractProfile);
    const mode = data ? 'Editar' : 'Crear';
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    const { getIncidenceTypesList } = useIncidenceTypes();
    const { updateIncidenceUsersHookFilters, fetchIncidenceUsersData } = useIncidenceUsers();

    const formik = useFormik({
        initialValues: {
            incidenceId: data?.id || '',
            incidenceTypeId: data?.incidenceType?.id || '',
            managerId: data?.manager?.id || '',
            contractId: data?.contract?.code || '',
            participants: data?.users?.map((u: any) => u.user.id) || [],
            subject: data?.subject || '',
            description: data?.description || '',
        },
        validationSchema: schema,
        onSubmit: values => { submit(values) },
    })

    useEffect(() => {
        if (mode === 'Crear' && contractId) {
            formik.setFieldValue('contractId', contractId);
            dispatch(setContractId(null));
        }
    }, [contractId])


    const getContent = () => {
        return (
            <div className="@container">
                <form onSubmit={formik.handleSubmit}>
                    <div className="grid gap-7 divide-y-2 divide-dashed divide-gray-200 font-medium">
                        <FormGroup
                            title="Información General"
                            description="Datos principales"
                            titleCols="@md:col-span-2"
                            childCols="@md:col-span-10 md:grid-cols-12"
                        >
                            <Textarea
                                id='subject'
                                label={<label>Asunto <span className="text-red-500"> *</span></label>}
                                className="[&>label>span]:font-medium col-span-12 md:col-span-6"
                                onChange={formik.handleChange}
                                value={formik.values.subject}
                                error={formik.errors.subject}
                            />

                            <Textarea
                                id='description'
                                label={<label>Descripción <span className="text-red-500"> *</span></label>}
                                className="[&>label>span]:font-medium col-span-12 md:col-span-6"
                                onChange={formik.handleChange}
                                value={formik.values.description}
                                error={formik.errors.description}
                            />

                            <CustomSelect
                                isSearchable
                                isClearable
                                id={'incidenceTypeId'}
                                label="Tipo de Incidencia"
                                value={getIncidenceTypesList()?.find((g: any) => g.value === formik.values.incidenceTypeId)}
                                options={getIncidenceTypesList()}
                                onChange={(e: any) => { formik.setFieldValue('incidenceTypeId', e?.value) }}
                                required
                                error={formik.errors.incidenceTypeId}
                                containerClassName='col-span-12 md:col-span-4'
                            />

                            <RequiredInput
                                required={false}
                                id="contractId"
                                label="Contrato"
                                formik={formik}
                                className="col-span-12 md:col-span-4"
                            />

                            <CustomSelectApiHookForm
                                id="managerId"
                                label="Responsable"
                                formik={formik}
                                containerClassName='col-span-12 md:col-span-4'
                                fetchOptions={async (searchText: string | null) => {
                                    updateIncidenceUsersHookFilters({ search_text: searchText });
                                    return fetchIncidenceUsersData();
                                }}
                                error={formik.errors.managerId}
                            />

                            <CustomSelectApiHookForm
                                id="participants"
                                label="Participantes"
                                isMulti={true}
                                formik={formik}
                                containerClassName='col-span-12 md:col-span-6'
                                fetchOptions={async (searchText: string | null) => {
                                    updateIncidenceUsersHookFilters({ search_text: searchText });
                                    return fetchIncidenceUsersData();
                                }}
                                error={formik.errors.managerId}
                            />

                        </FormGroup>

                    </div>
                </form>
            </div>
        )
    };

    return (
        <>
            <Page container="fluid">
                {(mode === "Editar" && data) && getContent()}
                {mode === "Crear" && getContent()}
            </Page>
            <FormFooter
                submitBtnText={mode + ' Incidencia'}
                handleCancelBtn={() => navigate(-1)}
                handleSubmitBtn={formik.submitForm}
                isLoading={isLoading}
            />
        </>
    );
};

export default IncidenceForm;