import { useEffect, useState } from 'react';
import { UserService } from '../../../services/user/userService';

/*----------------------------------------------------------------------------------------
* EN: Hook to manage incidence users fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de usuarios para incidencias.
----------------------------------------------------------------------------------------*/

const useIncidenceUsers = (
    limit = 50,
    initialFilters = {},
    initialOrders = {}
) => {

    const [data, setData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [filters, setFilters] = useState<Record<string, any>>({
        limit: limit,
        filter_filters: { ...initialFilters },
        filter_orders: { ...initialOrders },
    });

    const fetchData = async () => {
        setIsLoading(true);
        let mappedData: any[] = [];
        try {
            const service = new UserService();
            const response = await service.listUsers(filters);
            const fetchedData = response.getResponseData().data;

            if (fetchedData && fetchedData?.data) {
                mappedData = fetchedData.data?.map((data: any) => ({
                    value: data.id,
                    label: data.name + ' ' + data.lastName,
                }));
                setData(mappedData);
            }
        } catch (error) {
            console.log('Error fetching incidence users:', error);
            setIsLoading(false);
        } finally {
            setIsLoading(false);
            return mappedData;
        }
    };

    const getList = (entity?: string) => {
        if (entity) return data.filter((state: any) => state.value !== entity);
        return data;
    };

    const getEntity = (id: string) => {
        return data.find((entity: any) => entity.id === id);
    }

    const updateHookFilters = (newFilters: any) => {
        setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
    };

    return {
        getIncidenceUsersList: getList,
        getIncidenceUser: getEntity,
        updateIncidenceUsersHookFilters: updateHookFilters,
        fetchIncidenceUsersData: fetchData,
        isLoadingIncidenceUsers: isLoading
    };
}

export default useIncidenceUsers;