import React, { useEffect, useState } from 'react';
import OrdersCalendar from './OrdersCalendar';

interface OrdersCalendarPageProps { }

const OrdersCalendarPage: React.FC<OrdersCalendarPageProps> = ({ }) => {

    const [scriptLoaded, setScriptLoaded] = useState<boolean>(false);

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "/js/daypilot-all.min.js";
        script.async = true;
        script.onload = () => {
            console.log("DayPilot cargado correctamente.");
            setScriptLoaded(true);
        };
        script.onerror = () => {
            console.error("Error al cargar DayPilot.");
            setScriptLoaded(false);
        };
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script); // Limpieza cuando el componente se desmonta
        };
    }, []);

    return (
        <>
            {scriptLoaded && <OrdersCalendar />}
        </>
    );
};

export default OrdersCalendarPage;