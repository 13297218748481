export const validateCifNif = (cif: string) => {
  //Quitamos el primer caracter y el ultimo digito
  // var valueCif = cif.substr(1, cif.length - 2);
  var valueCif = cif.split('').slice(1, -1).join('');
  var suma = 0;
  var suma2 = 0;

  //Sumamos las cifras pares de la cadena
  for (let i = 1; i < valueCif.length; i = i + 2) {
    suma = suma + parseInt(valueCif.substr(i, 1));
  }

  //Sumamos las cifras impares de la cadena
  for (let i = 0; i < valueCif.length; i = i + 2) {
    let result: number = parseInt(valueCif.substr(i, 1)) * 2;
    if (String(result).length === 1) {
      // Un solo caracter
      suma2 = suma2 + result;
    } else {
      // Dos caracteres. Los sumamos...
      suma2 = suma2 + parseInt(String(result).substr(0, 1)) + parseInt(String(result).substr(1, 1));
    }
  }

  // Sumamos las dos sumas que hemos realizado
  suma = suma + suma2;

  let unidad: number = parseInt(String(suma).substr(1, 1))
  unidad = 10 - unidad;

  var primerCaracter = cif.substring(0, 1).toUpperCase();

  if (primerCaracter.match(/^[FJKNPQRSUVW]$/)) {
    //Empieza por .... Comparamos la ultima letra
    if (String.fromCharCode(64 + unidad).toUpperCase() === cif.substr(cif.length - 1, 1).toUpperCase())
      return true;
  } else if (primerCaracter.match(/^[XYZ]$/)) {
    //Se valida como un nie
    let newcif = "";
    if (primerCaracter === "X") 
        newcif = "0" + cif.slice(1);
    else if (primerCaracter === "Y")
      newcif = "1" + cif.slice(1);
    else if (primerCaracter === "Z")
      newcif = "2" + cif.slice(1);
    return validateDNI(newcif);
  } else if (primerCaracter.match(/^[ABCDEFGHLM]$/)) {
    //Se revisa que el ultimo valor coincida con el calculo
    if (unidad === 10)
      unidad = 0;
    if (cif.substr(cif.length - 1, 1) === String(unidad))
      return true;
  } else {
    //Se valida como un dni
    return validateDNI(cif);
  }
  return false;
};


export function validateDNI(value: string) {
  if (value) {
    const dniRegex = /^(\d{8})([A-Z])$/;
    const matches = value.match(dniRegex);
    if (matches) {
      const dniNumbers = matches[1];
      const dniLetter = matches[2];
      const letters = "TRWAGMYFPDXBNJZSQVHLCKE";
      const index = parseInt(dniNumbers, 10) % 23;
      return letters.charAt(index) === dniLetter.toUpperCase();
    }
  }
};


export function validateSS(numeroSS: string): boolean {
  // Quitar cualquier espacio en blanco
  const cleanNum = numeroSS.replace(/\s/g, '');

  // Verificar que tiene exactamente 12 dígitos
  if (!/^\d{12}$/.test(cleanNum)) {
    return false;
  }

  // Extraer los 10 primeros dígitos y los 2 dígitos de control
  const baseNum = cleanNum.slice(0, 10);
  const controlDigits = cleanNum.slice(10);

  // El número base debe estar entre 0 y 9999999999 (10 dígitos)
  const baseNumInt = parseInt(baseNum, 10);
  if (baseNumInt < 0 || baseNumInt > 9999999999) {
    return false;
  }

  // El dígito de control se calcula dividiendo por 97 y tomando el resto
  const calculateControl = ('0' + (baseNumInt % 97)).slice(-2);

  // Verificar que el dígito de control coincide con el calculado
  return calculateControl === controlDigits;
};

export function validateNotWhitespace(value: string) {
  if (value && value.trim() === '') {
    return false;
  }
  return true;
};