import React, { useCallback, useState } from 'react';
import { FaCommentAlt } from 'react-icons/fa';
import { HiOutlineEye } from 'react-icons/hi2';
import { IoTicketSharp } from 'react-icons/io5';
import { MdOutlineModeEditOutline } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { ActionIcon, Button, Input, Switch, Text, Tooltip } from 'rizzui';
import { toast } from 'sonner';
import DeletePopover from '../../../components/buttons/DeletePopover';
import ModalForm from '../../../components/forms/ModalForm';
import { usePrivilege } from '../../../components/priviledge/PriviledgeProvider';
import { useFiltersPR } from '../../../components/providers/FiltersProvider';
import { FilterDrawerView } from '../../../components/table/components/TableFilter';
import CustomTable from '../../../components/table/CustomTable';
import useFetch from '../../../hooks/useFetch';
import useHandleErrors from '../../../hooks/useHandleErrors';
import Page from '../../../layout/Page/Page';
import { menuRoutes } from '../../../router/menu';
import { ClientService } from '../../../services/client/clientService';
import { ClientsApiResponse } from '../../../type/entities/client-type';
import ClientsFilters from './ClientsFilters';
import { AddCommentModal } from './components/AddCommentModal';

interface ClientsListProps { }

const columnOrder = [
    'id',
    'companyName',
    'cifNif',
    'cnae',
    'email',
    'iban',
    'currentCredits',
    'telephone',
    'personResponsibleName',
    'personResponsibleNif',
    'lastComment',
    'active',
    'createdAt',
    'updatedAt',
];

const ClientsList: React.FC<ClientsListProps> = ({ }) => {

    const { userCan } = usePrivilege();
    const { handleErrors } = useHandleErrors();
    const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters } = useFiltersPR();
    const service = new ClientService();

    const [changingStatus, setChangingStatus] = useState<string[]>([]);
    const [openFilters, setOpenFilters] = useState<boolean>(false);
    const [openCommentModal, setOpenCommentModal] = useState<boolean>(false);
    const [rowId, setRowId] = useState<string>('');
    const [openCreditModal, setOpenCreditModal] = useState<boolean>(false);
    const [credits, setCredits] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = await service.listClients(filters);
        return response.getResponseData() as ClientsApiResponse;
    }, [filters]));

    const toggleState = async (id: string, status: boolean) => {
        try {
            setChangingStatus([...changingStatus, id]);
            const response = (await service.toggleClientState(id, status)).getResponseData();
            if (response.success) {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                refetch();
                toast.success(status ? "Se ha desactivado el cliente" : "Se ha activado el cliente");
            } else {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                toast.error(response.message);
            }
        } catch (error: any) {
            setChangingStatus(changingStatus.filter((item) => item !== id));
            toast.error(error.message);
        }
    };

    const addCredits = async () => {
        setIsLoading(true);
        const response = await (await service.addCredits(rowId, credits)).getResponseData();
        if (response.success) {
            setOpenCreditModal(false);
            setCredits(0);
            toast.success('Créditos actualizados correctamente');
            refetch();
        } else {
            handleErrors(response);
        }
        setIsLoading(false);
    };

    const handleDelete = async (id: string) => {
        const response = await (await service.deleteClient(id)).getResponseData();
        if (response.success) {
            toast.success('Cliente eliminado correctamente');
            refetch();
        } else {
            handleErrors(response);
        }
    };

    const handleMultiDelete = async (ids: string[]) => {
        const response = await (await service.deleteMultiClients(ids)).getResponseData();
        if (response.success) {
            toast.success('Clientes eliminados correctamente');
            refetch();
        } else {
            handleErrors(response);
        }
    };

    /**
     * Order the data to match the column order
     */
    const orderedData = () => data?.data.map((row: any) => {
        const orderedRow: any = {};
        columnOrder.forEach((key) => {
            orderedRow[key] = row[key];
        });
        return orderedRow;
    });

    return (
        <Page container='fluid'>
            {data !== undefined && (
                <CustomTable
                    largeTable
                    id={'clients-table'}
                    columnOrder={columnOrder}
                    data={orderedData()}
                    isLoading={loading}
                    columnsNotShown={[]}
                    overrideColumns={[
                        {
                            key: 'companyName',
                            render: (row: any) => {
                                return (
                                    userCan('get_clients', 'clients')
                                        ? (
                                            <Link to={`${menuRoutes.clients.path}/${row.row.original.id}/profile${menuRoutes.clients.profile.info}`}>
                                                <Text className='font-bold text-primary'>{row.row.original.companyName}</Text>
                                            </Link>
                                        )
                                        : <Text className='font-bold text-primary'>{row.row.original.companyName}</Text>
                                )
                            },
                        },
                        {
                            key: 'active',
                            render: (row: any) => {
                                return (
                                    <Tooltip
                                        size="sm"
                                        content={row.row.original.active ? 'Desactivar cliente' : 'Activar cliente'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <Switch
                                            id={row.row.original.id}
                                            checked={row.row.original.active}
                                            onChange={() => toggleState(row.row.original.id, row.row.original.active)}
                                            switchKnobClassName='bg-secondary'
                                        />
                                    </Tooltip>
                                );
                            },
                        },
                    ]}
                    actions={[
                        {
                            label: 'Add Credits',
                            permissions: { group: 'clients', permission: 'add_client_credits' },
                            render: (row: any) => {
                                return (
                                    <Tooltip
                                        size="sm"
                                        content={'Añadir créditos'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <ActionIcon
                                            as="span"
                                            size="sm"
                                            variant="outline"
                                            className="hover:!border-gray-900 hover:text-gray-700 cursor-pointer"
                                            onClick={() => { setRowId(row.id); setOpenCreditModal(true) }}
                                        >
                                            <IoTicketSharp className="h-4 w-4" size={20} />
                                        </ActionIcon>
                                    </Tooltip>
                                );
                            },
                        },
                        {
                            label: 'Add Comment',
                            permissions: { group: 'clients', permission: 'create_client_comments' },
                            render: (row: any) => {
                                return (
                                    <Tooltip
                                        size="sm"
                                        content={'Añadir comentario'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <ActionIcon
                                            as="span"
                                            size="sm"
                                            variant="outline"
                                            className="hover:!border-gray-900 hover:text-gray-700 cursor-pointer"
                                            onClick={() => { setRowId(row.id); setOpenCommentModal(true) }}
                                        >
                                            <FaCommentAlt className="h-4 w-4" size={20} />
                                        </ActionIcon>
                                    </Tooltip>
                                );
                            },
                        },
                        {
                            label: 'View',
                            permissions: { group: 'clients', permission: 'get_clients' },
                            render: (row: any) => {
                                return (
                                    <Tooltip
                                        size="sm"
                                        content={'Ver perfil'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <Link
                                            to={`${menuRoutes.clients.path}/${row.id}/profile${menuRoutes.clients.profile.info}`}>
                                            <ActionIcon
                                                as="span"
                                                size="sm"
                                                variant="outline"
                                                className="hover:!border-gray-900 hover:text-gray-700"
                                            >
                                                <HiOutlineEye className="h-4 w-4" size={20} />
                                            </ActionIcon>
                                        </Link>
                                    </Tooltip>
                                );
                            },
                        },
                        {
                            label: 'Edit',
                            permissions: { group: 'clients', permission: 'edit_clients' },
                            render: (row: any) => {
                                return (
                                    <Tooltip
                                        size="sm"
                                        content={'Editar cliente'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <Link to={`${menuRoutes.clients.path}/${row.id}/edit`}>
                                            <ActionIcon
                                                as="span"
                                                size="sm"
                                                variant="outline"
                                                className="hover:!border-gray-900 hover:text-gray-700"
                                            >
                                                <MdOutlineModeEditOutline className="h-4 w-4" size={20} />
                                            </ActionIcon>
                                        </Link>
                                    </Tooltip>
                                );
                            },
                        },
                        {
                            label: 'Delete',
                            permissions: { group: 'clients', permission: 'delete_clients' },
                            render: (row: any) => {
                                return (
                                    <Tooltip
                                        size="sm"
                                        content={'Eliminar'}
                                        placement="top"
                                        color="invert"
                                    >
                                        <div>
                                            <DeletePopover
                                                title={`Eliminar cliente`}
                                                description={`¿Estás seguro de que deseas eliminar a ${row.companyName}?`}
                                                onDelete={() => handleDelete(row.id)}
                                            />
                                        </div>
                                    </Tooltip>
                                );
                            },
                        },
                    ]}
                    handleMultipleDelete={handleMultiDelete}
                    filters={filters}
                    updateFilters={updateFilters}
                    updateFilterOrder={updateFilterOrder}
                    defaultOrder={filters.filter_order || undefined}
                    paginationData={{
                        pageSize: filters.limit,
                        currentPage: filters.page,
                        pageCount: (data as ClientsApiResponse) ? data.lastPage : 1,
                        totalCount: data?.totalRegisters,
                        handlePagination: updatePage,
                        handlePerPage: updatePageSize,
                    }}
                    toggleFilters={() => setOpenFilters(!openFilters)}
                />
            )}

            <AddCommentModal service={(new ClientService).addComment} id={rowId} isOpen={openCommentModal} onClose={() => setOpenCommentModal(false)} refetch={refetch} />

            <ModalForm isOpen={openCreditModal} onClose={() => setOpenCreditModal(false)} title='Añadir actualización de créditos'>
                <Input
                    label='Créditos'
                    type='number'
                    value={credits}
                    onChange={(e) => setCredits(parseInt(e.target.value))}
                />

                <div className='flex justify-center p-7'>
                    <Button
                        disabled={isLoading}
                        onClick={() => addCredits()}
                        color='secondary'
                    >
                        Actualizar créditos
                    </Button>
                </div>
            </ModalForm>

            <FilterDrawerView isOpen={openFilters} setOpenDrawer={setOpenFilters} drawerTitle={'Filtros Clientes'}>
                <ClientsFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} />
            </FilterDrawerView>
        </Page>
    );
};

export default ClientsList;