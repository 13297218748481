import { SVGProps } from 'react';

const SvgSimCard = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
			<path d='M0 0h24v24H0V0z' fill='none' />
			<path
				d='M6 8.83V20h12V4h-7.17L6 8.83zM9 19H7v-2h2v2zm0-4H7v-4h2v4zm6-4h2v4h-2v-4zm0 6h2v2h-2v-2zm-4-6h2v2h-2v-2zm0 4h2v4h-2v-4z'
				opacity={0.3}
			/>
			<path d='M18 2h-8L4 8v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 2v16H6V8.83L10.83 4H18zM7 17h2v2H7zm8 0h2v2h-2zm-8-6h2v4H7zm4 4h2v4h-2zm0-4h2v2h-2zm4 0h2v4h-2z' />
		</svg>
	);
};

export default SvgSimCard;
