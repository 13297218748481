import { isEqual } from 'lodash';
import React from 'react';
import { PiTrashDuotone } from 'react-icons/pi';
import { Button } from 'rizzui';
import CustomSearchableEntities from '../../../../../components/forms/CustomSearchableEntities';
import CustomStatusSelect from '../../../../../components/forms/CustomStatusSelect';
import CustomTextDelayInput from '../../../../../components/forms/CustomTextDelayInput';
import DateField from '../../../../../components/forms/DateField';
import SelectProvinceFilter from '../../../../../components/forms/SelectProvinceFilter';
import useContractCampaign from '../../../../../hooks/api-calls/contract/useContractCampaign';
import useContractCommercial from '../../../../../hooks/api-calls/contract/useContractCommercial';
import useContractCorporation from '../../../../../hooks/api-calls/contract/useContractCorporation';
import useContractNetwork from '../../../../../hooks/api-calls/contract/useContractNetwork';
import useContractGroups from '../../../../../hooks/api-calls/useContractGroups';
import { FilterOptions } from '../../../../../hooks/useFilters';

interface ClientContractsFiltersProps {
    updateFilters: (filters: any) => void
    resetFilters: (limit: any) => void
    filters: FilterOptions
}

const ClientContractsFilters: React.FC<ClientContractsFiltersProps> = ({ filters, updateFilters, resetFilters }) => {

    const { getContractGroupsList, updateContractGroupsHookFilters } = useContractGroups();
    const { getContractCommercialList, updateContractCommercialHookFilters } = useContractCommercial();
    const { getContractNetworkList, updateContractNetworkHookFilters } = useContractNetwork();
    const { getContractCorporationList, updateContractCorporationHookFilters } = useContractCorporation();
    const { getContractCampaignList, updateContractCampaignHookFilters } = useContractCampaign();

    return (
        <>
            <CustomSearchableEntities
                id="contract_group"
                label="Grupo"
                filters={filters}
                updateFilters={updateFilters}
                fetchOptions={async (searchText: string|null) => {
                    updateContractGroupsHookFilters({ finder: searchText });
                    return getContractGroupsList();
                }}
                permissions={[
                    {
                        group: 'contracts',
                        action: 'admin_contracts'
                    }
                ]}
            />

            <CustomTextDelayInput
                id="grouping"
                label="Agrupación"
                filters={filters}
                updateFilters={updateFilters}
            />

            <CustomSearchableEntities
                id="commercial"
                label="Comercial"
                filters={filters}
                updateFilters={updateFilters}
                fetchOptions={async (searchText: string|null) => {
                    updateContractCommercialHookFilters({ finder: searchText });
                    return getContractCommercialList();
                }}
                permissions={[
                    {
                        group: 'contracts',
                        action: 'admin_contracts'
                    },
                    {
                        group: 'commercial_network',
                        action: 'admin_all_commercial_network_contracts'
                    }
                ]}
            />

            <CustomSearchableEntities
                id="commercial_network"
                label="Red Comercial"
                filters={filters}
                updateFilters={updateFilters}
                fetchOptions={async (searchText: string|null) => {
                    updateContractNetworkHookFilters({ finder: searchText });
                    return getContractNetworkList();
                }}
                permissions={[
                    {
                        group: 'contracts',
                        action: 'admin_contracts'
                    },
                    {
                        group: 'commercial_network',
                        action: 'admin_all_commercial_network_contracts'
                    }
                ]}
            />

            <CustomSearchableEntities
                id="organising_corporation"
                label="Empresa Fiscal"
                filters={filters}
                updateFilters={updateFilters}
                fetchOptions={async (searchText: string|null) => {
                    updateContractCorporationHookFilters({ finder: searchText });
                    return getContractCorporationList();
                }}
                permissions={[
                    {
                        group: 'contracts',
                        action: 'admin_contracts'
                    }
                ]}
            />

            <CustomSearchableEntities
                id="campaing"
                label="Campaña"
                filters={filters}
                updateFilters={updateFilters}
                fetchOptions={async (searchText: string|null) => {
                    updateContractCampaignHookFilters({ finder: searchText });
                    return getContractCampaignList();
                }}
                permissions={[
                    {
                        group: 'contracts',
                        action: 'admin_contracts'
                    }
                ]}
            />

            <SelectProvinceFilter label='Provincia del cliente' id='client_province' filters={filters} updateFilters={updateFilters} />

            <CustomStatusSelect
                id="custom_status"
                label="Estados"
                selectedStateSearchValue={filters.filter_filters?.custom_status?.current}
                selectedStateEntityValues={filters.filter_filters?.custom_status?.entity}
                selectedStatesValues={filters.filter_filters?.custom_status?.statuses}
                startDate={filters.filter_filters?.custom_status?.startDate ? new Date(filters.filter_filters.custom_status.startDate) : null}
                endDate={filters.filter_filters?.custom_status?.endDate ? new Date(filters.filter_filters.custom_status.endDate) : null}
                onChange={(customStatus: any) => { updateFilters({ custom_status: customStatus }); }}
                filterValue={filters.filter_filters?.custom_status}
            />

            <DateField
                isClearable
                className="w-full"
                label="Fecha de Creación"
                placeholderText="Selecciona las fechas"
                selectedDateField={filters.filter_filters?.between_dates?.type || "createdAt"}
                startDate={filters.filter_filters?.between_dates?.startDate ? new Date(filters.filter_filters.between_dates.startDate) : null}
                endDate={filters.filter_filters?.between_dates?.endDate ? new Date(filters.filter_filters.between_dates.endDate) : null}
                onChange={(date: any) => {
                    const filters_between_dates = filters.filter_filters?.between_dates;
                    const between_dates = date ? { startDate: date[0], endDate: date[1] } : null;

                    if (between_dates && !isEqual(filters_between_dates, between_dates)) {
                        updateFilters({ between_dates: { startDate: date[0], endDate: date[1], type: date[2] } });
                    } else if (!between_dates && filters_between_dates) {
                        updateFilters({ between_dates: null });
                    }
                }}
            />

            <Button
                size="sm"
                onClick={() => {
                    resetFilters(50);
                }}
                variant="flat"
                className="h-9 bg-gray-200/70"
            >
                <PiTrashDuotone className="me-1.5 h-[17px] w-[17px]" /> Limpiar
            </Button>
        </>
    );
};

export default ClientContractsFilters;