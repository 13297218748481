import moment from 'moment';
import { useCallback, useEffect } from 'react';
import { PiCircle } from 'react-icons/pi';
import { useParams } from 'react-router-dom';
import { Text, Textarea, Title } from 'rizzui';
import CustomSelect from '../../../../../components/forms/CustomSelect';
import RequiredInput from '../../../../../components/forms/InputHOC';
import { Loader } from '../../../../../components/loader/SpinnerLogo';
import useIncidenceTypes from '../../../../../hooks/api-calls/Incidences/useIncidenceTypes';
import useIncidenceUsers from '../../../../../hooks/api-calls/Incidences/useIncidenceUsers';
import useFetch from '../../../../../hooks/useFetch';
import FormGroup from '../../../../../layout/shared/form-group';
import { IncidenceService } from '../../../../../services/incidence/incidenceService';
import { IncidenceApiResponse } from '../../../../../type/entities/incidence-type';
import cn from '../../../../../utils/classNames';
import IncidenceProfileLayout from '../../IncidenceProfileLayout';

const IncidenceInfo = () => {

    const { id = '' } = useParams<{ id: string }>();
    const service = new IncidenceService();

    const [data] = useFetch(useCallback(async () => {
        if (!id || id === '') return null;
        const response = await service.getIncidenceById(id);
        return response.getResponseData() as IncidenceApiResponse;
    }, [id]));

    const [statusTrackingData, statusTrackingLoading, statusTrackingError, statusTrackingRefetch] = useFetch(useCallback(async () => {
        if (!id) return [];
        const response = await service.getIncidenceStatusTracking(id);
        return response.getResponseData();
    }, [id]));

    const { getIncidenceTypesList } = useIncidenceTypes();
    const { getIncidenceUsersList, fetchIncidenceUsersData } = useIncidenceUsers();

    useEffect(() => {
        if (data && data?.users) {
            data.participants = data?.users.map((g: any) => g.user.id);
        }
    }, [data]);

    useEffect(() => {
        fetchIncidenceUsersData();
    }, [])

    return (
        <IncidenceProfileLayout>
            <div className="@container">
                <div className="grid grid-cols-12 gap-5 2xl:h-[75vh]">
                    <div className="col-span-12 2xl:col-span-9 overflow-y-auto h-full">
                        <FormGroup
                            title="Información General"
                            description="Datos asociados a la incidencia."
                            className={cn("py-4 @3xl:grid-cols-12 ")}
                            titleCols="@md:col-span-2"
                            childCols="@md:col-span-10 md:grid-cols-4"
                        >
                            <div className="grid grid-cols-12 col-span-8 gap-4">
                                <Textarea
                                    disabled={true}
                                    required={true}
                                    id='subject'
                                    label="Asunto"
                                    className="[&>label>span]:font-medium col-span-12 md:col-span-6"
                                    value={data?.subject}
                                    rows={8}
                                />

                                <Textarea
                                    disabled={true}
                                    required={true}
                                    id='description'
                                    label="Descripción"
                                    className="[&>label>span]:font-medium col-span-12 md:col-span-6"
                                    value={data?.description}
                                    rows={8}
                                />

                                <CustomSelect
                                    isDisabled={true}
                                    id={'incidenceTypeId'}
                                    label="Tipo de Incidencia"
                                    value={getIncidenceTypesList()?.find((g: any) => g.value === data?.incidenceType?.id)}
                                    options={getIncidenceTypesList()}
                                    required
                                    containerClassName='col-span-12 md:col-span-4'
                                />


                                <RequiredInput
                                    disabled={true}
                                    required={false}
                                    id="contractId"
                                    label="Contrato"
                                    value={data?.contract?.code || ''}
                                    className='col-span-12 md:col-span-4'
                                />

                                <CustomSelect
                                    isDisabled={true}
                                    id="managerId"
                                    label="Responsable"
                                    containerClassName='col-span-12 md:col-span-4'
                                    value={getIncidenceUsersList()?.find((g: any) => g.value === data?.manager?.id)}
                                />

                                <CustomSelect
                                    isDisabled={true}
                                    isMulti={true}
                                    id="participants"
                                    label="Participantes"
                                    containerClassName='col-span-12 md:col-span-6'
                                    value={getIncidenceUsersList().filter((g: any) => data?.participants?.includes(g.value))}
                                />

                            </div>
                        </FormGroup>
                    </div>
                    <div className="col-span-12 2xl:col-span-3 max-h-full h-full 2xl:ps-3">
                        <Title as="h2" className={cn("mb-8 mt-4 text-lg font-semibold text-start")}>
                            Historial completo de Incidencia
                        </Title>
                        <div className='h-[70vh] overflow-scroll overflow-x-hidden scrollbar'>
                            {!statusTrackingLoading && statusTrackingData && statusTrackingData?.length > 0 ? (
                                statusTrackingData?.map((item: any, index: number) => {
                                    return (
                                        <div className={cn("flex items-center ps-3")} key={item.id} >
                                            <div className={cn("w-[147px] flex-shrink-0 @lg:block")}>
                                                <Text as="span" className="pe-5 text-gray-500
                                            @2xl:pe-6 text-nowrap">
                                                    {moment(item?.createdAt?.date).format('DD/MM/YYYY HH:mm')}
                                                </Text>
                                            </div>
                                            <div
                                                className={cn(
                                                    "relative flex-grow border-l border-muted py-5 ps-6 before:absolute before:-left-[3px] before:-top-[3px] before:h-1.5 before:w-1.5 before:rounded-full before:bg-gray-200 dark:border-gray-700 dark:before:bg-gray-900 rtl:before:-right-[3px]",
                                                    index !== 0 && "before:hidden",
                                                    index === item.combined?.length - 1 && "before:-bottom-[3px] before:top-auto before:block"
                                                )}
                                            >
                                                <span
                                                    className={cn(
                                                        "absolute -left-3 top-1/2 flex h-6 w-6 -translate-y-1/2 items-center justify-center rounded-full bg-gray-0 dark:bg-gray-50 rtl:-right-3"
                                                    )}
                                                >
                                                    <PiCircle className="h-3 w-3" color='rgb(0 172 216)' />
                                                </span>
                                                <Title
                                                    as="h3"
                                                    className={cn("mb-3 flex items-center text-base font-semibold")}
                                                    style={{ color: item?.status?.color }}
                                                >
                                                    {item?.status?.name}
                                                </Title>
                                                <div className="relative -ms-10">
                                                    <div className={cn("ps-10",)}>
                                                        <Text className={cn("block")}>
                                                            {item?.user?.name} {item?.user?.lastName}
                                                        </Text>
                                                        <Text className="text-sm font-normal leading-loose text-gray-500">
                                                            {item?.comment}
                                                        </Text>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            ) :
                                <div>{statusTrackingLoading ? <Loader height='10vh' /> : 'No hay cambio de estados para esta incidencia'}</div>
                            }
                        </div>

                    </div>
                </div>

            </div>
        </IncidenceProfileLayout>
    );
};

export default IncidenceInfo;