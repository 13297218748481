import chroma from 'chroma-js';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import useFetch from '../../../../../hooks/useFetch';
import { OrderService } from '../../../../../services/order/orderService';
import OrderProfileLayout from '../../OrderProfileLayout';
import ContractStatusesCharts from './components/ContractStatusesCharts';
import ContractTutorsAndSchedules from './components/ContractTutorsAndSchedules';
import EconomicMetrics from './components/EconomicMetrics';
import ProductsTreeChart from './components/ProductsTreeChart';
import UsersAndNetworksCharts from './components/UsersAndNetworksCharts';

//-------------------------------------------------------------------------------------------------------------------------------
/**
 * @ES GENERAR UN COLOR A PARTIR DE UN COLOR ÚNICO BASADO EN EL ID UTILIZANDO HASH FNV-1A
 * @EN GENERATES A COLOR FROM A UNIQUE COLOR BASED ON THE ID USING FNV-1A HASH
 */
//-------------------------------------------------------------------------------------------------------------------------------
export const generateColor = (id: string) => {
    // Define a color palette with 50 values (increased to reduce collisions)
    const colors = chroma
        .scale(['#FFC107', '#FF5722', '#4CAF50', '#2196F3', '#9C27B0'])
        .mode('lch')
        .colors(50);

    // FNV-1a Hash to get a unique number based on the string
    let hash = 2166136261; // Offset basis
    for (let i = 0; i < id.length; i++) {
        hash ^= id.charCodeAt(i);
        hash *= 16777619; // Prime number
    }

    // Ensure the index is within the range of available colors
    const index = Math.abs(hash) % colors.length;

    return colors[index];
};
//-------------------------------------------------------------------------------------------------------------------------------

const OrderStats = () => {

    const { id = '' } = useParams<{ id: string }>();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [data, loading] = useFetch(useCallback(async () => {
        const response = await (new OrderService).getOrderContracts({ filter_filters: { order: id } });
        return response.getResponseData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]));

    return (
        <OrderProfileLayout>
            {!loading
                ? ((data?.data?.length > 0)
                    ? (
                        <>
                            <ContractStatusesCharts />

                            <ContractTutorsAndSchedules />

                            <ProductsTreeChart />

                            <UsersAndNetworksCharts />

                            <EconomicMetrics />
                        </>
                    )
                    : (
                        <div className="text-center text-muted500 mt-10">
                            El pedido no tiene contratos asociados. Añade un contrato para ver las estadísticas.
                        </div>
                    )
                )
                : <></>
            }
        </OrderProfileLayout>
    );
};

export default OrderStats;